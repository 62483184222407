const fonts = {
  title: 3,
  h2: 1.75,
  h3: 1.5,
  h4: 1.25,
  p: 1,
  small: 0.875
}

const lineHeight = {
  title: 57,
  h1: 48,
  h2: 33,
  h3: 28,
  h4: 24,
  p: 19,
  small: 17
}

const fontWeight = {
  default: 500,
  small: 400,
  specific: 'bold'
} as const

const fontFamily = {
  default: 'Rubik',
  specific: 'Work sans'
}

export { fonts, lineHeight, fontWeight, fontFamily }
