import styled from 'styled-components'

type DividerProps = {
  readonly margin: string
}

export const Divider = styled.hr<DividerProps>`
    width: 100%;
    margin: ${({ margin }) => margin};
`
