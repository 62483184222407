import * as S from './styles'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'
import TrashCan from 'src/assets/icons/news/trashCan.svg'
import KebabButton from 'src/components/Button/KebabButton'

const HighlightManagementDropdown = (props: any) => {
    const { item, setOpenDeleteModal, setIdDelete } = props
    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(1)

    const handleDelete = () => {
        setIdDelete(item.id)
        setOpenDeleteModal(true)
    }

    return (
        <>
            <S.DropdownContainer marginTop={'45px'}>
                <KebabButton dotSize={'20'} size={'small'} {...buttonProps} />
                <div
                    className={`dropdown ${isOpen ? 'visible' : ''}`}
                    role="menu"
                >
                    <a {...itemProps[1]} onClick={() => handleDelete()}>
                        <img src={TrashCan} />
                        Deletar
                    </a>
                </div>
            </S.DropdownContainer>
        </>
    )
}

export default HighlightManagementDropdown
