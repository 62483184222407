import { AxiosResponse } from 'axios'
import { webApi } from 'src/services/api/config'

/**
 * Gets all users with an active contract
 * @returns list of users
 */
export const getUsers = async (): Promise<AxiosResponse> => {
  return await webApi.get('api/users')
}
