import * as styles from './styles'

import { useTranslation } from 'react-i18next'
import { DesktopButton } from 'src/components/Button'
import ContactBox from './ContactBox'
import Typography from 'src/components/Typography'
import { postgraduatePromoBannerLenovo } from 'src/assets/images'

const Information = (props: any) => {
  // @TODO: Leia mais

  const { t } = useTranslation()

  return (
    <>
      <styles.TitleContainer>
        <styles.PromoImg
          style={{ marginBottom: '3.5rem' }}
          src={postgraduatePromoBannerLenovo}
        ></styles.PromoImg>
        <styles.TabTitle>{t('news.postgraduate.ufc-lenovo')}</styles.TabTitle>
        <styles.Title>{t('news.postgraduate.info.tab-title')}</styles.Title>
      </styles.TitleContainer>

      <styles.ContentContainer>
        <styles.BasicList>
          <li>
            <strong>{t('news.postgraduate.info.c1')}</strong>: 400 h/a (25
            créditos)
          </li>
          <li>
            <strong>{t('news.postgraduate.info.c2')}</strong>: 400 (25 créditos)
          </li>
          <li>
            <strong>{t('news.postgraduate.info.c3')}</strong>: 32 (2 créditos){' '}
          </li>
          <li>
            <strong>{t('news.postgraduate.info.c4')}</strong>: 96 (6 créditos)
          </li>
          <li>
            <strong>{t('news.postgraduate.info.total')}</strong>: 528 h/a (33
            créditos)
          </li>
          <li>
            <strong>{t('news.postgraduate.info.options')}</strong>: SEGUNDAS A
            SEXTAS, MANHÃ E TARDE.
          </li>
          <li>
            <strong>{t('news.postgraduate.info.spots')}</strong>: 10 vagas serão
            destinadas a ampla concorrência e 1 vaga será destinada a servidores
            e docentes da UFC.
          </li>
        </styles.BasicList>
      </styles.ContentContainer>

      <styles.Separator />

      {/* @TODO: Leia Mais */}
      <styles.ContentContainer>
        <styles.Title>{t('news.postgraduate.intro.info.title')}</styles.Title>
        <styles.Paragraph>{t('news.postgraduate.info.p1')}</styles.Paragraph>
        <styles.Paragraph>{t('news.postgraduate.info.p2')}</styles.Paragraph>
        <styles.DotList>
          <li>
            Desenvolvimento de softwares para assegurar a privacidade de dados;
          </li>
          <li>
            Desenvolvimento de softwares para a conformidade dos sistemas com a
            legislação vigente (LGPD, etc);
          </li>
          <li>
            Desenvolvimento de softwares para análise automática da segurança de
            bancos de dados;
          </li>
          <li>
            Desenvolvimento de softwares para análise automática da segurança de
            smartphones e dispositivos portáteis;
          </li>
          <li>
            Desenvolvimento de softwares para análise automática da segurança de
            redes de computadores;
          </li>
          <li>Desenvolvimento de softwares para detecção de intrusão;</li>
          <li>
            Desenvolvimento de softwares para aplicação automática de “patches”
            de segurança;
          </li>
          <li>
            Desenvolvimento de scripts e metodologias para a realização de
            testes de intrusão (pentests);
          </li>
          <li>Desenvolvimento de planos de segurança cibernética;</li>
          <li>Desenvolvimento de planos de gestão de riscos;</li>
          <li>Desenvolvimento de planos de resposta a incidentes;</li>
          <li>Desenvolvimento de software seguro;</li>
          <li>
            Refatoração de código para contornar vulnerabilidades ou problemas
            de segurança;
          </li>
          <li>Auditoria de sistemas informatizados e bancos de dados;</li>
          <li>
            Administração de ambientes computacionais, implantando e
            documentando rotinas, bem como controlando os níveis de serviço de
            sistemas operacionais, banco de dados e redes;
          </li>
          <li>Análise de códigos maliciosos;</li>
          <li>
            Desenvolvimento de antivírus utilizando inteligência artificial;
          </li>
          <li>
            Desenvolver guias de melhores práticas voltados para desenvolvimento
            de código seguro, segurança de bancos de dados, privacidade de
            dados, realização de testes de intrusão, monitoramento de
            vulnerabilidades, dentre outros.
          </li>
        </styles.DotList>
      </styles.ContentContainer>

      <styles.Separator />

      <styles.ContentContainer>
        <styles.Title>{t('news.postgraduate.info.obg')}</styles.Title>
        <styles.Paragraph>
          Capacitar os profissionais das áreas de Tecnologia da Informação (TI)
          e afins para que possam atuar na segurança cibernética de instituições
          públicas ou privadas, utilizando metodologias e ferramentas
          reconhecidas com base nas melhores práticas fornecidas pela família
          ABNT ISO/IEC 27000.
        </styles.Paragraph>
      </styles.ContentContainer>

      <styles.ContentContainer>
        <styles.Title>{t('news.postgraduate.info.obe')}</styles.Title>
        <styles.DotList>
          <li>
            Capacitar os alunos para o desenvolvimento e gestão de projetos e
            planos de segurança cibernética, com base em normas internacionais e
            modelos de melhores práticas;
          </li>
          <li>
            Capacitar os estudantes para atuar na proteção de ambientes físicos,
            equipamentos, sistemas computacionais e da informação;
          </li>
          <li>
            Capacitar profissionais para aplicar mecanismos e controles que
            permitam minimizar os impactos relacionados a falhas na segurança
            cibernética;
          </li>
          <li>
            Permitir ao profissional compreender as implicações das legislações
            relacionadas a segurança e privacidade de dados no universo da
            tecnologia de informação e comunicação;
          </li>
          <li>
            Capacitar os alunos no desenvolvimento de aplicações seguras
            (software seguro);
          </li>
          <li>
            Desenvolver, nos profissionais, habilidades para identificar
            problemas, vulnerabilidades e riscos relacionados a segurança
            cibernética, bem como de propor soluções apropriadas;
          </li>
          <li>
            Formar profissionais de segurança cibernética com visão de negócios,
            processos e tecnologias;
          </li>
          <li>
            Preparar o profissional de TI para planejar, implementar e gerir
            soluções de segurança cibernética eficientes e inovadoras;
          </li>
          <li>
            Preparar o profissional para avaliar os aspectos estratégicos que
            envolvem segurança cibernética.
          </li>
        </styles.DotList>
      </styles.ContentContainer>

      <styles.Separator />
    </>
  )
}

export default Information
