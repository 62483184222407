import * as styles from './styles'

const Spinner = (props: any) => {
  const { heightContainer, size } = props
  return (
    <styles.SpinnerContainer heightContainer={heightContainer} size={size}>
      <styles.StyledSpinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </styles.StyledSpinner>
    </styles.SpinnerContainer>
  )
}

export default Spinner
