import { useHistory } from 'react-router-dom'
import * as S from '../../Sidebar/styles'
import useInput from 'src/hooks/useInput'
import SearchIcon from 'src/assets/icons/news/search.svg'
import ClearIcon from 'src/assets/icons/news/clear.svg'
import { useEffect } from 'react'

const Sidebar = ({ isOpen, toggleSidebar, search }: any) => {
  const history = useHistory()
  const text = useInput('')

  const handleClear = () => {
    text.setValue('')
  }

  const handleSearch = () => {
    const words = text.value.split(' ').filter((e: any) => e !== '')

    if (words.length > 0) {
      toggleSidebar()
      history.push('/news/home/search=' + words.join(' '))
    }
  }

  useEffect(() => {
    if (search) {
      text.setValue(search)
    }
  }, [search])

  return (
    <>
      <S.SidebarContainer isOpen={isOpen}>
        <S.SidebarWrapper>
          <S.InputWrapper>
            <input
              placeholder="Procurar por título de notícia"
              type="text"
              maxLength={100}
              onChange={text.onChange}
              value={text.value}
            />
            <S.ClearIcon src={ClearIcon} onClick={handleClear} />
            <S.SearchIcon src={SearchIcon} onClick={handleSearch} />
          </S.InputWrapper>
          <S.SidebarMenu>
            <S.Link onClick={toggleSidebar} to="/news/home">
              Página Inicial
            </S.Link>
            <S.Link onClick={toggleSidebar} to="/news/careers">
              Vagas
            </S.Link>
            <S.Link onClick={toggleSidebar} to="/rsc">
              Pós-Graduação
            </S.Link>
          </S.SidebarMenu>
        </S.SidebarWrapper>
      </S.SidebarContainer>
    </>
  )
}

export default Sidebar
