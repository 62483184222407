/* eslint-disable multiline-ternary */
/* eslint-disable prettier/prettier */
import { Camera } from '@styled-icons/bootstrap/Camera'
import * as S from './styles'
import { SelectButton } from 'src/components/Button'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { categoriesRequest } from 'src/store/ducks/categories/actions'
import { postsRequest } from 'src/store/ducks/news/actions'
import {
  tagsRequest,
  createTagRequest,
  tagsListRequest
} from 'src/store/ducks/tags/actions'
import { useHistory } from 'react-router-dom'
import Modal, { ModalRequired, ModalRecuse } from 'src/components/Modal'
import Spinner from 'src/components/Spinner'
import usePersistedState from 'src/hooks/usePersistedState'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-build-classic-base64-upload'
import useInput from 'src/hooks/useInput'
import { webApi } from 'src/services/api/config'
import { showToast } from 'src/utils/Toast'
import { formatDateToBack } from 'src/utils/Date/formatDateToBack'
import Select from 'react-select'
import {
  DRAFT_STATUS,
  RECUSED_STATUS,
  PENDING_APPROVAL_STATUS,
  PUBLISHED_STATUS,
  PENDING_PUBLISHED_STATUS,
  PUBLISHED_SCREEN,
  MY_NEWS_SCREEN,
  CLOSED_STATUS,
  PENDING_APPROVAL_SCREEN,
  PENDING_PUBLISHED_SCREEN
} from 'src/components/NewsModule/constants'
import Typography from 'src/components/Typography'
import { SupportBilingual } from 'src/components/NewsModule/AddHighlight/index'
import { Input } from 'src/components/Inputs/Input'
import OpenNewTab from 'src/assets/icons/general/openNewTab.svg'
import HighlightPreviewModal from 'src/components/Modal/HighlightPreviewModal'
import translateCategoryToUrl from 'src/utils/News/translateCategoryToUrl'
import { ImageInput } from 'src/components/Inputs/ImageInput'
import {
  sendEmailApprovers,
  sendEmailReviewers
} from 'src/components/NewsModule/Api/api'
import { getUsers } from 'src/services/api/WebApi/Users/users'
import { getPost } from 'src/services/api/WebApi/NewsModule/newsModule'

const AddNewsContent = props => {
  const { id, context } = props
  const [post, setPost] = useState({})
  const [users, setUsers] = useState([])

  const [loading, setLoading] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const [user, setUser] = usePersistedState('auth-token', '')
  const [prevPage, setPrevPage] = usePersistedState('prev-page', '')
  const [management, setManagement] = usePersistedState('management', {
    access: false,
    link: '/'
  })

  const [openModalApprove, setOpenModalApprove] = useState(false)
  const [openModalPublish, setOpenModalPublish] = useState(false)
  const [openModalRecuse, setOpenModalRecuse] = useState(false)
  const [selectTags, setSelectTags] = useState([])
  const [justifyText, setJustifyText] = useState('')

  const handleChangeSelect = e => {
    setSelectTags(Array.isArray(e) ? e : [])
  }

  const fetchData = async () => {
    try {
      const postResponse = await getPost(id)
      const usersResponse = await getUsers()

      if (postResponse.status === 404) {
        history.push('/notFound')
      } else {
        setPost(postResponse.data)
      }

      setUsers(usersResponse.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    dispatch(categoriesRequest())
    dispatch(tagsRequest())
    dispatch(tagsListRequest())

    if (context !== 'Add') {
      fetchData()
    }
  }, [])

  const { categories } = useSelector(state => state.categories)
  const { tags } = useSelector(state => state.tags)
  const { tagsList } = useSelector(state => state.tagsList)

  const [requiredModal, setRequiredModal] = useState(false)
  const [requiredTag, setRequiredTag] = useState(false)

  const [requiredImage, setRequiredImage] = useState(false)

  const text = useInput('')
  const title = useInput('')
  const author = useInput('')
  const alt = useInput('')

  const styleHtml =
    '<style>figcaption{text-align: center; font-style: italic; color: #000000; font-size: 14px;} .image{margin: 0 auto;} img{max-width: 100%;} blockquote{border-left: 5px solid #ccc; font-style: italic; padding-top: 8px;} ul,ol,blockquote{padding-left: 40px;} li{padding-top: 8px;}br{display: inline;}</style>'
  const styleHtmlCareers =
    '<style>ul,ol{padding: 0 0 48px 32px;} li{padding-top: 16px;}br{display: inline;}</style>'
  const educationHtml = '<p><b>Formação Exigida</b></p>'
  const education = useInput('')
  const assignmentsHtml = '<p><b>Atividades a serem realizadas</b></p>'
  const assignments = useInput('')
  const requirementsHtml = '<p><b>Requisitos Obrigatórios</b></p>'
  const requirements = useInput('')
  const desirableHtml = '<p><b>Requisitos Desejáveis</b></p>'
  const desirable = useInput('')
  const salaryHtml = '<p><b>Salário</b></p>'
  const salary = useInput('')
  const benefitsHtml = '<p><b>Benefícios</b></p>'
  const benefitsCltHtml =
    '<ul><li>Plano de saúde e Plano odontológico sem coparticipação</li><li>Vale alimentação</li><li>Recesso de fim de ano sem desconto nas férias</li><li>Day off no dia do aniversário</li><li>40 horas semanais em horários flexíveis</li></ul>'
  const benefitsInternHtml =
    '<ul><li>Carga horária: 20, 25 ou 30 horas semanais em horários flexíveis</li><li>R$ 60,00 de auxílio transporte</li><li>Recesso de fim de ano sem desconto nas férias</li><li>Day off no dia do aniversário</li></ul>'
  const benefits = useInput(
    `<ul><li>Home office</li></ul><br>${benefitsCltHtml}<br>${benefitsInternHtml}`
  )
  const benefitsDefault = `<ul><li>Home office</li></ul><br>${benefitsCltHtml}<br>${benefitsInternHtml}`

  const currentValue = useInput({
    id: 0,
    title: 'Escolha a categoria',
    titlenEn: ''
  })

  const currentRegime = useInput({
    id: 0,
    title: 'Regime'
  })

  const currentAudience = useInput({
    id: 0,
    title: 'Público'
  })

  const checkEditPermission = screen => {
    const permission = user.generalPermissions.find(p => p.screen === screen)

    if (permission.allowEdit) {
      return true
    }
    return false
  }

  const canReview = (status, context) => {
    switch (context) {
      case 'ReviewApprove':
        return status === PENDING_APPROVAL_STATUS
          ? checkEditPermission(PENDING_APPROVAL_SCREEN)
          : false
      case 'ReviewPublish':
        return status === PENDING_PUBLISHED_STATUS
          ? checkEditPermission(PENDING_PUBLISHED_SCREEN)
          : false
      default:
        return false
    }
  }

  const canEdit = status => {
    switch (status) {
      case CLOSED_STATUS:
        return checkEditPermission(PUBLISHED_SCREEN)
      case PUBLISHED_STATUS:
        return checkEditPermission(PUBLISHED_SCREEN)
      case PENDING_APPROVAL_STATUS:
        return false
      case PENDING_PUBLISHED_STATUS:
        return false
      default:
        return checkEditPermission(MY_NEWS_SCREEN)
    }
  }

  const [highlightCheckbox, setHighlightCheckbox] = useState(false)

  const handleCheckbox = ev => {
    setHighlightCheckbox(!highlightCheckbox)
  }

  const [valuesEN, setValuesEN] = useState({
    titleEN: ''
  })

  const [titleENRequired, setTitleENRequired] = useState(false)

  const handleChangeEn = (name, value) => {
    setValuesEN({ ...valuesEN, [name]: value })
  }

  const verifyFields = () => {
    let res = true

    if (highlightCheckbox && !valuesEN.titleEN.length) {
      setTitleENRequired(true)
      res = false
    }

    return res
  }

  const handleSubmitPublish = () => {
    if (verifyFields()) {
      setOpenModalPublish(!openModalPublish)
    }
  }

  useEffect(() => {
    if (
      post &&
      Object.keys(post).length !== 0 &&
      categories.length > 0 &&
      tags.length > 0 &&
      tagsList.length > 0 &&
      users.length > 0
    ) {
      if (
        (post.status === DRAFT_STATUS || post.status === RECUSED_STATUS) &&
        post.userId !== parseInt(user.id)
      ) {
        history.push('/notFound')
      }
      if (context === 'Edit' && !canEdit(post.status)) {
        history.push('/notFound')
      }
      if (
        (context === 'ReviewApprove' || context === 'ReviewPublish') &&
        !canReview(post.status, context)
      ) {
        history.push('/notFound')
      }

      const categoryPost = categories.find(p => p.id === post.newsCategoriesId)
      currentValue.setValue(categoryPost)
      title.setValue(post.title)
      text.setValue(post.text)
      alt.setValue(post.alt)
      author.setValue(post.author)
      values.image = post.path
      currentRegime.setValue(
        post.alt === 'CLT'
          ? { id: 1, title: 'CLT' }
          : { id: 2, title: 'ESTÁGIO' }
      )
      currentAudience.setValue(
        post.audience === 'Externo'
          ? { id: 1, title: 'Externo' }
          : { id: 2, title: 'Interno' }
      )

      if (categoryPost.title === 'Vaga') {
        const regexContent = /<ul>.*?<\/ul>/g
        const contentList = post.text.match(regexContent)
        const regexLabel = /<p>.*?<\/p>/g
        const labelList = post.text.match(regexLabel)

        const matchLabelContent = (label, content) => {
          switch (label) {
            case educationHtml:
              education.setValue(content)
              break
            case assignmentsHtml:
              assignments.setValue(content)
              break
            case requirementsHtml:
              requirements.setValue(content)
              break
            case desirableHtml:
              desirable.setValue(content)
              break
            case salaryHtml:
              salary.setValue(content)
              break
            case benefitsHtml:
              benefits.setValue(content)
              break
            default:
              break
          }
        }

        for (let i = 0; i < labelList.length; i++) {
          matchLabelContent(labelList[i], contentList[i])
        }
      } else {
        const PostTagList = tagsList.filter(p => p.newsId === post.id)
        const aux = []
        PostTagList.forEach(item => {
          const PostTag = tags.find(t => t.id === item.tagId)
          aux.push(PostTag)
        })
        setSelectTags(aux)
      }
      setLoading(false)
    }
  }, [post, categories, tagsList, tags, users])

  const openModal = modal => {
    switch (modal) {
      case 'publish':
        handleSubmit('publish')
        break
      case 'approve':
        handleSubmit('toApprove')
        break
      case 'draft':
        handleSubmit('draft')
        break
      case 'cancel':
        history.push(prevPage.length > 0 ? prevPage : management.link)
        break
      case 'cancelReviewApprove':
        history.push('/news/management/pending-approval')
        break
      case 'cancelReviewPublish':
        history.push('/news/management/pending-published')
        break
      case 'Edit':
        handleSubmit('Edit')
        break
      case 'ReviewApprove':
        handleSubmit('ReviewApprove')
        break
      case 'ReviewPublish':
        handleSubmit('ReviewPublish')
        break
      case 'ReviewRecuse':
        handleSubmit('ReviewRecuse')
        break
      default:
        break
    }
  }

  const clearForm = () => {
    currentValue.setValue({
      id: 0,
      title: 'Escolha a categoria',
      titleEN: ''
    })

    values.image = ''

    text.setValue('')
    alt.setValue('')
    title.setValue('')
    author.setValue('')
  }

  const onSubmit = () => {
    let res = true

    if (currentValue.value.id === 0) {
      currentValue.setRequiredValue(true)
      res = false
    }
    if (!title.value) {
      title.setRequiredValue(true)
      res = false
    }

    if (currentValue.value.title === 'Vaga') {
      if (currentRegime.value.id === 0) {
        currentRegime.setRequiredValue(true)
        res = false
      }
      if (!education.value) {
        education.setRequiredValue(true)
        res = false
      }
      if (!requirements.value) {
        requirements.setRequiredValue(true)
        res = false
      }
      if (benefits.value === benefitsDefault || benefits.value.length === 0) {
        benefits.setRequiredValue(true)
        res = false
      }
    }

    if (currentValue.value.title !== 'Vaga') {
      if (!values.image) {
        res = false
        setRequiredImage(true)
      }

      if (!text.value) {
        text.setRequiredValue(true)
        res = false
      }

      if (currentAudience.value.id === 0) {
        currentAudience.setRequiredValue(true)
        res = false
      }

      if (selectTags.length === 0) {
        res = false
        setRequiredTag(true)
      }
    }

    return res
  }

  useEffect(() => {
    if (currentRegime.value.id !== 0) {
      currentRegime.setRequiredValue(false)
    }

    if (currentValue.value.id !== 0) {
      currentValue.setRequiredValue(false)
    }

    if (text.value.length !== 0) {
      text.setRequiredValue(false)
    }

    if (education.value.length !== 0) {
      education.setRequiredValue(false)
    }

    if (requirements.value.length !== 0) {
      requirements.setRequiredValue(false)
    }
    if (benefits.value !== benefitsDefault && benefits.value.length > 0) {
      benefits.setRequiredValue(false)
    }
    if (selectTags.length !== 0) {
      setRequiredTag(false)
    }
    if (values.image) {
      setRequiredImage(false)
    }
    if (currentAudience.value.id !== 0) {
      currentAudience.setRequiredValue(false)
    }
  }, [currentValue, text, selectTags.length])

  const makeCareersHtml = () => {
    let assignmentText = ''
    let desirableText = ''
    let salaryText = ''
    if (
      assignments.value.concat(assignmentsHtml).length > assignmentsHtml.length
    ) {
      assignmentText = assignmentsHtml.concat(assignments.value)
    }
    if (desirable.value.concat(desirableHtml).length > desirableHtml.length) {
      desirableText = desirableHtml.concat(desirable.value)
    }
    if (salary.value.length > 0) {
      salaryText = salaryHtml.concat(salary.value)
    }
    const newText = ''
      .concat(styleHtmlCareers)
      .concat(educationHtml)
      .concat(education.value)
      .concat(assignmentText)
      .concat(requirementsHtml)
      .concat(requirements.value)
      .concat(desirableText)
      .concat(salaryText)
      .concat(benefitsHtml)
      .concat(benefits.value)

    return newText
  }

  const handleSubmit = async type => {
    const canSubmit = onSubmit()
    const formData = new FormData()

    if (canSubmit) {
      if (
        type === 'Edit' ||
        type === 'ReviewApprove' ||
        type === 'ReviewRecuse' ||
        type === 'ReviewPublish'
      ) {
        formData.append('news.id', post.id)
      }
      formData.append('news.title', title.value)
      formData.append('news.author', author.value)
      formData.append(
        'news.text',
        currentValue.value.title === 'Vaga'
          ? makeCareersHtml()
          : `${styleHtml}${text.value}`
      )
      formData.append(
        'news.createdAt',
        type === 'Edit' ||
          type === 'ReviewApprove' ||
          type === 'ReviewRecuse' ||
          type === 'ReviewPublish'
          ? post.createdAt
          : formatDateToBack(new Date())
      )
      formData.append(
        'news.publishedAt',
        type === 'Edit' && post.publishedAt
          ? post.publishedAt
          : currentValue.value.title === 'Vaga' || type === 'ReviewPublish'
          ? formatDateToBack(new Date())
          : ''
      )
      formData.append(
        'news.lastEdit',
        type === 'Edit' ||
          type === 'ReviewApprove' ||
          type === 'ReviewRecuse' ||
          type === 'ReviewPublish'
          ? formatDateToBack(new Date())
          : ''
      )
      formData.append('news.path', values.image)
      formData.append(
        'news.alt',
        currentValue.value.title === 'Vaga'
          ? currentRegime.value.title
          : !alt.value
          ? ''
          : alt.value
      )
      formData.append(
        'news.status',
        type === 'toApprove'
          ? PENDING_APPROVAL_STATUS
          : type === 'draft'
          ? DRAFT_STATUS
          : type === 'publish'
          ? PUBLISHED_STATUS
          : type === 'Edit'
          ? post.status
          : type === 'ReviewApprove'
          ? PENDING_PUBLISHED_STATUS
          : type === 'ReviewRecuse'
          ? RECUSED_STATUS
          : type === 'ReviewPublish'
          ? PUBLISHED_STATUS
          : ''
      )
      formData.append('news.scheduled', '')
      formData.append('news.newsCategoriesId', currentValue.value.id.toString())
      formData.append('news.fileData', '')
      const user = JSON.parse(localStorage.getItem('auth-token'))
      formData.append(
        'news.userId',
        type === 'Edit' ||
          type === 'ReviewApprove' ||
          type === 'ReviewRecuse' ||
          type === 'ReviewPublish'
          ? post.userId
          : user.id
      )
      formData.append('news.audience', currentAudience.value.title)

      if (
        selectTags.length > 0 &&
        (type === 'Edit' ||
          type === 'ReviewApprove' ||
          type === 'ReviewRecuse' ||
          type === 'ReviewPublish')
      ) {
        selectTags.forEach((item, idx) => {
          formData.append(`tags[${idx}].tagId`, item.id)
        })
      }

      if (
        type !== 'Edit' &&
        type !== 'ReviewApprove' &&
        type !== 'ReviewRecuse' &&
        type !== 'ReviewPublish'
      ) {
        // TODO: Transfer this request to API layer
        const response = await webApi.post('api/site/news', formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        selectTags.forEach(item => {
          const tagsFormData = new FormData()
          tagsFormData.append('NewsId', response.data.toString())
          tagsFormData.append('TagId', item.id.toString())
          dispatch(createTagRequest(tagsFormData))
        })

        if (type === 'toApprove') {
          sendEmailApprovers(
            title.value,
            currentValue.value.title,
            user.name,
            user.id
          )
        }
      } else {
        const editPost = async data => {
          // TODO: Transfer this request to API layer
          await webApi
            .put('api/site/news', data, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(response => {
              dispatch(postsRequest())
            })
            .catch(err => {
              console.log(err)
            })
        }
        const sendEmail = async (
          name,
          email,
          status,
          titleNews,
          categoryNews,
          date,
          justifyText,
          editorName
        ) => {
          try {
            const body = {
              name,
              email,
              status,
              titleNews,
              categoryNews,
              date,
              justifyText,
              editorName
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await fetch(
              process.env.REACT_APP_EMAIL + 'api/news/ApproveOrRefuseNews',
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
              }
            )
          } catch (error) {
            console.log(error)
          }
        }

        formData.append('SB.TitleEn', valuesEN.titleEN)
        formData.append(
          'SB.Url',
          `${process.env.REACT_APP_LSBD_SITE}news/${translateCategoryToUrl(
            currentValue.value.title
          )}/${post.id}`
        )
        formData.append('SB.ButtonLabel', 'constants.heroButton.readMore')

        editPost(formData)

        if (type !== 'Edit') {
          const postUser = users.find(user => user.id === post.userId)
          sendEmail(
            postUser.name,
            postUser.email,
            type === 'ReviewApprove'
              ? 'Approved'
              : type === 'ReviewPublish'
              ? 'Published'
              : 'Recused',
            title.value,
            currentValue.value.title,
            new Date(),
            justifyText,
            user.name
          )
        }

        if (type === 'ReviewApprove') {
          sendEmailReviewers(
            title.value,
            currentValue.value.title,
            users.find(user => user.id === post.userId).name,
            user.name
          )
        }
      }

      switch (type) {
        case 'toApprove':
          history.push('/news/management/my-news')
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia enviada para aprovação.'
                : 'Vaga enviada para aprovação.'
          })
          break
        case 'draft':
          history.push('/news/management/my-news')
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia salva com sucesso.'
                : 'Vaga salva com sucesso.'
          })
          break
        case 'Edit':
          history.push(prevPage.length > 0 ? prevPage : management.link)
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia editada com sucesso.'
                : 'Vaga editada com sucesso.'
          })
          break
        case 'ReviewApprove':
          history.push('/news/management/pending-approval')
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia enviada para aprovação de publicação.'
                : 'Vaga enviada para aprovação de publicação'
          })
          break
        case 'ReviewPublish':
          history.push('/news/management/pending-published')
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia publicada com sucesso.'
                : 'Vaga publicada com sucesso.'
          })
          break
        case 'ReviewRecuse':
          history.push(prevPage.length > 0 ? prevPage : management.link)
          showToast({
            type: 'success',
            message:
              currentValue.value.title !== 'Vaga'
                ? 'Notícia recusada com sucesso.'
                : 'Vaga recusada com sucesso.'
          })
          break
      }
      clearForm()
    } else {
      setRequiredModal(prev => !prev)
    }
  }

  const customConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'uploadImage',
      'blockQuote',
      '|',
      'undo',
      'redo'
    ],
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'http://'
    },
    image: {
      toolbar: []
    }
  }

  const [values, setValues] = useState({
    image: ''
  })

  const [openPreviewHighlightModal, setOpenPreviewHighlightModal] =
    useState(false)

  const canPreviewHighlight =
    currentValue.value.id !== 0 && title.value && values.image

  useEffect(() => {
    valuesEN.titleEN = ''
    setHighlightCheckbox(false)
  }, [currentAudience.value.id])

  useEffect(() => {
    valuesEN.titleEN = ''
    setTitleENRequired(false)
  }, [highlightCheckbox])

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  return (
    <>
      <HighlightPreviewModal
        showModal={openPreviewHighlightModal}
        setShowModal={setOpenPreviewHighlightModal}
        image={values.image}
        labelOrange={currentValue.value.title}
        labelOrangeEN={currentValue.value.titleEN}
        title={title.value}
        titleEN={valuesEN.titleEN ? valuesEN.titleEN : title.value}
        additionalText={''}
        additionalTextEN={''}
        buttonLabel={'constants.heroButton.readMore'}
        hasButtons={false}
      />
      <ModalRecuse
        showModal={openModalRecuse}
        setShowModal={setOpenModalRecuse}
        buttonLeft={() => setOpenModalRecuse(!openModalRecuse)}
        buttonRight={() => openModal('ReviewRecuse')}
        title={'Recusar publicação da notícia?'}
        description={
          'Você tem certeza que deseja recusar? Você não poderá mais visualizar a notícia após a ação de recusar.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'recusar'}
        setJustify={setJustifyText}
      />
      <Modal
        showModal={openModalApprove}
        setShowModal={setOpenModalApprove}
        buttonLeft={() => setOpenModalApprove(!openModalApprove)}
        buttonRight={() => openModal('ReviewApprove')}
        title={'Aprovar publicação da notícia?'}
        description={
          'Esta notícia será enviada para revisão de publicação. Após aprovar não poderá mais editar a publicação.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'aprovar'}
      />
      <Modal
        showModal={openModalPublish}
        setShowModal={setOpenModalPublish}
        buttonLeft={() => setOpenModalPublish(!openModalPublish)}
        buttonRight={() => openModal('ReviewPublish')}
        title={'Aprovar publicação da notícia?'}
        description={
          'Aprovar e publicar no portal de notícias. Você ainda poderá editar esta notícia após a sua publicação.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'publicar agora'}
      />
      <ModalRequired
        showModal={requiredModal}
        setShowModal={setRequiredModal}
      />
      {context !== 'Add' && loading ? (
        <Spinner heightContainer="100vh" size={'60px'} />
      ) : (
        <S.Content>
          <S.ContentWrapper>
            <S.Header>
              <SelectButton
                width={280}
                currentValue={currentValue.value}
                setValue={currentValue.setValue}
                list={categories.filter(
                  category =>
                    category.title === 'Blog' ||
                    category.title === 'Vaga' ||
                    category.title === 'Evento' ||
                    category.title === 'Publicação' ||
                    category.title === 'Defesa' ||
                    category.title === 'Prêmio'
                )}
                colorBackground="#2E2972"
                colorText="#ffffff"
              />
              {currentValue.requiredValue && (
                <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
              )}
              {currentValue.value.title === 'Vaga' ? (
                <>
                  <input
                    className="title"
                    name="title"
                    placeholder="Título da vaga"
                    onChange={title.onChange}
                    value={title.value}
                    maxLength={100}
                  />
                  {title.requiredValue && (
                    <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                  )}
                  <S.RegimeContainer>
                    <S.LabelRegime>Selecione o regime</S.LabelRegime>
                    <SelectButton
                      width={280}
                      className="regimeSelect"
                      colorBackground="#F2F2F2"
                      colorText="#000000"
                      currentValue={currentRegime.value}
                      setValue={currentRegime.setValue}
                      list={[
                        { id: 1, title: 'CLT' },
                        { id: 2, title: 'ESTÁGIO' }
                      ]}
                    />
                    {currentRegime.requiredValue && (
                      <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                    )}
                  </S.RegimeContainer>
                </>
              ) : (
                <>
                  <input
                    className="title"
                    name="title"
                    placeholder="Escreva o título"
                    onChange={title.onChange}
                    value={title.value}
                    maxLength={100}
                  />
                  {title.requiredValue && (
                    <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                  )}
                  <S.RegimeContainer>
                    <S.LabelRegime>Selecione o público</S.LabelRegime>
                    <SelectButton
                      width={280}
                      className="regimeSelect"
                      colorBackground="#F2F2F2"
                      colorText="#000000"
                      currentValue={currentAudience.value}
                      setValue={currentAudience.setValue}
                      list={[
                        { id: 1, title: 'Externo' },
                        { id: 2, title: 'Interno' }
                      ]}
                    />
                    {currentAudience.requiredValue && (
                      <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                    )}
                  </S.RegimeContainer>
                  <input
                    className="author"
                    name="author"
                    placeholder="Insira o nome do autor"
                    onChange={author.onChange}
                    value={author.value !== 'null' ? author.value : ''}
                  />
                  {author.requiredValue && (
                    <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                  )}
                </>
              )}
            </S.Header>
            <S.Body>
              {currentValue.value.title === 'Vaga' ? (
                <>
                  <S.InputContainer>
                    <S.LabelEditorContainer>
                      <S.Label>Formação Exigida: *</S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          education.setValue(data)
                        }}
                        data={
                          context === 'Add'
                            ? '<ul><li></li></ul>'
                            : education.value
                        }
                        config={customConfig}
                      />
                      {education.requiredValue && (
                        <p style={{ color: 'red' }}>
                          Este campo é obrigatório!
                        </p>
                      )}
                    </S.LabelEditorContainer>

                    <S.LabelEditorContainer>
                      <S.Label>
                        Atividades a serem realizadas: (Opcional)
                      </S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          assignments.setValue(data)
                        }}
                        data={
                          context === 'Add'
                            ? '<ul><li></li></ul>'
                            : assignments.value
                        }
                        config={customConfig}
                      />
                    </S.LabelEditorContainer>

                    <S.LabelEditorContainer>
                      <S.Label>Requisitos Obrigatórios: *</S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          requirements.setValue(data)
                        }}
                        data={
                          context === 'Add'
                            ? '<ul><li></li></ul>'
                            : requirements.value
                        }
                        config={customConfig}
                      />
                      {requirements.requiredValue && (
                        <p style={{ color: 'red' }}>
                          Este campo é obrigatório!
                        </p>
                      )}
                    </S.LabelEditorContainer>

                    <S.LabelEditorContainer>
                      <S.Label>Requisitos Desejáveis: (Opcional)</S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          desirable.setValue(data)
                        }}
                        data={
                          context === 'Add'
                            ? '<ul><li></li></ul>'
                            : desirable.value
                        }
                        config={customConfig}
                      />
                    </S.LabelEditorContainer>

                    <S.LabelEditorContainer>
                      <S.Label>Salário: (Opcional)</S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          salary.setValue(data)
                        }}
                        data={
                          context === 'Add'
                            ? '<ul><li></li></ul>'
                            : salary.value
                        }
                        config={customConfig}
                      />
                    </S.LabelEditorContainer>

                    <S.LabelEditorContainer>
                      <S.Label>Benefícios: *</S.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          benefits.setValue(data)
                        }}
                        data={benefits.value}
                        config={customConfig}
                      />
                      {benefits.requiredValue &&
                      benefits.value !== benefitsDefault ? (
                        <p style={{ color: 'red' }}>
                          Este campo é obrigatório!
                        </p>
                      ) : benefits.requiredValue &&
                        benefits.value === benefitsDefault ? (
                        <p style={{ color: 'red' }}>
                          Você precisa realizar alterações nesse campo!
                        </p>
                      ) : (
                        <></>
                      )}
                    </S.LabelEditorContainer>

                    <S.AplicateContainer>
                      <S.Text>Como aplicar</S.Text>
                      <S.JobWrapper>
                        <S.TextJob>
                          Envie seu currículo para o email:{' '}
                        </S.TextJob>
                        <S.Email>jobs@lsbd.ufc.br</S.Email>
                      </S.JobWrapper>
                      <S.TextFooter>
                        Enviar e-mail com o assunto: [Seleção] {title.value} -
                        &lt;nome_candidato&gt;
                      </S.TextFooter>
                    </S.AplicateContainer>
                  </S.InputContainer>
                </>
              ) : (
                <>
                  <S.ImageContainer>
                    <ImageInput
                      required
                      margin={'24px 0 0'}
                      textInfo={
                        'Clique aqui para adicionar uma imagem (Resolução mínima 1280x720. Tamanho máximo 1MB)'
                      }
                      value={values.image}
                      valueName={'image'}
                      handleChange={handleChange}
                      maxSize={1000000}
                      minWidth={1280}
                      minHeight={720}
                      error={requiredImage}
                      setError={setRequiredImage}
                      errorMessage={'Campo obrigatório'}
                      maxSizeErrorMessage={
                        'Tamanho do arquivo é maior que 1MB*'
                      }
                      minResolutionErrorMessage={
                        'Resolução da imagem é menor que 1280x720*'
                      }
                    />
                    <input
                      placeholder="Adicione uma legenda"
                      name="alt"
                      onChange={alt.onChange}
                      value={alt.value}
                    />
                  </S.ImageContainer>
                  <S.InputContainer>
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        text.setValue(data)
                      }}
                      data={text.value}
                      config={customConfig}
                    />
                    {text.requiredValue && (
                      <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                    )}
                  </S.InputContainer>
                  <S.TagContainer>
                    <p className="tagsTitle">Tags:</p>
                    <Select
                      placeholder="Adicionar tag"
                      className="tagSelect"
                      isMulti
                      defaultValue={selectTags}
                      options={tags}
                      noOptionsMessage={() => 'Tag não encontrada'}
                      getOptionLabel={e => e.title}
                      getOptionValue={e => e.id}
                      onChange={handleChangeSelect}
                    />
                    {requiredTag && (
                      <p style={{ color: 'red' }}>Este campo é obrigatório!</p>
                    )}
                  </S.TagContainer>
                </>
              )}
              {context === 'ReviewPublish' &&
                currentValue.value.title !== 'Vaga' &&
                currentAudience.value.id === 1 && (
                  <S.AddHighlightContainer>
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: '12px'
                      }}
                      variant="pBlack"
                    >
                      Você pode adicionar essa publicação como destaque na
                      página inicial do site LSBD
                    </Typography>
                    <S.AddHighlightCheckboxWrapper
                      style={{
                        marginBottom: highlightCheckbox ? '24px' : '0'
                      }}
                    >
                      <S.AddHighlightCheckbox
                        type="checkbox"
                        value={highlightCheckbox}
                        onChange={handleCheckbox}
                      />
                      <Typography
                        style={{
                          fontWeight: 400,
                          marginBottom: '12px',
                          margin: 'auto 0'
                        }}
                        variant="pBlack"
                      >
                        Adicionar aos destaques da página inicial
                      </Typography>
                    </S.AddHighlightCheckboxWrapper>

                    {highlightCheckbox && (
                      <>
                        <SupportBilingual />
                        <Typography
                          style={{
                            fontWeight: 500,
                            margin: '32px 0 12px',
                            fontSize: '14px'
                          }}
                          variant="pBlack"
                        >
                          Título (em inglês) *
                        </Typography>
                        <Input
                          maxLength={100}
                          placeholder={
                            'Digite o título da sua publicação em inglês'
                          }
                          value={valuesEN.titleEN}
                          valueName={'titleEN'}
                          handleChange={handleChangeEn}
                          error={titleENRequired}
                          setError={setTitleENRequired}
                          errorMessage={'Campo obrigatório'}
                          whiteBackground={true}
                        />
                      </>
                    )}
                  </S.AddHighlightContainer>
                )}

              {canPreviewHighlight && currentValue.value.title !== 'Vaga' && (
                <S.PreviewHighlight>
                  <p>
                    Você pode visualizar como a publicação vai ser exibida na
                    página inicial:{' '}
                    <b onClick={() => setOpenPreviewHighlightModal(true)}>
                      Pré-visualizar publicação
                    </b>
                  </p>
                  <img src={OpenNewTab} />
                </S.PreviewHighlight>
              )}
            </S.Body>
            {id ? (
              context === 'Edit' ? (
                <S.Buttons>
                  <S.Cancel onClick={() => openModal('cancel')}>
                    Cancelar
                  </S.Cancel>
                  <S.SendToApprove onClick={() => openModal('Edit')}>
                    Salvar Alterações
                  </S.SendToApprove>
                </S.Buttons>
              ) : context === 'ReviewApprove' ? (
                <S.Buttons>
                  <S.Cancel onClick={() => openModal('cancelReviewApprove')}>
                    Voltar
                  </S.Cancel>
                  <S.SendToApprove
                    onClick={() => setOpenModalRecuse(!openModalRecuse)}
                  >
                    Recusar
                  </S.SendToApprove>
                  <S.SendToApprove
                    onClick={() => setOpenModalApprove(!openModalApprove)}
                  >
                    Aprovar
                  </S.SendToApprove>
                </S.Buttons>
              ) : (
                <S.Buttons>
                  <S.Cancel onClick={() => openModal('cancelReviewPublish')}>
                    Voltar
                  </S.Cancel>
                  <S.SendToApprove
                    onClick={() => setOpenModalRecuse(!openModalRecuse)}
                  >
                    Recusar
                  </S.SendToApprove>
                  <S.SendToApprove onClick={() => handleSubmitPublish()}>
                    Publicar
                  </S.SendToApprove>
                </S.Buttons>
              )
            ) : (
              <S.Buttons>
                <S.Cancel onClick={() => openModal('cancel')}>
                  Cancelar
                </S.Cancel>
                <S.Draft onClick={() => openModal('draft')}>Salvar</S.Draft>
                <S.SendToApprove onClick={() => openModal('approve')}>
                  enviar para aprovação
                </S.SendToApprove>
              </S.Buttons>
            )}
          </S.ContentWrapper>
        </S.Content>
      )}
    </>
  )
}

export default AddNewsContent
