export default function translateCategoryToUrl(title: string) {
    switch (title) {
        case 'Blog':
            return 'blog'
        case 'Evento':
            return 'events'
        case 'Vaga':
            return 'careers'
        case 'Publicação':
            return 'publications'
        case 'Defesa':
            return 'defenses'
        case 'Prêmio':
            return 'awards'
        default:
            return ''
    }
}
