import axios, { AxiosInstance } from 'axios'

/**
 * Axios instance to web API
 */
export const webApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 * Axios instance to email API
 */
export const emailsApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_EMAIL,
  headers: {
    'Content-Type': 'application/json'
  }
})
