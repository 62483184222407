import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import ListCareersContent from 'src/components/NewsModule/ListCareers'
import ModuleFooter from 'src/components/NewsModule/Footer'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

import NavbarMobile from 'src/components/Mobile/News/Navbar'
import useMedia from 'src/hooks/useMedia'
import usePersistedState from 'src/hooks/usePersistedState'

const ListCareers = () => {
  const { width } = useMedia()
  const [isOpen, setIsOpen] = React.useState(false)
  const [prevPage, setPrevPage] = usePersistedState('prev-page', '')

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPrevPage('/news/careers')
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        {width > maxMobileScreenWidth ? (
          <EditorHeader current="VAGA" />
        ) : (
          <NavbarMobile isOpen={isOpen} toggle={toggleSidebar} />
        )}
        <ListCareersContent />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default ListCareers
