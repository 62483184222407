import * as S from './styles'
import { Dispatch, SetStateAction, useState } from 'react'
import PlusMinus from 'src/components/PlusMinus'

type ModalConfigProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    title: string
    text: string
    highlightAmount: number
    fetchConfig: (a: number) => void
}

const HighlightConfigModal = ({
    showModal,
    setShowModal,
    highlightAmount,
    title,
    text,
    fetchConfig
}: ModalConfigProps) => {
    const [amount, setAmount] = useState(highlightAmount)

    const handleSave = () => {
        fetchConfig(amount)
        setShowModal(!showModal)
    }

    return (
        <>
            {showModal ? (
                <S.Background>
                    <S.ModalWrapper showModal={showModal}>
                        <S.ModalContent>
                            <h1>{title}</h1>
                            <p>{text}</p>
                            <PlusMinus
                                amount={amount}
                                min={3}
                                max={7}
                                setAmount={setAmount}
                            />
                            <div>
                                <S.ButtonLeft
                                    onClick={() => setShowModal(!showModal)}
                                >
                                    cancelar
                                </S.ButtonLeft>
                                <S.ButtonRight onClick={() => handleSave()}>
                                    salvar
                                </S.ButtonRight>
                            </div>
                        </S.ModalContent>
                        <S.CloseModalButton
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.ModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

export default HighlightConfigModal
