import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import { maxMobileScreenWidth } from 'src/utils/breakpoints'

interface SidebarProps {
  isOpen: boolean
}

export const SidebarContainer = styled.aside<SidebarProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #292566;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;

  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

  z-index: 998;
`
export const SidebarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 7.5rem;
`

export const SidebarMenu = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 24px;
  gap: 30px;
`

export const SidebarLink = styled(LinkS)`
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;

  &:hover {
    color: #fec074;
    transition: 0.2 ease-in-out;
  }
`

export const Link = styled(LinkR)`
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;

  &:hover {
    color: #fec074;
    transition: 0.2 ease-in-out;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px 24px;

  input {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    padding: 12px 85px 12px 16px;
  }
`

export const SearchIcon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  margin-right: 32px;
`

export const ClearIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  margin-right: 70px;
`
