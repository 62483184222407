import * as styles from './styles'
import { useEffect, useState } from 'react'
import {
    ResponsiveContainer,
    ContentContainer
} from 'src/components/Containers/containers'
import Typography from 'src/components/Typography'
import useInput from 'src/hooks/useInput'
import { useTranslation } from 'react-i18next'
import OrangeButton from 'src/components/Button/OrangeButton'
import emailSentImg from 'src/assets/images/password/emailSent.svg'
import ErrorIcon from 'src/assets/icons/password/error.svg'
import { sendForgotPasswordEmail } from 'src/services/api/EmailsApi/PasswordReset/forgotPassword'
import Spinner from 'src/components/Spinner'

const ForgotPasswordContent = () => {
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [emailSent, setEmailSent] = useState(false)
    const email = useInput('')
    const [isEmpty, setIsEmpty] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)

    const checkIsEmail = (email: string) => {
        if (
            email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            return true
        }
        setIsEmailInvalid(true)
        return false
    }

    const handleEmail = async (email: string) => {
        setLoading(true)
        if (email.length === 0) {
            setIsEmpty(true)
        }
        if (email.length > 0 && checkIsEmail(email)) {
            await sendForgotPasswordEmail({ email: email }).finally(() =>
                setLoading(false)
            )
            setEmailSent(true)
        }
        setLoading(false)
    }

    useEffect(() => {
        setIsEmpty(false)
        setIsEmailInvalid(false)
    }, [email.value])

    return (
        <ResponsiveContainer color={''}>
            <ContentContainer>
                {emailSent ? (
                    <>
                        <EmailSent />
                    </>
                ) : (
                    <styles.ContentWrapper>
                        <Typography
                            variant="h1DarkBlue"
                            style={{ textAlign: 'center' }}
                        >
                            {t('password.forgot')}
                        </Typography>
                        <Typography
                            variant="pBlack60"
                            style={{ textAlign: 'center', margin: '48px 0' }}
                        >
                            {t('password.dontWorry')}
                        </Typography>
                        <styles.InputEmailWrapper>
                            <Typography
                                variant="pDarkBlue"
                                style={{ fontWeight: 'bold' }}
                            >
                                {t('password.insertEmail')}
                            </Typography>
                            <styles.InputEmail
                                isEmpty={isEmpty || isEmailInvalid}
                                type="email"
                                onChange={email.onChange}
                                maxLength={50}
                                placeholder={t('password.inputEmail')}
                            />
                            {isEmpty && (
                                <styles.RequiredWrapper>
                                    <styles.Icon src={ErrorIcon} />
                                    <Typography variant="pBlack">
                                        {t('contato.obrigatorio')}
                                    </Typography>
                                </styles.RequiredWrapper>
                            )}
                            {isEmailInvalid && !isEmpty && (
                                <styles.RequiredWrapper>
                                    <styles.Icon src={ErrorIcon} />
                                    <Typography variant="pBlack">
                                        {t('password.emailInvalid')}
                                    </Typography>
                                </styles.RequiredWrapper>
                            )}
                        </styles.InputEmailWrapper>
                        <styles.ButtonWrapper>
                            <OrangeButton
                                title={t('password.request')}
                                onClick={() => handleEmail(email.value)}
                                disabled={loading}
                            ></OrangeButton>
                        </styles.ButtonWrapper>
                    </styles.ContentWrapper>
                )}
            </ContentContainer>
        </ResponsiveContainer>
    )
}

const EmailSent = () => {
    const { t } = useTranslation()

    return (
        <>
            <styles.EmailImageWrapper>
                <styles.EmailImage src={emailSentImg} />
            </styles.EmailImageWrapper>
            <Typography
                variant="h1DarkBlue"
                style={{ textAlign: 'center', margin: '80px 0 48px' }}
            >
                {t('password.sentEmail')}
            </Typography>
            <Typography
                variant="pBlack60"
                style={{ textAlign: 'center', marginBottom: '80px' }}
            >
                {t('password.emailInstructions')}
            </Typography>
        </>
    )
}

export default ForgotPasswordContent
