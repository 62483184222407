import {
    DesktopBtn,
    MobileBtn,
    MobileSec,
    SelectedButton,
    SelectWrapper
} from './styles'
import React, { useState } from 'react'
import { ChevronDown } from '@styled-icons/boxicons-regular'

import Typography from '../Typography'

interface ButtonProps {
    children: React.ReactNode
    active: boolean
}

interface SelectButtonProps {
    list: Array<{
        id: number
        title: string
    }>
    currentValue: {
        id: number
        title: string
        titleEN?: string
    }
    colorBackground: string
    colorText: string
    setValue: (value: any) => void
    disabled?: boolean
    width: number
}

export const DesktopButton: React.FC<
    ButtonProps & React.HTMLProps<HTMLButtonElement>
> = ({ children, active = true }) => {
    return (
        <>
            <DesktopBtn active={active}>
                <Typography variant={active ? 'buttonText' : 'buttonInactive'}>
                    {children}
                </Typography>
            </DesktopBtn>
        </>
    )
}

export const MobileButton: React.FC<
    ButtonProps & React.HTMLProps<HTMLButtonElement>
> = ({ children }: ButtonProps) => {
    return (
        <>
            <MobileBtn>
                <Typography variant="mobileButton">{children}</Typography>
            </MobileBtn>
        </>
    )
}

export const MobileSecondary = ({ children, active = true }: ButtonProps) => {
    return (
        <>
            <MobileSec active={active}>
                <Typography
                    variant={active ? 'mobileButton' : 'mobileButtonInactive'}
                >
                    {children}
                </Typography>
            </MobileSec>
        </>
    )
}

export const SelectButton = ({
    currentValue,
    setValue,
    list,
    colorBackground,
    colorText,
    disabled,
    width
}: SelectButtonProps) => {
    const [active, isActive] = useState<boolean>(false)

    const toggleSelect = () => {
        isActive(!active)
    }

    const toggleSelectValue = (value: any) => {
        setValue({
            id: value.id,
            title: value.title,
            titleEN: value.titleEN ? value.titleEN : ''
        })
        isActive(!active)
    }

    return (
        <>
            <SelectWrapper width={width}>
                <SelectedButton
                    colorBackground={colorBackground}
                    colorText={colorText}
                >
                    <div className="select-box">
                        <div
                            className={`options-container ${
                                active ? 'active' : ''
                            }`}
                        >
                            {list.map(option => {
                                return (
                                    <div
                                        onClick={() =>
                                            toggleSelectValue(option)
                                        }
                                        className="option"
                                        key={option.id}
                                    >
                                        <input
                                            value={option.title}
                                            type="radio"
                                            className="radio"
                                            name="categories"
                                        />
                                        <label>{option.title}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div
                            className={`selected ${active ? 'active' : ''}`}
                            onClick={!disabled ? toggleSelect : undefined}
                        >
                            {currentValue.title}
                            <ChevronDown size={24} />
                        </div>
                    </div>
                </SelectedButton>
            </SelectWrapper>
        </>
    )
}
