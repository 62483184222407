import * as S from './styles'

import { useEffect, useState } from 'react'

import { ThreeDots } from '@styled-icons/bootstrap'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'

import Eye from 'src/assets/icons/news/eye.svg'
import Pen from 'src/assets/icons/news/pen.svg'
import TrashCan from 'src/assets/icons/news/trashCan.svg'

import KebabButton from 'src/components/Button/KebabButton'
import Modal from 'src/components/Modal'
import DeleteModal from 'src/components/Modal/DeleteModal'

import usePersistedState from 'src/hooks/usePersistedState'

import { PUBLISHED_SCREEN } from 'src/components/NewsModule/constants'

const PublishedDropdown = (props: any) => {
  const { post, categories } = props

  const [category, setCategory] = useState('')
  const [postCategory, setPostCategory] = useState<any>('')

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3)

  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [user, setUser] = usePersistedState('auth-token', '')

  const checkCan = (context: string) => {
    const permission = user.generalPermissions.find(
      (p: any) => p.screen === PUBLISHED_SCREEN
    )
    if (context === 'Edit' && permission.allowEdit) {
      return true
    }
    if (context === 'Delete' && permission.allowDelete) {
      return true
    }
    return false
  }

  const deleteNews = () => {
    setOpenModal(!openModal)
    setOpenDeleteModal(!openDeleteModal)
  }

  useEffect(() => {
    if (categories.length > 0) {
      const postCategoryId = post.newsCategoriesId
      setPostCategory(
        categories.find((post: any) => post.id === postCategoryId)
      )
      switch (postCategory?.title) {
        case 'Blog':
          setCategory('blog')
          break
        case 'Vaga':
          setCategory('careers')
          break
        case 'Publicação':
          setCategory('publications')
          break
        case 'Defesa':
          setCategory('defenses')
          break
        case 'Evento':
          setCategory('events')
          break
        case 'Prêmio':
          setCategory('awards')
          break
      }
    }
  }, [postCategory])

  return (
    <>
      <DeleteModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        title={
          postCategory.title !== 'Vaga' ? 'Deletar notícia?' : 'Deletar vaga?'
        }
        text={
          postCategory.title !== 'Vaga'
            ? 'Ao continuar, a notícia não estará mais disponível para os leitores e não poderá ser restaurada.'
            : 'Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
        }
        id={post.id}
        category={postCategory.title}
      />
      <Modal
        showModal={openModal}
        setShowModal={setOpenModal}
        buttonLeft={() => setOpenModal(!openModal)}
        buttonRight={() => deleteNews()}
        title={
          postCategory.title !== 'Vaga' ? 'Deletar notícia?' : 'Deletar vaga?'
        }
        description={
          postCategory.title !== 'Vaga'
            ? 'Você tem certeza que deseja deletar a notícia permanentemente? Ao continuar, a notícia não estará mais disponível para os leitores e não poderá ser restaurada.'
            : 'Você tem certeza que deseja deletar a vaga permanentemente? Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'avançar'}
      />
      <S.DropdownContainer>
        <KebabButton {...buttonProps}>
          <ThreeDots size="30" />
        </KebabButton>
        <div className={`dropdown ${isOpen ? 'visible' : ''}`} role="menu">
          <a {...itemProps[1]} href={`/news/${category}/${post.id}`}>
            <img src={Eye} />
            Visualizar
          </a>

          {checkCan('Edit') ? (
            <a {...itemProps[0]} href={`/news/edit-news/${post.id}`}>
              <img src={Pen} />
              Editar
            </a>
          ) : (
            <></>
          )}

          {checkCan('Delete') ? (
            <a {...itemProps[2]} onClick={() => setOpenModal(!openModal)}>
              <img src={TrashCan} />
              Deletar
            </a>
          ) : (
            <></>
          )}
        </div>
      </S.DropdownContainer>
    </>
  )
}

export default PublishedDropdown
