import * as styles from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import useMedia from 'src/hooks/useMedia'

import { minDesktopScreenWidth } from 'src/utils/breakpoints'

const SearchNewsSkeleton = () => {
  const { width } = useMedia()
  return (
    <>
      {width >= minDesktopScreenWidth ? (
        <styles.Container>
          <styles.CategoryContainer>
            <Skeleton height={28} width={160} />
            <Skeleton containerClassName="results" height={28} width={350} />
            <styles.CategoryContent>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
            </styles.CategoryContent>
          </styles.CategoryContainer>
        </styles.Container>
      ) : (
        <>
          <styles.Container>
            <styles.CategoryContent>
              <Skeleton height={28} width={300} />
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
            </styles.CategoryContent>
          </styles.Container>
        </>
      )}
    </>
  )
}

export default SearchNewsSkeleton
