const colors = {
  // fonts
  heroTitle: '#DEEFFE',
  phasesTitle: '#009FDF',

  // all
  defaultBlue: '#2E2972',
  lightBlue: '#009FDF',
  darkBlue: '#2C3E51',

  // components
  buttonColor: '#FEC074',
  gradientBackground: '#EEF7FF',

  // basics
  black: '##000000',
  white: '#FFFFFF',
  gray: 'rgba(44, 62, 81, 0.3)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black55: 'rgba(0, 0, 0, 0.55)',
  black87: 'rgba(0, 0, 0, 0.87)',
  gray87: 'rgba(121, 121, 121, 0.87)'
}

export default colors
