import * as S from './styles'
import { useState } from 'react'
import { ThreeDots } from '@styled-icons/bootstrap'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'
import Eye from 'src/assets/icons/news/eye.svg'
import Pen from 'src/assets/icons/news/pen.svg'
import Fixed from 'src/assets/icons/news/fixed.svg'
import TrashCan from 'src/assets/icons/news/trashCan.svg'
import KebabButton from 'src/components/Button/KebabButton'
import Modal from 'src/components/Modal'
import HighlightDeleteModal from 'src/components/Modal/HighlightDeleteModal'
import HighlightPreviewModal from 'src/components/Modal/HighlightPreviewModal'

const HighlightDropdown = (props: any) => {
    const { item, fetchFixed, fetchDelete, highlightPermissions } = props
    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(4)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openPreviewModal, setOpenPreviewModal] = useState(false)
    const [openDeleteModalPassword, setOpenDeleteModalPassword] =
        useState(false)

    return (
        <>
            <Modal
                showModal={openDeleteModal}
                setShowModal={setOpenDeleteModal}
                buttonLeft={() => setOpenDeleteModal(!openDeleteModal)}
                buttonRight={() => {
                    setOpenDeleteModal(false)
                    setOpenDeleteModalPassword(true)
                }}
                title={'Deletar destaque?'}
                description={
                    'Ao continuar, a notícia destaque não estará mais disponível na página inicial do site LSBD e não poderá ser restaurada.'
                }
                titleButtonLeft={'cancelar'}
                titleButtonRight={'avançar'}
            />
            <HighlightDeleteModal
                showModal={openDeleteModalPassword}
                setShowModal={setOpenDeleteModalPassword}
                title={'Deletar destaque?'}
                text={
                    'Ao continuar, a notícia destaque não estará mais disponível na página inicial do site LSBD e não poderá ser restaurada.'
                }
                id={item.id}
                fetchDelete={fetchDelete}
            />
            <HighlightPreviewModal
                showModal={openPreviewModal}
                setShowModal={setOpenPreviewModal}
                image={item.highlight.image}
                labelOrange={item.highlight.category.title}
                labelOrangeEN={item.highlight.category.titleEN}
                title={item.highlight.title}
                titleEN={item.highlight.titleEN}
                additionalText={item.highlight.subtitle}
                additionalTextEN={item.highlight.subtitleEN}
                buttonLabel={item.highlight.buttonLabel}
                hasButtons={true}
                id={item.highlight.id}
            />
            <S.DropdownContainer>
                <KebabButton {...buttonProps}>
                    <ThreeDots size="30" />
                </KebabButton>
                <div
                    className={`dropdown ${isOpen ? 'visible' : ''}`}
                    role="menu"
                >
                    {highlightPermissions && highlightPermissions.allowEdit && (
                        <a
                            {...itemProps[1]}
                            onClick={() => fetchFixed(item.id, !item.isFixed)}
                        >
                            <img src={Fixed} />
                            {item.isFixed ? 'Desafixar' : 'Fixar'}
                        </a>
                    )}

                    <a
                        {...itemProps[1]}
                        onClick={() => setOpenPreviewModal(true)}
                    >
                        <img src={Eye} />
                        Visualizar
                    </a>

                    {highlightPermissions && highlightPermissions.allowEdit && (
                        <a
                            {...itemProps[0]}
                            href={`/edit-highlight/${item.highlight.id}`}
                        >
                            <img src={Pen} />
                            Editar
                        </a>
                    )}

                    {highlightPermissions && highlightPermissions.allowDelete && (
                        <a
                            {...itemProps[3]}
                            onClick={() => setOpenDeleteModal(true)}
                        >
                            <img src={TrashCan} />
                            Deletar
                        </a>
                    )}
                </div>
            </S.DropdownContainer>
        </>
    )
}

export default HighlightDropdown
