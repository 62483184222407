import { webApi } from 'src/services/api/config'
import { ITag, IPostTag } from './interfaces'
import { AxiosResponse } from 'axios'

const baseRoute = 'api/site/news'

/**
 * Gets a post
 * @param id post id
 * @returns The post
 */
export const getPost = async (id: number): Promise<AxiosResponse> => {
  return await webApi.get(`${baseRoute}/${id}`)
}

/**
 * Gets a post category
 * @param id postCategory id
 * @returns The post category
 */
export const getPostCategory = async (id: number): Promise<AxiosResponse> => {
  return await webApi.get(`${baseRoute}/categories/${id}`)
}

/**
 * Gets all tags
 * @returns list of tags
 */
export const getTags = async (): Promise<AxiosResponse<ITag[]>> => {
  return await webApi.get(`${baseRoute}/tags`)
}

/**
 * Gets all tags of a post
 * @param postId post id
 * @returns list of tags
 */
export const getPostTags = async (
  postId: number
): Promise<AxiosResponse<IPostTag[]>> => {
  return await webApi.get(`${baseRoute}/tags/${postId}`)
}
