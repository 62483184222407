import * as styles from './styles'
import { useTranslation } from 'react-i18next'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Divider from 'src/assets/developmentBar.svg'
import Graphism from 'src/assets/images/graphism.svg'
import Group from 'src/assets/icons/development/group.svg'
import OrangeArrow from 'src/assets/icons/development/orangeArrow.svg'
import Quality from 'src/assets/icons/development/quality.svg'
import Sprint from 'src/assets/icons/development/sprint.svg'
import Woman from 'src/assets/icons/development/woman.svg'
import Typography from 'src/components/Typography'
import useMedia from 'src/hooks/useMedia'
import { maxMobileScreenWidth } from 'src/utils/breakpoints'
import { useState } from 'react'

SwiperCore.use([Pagination])

const Step = () => {
    const { t } = useTranslation()
    const { width } = useMedia()

    const [stepSwiper, setStepSwiper] = useState<any>(null)

    return (
        <>
            <styles.TextWrapper>
                <Typography variant="h1Blue" style={{ fontWeight: 'bold' }}>
                    {t('desenvolvimento.projeto.titulo')}
                </Typography>
                <Typography
                    variant="h1LightBlue"
                    style={{ fontWeight: 'bold' }}
                >
                    {t('desenvolvimento.projeto.steps')}
                </Typography>
            </styles.TextWrapper>
            {width <= maxMobileScreenWidth && <styles.DividerMobile />}
            {width <= maxMobileScreenWidth ? (
                <>
                    <styles.PhasesWrapper>
                        <styles.Text>
                            <styles.TypographyWrapper>
                                <Typography variant="blueP">
                                    {t('desenvolvimento.projeto.descricao')}
                                </Typography>
                            </styles.TypographyWrapper>
                        </styles.Text>
                        <styles.Phases>
                            <Swiper
                                observer={true}
                                observeParents={true}
                                pagination={true}
                                slidesPerView={1}
                                onInit={ev => {
                                    setStepSwiper(ev)
                                }}
                            >
                                <SwiperSlide>
                                    <styles.PhasesItem>
                                        <img src={Woman} />
                                        <styles.PhasesText>
                                            <Typography
                                                variant="h4Blue"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {t(
                                                    'desenvolvimento.projeto.lista.requisitos'
                                                )}
                                            </Typography>
                                            <Typography variant="blueP">
                                                Product Owner
                                            </Typography>
                                        </styles.PhasesText>
                                    </styles.PhasesItem>
                                    <styles.MobileArrow
                                        src={OrangeArrow}
                                        onClick={() => stepSwiper.slideNext()}
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <styles.PhasesItem>
                                        <img src={Group} />
                                        <styles.PhasesText>
                                            <Typography
                                                variant="h4Blue"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {t(
                                                    'desenvolvimento.projeto.lista.interacao'
                                                )}
                                            </Typography>
                                            <Typography variant="blueP">
                                                UX/UI designers
                                            </Typography>
                                        </styles.PhasesText>
                                    </styles.PhasesItem>
                                    <styles.MobileArrow
                                        src={OrangeArrow}
                                        onClick={() => stepSwiper.slideNext()}
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <styles.PhasesItem>
                                        <img src={Sprint} />
                                        <styles.PhasesText>
                                            <Typography
                                                variant="h4Blue"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {t(
                                                    'desenvolvimento.projeto.lista.entrega'
                                                )}
                                            </Typography>
                                            <Typography variant="blueP">
                                                {t(
                                                    'desenvolvimento.projeto.lista.descricao-entrega'
                                                )}
                                            </Typography>
                                        </styles.PhasesText>
                                    </styles.PhasesItem>
                                    <styles.MobileArrow
                                        src={OrangeArrow}
                                        onClick={() => stepSwiper.slideNext()}
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <styles.PhasesItem>
                                        <img src={Quality} />
                                        <styles.PhasesText>
                                            <Typography
                                                variant="h4Blue"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {t(
                                                    'desenvolvimento.projeto.lista.qualidade'
                                                )}
                                            </Typography>
                                            <Typography variant="blueP">
                                                {t(
                                                    'desenvolvimento.projeto.lista.descricao-qualidade'
                                                )}
                                            </Typography>
                                        </styles.PhasesText>
                                    </styles.PhasesItem>
                                </SwiperSlide>
                            </Swiper>
                        </styles.Phases>
                    </styles.PhasesWrapper>
                </>
            ) : (
                <>
                    <styles.BarWrapper>
                        <img src={Divider} />
                    </styles.BarWrapper>
                    <styles.PhasesWrapper>
                        <styles.Text>
                            <styles.TypographyWrapper>
                                <Typography variant="blueP">
                                    {t('desenvolvimento.projeto.descricao')}
                                </Typography>
                            </styles.TypographyWrapper>
                        </styles.Text>
                        <styles.Phases>
                            <styles.PhasesItem>
                                <img src={Woman} />
                                <styles.PhasesText>
                                    <Typography
                                        variant="h4Blue"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t(
                                            'desenvolvimento.projeto.lista.requisitos'
                                        )}
                                    </Typography>
                                    <Typography variant="blueP">
                                        Product Owner
                                    </Typography>
                                </styles.PhasesText>
                            </styles.PhasesItem>
                            <img src={OrangeArrow} className="orangeArrow" />
                            <styles.PhasesItem>
                                <img src={Group} />
                                <styles.PhasesText>
                                    <Typography
                                        variant="h4Blue"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t(
                                            'desenvolvimento.projeto.lista.interacao'
                                        )}
                                    </Typography>
                                    <Typography variant="blueP">
                                        UX/UI designers
                                    </Typography>
                                </styles.PhasesText>
                            </styles.PhasesItem>
                            <img src={OrangeArrow} className="orangeArrow" />
                            <styles.PhasesItem>
                                <img src={Sprint} />
                                <styles.PhasesText>
                                    <Typography
                                        variant="h4Blue"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t(
                                            'desenvolvimento.projeto.lista.entrega'
                                        )}
                                    </Typography>
                                    <Typography variant="blueP">
                                        {t(
                                            'desenvolvimento.projeto.lista.descricao-entrega'
                                        )}
                                    </Typography>
                                </styles.PhasesText>
                            </styles.PhasesItem>
                            <img src={OrangeArrow} className="orangeArrow" />
                            <styles.PhasesItem>
                                <img src={Quality} />
                                <styles.PhasesText>
                                    <Typography
                                        variant="h4Blue"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t(
                                            'desenvolvimento.projeto.lista.qualidade'
                                        )}
                                    </Typography>
                                    <Typography variant="blueP">
                                        {t(
                                            'desenvolvimento.projeto.lista.descricao-qualidade'
                                        )}
                                    </Typography>
                                </styles.PhasesText>
                            </styles.PhasesItem>
                        </styles.Phases>
                    </styles.PhasesWrapper>
                    <img src={Graphism} className="graphism" />
                </>
            )}
        </>
    )
}

export default Step
