import * as styles from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const HighlightManagementSkeleton = () => {
    return (
        <>
            <styles.Container>
                <styles.Wrapper>
                    <Skeleton containerClassName="post" count={4} height={90} />
                </styles.Wrapper>
            </styles.Container>
        </>
    )
}

export default HighlightManagementSkeleton
