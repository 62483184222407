/* eslint-disable prettier/prettier */
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import Logout from 'src/assets/icons/login/logout.svg'
import News from 'src/assets/icons/login/news.svg'
import People from 'src/assets/icons/login/people.svg'
import Patrimony from 'src/assets/icons/login/patrimony.svg'
import Project from 'src/assets/icons/login/project.svg'
import Library from 'src/assets/icons/login/library.svg'
import Quota from 'src/assets/icons/login/quota.svg'
import Admin from 'src/assets/icons/login/adm.svg'
import EyeOpen from 'src/assets/icons/password/eyeOpen.svg'
import EyeClosed from 'src/assets/icons/password/eyeClosed.svg'
import useInput from 'src/hooks/useInput'
import { webApi } from 'src/services/api/config'
import Spinner from 'src/components/Spinner'
import Cookies from 'js-cookie'
import {
    PATRIMONY_PERMISSIONS,
    NEWS_PERMISSIONS,
    HR_PERMISSIONS,
    LIBRARY_PERMISSIONS,
    QUOTA_PERMISSIONS,
    ADMIN_PERMISSIONS
} from 'src/components/NewsModule/constants'
import usePersistedState from 'src/hooks/usePersistedState'
import { QueryGetValue } from 'src/utils/General/QueryGetValue'

interface Props {
    setLoggedIn(data: any): void
    openModal(isOpen: boolean): void
    loggedIn: any
}

const ModalLogin: React.FC<Props> = ({ loggedIn, setLoggedIn, openModal }) => {
    const { t } = useTranslation()

    const [validCredential, setValidCredential] = useState(true)
    const [isEnable, setIsEnable] = useState(true)
    const [loadingLogin, setLoadingLogin] = useState(false)

    const email = useInput('')
    const password = useInput('')
    const [isEyeOpen, setIsEyeOpen] = useState(false)

    const [management, setManagement] = usePersistedState('management', {
        access: false,
        link: '/'
    })
    const [approvers, setApprovers] = usePersistedState('approvers', [])

    const [systemAccess, setSystemAccess] = usePersistedState('system-access', {
        project: false,
        patrimony: false,
        hr: false,
        library: false,
        quota: false
    })

    const logout = () => {
        localStorage.clear()
        Cookies.remove('auth-token', {
            path: '/',
            domain: process.env.REACT_APP_DOMAIN
        })
        openModal(false)
        window.location.reload()
    }

    const handleEye = () => {
        setIsEyeOpen(!isEyeOpen)
    }

    const handleKey = (e: any) => {
        if (e.key === 'Enter') {
            sendRequest(email.value, password.value)
        }
    }

    const getApprovers = async (user: any) => {
        // TODO: Transfer this request to API layer
        const requestInfo = await webApi
            .get('api/users/approved/' + user.id, {
                headers: {
                    'Content-Type': 'application/json',
                    // eslint-disable-next-line quote-props
                    Authorization:
                        'bearer ' +
                        (Cookies.get('auth-token')
                            ? Cookies.get('auth-token')
                            : '')
                }
            })
            .then(response => {
                setApprovers(response.data.map((e: any) => e.id))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoadingLogin(false)
                window.location.reload()
            })
    }

    const getSystemAccess = (user: any) => {
        const accessProject = user.profileId.length > 0
        const accessPatrimony = getSystemPermissions(
            user,
            PATRIMONY_PERMISSIONS
        )
        const accessHr = getSystemPermissions(user, HR_PERMISSIONS)
        const accessNews = getNewsPermissions(user, NEWS_PERMISSIONS)
        const accessLibrary = getSystemPermissions(user, LIBRARY_PERMISSIONS)
        const accessQuota = getSystemPermissions(user, QUOTA_PERMISSIONS)
        const accessAdmin = getSystemPermissions(user, ADMIN_PERMISSIONS)

        setSystemAccess({
            project: accessProject,
            patrimony: accessPatrimony,
            hr: accessHr,
            library: accessLibrary,
            quota: accessQuota,
            admin: accessAdmin
        })
        setManagement(accessNews)
    }

    const getNewsPermissions = (user: any, systemPermissions: any) => {
        for (let i = 0; i < systemPermissions.length; i++) {
            const aux = user.generalPermissions.find(
                (s: any) => s.screen === systemPermissions[i].screen
            )
            if (aux && aux.allowRead) {
                return { access: true, link: systemPermissions[i].route }
            }
        }
        return { access: false, link: '/' }
    }

    const getSystemPermissions = (user: any, systemPermissions: any) => {
        for (let i = 0; i < systemPermissions.length; i++) {
            const aux = user.generalPermissions.find(
                (s: any) => s.screen === systemPermissions[i].screen
            )
            if (aux && aux.allowRead) {
                return true
            }
        }
        return false
    }

    const sendRequest = async (email: string, password: string) => {
        setLoadingLogin(true)
        // TODO: Transfer this request to API layer
        const requestInfo = await webApi
            .post(
                'api/auth/login',
                JSON.stringify({
                    email: email,
                    password: password
                }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(response => {
                setLoggedIn(response.data.authData)

                Cookies.set('auth-token', response.data.token, {
                    path: '/',
                    domain: process.env.REACT_APP_DOMAIN
                })
                getSystemAccess(response.data.authData)
                getApprovers(response.data.authData)

                openModal(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingLogin(false)
                setValidCredential(false)
            })
    }

    useEffect(() => {
        setIsEnable(true)
        setValidCredential(true)
    }, [email.value, password.value])

    const [queryString, setQueryString] = useState('')

    useEffect(() => {
        setQueryString(QueryGetValue('logout') || '')
    }, [])

    useEffect(() => {
        if (queryString) {
            setLoggedIn(false)
            setQueryString('')
        }
    }, [queryString])

    return (
        <>
            {!loggedIn ? (
                <>
                    <S.SidebarForm>
                        {!validCredential ? (
                            <S.invalidCredential>
                                {t('login.credenciais-invalidas')}
                            </S.invalidCredential>
                        ) : (
                            <></>
                        )}
                        {!isEnable ? (
                            <S.invalidCredential>
                                Usuário desativado
                            </S.invalidCredential>
                        ) : (
                            <></>
                        )}
                        <S.EmailInputContainer>
                            <S.LabelInput>E-Mail</S.LabelInput>
                            <S.SidebarInput
                                onChange={email.onChange}
                                type="email"
                                placeholder="E-mail"
                                maxLength={256}
                                onKeyPress={e => handleKey(e)}
                            ></S.SidebarInput>
                        </S.EmailInputContainer>
                        <S.PasswordInputContainer>
                            <S.LabelInput>{t('sidebar.senha')}</S.LabelInput>
                            <S.SidebarInput
                                maxLength={50}
                                type={isEyeOpen ? 'text' : 'password'}
                                onKeyPress={e => handleKey(e)}
                                onChange={password.onChange}
                                placeholder={t('sidebar.senha')}
                            ></S.SidebarInput>
                            <S.EyeIcon
                                src={isEyeOpen ? EyeOpen : EyeClosed}
                                onClick={handleEye}
                            />
                        </S.PasswordInputContainer>
                        {loadingLogin ? (
                            <S.LoginButton type="button" disabled={true}>
                                <Spinner heightContainer="100%" size={'15px'} />
                            </S.LoginButton>
                        ) : (
                            <S.LoginButton
                                type="button"
                                onClick={() =>
                                    sendRequest(email.value, password.value)
                                }
                            >
                                Login
                            </S.LoginButton>
                        )}
                        <S.ForgotPassword to="/forgot-password">
                            {t('sidebar.esquecer')}
                        </S.ForgotPassword>
                    </S.SidebarForm>
                </>
            ) : (
                <>
                    <S.LoggedItemContainer>
                        <S.LoggedItemWrapper>
                            {systemAccess.admin && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'Admin'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={Admin} />
                                        <S.LoggedItemLabel>
                                            Administrativo
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {systemAccess.project && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'projects'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={Project} />
                                        <S.LoggedItemLabel>
                                            Projeto
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {systemAccess.patrimony && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'Patrimony'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={Patrimony} />
                                        <S.LoggedItemLabel>
                                            Patrimônio
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {systemAccess.hr && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'HumanResources'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={People} />
                                        <S.LoggedItemLabel>
                                            Gestão de Pessoas
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {systemAccess.library && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'Library'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={Library} />
                                        <S.LoggedItemLabel>
                                            Biblioteca
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {systemAccess.quota && (
                                <S.LoggedItemLink
                                    href={
                                        process.env.REACT_APP_LSBD_SYSTEM +
                                        'Quotas'
                                    }
                                >
                                    <S.LoggedItem>
                                        <img src={Quota} />
                                        <S.LoggedItemLabel>
                                            Cotas
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLink>
                            )}
                            {management.access && (
                                <S.LoggedItemLinkNews to={management.link}>
                                    <S.LoggedItem>
                                        <img src={News} />
                                        <S.LoggedItemLabel>
                                            Módulo de notícias
                                        </S.LoggedItemLabel>
                                    </S.LoggedItem>
                                </S.LoggedItemLinkNews>
                            )}
                            <S.LoggedItem onClick={() => logout()}>
                                <img src={Logout} />
                                <S.LoggedItemLabel>Sair</S.LoggedItemLabel>
                            </S.LoggedItem>
                        </S.LoggedItemWrapper>
                    </S.LoggedItemContainer>
                </>
            )}
        </>
    )
}

export default ModalLogin
