/**
 * Action types
 */

export enum postTypes {
  POST_REQUEST = '@news/FETCH_POST_REQUEST',
  POST_SUCCESS = '@news/FETCH_POST_SUCCESS',
  POST_FAILURE = '@news/FETCH_POST_FAILURE',

  // POST_REQUEST_UNIQUE_NEWS = '@news/FETCH_POST_REQUEST_UNIQUE_NEWS',
  // POST_SUCCESS_UNIQUE_NEWS = '@news/FETCH_POST_SUCCESS_UNIQUE_NEWS',
  // POST_FAILURE_UNIQUE_NEWS = '@news/FETCH_POST_FAILURE_UNIQUE_NEWS',

  CREATE_REQUEST = '@news/CREATE_REQUEST',
  CREATE_SUCCESS = '@news/CREATE_SUCCESS',
  CREATE_FAILURE = '@news/CREATE_FAILURE',

  EDIT_REQUEST = '@news/EDIT_REQUEST',
  EDIT_SUCCESS = '@news/EDIT_SUCCESS',
  EDIT_FAILURE = '@news/EDIT_FAILURE',

  DELETE_REQUEST = '@news/DELETE_REQUEST',
  DELETE_SUCCESS = '@news/DELETE_SUCCESS',
  DELETE_FAILURE = '@news/DELETE_FAILURE',
  DELETE_ALL_REQUEST = '@news/DELETE_ALL_REQUEST'
}

/**
 * Data types
 */

export interface News {
  id: number
  title: string
  path: string
  alt: string
  author: string
  text: string
  status: string
  scheduled: boolean
  publishedAt: Date | null
  createdAt: string
  fileData: null
  lastEdit: Date | null
  newsCategories: string | null
  newsCategoriesId: number | null
  userId: number | null
  audience: string
  newsModuleListTags: string[]
}

/**
 * State type
 */
export interface NewsState {
  readonly news: News[]
  readonly loading: boolean
  readonly error: boolean
}

export interface FetchPostsSuccessPayload {
  readonly data: News[]
}

// export interface FetchUniquePostPayload {
//   readonly data: News
// }

export interface FetchPostsFailurePayload {
  error: string
}

// export interface FetchPostsRequestUnique {
//   type: typeof postTypes.POST_REQUEST_UNIQUE_NEWS
//   payload: number
// }

// export interface FetchPostsSuccessUnique {
//   type: typeof postTypes.POST_SUCCESS_UNIQUE_NEWS
//   payload: FetchUniquePostPayload
// }

// export interface FetchPostsFailureUnique {
//   type: typeof postTypes.POST_FAILURE_UNIQUE_NEWS
//   payload: FetchPostsFailurePayload
// }

export interface FetchPostsRequest {
  type: typeof postTypes.POST_REQUEST
}

export type FetchPostsSuccess = {
  type: typeof postTypes.POST_SUCCESS
  payload: FetchPostsSuccessPayload
}

export type FetchPostsFailure = {
  type: typeof postTypes.POST_FAILURE
  payload: FetchPostsFailurePayload
}

export interface CreatePostRequest {
  type: typeof postTypes.CREATE_REQUEST
  payload: FormData
}
export type CreatePostSuccess = {
  type: typeof postTypes.CREATE_SUCCESS
  payload: FetchPostsSuccessPayload
}

export type CreatePostFailure = {
  type: typeof postTypes.CREATE_FAILURE
  payload: FetchPostsFailurePayload
}

export interface DeletePostRequest {
  type: typeof postTypes.DELETE_REQUEST
  payload?: number
}

export interface DeletePostSuccess {
  type: typeof postTypes.DELETE_SUCCESS
  payload: FetchPostsSuccessPayload
}

export interface DeleteAllRequest {
  type: typeof postTypes.DELETE_ALL_REQUEST
}

export interface DeletePostFailure {
  type: typeof postTypes.DELETE_FAILURE
  payload: FetchPostsFailurePayload
}

export type PostsActions =
  | FetchPostsRequest
  | FetchPostsSuccess
  | FetchPostsFailure
  | CreatePostRequest
  | CreatePostFailure
  | CreatePostSuccess
  | DeletePostRequest
  | DeletePostSuccess
  | DeletePostFailure
  | DeleteAllRequest
// | FetchPostsRequestUnique
// | FetchPostsSuccessUnique
// | FetchPostsFailureUnique
