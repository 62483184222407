import * as S from './styles'

import { ThreeDots } from '@styled-icons/bootstrap'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'

import Pen from 'src/assets/icons/news/pen.svg'

import KebabButton from 'src/components/Button/KebabButton'

import usePersistedState from 'src/hooks/usePersistedState'

const PendingDropdown = (props: any) => {
  const { post, screen, context } = props
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(4)
  const [user, setUser] = usePersistedState('auth-token', '')

  const checkCanEdit = () => {
    const permission = user.generalPermissions.find(
      (p: any) => p.screen === screen
    )
    if (permission.allowEdit) {
      return true
    }
    return false
  }

  return (
    <>
      {checkCanEdit() ? (
        <S.DropdownContainer>
          <KebabButton {...buttonProps}>
            <ThreeDots size="30" />
          </KebabButton>
          <div className={`dropdown ${isOpen ? 'visible' : ''}`} role="menu">
            <a {...itemProps[0]} href={`/news/review-${context}/${post.id}`}>
              <img src={Pen} />
              Revisar
            </a>
          </div>
        </S.DropdownContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export default PendingDropdown
