import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { Link as LinkRouter } from 'react-router-dom'

export const InfoBox = styled.div`
  margin-bottom: 1rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  background: #f6f6f6;
  color: #797979;
  border-radius: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const InfoBoxColumn = styled.div`
  padding: 1.5rem 2rem;

  display: flex;
  flex: 1 1 auto;
  align-self: stretch;
  align-items: center;

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }
`

export const InfoBoxContent = styled.span`
  width: 100%;
  text-align: center;
`
