export default function checkPermission(user: any, permission: string) {
  const havePermission = user.generalPermissions.find(
    (p: any) => p.screen === permission
  )

  if (havePermission) {
    if (!havePermission.allowRead) {
      return false
    }
  } else {
    return false
  }

  return true
}
