import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { Link as LinkRouter } from 'react-router-dom'

export const Legend = styled.small`
  padding-top: 0.5rem;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9a9a9a;
`
export const ButtonGroup = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-evenly;

  flex-direction: row;
  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const ButtonContainer = styled.div`
  padding: 5px;
`

export const Submit = styled.button`
  padding: 16px 64px;
  background: #fec074;
  border: none;
  border-radius: 30px;

  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;
  text-align: center;
  color: white;
  text-transform: uppercase;

  /* &.deactivated {
    background: gray;
  } */
`

export const OutlineButton = styled.button`
  padding: 16px 64px;
  background: rgba(255, 255, 255, 0.87);
  border: 2px solid #fec074;
  border-radius: 30px;

  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;
  text-align: center;
  color: #fec074;
  text-transform: uppercase;
`
