import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

type TabButtonProps = {
  readonly active: boolean
}

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  background: ${({ theme }) => theme.colors.gradientBackground};
`

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.gradientBackground};
  width: 100%;
  overflow: hidden;

  .graphism {
    position: absolute;
    bottom: -420px;
    left: -290px;

    transform: rotate(10deg);
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    padding: 37px 24px 64px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 114px 86px;
    max-width: 1440px;
  }
`

export const TabContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    justify-content: space-between;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    div + div {
      margin-left: 20px;
    }

    justify-content: flex-end;
    align-self: flex-end;
  }
`

export const TabButton = styled.button<TabButtonProps>`
  border: none;
  background: none;

  border-bottom: ${({ active, theme }) =>
    active ? `2px solid ${theme.colors.buttonColor}` : 'none'};

  p {
    color: ${({ active }) => (active ? '#2C3E51' : '')};
    opacity: ${({ active }) => (active ? '1.0' : '')};
    font-weight: ${({ active }) => (active ? 'bold' : 'none')};
  }

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ active, theme }) =>
    !active ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      color: #2c3e51;
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`
export const TabItem = styled.div``

export const DividerMobile = styled.hr`
  border: 1px solid #000000;
  margin-top: 24px;
`
