import styled from 'styled-components'

type NewsInputProps = {
    textColor: string
    textSize: number
    error: boolean
    margin: string
}

type InputProps = {
    error: boolean
    margin: string
    whiteBackground?: boolean
}

type GeneralContainerProps = {
    margin: string
}

export const GeneralContainer = styled.div<GeneralContainerProps>`
    margin: ${({ margin }) => margin};
    width: 100%;
`

export const TextError = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    color: red;
    font-size: 16px;
`

export const NewsInput = styled.input<NewsInputProps>`
    border: none;
    width: 100%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: ${({ textSize }) => '' + textSize + 'px'};
    color: ${({ textColor }) => textColor};
    margin: ${({ margin }) => margin};

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${({ textColor, error }) => error ? 'red' : textColor};
    }
`

export const Input = styled.input<InputProps>`
    border: ${({ error }) => error ? '1px solid red' : '1px solid transparent'};
    width: 100%;
    background: ${({ whiteBackground }) => whiteBackground ? '#ffffff' : '#EDF1F4'};
    border-radius: 30px;
    padding: 10px 16px;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    margin: ${({ margin }) => margin};

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${({ error }) => error ? 'red' : 'rgba(0, 0, 0, 0.54)'};
    }
`

export const ImageContainer = styled.div`
  width: 100%;
  max-height: 360px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 360px;
    object-fit: cover;
    cursor: pointer;
  }

  button {
    width: 100%;
    height: 360px;
    background: #f2f2f2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #000000;
    font-style: italic;

    text-align: center;

    ::placeholder {
      color: #000000;
    }
  }
`

export const TextImageInput = styled.p`
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    margin-top: 16px;
`

export const ImageButton = styled.button`
    display: flex;
    flex-direction: column;
`

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 35px;
`

type DropDownHeaderProps = {
    isOpen: boolean
}

export const DropDownHeader = styled.div<DropDownHeaderProps>`
  display: flex;
  width: 170px;
  padding: 0 12px;
  border-radius: ${props => props.isOpen ? '24px 24px 0 0' : '24px'};
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border: 1px solid #2E2972;
  color: #2E2972;
  cursor: pointer;
`

export const DropDownListContainer = styled.div`
  position: absolute;
  margin-top: 35px;
  top: 0;
  z-index: 100;
  width: 170px;
  cursor: pointer;
`

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-sizing: border-box;
  color: #2E2972;
  font-size: 14px;
  border-radius: 0 0 24px 24px;
  border-right: 1px solid #2E2972;
  border-left: 1px solid #2E2972;
`

type ListItemProps = {
    isTheLast: boolean
}

export const ListItem = styled.li<ListItemProps>`
  list-style: none;
  text-align: center;
  border-bottom: 1px solid #2E2972;
  padding: 8px 0;
  border-radius: ${props => props.isTheLast ? '0 0 24px 24px' : 0};
  &:hover {
    background: #fd9e46;
  }
`
