import { HelpCircle } from '@styled-icons/boxicons-regular'
import { QuestionCircle } from '@styled-icons/fa-solid'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import styled from 'styled-components'
import { Field, Input } from '../styles'

export const InputFile = styled(Input)`
  padding: 1.5rem;
  height: auto;
  border-style: dashed;
  border-width: 2px;
  border-color: #c6c6c6;
  margin-top: 1rem;
`

export const FieldFile = styled(Field)`
  label {
    font-size: 1.2rem;
  }
`
