import * as S from './styles'

interface WhiteButtonProps {
  title: string
}

const WhiteButton: React.FC<WhiteButtonProps> = ({ title }) => {
  return (
    <>
      <S.WhiteButton>{title}</S.WhiteButton>
    </>
  )
}

export default WhiteButton
