import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from '../../utils/breakpoints'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`

export const Container = styled.div`
  position: relative;
  width: 100%;

  .graphism {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      position: absolute;
      bottom: -350px;
      left: -290px;
      transform: rotate(40deg);
    }

    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      position: absolute;
      bottom: -250px;
      left: -270px;
      transform: rotate(40deg);
    }
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 37px 24px 64px;
    overflow: hidden;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px 86px 145px;
    max-width: 1440px;
    overflow: hidden;
  }
`

export const Divider = styled.hr`
  border: 1px solid #000000;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  margin-top: 2.25rem;
`

export const ExpertiseCard = styled.div`
  .swiper-slide {
    display: flex;
    width: auto;
    height: auto;
    padding: 5px 0 50px 5px;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #2e2972;
    border-radius: 40px;
    opacity: 1;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    background: #2e2972;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    margin-top: 3.235rem;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    display: grid;
    grid-gap: 2rem;
    margin-top: 2.25rem;
    padding-bottom: 3.75rem;
    grid-template-columns: repeat(4, 1fr);
    z-index: 3;
  }
`

export const Card = styled.div`
  width: 100%;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  background: #ffffff;

  img {
    width: 70px;
    height: 70px;
    margin-top: 4rem;
  }

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    height: 26.625rem;

    &:hover {
      transform: scale(1.1);
      transition: ease-in-out 0.2s;
    }
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 90%;
    height: 95%;
    padding-bottom: 50px;
  }
`

export const CardTitle = styled.div`
  margin-top: 44px;
  display: flex;
  width: 160px;
  height: 48px;
  font-style: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 500;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 116px;
    height: 48px;
  }
`

export const CardTextList = styled.ul`
  margin-top: 44px;
  width: 60%;
`

export const CardListItem = styled.li`
  margin-top: 4px;
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;
`
