import { webApi } from 'src/services/api/config'
import { showToast } from 'src/utils/Toast'
import Cookies from 'js-cookie'

export const createHighlight = async (obj, type) => {
    return await webApi
        .post('/api/News/Highlight?type=' + type, obj, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            showToast({
                type: 'success',
                message:
                    type === 'SAVE'
                        ? 'Notícia de destaque salva.'
                        : 'Destaque adicionado com sucesso.'
            })
        })
        .catch(error => {
            const status = error.status
            showToast({
                type: 'error',
                message: 'Erro ao adicionar/salvar o destaque.'
            })
        })
}

export const updateHighlight = async (obj, id) => {
    return await webApi
        .put('/api/News/Highlight/' + id, obj, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            showToast({
                type: 'success',
                message: 'Destaque atualizado com sucesso.'
            })
        })
        .catch(error => {
            const status = error.status
            showToast({
                type: 'error',
                message: 'Erro ao atualizar o destaque.'
            })
        })
}

export const updateHighlightsOrder = async obj => {
    return await webApi
        .post('/api/News/Highlight/UpdateOrder', obj, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            showToast({
                type: 'success',
                message: 'Exibição dos destaques atualizada com sucesso.'
            })
        })
        .catch(error => {
            const status = error.status
            showToast({
                type: 'error',
                message: 'Erro ao atualizar a exibição dos destaques.'
            })
        })
}

export const getHighlightsOrder = async () => {
    return await webApi.get('/api/News/Highlight/Order')
}

export const getHighlights = async () => {
    return await webApi.get('/api/News/Highlight', {
        headers: {
            'Content-type': 'application/json',
            Authorization:
                'bearer ' +
                (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
        }
    })
}

export const getHighlight = async id => {
    return await webApi
        .get('/api/News/Highlight/' + id, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            return response
        })
        .catch(error => {
            return error
        })
}

export const putHighlightsAmount = async amount => {
    return await webApi.put(
        '/api/News/Highlight?amount=' + amount,
        {},
        {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        }
    )
}

export const putHighlightFixed = async id => {
    return await webApi.put(
        '/api/News/Highlight/ToggleFixed/' + id,
        {},
        {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        }
    )
}

export const deleteHighlightOrder = async id => {
    const deleteReq = await webApi
        .delete('api/News/Highlight/Order/' + id, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            showToast({
                type: 'success',
                message: 'Destaque deletado'
            })
        })
        .catch(err => {
            console.log(err)
            showToast({
                type: 'error',
                message: 'Erro ao deletar o destaque.'
            })
        })
}

export const deleteHighlight = async id => {
    const deleteReq = await webApi
        .delete('api/News/Highlight/' + id, {
            headers: {
                'Content-type': 'application/json',
                Authorization:
                    'bearer ' +
                    (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
        })
        .then(response => {
            showToast({
                type: 'success',
                message: 'Destaque deletado'
            })
        })
        .catch(err => {
            console.log(err)
            showToast({
                type: 'error',
                message: 'Erro ao deletar o destaque.'
            })
        })
}

export const sendEmailApprovers = async (
    newsTitle,
    newsCategory,
    colaboratorName,
    colaboratorId
) => {
    try {
        const body = {
            newsTitle: newsTitle,
            newsCategory: newsCategory,
            colaboratorName: colaboratorName,
            colaboratorId: colaboratorId
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await fetch(
            process.env.REACT_APP_EMAIL + 'api/News/newReview',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            }
        )
    } catch (error) {
        console.log(error)
    }
}

export const sendEmailReviewers = async (
    newsTitle,
    newsCategory,
    colaboratorName,
    reviewerName
) => {
    try {
        const body = {
            newsTitle: newsTitle,
            newsCategory: newsCategory,
            colaboratorName: colaboratorName,
            reviewerName: reviewerName
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await fetch(
            process.env.REACT_APP_EMAIL + 'api/News/newReviewPublish',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            }
        )
    } catch (error) {
        console.log(error)
    }
}
