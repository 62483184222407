import * as styles from './styles'
import Typography from 'src/components/Typography'
import Linkedin from 'src/assets/icons/social/linkedin.svg'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

import UfcLogo from 'src/assets/images/ufc/logoufc.svg'
import LsbdLogo from 'src/assets/images/ufc/lsbd.svg'
import MdccLogo from 'src/assets/images/ufc/mdcc.svg'
import Dc from 'src/assets/images/ufc/dc.svg'

import { useTranslation } from 'react-i18next'

import useMedia from 'src/hooks/useMedia'

const Footer = () => {
  const { t } = useTranslation()
  const { width } = useMedia()
  return (
    <styles.ResponsiveContainer>
      <styles.Container>
        <styles.FooterSectionWrapper>
          <styles.IconsWrapper>
            <a
              href="https://www.instagram.com/lsbdufc/"
              target="_blank"
              rel="noreferrer"
            >
              <styles.Instagram size={40} />
            </a>
            <a
              href="https://www.linkedin.com/company/lsbd---ufc/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} />
            </a>
          </styles.IconsWrapper>
          {width <= maxMobileScreenWidth && <styles.Divider />}
          <styles.SectionNamesWrapper>
            <styles.SectionLink
              smooth={true}
              duration={1000}
              offset={-80}
              to="about"
            >
              <Typography variant="whiteP">{t('navbar.sobre')}</Typography>
            </styles.SectionLink>

            <styles.Link to="/news/home">
              <Typography variant="whiteP">{t('navbar.news')}</Typography>
            </styles.Link>

            <styles.Link to="/rsc">
              <Typography variant="whiteP">
                {t('navbar.pos-graduacao')}
              </Typography>
            </styles.Link>

            <styles.Link to="/news/careers">
              <Typography variant="whiteP">{t('navbar.vagas')}</Typography>
            </styles.Link>

            <styles.SectionLink
              smooth={true}
              duration={1000}
              offset={-80}
              to="contact"
            >
              <Typography variant="whiteP">{t('navbar.contato')}</Typography>
            </styles.SectionLink>
          </styles.SectionNamesWrapper>
          {width >= minDesktopScreenWidth && <styles.Divider />}
          <styles.LogoWrapper>
            <a href="https://www.ufc.br/" target="_blank" rel="noreferrer">
              <img src={UfcLogo} alt="logo da UFC" />
            </a>
            <a href="https://dc.ufc.br" target="_blank" rel="noreferrer">
              <img src={Dc} alt="logo do MDCC-ufc" />
            </a>
            <a href="http://www.mdcc.ufc.br" target="_blank" rel="noreferrer">
              <img src={MdccLogo} alt="logo do MDCC-ufc" />
            </a>
            <a href="/">
              <img src={LsbdLogo} alt="logo do LSBD-ufc" />
            </a>
          </styles.LogoWrapper>
          <Typography
            style={{ textAlign: 'center', margin: '0 auto' }}
            variant="whiteP"
          >
            {t('footer.direitos')}
          </Typography>
        </styles.FooterSectionWrapper>
      </styles.Container>
    </styles.ResponsiveContainer>
  )
}

export default Footer
