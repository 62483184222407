import Cookies from 'js-cookie'

export default function logoutQueryString() {
    const myParam = (new URLSearchParams(window.location.search)).get('logout')
    if (myParam) {
        localStorage.clear()
        Cookies.remove('auth-token', {
            path: '/',
            domain: process.env.REACT_APP_DOMAIN
        })
        window.history.pushState({}, document.title, process.env.REACT_APP_LSBD_SITE)
    }

    return true
}
