import { webApi } from 'src/services/api/config'
import { AxiosResponse } from 'axios'

const baseRoute = 'api/PasswordReset'

/**
 * Changes users password
 */
export const postResetPassword = async (
  data: FormData
): Promise<AxiosResponse> => {
  return await webApi.post(`${baseRoute}/Reset`, data)
}

/**
 * Gets password reset token data
 */
export const getPasswordResetToken = async (
  token: string
): Promise<AxiosResponse> => {
  return await webApi.get(`${baseRoute}/Token/${token}`)
}
