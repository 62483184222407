import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { Search } from '@styled-icons/boxicons-regular'

type hoverProps = {
  readonly hoverActive: boolean
}

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px 80px 135px;
    max-width: 1440px;
    min-height: 100vh;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const Title = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  color: #2c3e51;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 36px;
    line-height: 48px;
    padding-top: 80px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    font-size: 48px;
    line-height: 48px;
  }
`

export const InputWrapper = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: flex;
    align-items: center;
  }
`

export const Input = styled.input`
  width: 17.5rem;
  border-radius: 30px;
  height: 2rem;
  outline: none;
  border: none;
  background: #eef2f6;

  padding: 0.625rem 2.275rem;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    height: 100%;
  }
`

export const CareersWrapper = styled.ul`
  display: flex;
  width: 100%;
  list-style-type: none;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 50px;
  }
`

export const CareersWrapperItem = styled.li`
  display: flex;
  padding-top: 16px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 49%;
  }
`

export const LinkC = styled(LinkR)`
  display: flex;
  width: 100%;
  text-decoration: none;
`

export const Link = styled(LinkR)`
  display: flex;
  width: fit-content;
  padding-top: 16px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #009fdf;

  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: transform 0.5s;

  &:hover {
    border-bottom: 1px solid #009fdf;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 13px;
    padding-top: 8px;
  }
`

export const ReadMoreCareer = styled.p`
  display: flex;
  width: fit-content;
  padding-top: 16px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #009fdf;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 13px;
    padding-top: 8px;
  }
`

export const CareersItemContainer = styled.div<hoverProps>`
  display: flex;
  background: #eef2f6;
  width: 100%;
  border: 1px solid transparent;

  ${({ hoverActive }) =>
    hoverActive &&
    `
      &:hover {
        cursor: pointer;
        border: 1px solid #2E2972;
        transition: all 0.2s ease-in-out;
        filter: brightness(95%);
      }
  `}

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
    padding: 16px 12px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 18px 8px 18px 24px;
    justify-content: space-between;
  }
`

export const CareersItemTag = styled.p`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 12px;
    height: 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: fit-content;
    padding: 0 8px;
  }
`

export const TitleMoreContainer = styled.div`
  display: flex;
  width: 100%;

  .estagio {
    display: flex;
    font-family: Rubik;
    border-radius: 32px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #7b61ff;
  }

  .closed {
    display: flex;
    font-family: Rubik;
    border-radius: 32px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #6d6f71;
    margin-left: 12px;
  }

  .clt {
    display: flex;
    font-family: Rubik;
    border-radius: 32px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #009fdf;

    @media only screen and (min-width: ${minDesktopScreenWidth}px) {
      width: 12%;
    }
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    flex-direction: column;
  }
`

export const TagsContainer = styled.div`
  display: flex;
`

export const CareersItemTitle = styled.p`
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 8px 0;
  }
`

export const CareersItemMore = styled.p`
  display: flex;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #009fdf;
  border-bottom: 1px solid transparent;
  width: fit-content;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #009fdf;
    transition: all 0.2s ease-in-out;
    filter: brightness(95%);
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 4px;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
  }
`

export const RightContainer = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`

export const JobNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #eef2f6;
  margin-top: 24px;
`

export const JobNotFoundText = styled.div`
  width: 40%;
  padding: 32px 0;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
  }
`

export const JobNotFoundP1 = styled.p`
  display: flex;
  justify-content: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
`

export const JobNotFoundP2 = styled.p`
  display: flex;
  justify-content: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 12px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
`

export const SearchIcon = styled(Search)`
  color: #797979;
  position: absolute;
  padding: 0 10px;
`
