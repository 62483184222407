import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const TabList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 3.5rem;

  border-bottom: 1px solid #c4c4c4;

  overflow: auto;
`

export const TabListItem = styled.li`
  display: block;
`

export const TabButton = styled.button`
  padding: 0.5em 1.5rem;
  background: none;
  border: 1px solid #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 140.62%;

  &:active,
  &:hover,
  &.active {
    background: #eef2f6;
    border: 1px solid #c4c4c4;
    border-bottom: none;
    color: #009fdf;
    /* font-weight: 600; */
  }
`
