/**
 * Action types
 */
export enum categoriesTypes {
  CATEGORIES_REQUEST = '@categories/FETCH_CATEGORIES_REQUEST',
  CATEGORIES_SUCCESS = '@categories/FETCH_CATEGORIES_SUCCESS',
  CATEGORIES_FAILURE = '@categories/FETCH_CATEGORIES_FAILURE'
}

/**
 * Data types
 */

export interface Categories {
  id: number
  title: string
  titleEN: string
}

/**
 * State type
 */

export interface CategoriesState {
  readonly categories: Categories[]
  readonly loading: boolean
  readonly error: boolean
}

export interface FetchCategoriesSuccessPayload {
  readonly data: Categories[]
}

export interface FetchCategoriesFailurePayload {
  error: string
}

export type fetchCategoriesRequest = {
  type: typeof categoriesTypes.CATEGORIES_REQUEST
}

export type fetchCategoriesSuccess = {
  type: typeof categoriesTypes.CATEGORIES_SUCCESS
  payload: FetchCategoriesSuccessPayload
}

export type fetchCategoriesFailure = {
  type: typeof categoriesTypes.CATEGORIES_FAILURE
  payload: FetchCategoriesFailurePayload
}

export type CategoriesAction =
  | fetchCategoriesRequest
  | fetchCategoriesSuccess
  | fetchCategoriesFailure
