/**
 * Action types
 */
export enum tagsTypes {
  TAGS_REQUEST = '@tags/FETCH_TAGS_REQUEST',
  TAGS_SUCCESS = '@tags/FETCH_TAGS_SUCCESS',
  TAGS_FAILURE = '@tags/FETCH_TAGS_FAILURE',

  REQUEST_UNIQUE_NEWS_TAGS = '@tags/REQUEST_UNIQUE_NEWS_TAGS',
  SUCCESS_UNIQUE_NEWS_TAGS = '@tags/SUCCESS_UNIQUE_NEWS_TAGS',
  FAILURE_UNIQUE_NEWS_TAGS = '@tags/FAILURE_UNIQUE_NEWS_TAGS',

  CREATE_TAG_IN_NEWS_REQUEST = '@tags/CREATE_TAG_IN_NEWS_REQUEST',
  CREATE_TAG_IN_NEWS_SUCCESS = '@tags/CREATE_TAG_IN_NEWS_SUCCESS',
  CREATE_TAG_IN_NEWS_FAILURE = '@tags/CREATE_TAG_IN_NEWS_FAILURE',

  TAGS_LIST_REQUEST = '@tags/FETCH_TAGS_LIST_REQUEST',
  TAGS_LIST_SUCCESS = '@tags/FETCH_TAGS_LIST_SUCCESS',
  TAGS_LIST_FAILURE = '@tags/FETCH_TAGS_LIST_FAILURE'
}

/**
 * Data types
 */

export interface Tag {
  id: number
  title: string
}

export interface TagsList {
  fk?: number
  fk1?: number
}

/**
 * State type
 */
export interface TagsState {
  readonly tags: Tag[]
  readonly loading: boolean
  readonly error: boolean
}

export interface TagsListState {
  readonly tagsList: TagsList[]
  readonly loading: boolean
  readonly error: boolean
}

export interface FetchTagsSuccessPayload {
  readonly data: Tag[]
}

export interface FetchUniqueTagsSuccessPayload {
  readonly data: Tag
}

export interface FetchTagsFailurePayload {
  error: string
}

export interface FetchTagsListSuccessPayload {
  readonly data: TagsList[]
}

export interface FetchTagsListFailurePayload {
  error: string
}

export interface FetchTagsRequest {
  type: typeof tagsTypes.TAGS_REQUEST
}
export type FetchTagsSuccess = {
  type: typeof tagsTypes.TAGS_SUCCESS
  payload: FetchTagsSuccessPayload
}
export type FetchTagsFailure = {
  type: typeof tagsTypes.TAGS_FAILURE
  payload: FetchTagsFailurePayload
}

export interface FetchTagsListRequest {
  type: typeof tagsTypes.TAGS_LIST_REQUEST
  payload?: number
}

export interface FetchTagsListSuccess {
  type: typeof tagsTypes.TAGS_LIST_SUCCESS
  payload: FetchTagsListSuccessPayload
}

export interface FetchTagsListFailure {
  type: typeof tagsTypes.TAGS_LIST_FAILURE
  payload: FetchTagsListFailurePayload
}

export interface CreateTagInNewsRequest {
  type: typeof tagsTypes.CREATE_TAG_IN_NEWS_REQUEST
  payload: FormData
}

export interface CreateTagInNewsSuccess {
  type: typeof tagsTypes.CREATE_TAG_IN_NEWS_SUCCESS
  payload: FetchTagsListSuccessPayload
}

export interface CreateTagInNewsFailure {
  type: typeof tagsTypes.CREATE_TAG_IN_NEWS_FAILURE
  payload: FetchTagsListFailurePayload
}

export interface FetchUniqueNewsTagsRequest {
  type: typeof tagsTypes.REQUEST_UNIQUE_NEWS_TAGS
  payload: number
}

export interface FetchUniqueNewsTagsSuccess {
  type: typeof tagsTypes.SUCCESS_UNIQUE_NEWS_TAGS
  payload: FetchTagsSuccessPayload
}

export interface FetchUniqueNewsTagsFailure {
  type: typeof tagsTypes.FAILURE_UNIQUE_NEWS_TAGS
  payload: FetchTagsFailurePayload
}

export type TagsActionTypes =
  | FetchTagsRequest
  | FetchTagsSuccess
  | FetchTagsFailure
  | FetchTagsListRequest
  | FetchTagsListSuccess
  | FetchTagsListFailure
  | CreateTagInNewsRequest
  | CreateTagInNewsSuccess
  | CreateTagInNewsFailure
  | FetchUniqueNewsTagsRequest
  | FetchUniqueNewsTagsSuccess
  | FetchUniqueNewsTagsFailure
