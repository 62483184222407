/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import ManagementContent from 'src/components/NewsModule/Management'
import ModuleFooter from 'src/components/NewsModule/Footer'
import usePersistedState from 'src/hooks/usePersistedState'
import { HIGHLIGHT_SCREEN } from 'src/components/NewsModule/constants'
import { useHistory } from 'react-router-dom'
import checkPermission from 'src/utils/checkPermission'

const Highlight = () => {
    const history = useHistory()
    const [user, setUser] = usePersistedState('auth-token', '')
    const [prevPage, setPrevPage] = usePersistedState('prev-page', '')

    useEffect(() => {
        if (user) {
            if (!checkPermission(user, HIGHLIGHT_SCREEN)) {
                history.push('/notFound')
            } else {
                setPrevPage('/news/management/highlight')
            }
        }
    }, [user])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <>
            <React.Suspense fallback="loading..">
                <EditorHeader current="MANAGEMENT" />
                <ManagementContent context={'Highlight'} />
                <ModuleFooter />
            </React.Suspense>
        </>
    )
}

export default Highlight
