import { CategoriesAction, CategoriesState, categoriesTypes } from './types'

const INITIAL_STATE: CategoriesState = {
  categories: [],
  error: false,
  loading: false
}

export default function (state = INITIAL_STATE, action: CategoriesAction) {
  switch (action.type) {
    case categoriesTypes.CATEGORIES_REQUEST:
      return { ...state, loading: true }
    case categoriesTypes.CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.data,
        error: false
      }
    case categoriesTypes.CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        payload: action.payload.error,
        categories: []
      }
    default:
      return { ...state }
  }
}
