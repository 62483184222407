import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { Link as LinkRouter } from 'react-router-dom'

export const CenterBlockGray = styled.div`
  padding: 24px 16px;
  background: #eef2f6;
  border-radius: 2px;
  text-align: center;
`

export const BlackLink = styled.a`
  text-decoration: none;
  color: black;
`
