import styled from 'styled-components'

type ButtonProps = {
    disabled: boolean
}

export const Container = styled.div`
    display: flex;
    align-items: center;
`

export const Button = styled.img<ButtonProps>`
    width: 24px;
    height: 24px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const Input = styled.input`
    width: 40px !important;
    margin: 0 8px !important;
    border-radius: 10px !important;
    padding: 10px !important;
    text-align: center;

    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    } 
`
