import * as S from './styles'
import { useTranslation } from 'react-i18next'

const Sidebar = ({ isOpen, toggleSidebar }: any) => {
  const { t } = useTranslation()
  return (
    <>
      <S.SidebarContainer isOpen={isOpen}>
        <S.SidebarWrapper>
          <S.SidebarMenu>
            <S.SidebarLink
              onClick={toggleSidebar}
              smooth={true}
              duration={1000}
              offset={-80}
              to="about"
            >
              {t('navbar.sobre')}
            </S.SidebarLink>
            <S.Link onClick={toggleSidebar} to="/news/home">
              {t('navbar.news')}
            </S.Link>
            <S.Link onClick={toggleSidebar} to="/rsc">
              {t('navbar.pos-graduacao')}
            </S.Link>
            <S.Link onClick={toggleSidebar} to="/news/careers">
              {t('navbar.vagas')}
            </S.Link>
            <S.SidebarLink
              onClick={toggleSidebar}
              smooth={true}
              duration={1000}
              offset={-80}
              to="contact"
            >
              {t('navbar.contato')}
            </S.SidebarLink>
          </S.SidebarMenu>
        </S.SidebarWrapper>
      </S.SidebarContainer>
    </>
  )
}

export default Sidebar
