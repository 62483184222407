import * as styles from './styles'

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Introduction from './introduction'
import { useTranslation } from 'react-i18next'
import { FileX } from '@styled-icons/bootstrap'
import InfoBox from './InfoBox'
import Tabs from './Tabs/tabs'
import Tab from './Tabs/tab'
import Information from './information'
import Disciplines from './disciplines'
import Selection from './selection'
import useMedia from 'src/hooks/useMedia'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import OrangeButton from 'src/components/Button/OrangeButton'
import { webApi } from 'src/services/api/config'
import usePersistedState from 'src/hooks/usePersistedState'

const PostGraduateContent = (props: any) => {
  const [user, setUser] = usePersistedState('auth-token', '')

  const { t } = useTranslation()
  const { width } = useMedia()

  const fileUri =
    process.env.REACT_APP_SERVER + 'api/file/;portal_lsbd;postgraduate_files'

  // const fileUri = process.env.REACT_APP_SERVER + 'api/file/debug;public'

  const fieldToReadableString = (field: string): string => {
    switch (field) {
      case 'fullName':
        return 'Nome Completo'
      case 'socialName':
        return 'Nome Social'
      case 'address':
        return 'Endereço'
      case 'phone':
        return 'Telefone'
      case 'city':
        return 'Cidade'
      case 'state':
        return 'Estado'
      case 'country':
        return 'Pais'
      case 'cpf':
        return 'CPF'
      case 'rg':
        return 'RG'
      case 'email':
        return 'Email'

      case 'courseName':
        return 'Nome do Curso Superior'
      case 'educationalInstitution':
        return 'Nome da Instituição de Ensino'

      case 'courseStartDate':
        return 'Ano de Início do Curso'
      case 'courseEndDate':
        return 'Ano de Conclusão do Curso'

      case 'fileDocPhoto':
        return 'Documento com Foto'
      case 'fileDocSIGAA':
        return 'Comprovante do SIGAA'
      case 'fileDocCommitment':
        return 'Termo de Compromisso'
      case 'fileDocCourse':
        return 'Comprovante de Conclusão do Curso'
      case 'fileDocUFC':
        return 'Comprovante de Vínculo UFC'
      case 'fileDocBarema':
        return 'Barema Curricular'
      case 'fileDocLetter':
        return 'Carta de Motivação'
      default:
        return field
    }
  }

  const replacerText = (_key: any, value?: any) => {
    return value === null ? '' : value
  }

  const replacerYear = (_key: any, value?: string) => {
    return value === null ? '' : value?.substring(0, 4)
  }

  const replacerLink = (_key: any, value?: string) => {
    if (value != null) {
      return fileUri + value.replaceAll('/', ';')
    } else return ''
  }

  const fieldToCallback = (field: string) => {
    switch (field) {
      case 'courseStartDate':
        return replacerYear
      case 'courseEndDate':
        return replacerYear

      case 'fileDocPhoto':
        return replacerLink
      case 'fileDocSIGAA':
        return replacerLink
      case 'fileDocCommitment':
        return replacerLink
      case 'fileDocCourse':
        return replacerLink
      case 'fileDocUFC':
        return replacerLink
      case 'fileDocBarema':
        return replacerLink
      case 'fileDocLetter':
        return replacerLink

      default:
        return replacerText
    }
  }

  const padZero = (string: string | number) => {
    return ('0' + string).slice(-2)
  }

  const downloadSubscriptions = async () => {
    // TODO: Transfer this request to API layer
    await webApi
      .get('/api/site/postgraduate/subscriptions/cybersec')
      .then(response => {
        const json = response.data
        const fields = Object.keys(json[0]).filter(value => {
          return value !== 'id'
        })

        let csv = json.map((row: any) => {
          return fields
            .map((fieldName: string) => {
              return JSON.stringify(row[fieldName], fieldToCallback(fieldName))
            })
            .join(',')
        })

        csv.unshift(fields.map(fieldToReadableString).join(','))
        csv = csv.join('\r\n')

        const now = new Date()
        const formattedDate = `${now.getFullYear()}-${padZero(
          now.getMonth() + 1
        )}-${padZero(now.getDate())}-${padZero(now.getHours())}h${padZero(
          now.getMinutes()
        )}m`

        const link = document.createElement('a')
        link.href = `data:text/csv;chatset=utf-8,${encodeURIComponent(csv)}`
        link.download = 'inscricoes-rsc-' + formattedDate + '.csv'
        link.click()
      })
  }

  const boxes = [
    'Presencial',
    '528 h/aulas,</br> 33 créditos',
    'Período de</br> 22 meses',
    'Aulas iniciam</br> dia 10/05/22'
  ]

  return (
    <styles.ResponsiveContainer>
      <styles.Container>
        <styles.BreadCrumbWrapper>
          <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
          <styles.ChevronWrapper>
            <styles.Chevron />
          </styles.ChevronWrapper>
          <styles.TitleCrumb>Pós-Graduação</styles.TitleCrumb>
        </styles.BreadCrumbWrapper>

        <styles.ContentWrapper>
          <styles.Header>
            <styles.PageTitle>{t('news.postgraduate.title')}</styles.PageTitle>

            {width >= minDesktopScreenWidth ? (
              <InfoBox boxes={boxes}></InfoBox>
            ) : (
              <>
                <InfoBox boxes={boxes.slice(0, 2)}></InfoBox>
                <InfoBox boxes={boxes.slice(2)}></InfoBox>
              </>
            )}

            {/* Javam ou Zé Maria */}
            {user && (user.id === '127' || user.id === '9459051') ? (
              <styles.ContentContainer>
                <styles.ButtonWrapper>
                  <styles.ButtonContainer style={{ maxWidth: '340px' }}>
                    <OrangeButton
                      title="Baixar planilha de inscritos"
                      onClick={downloadSubscriptions}
                    ></OrangeButton>
                  </styles.ButtonContainer>
                </styles.ButtonWrapper>
              </styles.ContentContainer>
            ) : (
              <></>
            )}
          </styles.Header>

          <Tabs>
            <Tab title="Apresentação">
              <Introduction></Introduction>
            </Tab>
            <Tab title="Formato">
              <Information></Information>
            </Tab>
            <Tab title="Disciplinas">
              <Disciplines></Disciplines>
            </Tab>
            <Tab title="Seleção">
              <Selection></Selection>
            </Tab>
          </Tabs>
        </styles.ContentWrapper>
      </styles.Container>
    </styles.ResponsiveContainer>
  )
}

export default PostGraduateContent
