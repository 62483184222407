import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import HomeNews from './pages/newsModule/HomeNews'
import SearchNews from './pages/newsModule/SearchNews'
import ListNews from './pages/newsModule/ListNews'
import ListCareers from './pages/newsModule/ListCareers'
import AddNews from './pages/newsModule/AddNews'
import EditNews from './pages/newsModule/EditNews'
import ReviewApprove from './pages/newsModule/ReviewApprove'
import ReviewPublish from './pages/newsModule/ReviewPublish'
import ViewNews from './pages/newsModule/ViewNews'
import Published from './pages/newsModule/Management/Published'
import PendingApproval from './pages/newsModule/Management/PendingApproval'
import PendingPublished from './pages/newsModule/Management/PendingPublished'
import MyNews from './pages/newsModule/Management/MyNews'
import NotFound from './pages/NotFound'
import ProtectedRoute from './protectedRoutes'
import GeneralRoute from './generalRoutes'
import ViewPostGraduate from './pages/newsModule/PostGraduate/ViewPostGraduate'
import PostGraduateSubscribe from './pages/newsModule/PostGraduate/Subscribe'
import PostGraduateResuls from './pages/newsModule/PostGraduate/Results'
import PostGraduateInauguralClass from './pages/newsModule/PostGraduate/InauguralClass'
import ForgotPassword from './pages/password/ForgotPassword'
import ResetPassword from './pages/password/ResetPassword'
import Highlight from './pages/newsModule/Management/Highlight'
import AddHighlight from './pages/newsModule/AddHighlight'
import EditHighlight from './pages/newsModule/EditHighlight'

const Routes = () => {
    return (
        <Router>
            <Switch>
                <GeneralRoute exact path="/" component={Home} />
                <Route exact path="/news/home" component={HomeNews} />
                <Route
                    exact
                    path="/news/home/search=:text"
                    component={SearchNews}
                />

                <ProtectedRoute
                    exact
                    path="/news/management"
                    component={Published}
                />

                <ProtectedRoute
                    exact
                    path="/news/management/my-news"
                    component={MyNews}
                />

                <ProtectedRoute
                    exact
                    path="/news/management/pending-approval"
                    component={PendingApproval}
                />

                <ProtectedRoute
                    exact
                    path="/news/management/pending-published"
                    component={PendingPublished}
                />

                <ProtectedRoute
                    exact
                    path="/news/management/my-news/:id"
                    component={ViewNews}
                />

                <ProtectedRoute
                    exact
                    path="/news/review-approve/:id"
                    component={ReviewApprove}
                />
                <ProtectedRoute
                    exact
                    path="/news/review-publish/:id"
                    component={ReviewPublish}
                />

                <ProtectedRoute
                    exact
                    path="/news/add-news"
                    component={AddNews}
                />
                <ProtectedRoute
                    exact
                    path="/news/edit-news/:id"
                    component={EditNews}
                />

                <Route exact path="/news/careers" component={ListCareers} />
                <Route exact path="/news/blog" component={ListNews} />
                <Route exact path="/news/publications" component={ListNews} />
                <Route exact path="/news/defenses" component={ListNews} />
                <Route exact path="/news/events" component={ListNews} />
                <Route exact path="/news/awards" component={ListNews} />

                <Route exact path="/news/careers/:id" component={ViewNews} />
                <Route exact path="/news/blog/:id" component={ViewNews} />
                <Route
                    exact
                    path="/news/publications/:id"
                    component={ViewNews}
                />
                <Route exact path="/news/defenses/:id" component={ViewNews} />
                <Route exact path="/news/events/:id" component={ViewNews} />
                <Route exact path="/news/awards/:id" component={ViewNews} />

                <Route exact path="/rsc" component={ViewPostGraduate} />
                <Route
                    exact
                    path="/rsc/inscricao"
                    component={PostGraduateSubscribe}
                />
                <Route
                    exact
                    path="/rsc/resultados"
                    component={PostGraduateResuls}
                />
                <Route
                    exact
                    path="/rsc/aula-magna"
                    component={PostGraduateInauguralClass}
                />

                <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                />
                <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                />

                <ProtectedRoute
                    exact
                    path="/news/management/highlight"
                    component={Highlight}
                />

                <ProtectedRoute
                    exact
                    path="/add-highlight"
                    component={AddHighlight}
                />

                <ProtectedRoute
                    exact
                    path="/edit-highlight/:id"
                    component={EditHighlight}
                />

                <Route exact path="*" component={NotFound} />
            </Switch>
        </Router>
    )
}

export default Routes
