import styled from 'styled-components'

import { AiOutlineInstagram } from 'react-icons/ai'

import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #2e2972;
  width: 100vw;
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #2e2972;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 60px 80px 24px;
    max-width: 1440px;
  }
`

export const FooterSectionWrapper = styled.div`
  display: flex;
  /* height: 360px; */
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    align-items: flex-start;
  }
`

export const IconsWrapper = styled.div`
  display: flex;

  img {
    cursor: pointer;
    margin-left: 20px;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    align-self: center;
    padding-bottom: 32px;
  }
`

export const Instagram = styled(AiOutlineInstagram)`
  background: #fff;
  color: #2e2972;

  border-radius: 50%;
  cursor: pointer;
`

export const ContactWrapper = styled.div`
  text-align: center;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 22px 0 39px;
  }
`

export const SectionNamesWrapper = styled.div`
  display: flex;

  a + a {
    margin-left: 40px;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    margin-top: 2rem;
    a + a {
      margin: 2rem 0 0 0;
    }
    flex-direction: column;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 38px 0 51px;
  }
`

export const SectionLink = styled(LinkS)`
  cursor: pointer;
`

export const Divider = styled.hr`
  border: 1px solid #ffffff;
  width: 100%;
`
export const LogoWrapper = styled.div`
  display: flex;

  a + a {
    margin-left: 40px;
    cursor: pointer;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 0;
    margin: 4rem auto;

    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;

    a + a {
      margin: 0;
    }
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 58px 0 71px;
  }
`

export const ButtonLink = styled(LinkR)`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 40px 0 22px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: none;
  }
`

export const Button = styled.button`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fec074;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #fec074;
  padding: 8px 10px;
`

export const TestVersion = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.87);
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding-top: 48px;
  margin-left: auto;
`
