import styled from 'styled-components'

type ResponsiveContainerProps = {
  readonly color: string
}

export const ResponsiveContainer = styled.div<ResponsiveContainerProps>`
  display: flex;
  justify-content: center;
  width: 100vw;
  background: ${({ color }) => color || 'none'};
`

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 80px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
