import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { Link as LinkR } from 'react-router-dom'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 80px 24px 36px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px;
    max-width: 1440px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding-top: 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 1440px;
    align-items: center;
    padding-top: 40px;
  }
`

export const NewsTitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const NewsTitle = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;

  font-size: 36px;
  line-height: 48px;
  color: #000000;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 32px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const Author = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  padding: 32px 0 8px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 18px;
    line-height: 20px;
    padding: 20px 0 8px;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const Date = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 32px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
    @media only screen and (min-width: ${minDesktopScreenWidth}px) {
      margin-bottom: 16px;
    }
  }
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const AltImage = styled.p`
  display: flex;
  width: 100%;
  padding: 0 0 32px;
  justify-content: center;

  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #000000;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 12px 0;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  word-break: break-word;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 18px;
  }
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 40px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 1440px;
    padding-top: 40px;
  }
`

export const TagLabel = styled.p`
  display: flex;
  width: 100%;
  max-width: 700px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  color: #000000;
`

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  padding-top: 24px;
  gap: 16px;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 12px;
    gap: 8px;
  }
`

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px;
  background: #009fdf;
  color: #ffffff;
  border-radius: 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 40px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 1440px;
    padding-top: 40px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: flex-end;
  max-width: 700px;
  padding-top: 40px;
`

export const ResponsiveContainerCareers = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const ContainerCareers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px 80px 113px;
    max-width: 1440px;
  }
`

export const BreadCrumbWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
  }
`

export const Careers = styled(LinkR)`
  display: flex;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.62%;

  color: #009fdf;
  cursor: pointer;
  text-decoration: none;
`

export const TitleCrumb = styled.p`
  display: flex;
  align-items: center;
`

export const Chevron = styled(ChevronRight)`
  display: flex;
  width: 15px;

  color: #000000;
`

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0 19px;
`

export const AplicateContainer = styled.div`
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 200px;
  }
`

export const Text = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const JobWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #eef2f6;
  padding: 24px 0;
`

export const TextJob = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const Email = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const TextFooter = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-self: center;
  padding-top: 16px;

  color: #000000;
`

export const JobTitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 24px;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 40px 200px 0;
  }

  .estagio {
    display: flex;
    font-family: Rubik;
    border-radius: 32px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    background: #7b61ff;
    margin-top: 8px;
  }

  .clt {
    display: flex;
    font-family: Rubik;
    border-radius: 32px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #009fdf;
    margin-top: 8px;

    @media only screen and (min-width: ${minDesktopScreenWidth}px) {
      width: 10%;
    }
  }
`

export const JobTag = styled.p`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 12px;
    height: 24px;
    width: 20%;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 15%;
  }
`

export const ContentContainerCareers = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 40px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 40px 200px 0;
  }
`

export const Cancel = styled.button`
  border: none;
  background: none;
  text-decoration: none;
  padding: 1rem 1rem;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #fec074;
`
