/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import ManagementContent from 'src/components/NewsModule/Management'
import ModuleFooter from 'src/components/NewsModule/Footer'

import { useHistory } from 'react-router-dom'
import usePersistedState from 'src/hooks/usePersistedState'
import checkPermission from 'src/utils/checkPermission'
import { PUBLISHED_SCREEN } from 'src/components/NewsModule/constants'

const Published = () => {
  const history = useHistory()
  const [user, setUser] = usePersistedState('auth-token', '')
  const [prevPage, setPrevPage] = usePersistedState('prev-page', '')

  useEffect(() => {
    if (user) {
      if (!checkPermission(user, PUBLISHED_SCREEN)) {
        history.push('/notFound')
      } else {
        setPrevPage('/news/management')
      }
    }
  }, [user])

  useEffect(() => {
    setPrevPage('/news/management')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        <EditorHeader current="MANAGEMENT" />
        <ManagementContent context={'Published'} />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default Published
