import { Link } from 'react-router-dom'
import { maxMobileScreenWidth } from 'src/utils/breakpoints'
import useMedia from 'src/hooks/useMedia'

const NotFound = () => {
  const { width } = useMedia()

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {width <= maxMobileScreenWidth ? (
        <h1 style={{ fontSize: '36px', alignSelf: 'center' }}>
          404 - Not Found!
        </h1>
      ) : (
        <h1 style={{ fontSize: '48px', alignSelf: 'center' }}>
          404 - Not Found!
        </h1>
      )}

      <Link to="/" style={{ fontSize: '24px', marginTop: '24px' }}>
        Go Home
      </Link>
    </div>
  )
}

export default NotFound
