import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { Link as LinkR } from 'react-router-dom'
import { Search } from '@styled-icons/boxicons-regular'

type ModalProps = {
  showModal: boolean
  zIndex?: number
}

type zIndexProps = {
    zIndex?: number
}

export const Background = styled.div<zIndexProps>`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${props => props.zIndex ? props.zIndex : 10};
`

export const ModalWrapper = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 560px;
  background: #ffffff;
  position: relative;
  z-index: ${props => props.zIndex ? props.zIndex : 10};
  border-radius: 10px;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .justifyText {
    margin: 12px 0;
  }

  h1 {
    max-width: 374px;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    color: #6d6f71;

    margin: 2.25rem 5.625rem;
    white-space: nowrap;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

    color: rgba(0, 0, 0, 0.54);
    width: 90%;
  }

  input {
    width: 300px;
    margin: 12px 0 24px;
    padding: 10px 10px 10px 16px;
    background: #edf1f4;
    border-radius: 30px;
    border: none;
    outline: none;
  }

  div {
    display: flex;
    margin: 2.25rem 5.625rem 1.2rem;
    justify-content: space-evenly;
  }
`

export const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  width: 450px;
  flex-direction: column;
  margin: 0 !important;

  .textLength {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 8px 0 0 !important;
    color: #797979;
  }

  .textLengthFull {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 8px 0 0 !important;
    color: red;
  }

  .textLengthAlmostFull {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 8px 0 0 !important;
    color: #ffdd33;
  }

  textarea {
    width: 100%;
    display: flex;
    min-height: 120px;
    padding: 16px 32px;
    background: #edf1f4;
    border-radius: 12px;
    border: none;
    outline: none;
    resize: none;
  }
`

export const InvalidPassword = styled.p`
  position: absolute;
  margin-top: 32px;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: red !important;
  width: 90%;
`

export const EmptyJustify = styled.p`
  position: absolute;
  width: 100% !important;
  bottom: 0;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: red !important;
`

export const ButtonLeft = styled.button`
  font-size: 14px;
  line-height: 24px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0357143em;
  white-space: nowrap;

  color: #9a9a9a;

  background: none;
  border: none;

  cursor: pointer;

  text-transform: uppercase;
`

export const ButtonRight = styled.button`
  margin-left: 2rem;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0357143em;
  white-space: nowrap;

  color: #ffffff;

  padding: 8px 16px;

  background: #2e2972;
  border-radius: 20px;
  border: none;

  max-width: 215px;
  max-height: 40px;

  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
`

export const CloseModalButton = styled(MdClose)<zIndexProps>`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  padding: 0;
  z-index: ${props => props.zIndex ? props.zIndex : 10};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  h1 {
    max-width: 374px;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    color: #6d6f71;

    margin: 2.25rem 5.625rem;
    white-space: nowrap;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

    color: rgba(0, 0, 0, 0.54);
  }
`

export const OkayButton = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0357143em;
  white-space: nowrap;

  color: #ffffff;

  padding: 8px 16px;
  margin: 3rem auto;

  background: #2e2972;
  border-radius: 20px;
  border: none;

  max-width: 53px;
  max-height: 40px;

  text-transform: uppercase;
  &:hover {
    opacity: 0.8;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
`

export const SidebarForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 80%;
    margin-top: 3rem;
  }
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 100%;
    height: 100%;
    background: #fec074;
    padding: 40px 35px 60px;
    justify-content: center;
    border-radius: 5px;
  }
`

export const EmailInputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 12px;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
`

export const PasswordInputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LabelInput = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: none;
  }
  width: fit-content;
  align-self: center;
  color: #000000;
  padding-right: 8px;
`

export const SidebarInput = styled.input`
  background: #ffffff;
  border: 0;
  border-radius: 12px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 10px;
    outline: 0;

    height: 26px;
    min-width: 200px;
    max-width: 400px;

    margin-top: 1.5rem;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 6px 40px 6px 8px;
    min-width: 270px;

    ::placeholder {
      color: transparent;
    }
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
`

export const Checkbox = styled.input`
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
  border: 0;
  outline: 0;

  width: 16px;
  height: 16px;
  border-radius: 3px;
`

export const Label = styled.label`
  color: #ffffff;
  font-size: 1rem;
  margin-left: 1rem;
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    color: #000000;
  }
`

export const LoginForgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const LoginButton = styled.button`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    color: #ffffff;
    width: 113px;
    height: 48px;
    left: 123.5px;
    top: 161px;

    border: 0;
    outline: 0;

    background: #fec074;
    border-radius: 30px;

    margin: 1.5rem 0;
  }
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    color: #ffffff;
    border-radius: 100px;
    width: 113px;
    height: 32px;
    background: #2e2972;
    margin: 10px 0 0 0;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const ForgotPassword = styled(LinkR)`
  position: absolute;
  bottom: 0;
  margin: 0 0 30px 0;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  color: #ffffff;
  font-size: 1rem;
  transition: width ease 1s;
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    color: #009fdf;
  }
  &:hover {
    border-bottom: 1px solid #009fdf;
  }
`

export const invalidCredential = styled.div`
  position: absolute;
  top: 0;
  color: red;
  padding-top: 12px;
`

export const LoggedItemContainer = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`

export const LoggedItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 300px;
`

export const LoggedItem = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 100px;
  padding: 20px 0;

  img {
    margin: 0 auto;
    width: 25px;
    height: 25px;
  }

  &:hover {
    opacity: 0.6;
  }
`

export const LoggedItemTitle = styled.p`
  color: #fec074;
  font-style: normal;
  font-size: 12px;
  font-weight: 900;
  min-width: 120px;
  padding-bottom: 12px;
`

export const LoggedItemLabel = styled.p`
  font-style: normal;
  color: #2e2972;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`

export const LoggedItemDivider = styled.hr`
  width: 160px;
  border: 0.3px solid #fec074;
  margin: 16px 0;
`

export const LoggedItemLink = styled.a`
  text-decoration: none;
`

export const LoggedItemLinkNews = styled(LinkR)`
  text-decoration: none;
`

export const EyeIcon = styled.img`
  width: 18px;
  height: 18px;
  right: 0;
  margin: 5px 45px 0 0;
  position: absolute;
  cursor: pointer;
`

export const HighlightManagementModalWrapper = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1200px;
  height: 620px;
  background: #ffffff;
  position: relative;
  z-index: 10;
  border-radius: 40px;
`

export const HighlightManagementTitle = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin: 22px 0 10px;
    color: #6D6F71;
`

export const HighlightManagementHorizontalDivider = styled.hr`
    width: 100%;
    color: rgba(109, 111, 113, 0.2);
`

export const HighlightManagementButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 24px 22px 0;
`

export const HighlightManagementContent = styled.div`
    height: 80%;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
`

export const HighlightManagementContentSideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 550px;
`

export const HighlightManagementVerticalDivider = styled.div`
    height: 100%;
    margin: 0 16px;
    border: 1px solid rgba(109, 111, 113, 0.2);
`

export const HighlightManagementContentSideWrapperHeader = styled.div`
    display: flex;
    flex-direction: column;
    height: 15%;
`

export const HighlightManagementContentSideWrapperHeaderRight = styled.div`
    display: flex;
    height: 15%;
    justify-content: flex-end;
`

export const HighlightManagementContentSideWrapperBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 85%;
`

type HighlightDragDropContainerProps = {
    isDragging: boolean
}

export const HighlightDragDropContainer = styled.ul<HighlightDragDropContainerProps>`
    height: 500px;
    overflow-y: auto;
    border: ${(props) => props.isDragging ? '2px dashed #009FDF' : 'transparent'};
    background: ${(props) => props.isDragging ? 'rgba(0,191,165,.04)' : 'none'};
`

export const HighlightDragDropItem = styled.li`
    height: 100px;
`

export const HighlightItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 90px;
    margin-bottom: 3px;
`

type HighlightItemOrderProps = {
    color: string
}

export const HighlightItemOrder = styled.div<HighlightItemOrderProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 5%;

    padding: 36px 12px;
    background-color: ${({ color }) => color};

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
`

export const HighlightItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 11px 15px;
    background-color: #EEF2F6;
`

export const HighlightItemContent = styled.div`
    display: flex;
    width: 90%;
`

export const HighlightItemImage = styled.img`
    width: 64px;
    height: 64px;
    margin: auto 0;
    object-fit: cover;
`

export const HighlightItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0 auto 16px;
    width: 90%;
    height: 64px;
`

export const HighlightItemDateWrapper = styled.div`
    display: flex;
`

export const HighlightItemFixedWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #009FDF;
    margin-left: 8px;
    border-radius: 30px;

    img {
        width: 6px;
        height: 6px;
    }
`

export const HighlightItemInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75%;
`

export const HighlightDropdownContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;
`

export const HighlightItemEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const HighlightItemDragDotsImage = styled.img`
    width: 10px;
    height: 16px;
    margin: auto 12px auto 0;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    height: 100%;
    width: 17.5rem;
    border-radius: 30px;
    outline: none;
    border: none;
    background-color: rgba(237, 241, 244, 1);

    padding: 10px 2.275rem;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;
  margin-right: 1rem;
  align-items: center;
  cursor: pointer;
  height: 35px;
`

export const SearchIcon = styled(Search)`
  color: #797979;
  position: absolute;
  padding: 0 10px;
`

export const HighlightPreviewContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 73%;
  height: 90%;
  background: #ffffff;
  position: relative;
  z-index: 10;
  border-radius: 40px;
`

export const HighlightPreviewContentContainer = styled.div`
    margin: 0 auto;
    height: 80%;
    width: 98%;
    display: flex;
    position: relative;
`

type HeroPreviewLeftContainerProps = {
    readonly url: string
  }

export const HeroPreviewLeftContainer = styled.aside<HeroPreviewLeftContainerProps>`
    width: 50%;
    background: ${props => `url(${props.url})`} center no-repeat;
    background-size: cover;
    transition: ease 0.2s;
`

export const HeroPreviewRightContainer = styled.main`
  width: 50%;
  background: ${({ theme }) => theme.colors.defaultBlue};
  padding: 72px 30px 40px;
`

export const HeroPreviewRightContainerWrapper = styled.div`
  height: 100%;
`

export const HeroPreviewContentWrapper = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HeroPreviewTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;

  .info {
    margin-bottom: 16px;
  }
`

export const HeroPreviewTitleWrapper = styled.div`
    width: 80%;

    p {
        font-size: 24px;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const HeroPreviewAditionalText = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  color: #deeffe;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeroPreviewButtonContainer = styled.div`
  width: 150px;
  height: 45px;
  border-radius: 30px;
`

export const HeroPreviewButton = styled.div`
    font-family: 'Work sans';
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
    padding: 16px 0;
    background: #FEC074;
    color: #ffffff;
    text-align: center;
    border-radius: 30px;
`

export const HighlightPreviewButtonsWrapper = styled.div`
  display: flex;
  height: 10%;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
`

export const HighlightPreviewTitleWrapper = styled.div`
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HighlightPreviewTitle = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #6D6F71;
`

export const HighlightPreviewLanguageWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #797979;
    border-radius: 30px;
    padding: 6px 8px;
`

type HighlightPreviewLanguageOptionProps = {
    isSelected: boolean
}

export const HighlightPreviewLanguageOption = styled.div<HighlightPreviewLanguageOptionProps>`
    background: ${props => props.isSelected ? '#FEC074' : 'transparent'};
    border-radius: 20px;
    padding: 4px 8px;
    cursor: pointer;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.isSelected ? '#ffffff' : '#797979'};
`

export const HighlightPreviewLanguageDivider = styled.div`
    width: 1px;
    height: 20px;
    background: #797979;
    margin: 0 8px;
`

export const HighlightPreviewNavBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 24px 8px 0 30px;
`

export const HighlightPreviewNavBarLogo = styled.img`
    width: 45px;
    height: 30px;
    margin: auto 0;
`

export const HighlightPreviewNavBarWrapper = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-between;
    padding-left: 18px;
    align-items: center;
`

export const HighlightPreviewNavBarOption = styled.p`
    color: #ffffff;
    font-size: 10px;
    font-family: 'Rubik';
`

export const HighlightPreviewNavBarLogin = styled.div`
    color: #fec074;
    font-family: 'Rubik';
    font-size: 11px;
    padding: 8px 30px;
    border-radius: 24px;
    border: 1px solid #fec074;
    background: rgba(207,221,228,0.1);
`

export const HighlightPreviewNavBarLanguageWrapper = styled.div`
    display: flex;
`

export const HighlightPreviewNavBarLanguageOption = styled.p<HighlightPreviewLanguageOptionProps>`
    font-size: 12px;
    color: ${props => props.isSelected ? '#fec074' : 'rgba(255,255,255,0.3)'};
`

export const HighlightPreviewNavBarLanguageDivider = styled.div`
    border: 1px solid #ffffff;
    height: 14px;
    margin: 0 4px;
`

export const HighlightManagementEmptyHighlightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #EEF2F6;
`
