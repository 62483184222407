import * as styles from './styles'
import CreatableSelect from 'react-select/creatable'
import Select, { components } from 'react-select'

type SelectObjectProps = {
    value: any
    label: string
}

type SelectInputProps = {
    isCreatable?: boolean
    required?: boolean
    placeholder?: string
    value: SelectObjectProps
    valueName: string
    handleChange: (name: string, value: any) => void
    error?: boolean
    errorMessage?: string
    setError?: (a: boolean) => void
    selectList: SelectObjectProps[]
    maxLength?: number
}

export const SelectInput = ({
    isCreatable,
    required,
    placeholder,
    selectList,
    value,
    valueName,
    handleChange,
    error,
    errorMessage,
    setError,
    maxLength
}: SelectInputProps) => {
    const handleChangeSelect = (e: any) => {
        if (setError) {
            setError(false)
        }

        if (e.__isNew__) {
            handleChange(valueName, { value: 0, label: e.label })
        } else {
            handleChange(valueName, e)
        }
    }

    const Input = (props: any) => (
        <components.Input {...props} maxLength={maxLength} />
    )

    return (
        <>
            {!isCreatable ? (
                <Select
                    placeholder={placeholder || ''}
                    defaultValue={value}
                    options={selectList}
                    onChange={handleChangeSelect}
                />
            ) : (
                <CreatableSelect
                    placeholder={placeholder || ''}
                    defaultValue={value}
                    options={selectList}
                    onChange={handleChangeSelect}
                    components={maxLength ? { Input } : {}}
                />
            )}

            {error && <styles.TextError>{errorMessage}*</styles.TextError>}
        </>
    )
}
