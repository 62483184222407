import * as styles from './styles'
import { SelectButton } from 'src/components/Button'
import { useState, useEffect } from 'react'
import {
    ResponsiveContainer,
    ContentContainer,
    ButtonsContainer
} from 'src/components/Containers/containers'
import { NewsInput } from 'src/components/Inputs/NewsInput'
import { Input } from 'src/components/Inputs/Input'
import CancelButton from 'src/components/Button/CancelButton'
import GenericButton from 'src/components/Button/GenericButton'
import { ImageInput } from 'src/components/Inputs/ImageInput'
import { SelectInput } from 'src/components/Inputs/SelectInput'
import { Divider } from 'src/components/Divider/divider'
import Typography from 'src/components/Typography'
import { arrayToTypes } from 'src/utils/General/arrayToTypes'
import BilingualIcon from 'src/assets/icons/general/bilingual.svg'
import { categoriesRequest } from 'src/store/ducks/categories/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/ducks/rootReducer'
import {
    createHighlight,
    getHighlight,
    updateHighlight
} from 'src/components/NewsModule/Api/api'
import { useHistory } from 'react-router-dom'
import Modal from 'src/components/Modal'
import Spinner from 'src/components/Spinner'
import OpenNewTab from 'src/assets/icons/general/openNewTab.svg'
import HighlightPreviewModal from 'src/components/Modal/HighlightPreviewModal'

export const SupportBilingual = () => {
    return (
        <>
            <Typography
                style={{
                    fontWeight: 600,
                    marginBottom: '16px'
                }}
                variant="h3Black"
            >
                Suporte bilíngue
            </Typography>

            <styles.SupportBilingualContainer>
                <styles.SupportBilingualIconWrapper>
                    <styles.SupportBilingualIcon src={BilingualIcon} />
                    <Typography
                        style={{
                            fontWeight: 600,
                            marginLeft: '8px'
                        }}
                        variant="buttonInactive"
                    >
                        Conteúdos da página inicial
                    </Typography>
                </styles.SupportBilingualIconWrapper>

                <Typography
                    style={{
                        fontWeight: 400,
                        marginTop: '8px'
                    }}
                    variant="pWhite"
                >
                    Nosso site possui suporte bilíngue e para mantermos o padrão
                    nas publicações, sugerimos que seja preenchido os campos
                    abaixo com uma versão em{' '}
                    <b
                        style={{
                            color: '#FEC074'
                        }}
                    >
                        Inglês
                    </b>{' '}
                    da sua publicação.
                </Typography>
            </styles.SupportBilingualContainer>
        </>
    )
}

const AddHighlightContent = (props: any) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { context, id } = props
    const selectList = [{ id: 0, title: 'Destaque na página inicial' }]
    const [selectItem, setSelectItem] = useState({
        id: 0,
        title: 'Destaque na página inicial'
    })
    const [loading, setLoading] = useState(false)

    const [titleRequired, setTitleRequired] = useState(false)
    const [imageRequired, setImageRequired] = useState(false)
    const [categoryRequired, setCategoryRequired] = useState(false)
    const [titleENRequired, setTitleENRequired] = useState(false)
    const [subtitleENRequired, setSubtitleENRequired] = useState(false)
    const [categoryENRequired, setCategoryENRequired] = useState(false)
    const [urlRequired, setUrlRequired] = useState(false)
    const [urlInvalid, setUrlInvalid] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)

    const { categories } = useSelector((state: RootState) => state.categories)

    const buttonLabelSelectData = [
        { value: 'constants.heroButton.readMore', label: 'Leia mais' },
        { value: 'constants.heroButton.knowMore', label: 'Saiba mais' }
    ]

    const [values, setValues] = useState({
        id: '',
        title: '',
        subtitle: '',
        image: '',
        category: { value: '', label: '' },
        categoryEN: '',
        titleEN: '',
        subtitleEN: '',
        url: '',
        buttonLabel: buttonLabelSelectData[0]
    })

    const handleChange = (name: string, value: any) => {
        setValues({ ...values, [name]: value })
    }

    const handleSubmit = (type: string) => {
        if (verifyFields()) {
            fetchHighlight(createFormData(), type)
        }
    }

    const createFormData = () => {
        const formData = new FormData()

        formData.append('Title', values.title)
        formData.append('TitleEN', values.titleEN)
        formData.append('Subtitle', values.subtitle)
        formData.append('SubtitleEN', values.subtitleEN)
        formData.append('Image', values.image)
        formData.append('Url', values.url)
        formData.append('CategoryId', values.category.value)
        formData.append('CategoryTitle', values.category.label)
        formData.append('CategoryTitleEN', values.categoryEN)
        formData.append('ButtonLabel', values.buttonLabel.value)

        return formData
    }

    const fetchHighlight = async (formData: FormData, type: string) => {
        setLoadingSubmit(true)
        const res =
            type !== 'EDIT'
                ? await createHighlight(formData, type)
                : await updateHighlight(formData, values.id)
        setLoadingSubmit(false)
        history.push('/news/management/highlight')
    }

    const fetchGetHighlight = async (id: string) => {
        setLoading(true)
        const res = await getHighlight(id)

        if (res.status === 200) {
            const data = res.data

            setValues({
                id: data.id,
                title: data.title,
                subtitle: data.subtitle || '',
                image: data.image,
                category: {
                    value: '' + data.category.id,
                    label: data.category.title
                },
                categoryEN: '',
                titleEN: data.titleEN,
                subtitleEN: data.subtitleEN || '',
                url: data.url,
                buttonLabel: buttonLabelSelectData.find(
                    item => item.value === data.buttonLabel
                ) || { value: '', label: '' }
            })
        } else {
            history.push('/notFound')
        }

        setLoading(false)
    }

    const verifyFields = () => {
        let res = true

        if (!values.title.length) {
            setTitleRequired(true)
            res = false
        }
        if (!values.image.length) {
            setImageRequired(true)
            res = false
        }
        if (!values.category.label.length) {
            setCategoryRequired(true)
            res = false
        }
        if (!values.titleEN.length) {
            setTitleENRequired(true)
            res = false
        }
        if (!values.subtitleEN.length && values.subtitle.length) {
            setSubtitleENRequired(true)
            res = false
        }
        if (!values.categoryEN.length && !isCategoryAlreadyInDatabase) {
            setCategoryENRequired(true)
            res = false
        }
        if (!values.url.length) {
            setUrlRequired(true)
            res = false
        }
        if (
            values.url.length &&
            !values.url.match(
                // eslint-disable-next-line no-useless-escape
                /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
            )
        ) {
            setUrlRequired(true)
            setUrlInvalid(true)
            res = false
        }

        return res
    }

    const isCategoryAlreadyInDatabase = categories.find(
        a => a.title === values.category.label
    )

    const [openPreviewHighlightModal, setOpenPreviewHighlightModal] =
        useState(false)

    const canPreviewHighlight =
        values.title && values.image && values.category.label

    useEffect(() => {
        if (context === 'EDIT') {
            fetchGetHighlight(id)
        }
    }, [context])

    useEffect(() => {
        setCategoryENRequired(false)
    }, [isCategoryAlreadyInDatabase])

    useEffect(() => {
        if (!values.subtitle.length) {
            setSubtitleENRequired(false)
            values.subtitleEN = ''
        }
    }, [values.subtitle])

    useEffect(() => {
        if (!urlRequired) {
            setUrlInvalid(false)
        }
    }, [urlRequired])

    useEffect(() => {
        dispatch(categoriesRequest())
    }, [])

    const getLabelOrangeEN = () => {
        if (values.category.value) {
            const category = categories.find(
                item => `${item.id}` === `${values.category.value}`
            )
            return category ? category.titleEN : values.category.label
        }

        return values.categoryEN ? values.categoryEN : values.category.label
    }

    return (
        <>
            <HighlightPreviewModal
                showModal={openPreviewHighlightModal}
                setShowModal={setOpenPreviewHighlightModal}
                image={values.image}
                labelOrange={values.category.label}
                labelOrangeEN={getLabelOrangeEN()}
                title={values.title}
                titleEN={values.titleEN ? values.titleEN : values.title}
                additionalText={values.subtitle}
                additionalTextEN={
                    values.subtitleEN ? values.subtitleEN : values.subtitle
                }
                buttonLabel={values.buttonLabel.value}
                hasButtons={false}
            />
            <ResponsiveContainer color="#ffffff">
                {context === 'EDIT' && loading ? (
                    <Spinner heightContainer="100vh" size={'60px'} />
                ) : (
                    <ContentContainer>
                        <styles.Container>
                            <SelectButton
                                disabled
                                width={280}
                                currentValue={selectItem}
                                setValue={setSelectItem}
                                list={selectList}
                                colorBackground="#797979"
                                colorText="#ffffff"
                            />
                            <NewsInput
                                required
                                margin={'48px 0 0'}
                                maxLength={100}
                                placeholder={'Escreva o título'}
                                textColor={'rgba(0, 0, 0, 0.7)'}
                                textSize={36}
                                value={values.title}
                                valueName={'title'}
                                handleChange={handleChange}
                                error={titleRequired}
                                setError={setTitleRequired}
                                errorMessage={'Campo obrigatório'}
                            />
                            <NewsInput
                                margin={'24px 0'}
                                maxLength={100}
                                placeholder={'Escreva o subtítulo'}
                                textColor={'rgba(0, 0, 0, 0.5)'}
                                textSize={24}
                                value={values.subtitle}
                                valueName={'subtitle'}
                                handleChange={handleChange}
                            />
                            <styles.ImageContainer>
                                <ImageInput
                                    required
                                    textInfo={
                                        'Clique aqui para adicionar uma imagem (Resolução mínima 1280x720. Tamanho máximo 1MB)'
                                    }
                                    value={values.image}
                                    valueName={'image'}
                                    handleChange={handleChange}
                                    maxSize={1000000}
                                    minWidth={1280}
                                    minHeight={720}
                                    error={imageRequired}
                                    setError={setImageRequired}
                                    errorMessage={'Campo obrigatório'}
                                    maxSizeErrorMessage={
                                        'Tamanho do arquivo é maior que 1MB*'
                                    }
                                    minResolutionErrorMessage={
                                        'Resolução da imagem é menor que 1280x720*'
                                    }
                                />
                            </styles.ImageContainer>

                            <Typography
                                style={{
                                    fontWeight: 500,
                                    marginBottom: '12px'
                                }}
                                variant="pBlack"
                            >
                                Escolha a categoria do destaque: *
                            </Typography>

                            <styles.SelectContainer>
                                <SelectInput
                                    required
                                    maxLength={50}
                                    isCreatable
                                    placeholder={'Selecione a categoria'}
                                    value={values.category}
                                    valueName={'category'}
                                    handleChange={handleChange}
                                    error={categoryRequired}
                                    setError={setCategoryRequired}
                                    errorMessage={'Campo obrigatório'}
                                    selectList={arrayToTypes(
                                        categories,
                                        'id',
                                        'title'
                                    )}
                                />
                            </styles.SelectContainer>

                            <Divider margin={'24px 0'} />

                            <SupportBilingual />

                            <Typography
                                style={{
                                    fontWeight: 500,
                                    margin: '32px 0 12px',
                                    fontSize: '14px'
                                }}
                                variant="pBlack"
                            >
                                Título (em inglês) *
                            </Typography>
                            <Input
                                maxLength={100}
                                placeholder={
                                    'Digite o título da sua publicação em inglês'
                                }
                                value={values.titleEN}
                                valueName={'titleEN'}
                                handleChange={handleChange}
                                error={titleENRequired}
                                setError={setTitleENRequired}
                                errorMessage={'Campo obrigatório'}
                            />

                            {values.subtitle.length ? (
                                <>
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            margin: '24px 0 12px',
                                            fontSize: '14px'
                                        }}
                                        variant="pBlack"
                                    >
                                        Subtítulo (em inglês)
                                    </Typography>
                                    <Input
                                        maxLength={100}
                                        placeholder={
                                            'Digite o subtítulo da sua publicação em inglês'
                                        }
                                        value={values.subtitleEN}
                                        valueName={'subtitleEN'}
                                        handleChange={handleChange}
                                        error={subtitleENRequired}
                                        setError={setSubtitleENRequired}
                                        errorMessage={'Campo obrigatório'}
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            {!isCategoryAlreadyInDatabase &&
                            values.category.label.length ? (
                                <>
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            margin: '24px 0 12px',
                                            fontSize: '14px'
                                        }}
                                        variant="pBlack"
                                    >
                                        Categoria do destaque (em inglês) *
                                    </Typography>
                                    <Input
                                        maxLength={50}
                                        placeholder={
                                            'Digite o nome da categoria da sua publicação em inglês'
                                        }
                                        value={values.categoryEN}
                                        valueName={'categoryEN'}
                                        handleChange={handleChange}
                                        error={categoryENRequired}
                                        setError={setCategoryENRequired}
                                        errorMessage={'Campo obrigatório'}
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            <Divider margin={'24px 0'} />

                            <Typography
                                style={{
                                    fontWeight: 500,
                                    margin: '24px 0 12px',
                                    fontSize: '14px'
                                }}
                                variant="pBlack"
                            >
                                Insira a URL do link: *
                            </Typography>
                            <Input
                                required
                                maxLength={2048}
                                placeholder={
                                    'Digite ou cole o endereço do link aqui'
                                }
                                value={values.url}
                                valueName={'url'}
                                handleChange={handleChange}
                                error={urlRequired}
                                setError={setUrlRequired}
                                errorMessage={
                                    urlInvalid
                                        ? 'URL inválida. Você deve preencher o campo com o seguinte formato: "http(s)://exemplo.com"'
                                        : 'Campo obrigatório'
                                }
                            />

                            <Typography
                                style={{
                                    fontWeight: 500,
                                    margin: '24px 0 12px',
                                    fontSize: '14px'
                                }}
                                variant="pBlack"
                            >
                                Escolha de botão no link do destaque: *
                            </Typography>

                            <Typography
                                style={{
                                    fontWeight: 400,
                                    margin: '8px 0'
                                }}
                                variant="pBlackMini55"
                            >
                                <b>Sugestão: </b>o seu destaque for uma página
                                do nosso site use o botão <b>“Saiba mais”</b>.
                                Caso seu destaque for publicação do portal de
                                notícias, use o botão <b>“Leia mais”</b>.
                            </Typography>

                            <styles.ButtonSelectContainer>
                                <SelectInput
                                    value={values.buttonLabel}
                                    valueName={'buttonLabel'}
                                    handleChange={handleChange}
                                    selectList={buttonLabelSelectData}
                                />
                            </styles.ButtonSelectContainer>

                            {canPreviewHighlight && (
                                <styles.PreviewHighlight>
                                    <p>
                                        Você pode visualizar como a publicação
                                        vai ser exibida na página inicial:{' '}
                                        <b
                                            onClick={() =>
                                                setOpenPreviewHighlightModal(
                                                    true
                                                )
                                            }
                                        >
                                            Pré-visualizar publicação
                                        </b>
                                    </p>
                                    <img src={OpenNewTab} />
                                </styles.PreviewHighlight>
                            )}

                            <ButtonsContainer>
                                <CancelButton
                                    textColor={'#FEC074'}
                                    onClick={() => setShowCancelModal(true)}
                                />
                                {context !== 'EDIT' ? (
                                    <>
                                        <GenericButton
                                            backgroundColor={'#FFFFFF'}
                                            textColor={'#FEC074'}
                                            onClick={() => handleSubmit('SAVE')}
                                            size={'large'}
                                            margin={'0 0 0 24px'}
                                            loading={loadingSubmit}
                                        >
                                            salvar
                                        </GenericButton>
                                        <GenericButton
                                            backgroundColor={'#FEC074'}
                                            textColor={'#FFFFFF'}
                                            onClick={() => handleSubmit('ADD')}
                                            size={'large'}
                                            margin={'0 0 0 24px'}
                                            loading={loadingSubmit}
                                        >
                                            adicionar
                                        </GenericButton>
                                    </>
                                ) : (
                                    <>
                                        <GenericButton
                                            backgroundColor={'#FEC074'}
                                            textColor={'#FFFFFF'}
                                            onClick={() => handleSubmit('EDIT')}
                                            size={'large'}
                                            margin={'0 0 0 24px'}
                                            loading={loadingSubmit}
                                        >
                                            salvar alterações
                                        </GenericButton>
                                    </>
                                )}
                            </ButtonsContainer>
                        </styles.Container>
                    </ContentContainer>
                )}
            </ResponsiveContainer>

            <Modal
                showModal={showCancelModal}
                setShowModal={setShowCancelModal}
                buttonLeft={() => history.push('/news/management/highlight')}
                buttonRight={() => setShowCancelModal(false)}
                title={'Cancelar edição?'}
                description={
                    'Todas as alterações feitas serão descartadas e não poderão ser recuperadas.'
                }
                titleButtonLeft={'sair sem salvar'}
                titleButtonRight={'continuar edição'}
            />
        </>
    )
}

export default AddHighlightContent
