import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { IdleTimer } from './IdleTimer'

ReactDOM.render(
  <React.StrictMode>
    <IdleTimer
      throttle={
        (process.env.REACT_APP_THROTTLE as unknown as number) !== undefined
          ? (process.env.REACT_APP_THROTTLE as unknown as number)
          : 500000
      } // tempo (em ms) para captura de evento onAction (refresh do token)
      timeout={
        // 3000
        (process.env.REACT_APP_TIMEOUT as unknown as number) !== undefined
          ? (process.env.REACT_APP_TIMEOUT as unknown as number)
          : 900000
      } // tempo (em ms) de inatividade para deslogar
      crossTab
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
