import * as styles from './styles'
import Step from './Step'
import Management from './Management'
import Technology from './Technology'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Typography from 'src/components/Typography'

import useMedia from 'src/hooks/useMedia'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

const Development = () => {
  const { t } = useTranslation()
  const [current, setCurrent] = useState(1)
  const { width } = useMedia()

  const handleChangeComponent = (id: number) => {
    setCurrent(id)
  }

  return (
    <styles.ResponsiveContainer>
      <styles.Container id="development">
        <styles.TabContainer>
          <styles.TabItem>
            <styles.TabButton
              onClick={() => handleChangeComponent(1)}
              active={current === 1}
            >
              <Typography variant="small">
                {t('desenvolvimento.tabs.etapas')}
              </Typography>
            </styles.TabButton>
          </styles.TabItem>
          <styles.TabItem>
            <styles.TabButton
              onClick={() => handleChangeComponent(2)}
              active={current === 2}
            >
              <Typography variant="small">
                {t('desenvolvimento.tabs.gerenciamento')}
              </Typography>
            </styles.TabButton>
          </styles.TabItem>
          <styles.TabItem>
            <styles.TabButton
              onClick={() => handleChangeComponent(3)}
              active={current === 3}
            >
              <Typography variant="small">
                {t('desenvolvimento.tabs.tecnologias')}
              </Typography>
            </styles.TabButton>
          </styles.TabItem>
        </styles.TabContainer>
        {width <= maxMobileScreenWidth && <styles.DividerMobile />}
        {current === 1 ? (
          <Step />
        ) : current === 2 ? (
          <Management />
        ) : current === 3 ? (
          <Technology />
        ) : (
          ''
        )}
      </styles.Container>
    </styles.ResponsiveContainer>
  )
}

export default Development
