import styled from 'styled-components'
import { ChevronDown } from '@styled-icons/boxicons-regular'
import { Link as LinkR } from 'react-router-dom'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  padding: 80px 0;
`

export const Chevron = styled(ChevronDown)``

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .regimeSelect {
    color: #000000;
  }

  .title {
    margin: 1.5rem 0 0 0;
    width: 42.5rem;
    height: auto;
    resize: none;
    border: none;
    outline: none;
    font-size: 2rem;
    color: #000000;

    ::placeholder {
      color: #000000;
    }
  }

  .author {
    margin: 1.5rem 0 0 0;
    width: 42.5rem;
    height: auto;
    resize: none;
    border: none;
    outline: none;
    font-size: 1.275rem;
    color: #000000;

    ::placeholder {
      color: #000000;
    }
  }
`
export const RegimeContainer = styled.div`
  padding-top: 32px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .tagSelect {
    max-width: 736px;
  }

  .tagsTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 48px;

    color: rgba(0, 0, 0, 0.87);
  }
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const ImageContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* img {
    max-width: 46rem;
    max-height: 22.5rem;
    margin: 2rem 0 0;
    object-fit: cover;
    cursor: pointer;
  } */

  /* button {
    width: 100%;
    height: 22.5rem;
    background: #f2f2f2;
    margin: 2rem 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  } */

  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #000000;
    font-style: italic;

    text-align: center;
    margin-top: 2rem;

    ::placeholder {
      color: #000000;
    }
  }
`

export const InputContainer = styled.div`
  margin: 3rem 0;

  max-width: 46rem;

  .ck.ck-content ul {
    padding-left: 40px;
  }

  .ck.ck-content ol {
    padding-left: 40px;
  }
`

export const Label = styled.p`
  width: 100%;
  padding-bottom: 8px;
`

export const LabelRegime = styled.p`
  width: 100%;
  padding-bottom: 8px;
  font-weight: 500;
`

export const LabelEditorContainer = styled.div`
  width: 100%;
  padding-bottom: 48px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 2rem;
  }
`

export const LinkCancel = styled(LinkR)`
  padding: 16px 32px;
  text-decoration: none;
`

export const Cancel = styled.button`
  border: none;
  background: none;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #fec074;
`

export const Draft = styled.button`
  border: 1px solid #fec074;
  border-radius: 30px;
  background: none;
  padding: 1rem 3.5rem;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #fec074;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
    color: #ffffff;
    background: #fec074;
    border: 1px solid transparent;
  }
`

export const SendToApprove = styled.button`
  background: #fec074;
  max-width: 20rem;
  border-radius: 30px;
  border: none;
  padding: 1rem 3.5rem;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  border: 1px solid transparent;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
    color: #fec074;
    background: none;
    border: 1px solid #fec074;
  }
`

export const AplicateContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Text = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const JobWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #eef2f6;
  padding: 24px 0;
`

export const TextJob = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const Email = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const TextFooter = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-self: center;
  padding-top: 16px;

  color: #000000;
`

export const AddHighlightContainer = styled.div`
    margin-bottom: 32px;
    background: #EDF1F4;
    padding: 20px;
    border-radius: 10px;
`

export const AddHighlightCheckbox = styled.input`
    margin-right: 12px;
    width: 24px;
    height: 24px;
`

export const AddHighlightCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const PreviewHighlight = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;

        color: rgba(0, 0, 0, 0.54);

        b {
            font-weight: 500;
            color: #2E2972;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    img {
        margin-left: 10px;
    }
`
