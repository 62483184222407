import * as styles from './styles'
import MinusIcon from 'src/assets/icons/buttons/minus.svg'
import PlusIcon from 'src/assets/icons/buttons/plus.svg'

type PlusMinusProps = {
    min: number
    max: number
    amount: number
    setAmount: (a: number) => void
}

const PlusMinus = ({ min, max, amount, setAmount }: PlusMinusProps) => {
    const handleMinus = () => {
        if (amount > min) {
            setAmount(amount - 1)
        }
    }

    const handlePlus = () => {
        if (amount < max) {
            setAmount(amount + 1)
        }
    }

    return (
        <styles.Container>
            <styles.Button
                src={MinusIcon}
                onClick={() => handleMinus()}
                disabled={amount === min}
            />
            <styles.Input
                type={'number'}
                maxLength={1}
                value={amount}
                disabled
            />
            <styles.Button
                src={PlusIcon}
                onClick={() => handlePlus()}
                disabled={amount === max}
            />
        </styles.Container>
    )
}

export default PlusMinus
