import * as styles from './styles'

import { useTranslation } from 'react-i18next'
import { DesktopButton } from 'src/components/Button'
import ContactBox from './ContactBox'
import Typography from 'src/components/Typography'
import { postgraduatePromoBannerLenovo } from 'src/assets/images'
import Table, { TableBody, TableCell, TableHeader, TableRow } from './Table'

const Disciplines = (props: any) => {
  // @TODO: Leia mais

  const { t } = useTranslation()

  const disciplines = [
    { name: 'Introdução à Segurança e à Privacidade', cred: 1, work: 16 },
    { name: 'Redes e Computação em Nuvem', cred: 2, work: 32 },
    { name: 'Metodologia de Pesquisa Científica', cred: 1, work: 16 },
    { name: 'Desenvolvimento Seguro de Software ', cred: 1, work: 16 },
    { name: 'Aprendizado de Máquina', cred: 2, work: 32 },
    { name: 'Segurança da Informação e Criptografia', cred: 1, work: 16 },
    {
      name: 'Palestras Técnicas em Segurança e Privacidade',
      cred: 2,
      work: 32
    },
    { name: 'Segurança em Redes de Computadores', cred: 1, work: 16 },
    { name: 'Segurança em Aplicações Web', cred: 1, work: 16 },
    { name: 'Segurança em Aplicações Móveis e IoT', cred: 1, work: 16 },
    { name: 'Certificações e Entidades Certificadoras', cred: 1, work: 16 },
    { name: 'Leis e Regulamentações', cred: 1, work: 16 },
    { name: 'Algoritmos Distribuídos e Blockchain', cred: 2, work: 32 },
    { name: 'Segurança e Privacidade de Dados', cred: 2, work: 32 },
    {
      name: 'Vulnerabilidade em Sistemas Operacionais (Hardening)',
      cred: 1,
      work: 16
    },
    { name: 'Análise de Malware ', cred: 1, work: 16 },
    {
      name: 'Aprendizagem de Máquina Aplicada a Segurança ',
      cred: 1,
      work: 16
    },
    { name: 'Segurança em Nuvens Computacionais', cred: 1, work: 16 },
    {
      name: 'Segurança Cibernética e Processamento de Imagem',
      cred: 1,
      work: 16
    },
    { name: 'Computação Forense', cred: 1, work: 16 },
    { name: 'TOTAL', cred: 25, work: 400 }
  ]

  return (
    <>
      <styles.TitleContainer>
        <styles.PromoImg
          style={{ marginBottom: '3.5rem' }}
          src={postgraduatePromoBannerLenovo}
        ></styles.PromoImg>
        <styles.TabTitle>{t('news.postgraduate.ufc-lenovo')}</styles.TabTitle>
        <styles.Title>{t('news.postgraduate.disc.tab-title')}</styles.Title>
      </styles.TitleContainer>

      <styles.ContentContainer>
        <styles.Paragraph>{t('news.postgraduate.disc.p1')}</styles.Paragraph>

        <Table lastRowShouldBeHighlighted>
          <TableHeader>
            <TableCell>{t('news.postgraduate.disc.tab-title')}</TableCell>
            <TableCell>{t('news.postgraduate.disc.cred')}</TableCell>
            <TableCell>{t('news.postgraduate.disc.hours')}</TableCell>
          </TableHeader>
          <TableBody>
            {disciplines.map((discipline, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{discipline.name}</TableCell>
                  <TableCell textAlign="center">
                    {('0' + discipline.cred).slice(-2)}
                  </TableCell>
                  <TableCell textAlign="center">
                    {discipline.work} horas
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </styles.ContentContainer>
    </>
  )
}

export default Disciplines
