import styled from 'styled-components'

import typography, { TypographyVariants } from '../../styles/typography'

type TypoTextProps = {
  variant?: TypographyVariants
  children?: React.ReactNode
}

const Typography = styled.p<TypoTextProps>`
  ${({ variant }) => typography[variant || 'whiteP']};
`

export default Typography
