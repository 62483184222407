/* eslint-disable prettier/prettier */
import styled, { StyledComponent } from 'styled-components'
import { Search, ChevronDown } from '@styled-icons/boxicons-regular'
import { FilterCircle } from '@styled-icons/bootstrap'
import { Link as LinkR } from 'react-router-dom'

type HeaderTabProps = {
  readonly active: boolean
}
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: 5rem auto 3rem;
`

export const Title = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  color: #2c3e51;

  display: flex;
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
`

export const PostsContainer = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
`

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const PostList = styled.ul`
  width: 100%;

  list-style: none;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;

  li {
    width: 100%;
    padding: 0 24px;

    background: #eef2f6;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .leftContainer {
      display: flex;
      align-items: center;
    }

    .category {
      width: 150px;
      height: 43px;
      border-radius: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #000000;
      background: #c4c4c4;

      text-transform: capitalize;
    }

    .status {
      width: fit-content;
      height: 25px;
      margin-left: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #ffffff;

      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;

      .published {
        background: #90db3e;
        padding: 6px 14px;
        border-radius: 20px;
        height: 100%;
      }

      .pendingapproval {
        background: #ffc700;
        padding: 6px 14px;
        border-radius: 20px;
        height: 100%;
      }

      .pendingpublished {
        background: #009fdf;
        padding: 6px 14px;
        border-radius: 20px;
        height: 100%;
      }

      .recused {
        background: #ff3366;
        padding: 6px 14px;
        border-radius: 20px;
        height: 100%;
      }

      .closed {
        background: #6d6f71;
        padding: 6px 14px;
        border-radius: 20px;
        height: 100%;
      }
    }

    .description {
      margin-left: 2.5rem;

      .dateLabelContainer {
        display: flex;
        flex-direction: row;
      }

      .date {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140.62%;

        color: #000000;
      }

      .title {
        margin-top: 1rem;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 140.62%;

        color: #000000;
      }
    }
  }
`

export const Content = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;
  padding-bottom: 70px;
`

export const ItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
`

export const RightContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
`

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`

export const HeaderBoxShadow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 24px;
`

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
`

export const Header = styled.div`
  width: 100%;
  min-height: 100px;
  max-width: 1440px;
  padding: 0 80px;

  ul {
    display: flex;
    list-style: none;

    li + li {
      margin-left: 24px;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 48px;

    color: ${({ theme }) => theme.colors.defaultBlue};
  }

  &a:last-child {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }

  button {
    padding: 1.375rem 4rem;
    background: ${({ theme }) => theme.colors.buttonColor};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 30px;
    border: none;
    text-transform: uppercase;
    max-height: 3.75rem;
    max-width: 19.375rem;
  }
`

export const Chevron = styled(ChevronDown)`
  color: ${({ theme }) => theme.colors.white};
`

export const Filter = styled(FilterCircle)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.5rem;
`

export const SearchHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`

export const SearchHeaderBoxShadow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 24px;
  background: ${({ theme }) => theme.colors.defaultBlue};
`

export const SearchHeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const FilterSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
`

export const SearchHeader = styled.div`
  width: 100%;
  min-height: 100px;
  max-width: 1440px;
  padding: 0 80px;
  min-height: 3.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;

    cursor: pointer;
  }
`

export const SearchIcon = styled(Search)`
  color: #797979;
  position: absolute;
  padding: 0 10px;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 17.5rem;
    border-radius: 30px;
    height: 2rem;
    outline: none;
    border: none;

    padding: 0.625rem 2.275rem;
  }
`

export const Navlink = styled(LinkR) <HeaderTabProps>`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  text-transform: uppercase;

  color: ${props => (props.active ? '#2E2972' : ' rgba(46, 41, 114, 0.3)')};
  cursor: pointer;

  text-decoration: none;
`

export const Body = styled.div``

export const SelectedButton = styled.div`
  .select-box {
    display: flex;
    flex-direction: column;
    max-width: 10rem;
    border: 1px solid #ffffff;
    border-radius: 30px;
  }

  .select-box .options-container {
    color: #ffffff;
    max-height: 0;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
    order: 1;
  }

  .selected {
    background: ${({ theme }) => theme.colors.defaultBlue};
    border-radius: 30px;
    max-width: 260px;
    color: #ffffff;
    position: relative;
    order: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .select-box .options-container.active {
    max-height: 300px;
    opacity: 1;
    overflow-y: hidden;
    border: 0.25px solid #2e2972;
    border-radius: 0px 0px 30px 30px;
  }

  .select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0 8px 8px 0;
  }

  .select-box .options-container::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonColor};
    border-radius: 0 8px 8px 0;
  }

  .select-box .option,
  .selected {
    padding: 12px 20px;
    text-align: center;
  }

  .select-box .option:not(:last-child) {
    color: #000000;
    background: #f2f2f2;
    border-bottom: 0.25px solid #2e2972;
  }

  .select-box .option:last-child {
    border-radius: 0px 0px 30px 30px;
    color: #000000;
    background: #f2f2f2;
  }

  .select-box .option:hover {
    background: ${({ theme }) => theme.colors.buttonColor};
  }

  .selected.active {
    border-radius: 30px 30px 0px 0px;
  }

  .select-box .option .radio {
    display: none;
  }
`

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`

export const EmptyTitle = styled.h1`
  font-family: 'Rubik';
  font-size: 24px;
  color: #2e2972;
  margin-bottom: 12px;
`

export const EmptyText = styled.p`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #2e2972;
`

export const HighlightWarningContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 24px;
    background-color: #EEF2F6;
    margin: 4px 0 8px;
`

export const HighlightWarningIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 12px;
`

export const HighlightWarningText = styled.p`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
`

export const HighlightWarningSaveButton = styled.button`
`

export const HighlightWarningIconTextWrapper = styled.div`
    display: flex;
`

export const HighlightsContainer = styled.div`
    width: 100%;
    margin-bottom: 64px;
`

export const HighlightItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    margin-bottom: 3px;
`

type HighlightItemOrderProps = {
    color: string
}

export const HighlightItemOrder = styled.div<HighlightItemOrderProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 5%;

    padding: 60px 20px;
    background-color: ${({ color }) => color};

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
`

export const HighlightItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 16px 32px;
    background-color: #EEF2F6;
`

export const HighlightItemContent = styled.div`
    display: flex;
    width: 90%;
`

export const HighlightItemImage = styled.img`
    width: 120px;
    height: 120px;
    object-fit: cover;
`

export const HighlightItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
    width: 90%;
`

export const HighlightItemDateWrapper = styled.div`
    display: flex;
`

export const HighlightItemFixedWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: #009FDF;
    margin-left: 16px;
    border-radius: 30px;

    p {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
        margin-right: 6px;
    }

    img {
        width: 12px;
        height: 12px;
    }
`

export const HighlightItemInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75%;
`

export const HighlightDropdownContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
`

export const HighlightItemEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
