import * as S from '../../Navbar/styles'

import { Bars } from '@styled-icons/fa-solid/Bars'

import Logo from 'src/assets/images/ufc/lsbd.svg'
import Sidebar from '../Sidebar'

import { animateScroll as scroll } from 'react-scroll'

const NavbarMobile = ({ isOpen, toggle, search }: any) => {
  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      <S.Nav>
        <S.NavbarContainer>
          <S.LeftContainer>
            <S.NavLogo onClick={toggleHome} to="/">
              <img src={Logo} alt="logo lsbd" />
            </S.NavLogo>
            <S.DividerMobile></S.DividerMobile>
            <S.NavTitle>PORTAL DE NOTÍCIAS</S.NavTitle>
          </S.LeftContainer>
          <S.RightContainer>
            <S.MobileIcon>
              <Bars size={24} onClick={toggle} />
            </S.MobileIcon>
          </S.RightContainer>
        </S.NavbarContainer>
      </S.Nav>
      <Sidebar isOpen={isOpen} toggleSidebar={toggle} search={search} />
    </>
  )
}

export default NavbarMobile
