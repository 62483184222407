import { useTranslation } from 'react-i18next'
import * as styles from './styles'

const ContactBox = (props: any) => {
  const { t } = useTranslation()

  const email = props.email

  return (
    <styles.CenterBlockGray style={{ marginTop: '3rem' }}>
      {t('news.postgraduate.intro.info2.p1')}&nbsp;
      <strong>
        <styles.BlackLink href={`mailto:${email}`}>{email}</styles.BlackLink>
      </strong>
    </styles.CenterBlockGray>
  )
}

export default ContactBox
