// import { DEFAULT } from '../../types'
import { NewsState, PostsActions, postTypes } from './types'

const INITIAL_STATE: NewsState = {
  news: [],
  error: false,
  loading: false
}

export default (state = INITIAL_STATE, action: PostsActions) => {
  switch (action.type) {
    case postTypes.POST_REQUEST:
      return {
        ...state,
        loading: true
      }
    case postTypes.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        news: action.payload.data
      }
    case postTypes.POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        news: []
      }
    // case postTypes.POST_REQUEST_UNIQUE_NEWS:
    //   return { ...state, loading: true }
    // case postTypes.POST_SUCCESS_UNIQUE_NEWS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     news: action.payload.data
    //   }
    // case postTypes.POST_FAILURE_UNIQUE_NEWS:
    //   return { ...state, error: action.payload.error, loading: false, news: [] }
    case postTypes.CREATE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case postTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data
      }
    case postTypes.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: action.payload.error
      }
    case postTypes.DELETE_ALL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case postTypes.DELETE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case postTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data
      }
    case postTypes.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        news: []
      }
    default:
      return {
        ...state
      }
  }
}
