import * as InfoReqStyles from './styles'

import { CLOSED_STATUS } from 'src/components/NewsModule/constants'

const ViewCareer = (job: any) => {
  return (
    <>
      <InfoReqStyles.ResponsiveContainer>
        <InfoReqStyles.Container>
          {job.job.status !== CLOSED_STATUS ? (
            <InfoReqStyles.BreadCrumbWrapper>
              <InfoReqStyles.Careers to="/news/home">
                Home
              </InfoReqStyles.Careers>
              <InfoReqStyles.ChevronWrapper>
                <InfoReqStyles.Chevron />
              </InfoReqStyles.ChevronWrapper>
              <InfoReqStyles.Careers to="/news/careers">
                Vagas
              </InfoReqStyles.Careers>
              <InfoReqStyles.ChevronWrapper>
                <InfoReqStyles.Chevron />
              </InfoReqStyles.ChevronWrapper>
              <InfoReqStyles.TitleCrumb>
                {job.job.title}
              </InfoReqStyles.TitleCrumb>
            </InfoReqStyles.BreadCrumbWrapper>
          ) : (
            <></>
          )}

          <InfoReqStyles.JobTitleContainer>
            <InfoReqStyles.NewsTitle>{job.job.title}</InfoReqStyles.NewsTitle>
            <InfoReqStyles.JobTag
              className={
                job.job.alt
                  ? `${job.job.alt
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()}`
                  : ''
              }
            >
              {job.job.alt ? job.job.alt.toUpperCase() : ''}
            </InfoReqStyles.JobTag>
          </InfoReqStyles.JobTitleContainer>
          <InfoReqStyles.ContentContainerCareers
            dangerouslySetInnerHTML={{
              __html: job.job.text
            }}
          ></InfoReqStyles.ContentContainerCareers>
          <InfoReqStyles.AplicateContainer>
            <InfoReqStyles.Text>Como aplicar</InfoReqStyles.Text>
            <InfoReqStyles.JobWrapper>
              <InfoReqStyles.TextJob>
                Envie seu currículo para o email:{' '}
              </InfoReqStyles.TextJob>
              <InfoReqStyles.Email>jobs@lsbd.ufc.br</InfoReqStyles.Email>
            </InfoReqStyles.JobWrapper>
            <InfoReqStyles.TextFooter>
              Enviar e-mail com o assunto: [Seleção] {job.job.title} -
              &lt;nome_candidato&gt;
            </InfoReqStyles.TextFooter>
          </InfoReqStyles.AplicateContainer>
        </InfoReqStyles.Container>
      </InfoReqStyles.ResponsiveContainer>
    </>
  )
}

export default ViewCareer
