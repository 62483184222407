import * as styles from './styles'
import SliderHorizontal from 'src/assets/icons/news/sliderHorizontal.svg'
import * as S from 'src/components/NewsModule/Management/styles'

type HighlightContainerProps = {
    showConfigModal: boolean
    setShowConfigModal: (a: boolean) => void
    canEdit: boolean
}

const HighlightContainer = ({
    showConfigModal,
    setShowConfigModal,
    canEdit
}: HighlightContainerProps) => {
    const toggleConfig = () => {
        setShowConfigModal(!showConfigModal)
    }
    return (
        <S.SearchHeaderWrapper>
            <S.SearchHeader>
                <S.SearchHeaderBoxShadow>
                    <S.SearchHeaderContent>
                        <S.FilterSearchContainer>
                            <styles.FilterWrapper>
                                {canEdit && (
                                    <styles.DropDownHeader
                                        onClick={toggleConfig}
                                    >
                                        <img src={SliderHorizontal} />
                                        {'Configurações'}
                                    </styles.DropDownHeader>
                                )}
                            </styles.FilterWrapper>
                        </S.FilterSearchContainer>
                    </S.SearchHeaderContent>
                </S.SearchHeaderBoxShadow>
            </S.SearchHeader>
        </S.SearchHeaderWrapper>
    )
}

export default HighlightContainer
