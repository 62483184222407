import GlobalStyle from './styles/global'
import { ThemeProvider } from 'styled-components'
import './i18next'
import theme from './styles/theme'

import Routes from './routes'

import { Provider } from 'react-redux'
import store from 'src/store'
import { TabNavigationProvider } from './contexts/TabNavigationContext'

import ToastAnimated from 'src/utils/Toast'

function App() {
  return (
    <>
      <Provider store={store}>
        <TabNavigationProvider>
          <ThemeProvider theme={theme}>
            <ToastAnimated />
            <Routes />
            <GlobalStyle />
          </ThemeProvider>
        </TabNavigationProvider>
      </Provider>
    </>
  )
}
export default App
