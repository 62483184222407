import * as styles from './styles'
import { useRef, useState, useEffect } from 'react'
import { Camera } from '@styled-icons/bootstrap/Camera'
import { fileToBase64 } from 'src/utils/File/fileToBase64'

type ImageInputProps = {
    textInfo?: string
    value: string
    valueName: string
    handleChange: (name: string, value: any) => void
    maxSize?: number
    minWidth?: number
    minHeight?: number
    required?: boolean
    error?: boolean
    errorMessage?: string
    setError?: (a: boolean) => void
    margin?: string
    maxSizeErrorMessage: string
    minResolutionErrorMessage: string
}

export const ImageInput = ({
    textInfo,
    value,
    valueName,
    handleChange,
    maxSize,
    minWidth,
    minHeight,
    required,
    error,
    errorMessage,
    setError,
    margin,
    maxSizeErrorMessage,
    minResolutionErrorMessage
}: ImageInputProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [errorType, setErrorType] = useState({ value: false, type: '' })

    const handleImagePreview = async (event: any) => {
        if (setError) {
            setError(false)
        }

        if (!event.target.files) return

        const file = event.target.files[0]
        if (file && file.type.substring(0, 5) === 'image') {
            if (maxSize && file.size > maxSize) {
                setErrorType({ value: true, type: 'SIZE' })
            } else {
                setErrorType({ value: false, type: '' })
                const base64 = await fileToBase64(file)
                handleChange(valueName, base64)
            }
        }
    }

    const onImageLoad = (target: any) => {
        if (minWidth && target.naturalWidth < minWidth) {
            setErrorType({ value: true, type: 'DIMENSION' })
            handleChange(valueName, '')
        }
        if (minHeight && target.naturalHeight < minHeight) {
            setErrorType({ value: true, type: 'DIMENSION' })
            handleChange(valueName, '')
        }
    }

    useEffect(() => {
        if (error) {
            setErrorType({ value: false, type: '' })
        }
    }, [error])

    return (
        <styles.GeneralContainer margin={margin || '0'}>
            <styles.ImageContainer>
                {value && value !== 'undefined' ? (
                    <img
                        src={value}
                        style={{ objectFit: 'cover' }}
                        onClick={() => {
                            fileInputRef.current?.click()
                        }}
                        onLoad={ev => onImageLoad(ev.target)}
                    />
                ) : (
                    <styles.ImageButton
                        onClick={() => {
                            fileInputRef.current?.click()
                        }}
                    >
                        <Camera size={60} />
                        <styles.TextImageInput>
                            {textInfo}
                        </styles.TextImageInput>
                    </styles.ImageButton>
                )}

                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    accept="image/*"
                    name="path"
                    onChange={e => {
                        handleImagePreview(e)
                    }}
                />
            </styles.ImageContainer>
            {errorType.value && errorType.type === 'SIZE' && (
                <styles.TextError>{maxSizeErrorMessage}</styles.TextError>
            )}
            {errorType.value && errorType.type === 'DIMENSION' && (
                <styles.TextError>{minResolutionErrorMessage}</styles.TextError>
            )}
            {error && !errorType.value && (
                <styles.TextError>{errorMessage}*</styles.TextError>
            )}
        </styles.GeneralContainer>
    )
}
