import * as styles from './styles'

import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Paragraph, Separator, Title } from '../../styles'
import ContactBox from '../../ContactBox'

const FormSent = (props: any) => {
  return (
    <>
      <Title>Formulário de inscrição enviado.</Title>
      <Paragraph style={{ fontSize: '1.2rem' }}>
        Seu formulário foi enviado com sucesso!
      </Paragraph>
      <Paragraph>
        Obrigado por se inscrever na Residência em Segurança Cibernética.
        <br></br>A divulgação do resultado e da lista de selecionados ocorrerá
        no dia 29 de abril de 2022.
      </Paragraph>

      <Separator></Separator>

      <Title>Contato</Title>
      <ContactBox email="rsc@lsbd.ufc.br"></ContactBox>
    </>
  )
}

export default FormSent
