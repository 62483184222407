import {
  categoriesTypes,
  fetchCategoriesFailure,
  FetchCategoriesFailurePayload,
  fetchCategoriesRequest,
  fetchCategoriesSuccess,
  FetchCategoriesSuccessPayload
} from './types'

export const categoriesRequest = (): fetchCategoriesRequest => ({
  type: categoriesTypes.CATEGORIES_REQUEST
})

export const categoriesSuccess = (
  payload: FetchCategoriesSuccessPayload
): fetchCategoriesSuccess => ({
  type: categoriesTypes.CATEGORIES_SUCCESS,
  payload
})

export const categoriesFailure = (
  payload: FetchCategoriesFailurePayload
): fetchCategoriesFailure => ({
  type: categoriesTypes.CATEGORIES_FAILURE,
  payload
})
