import * as S from './styles'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThreeDots } from '@styled-icons/bootstrap'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'
import Eye from 'src/assets/icons/news/eye.svg'
import Pen from 'src/assets/icons/news/pen.svg'
import SendTo from 'src/assets/icons/news/sendTo.svg'
import TrashCan from 'src/assets/icons/news/trashCan.svg'
import KebabButton from 'src/components/Button/KebabButton'
import Modal from 'src/components/Modal'
import DeleteModal from 'src/components/Modal/DeleteModal'
import usePersistedState from 'src/hooks/usePersistedState'
import { showToast } from 'src/utils/Toast'
import { postsRequest } from 'src/store/ducks/news/actions'
import { webApi } from 'src/services/api/config'
import {
    DRAFT_STATUS,
    MY_NEWS_SCREEN,
    PENDING_APPROVAL_STATUS,
    PENDING_PUBLISHED_STATUS,
    PUBLISHED_SCREEN,
    PUBLISHED_STATUS,
    RECUSED_STATUS
} from 'src/components/NewsModule/constants'
import { sendEmailApprovers } from 'src/components/NewsModule/Api/api'

const MyNewsDropdown = (props: any) => {
    const { post, categories } = props
    const dispatch = useDispatch()
    const history = useHistory()

    const [category, setCategory] = useState('')
    const [postCategory, setPostCategory] = useState<any>('')

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(4)

    const [openModal, setOpenModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openModalPending, setOpenModalPending] = useState(false)

    const [user, setUser] = usePersistedState('auth-token', '')

    const checkCan = (context: string, screen: string) => {
        const permission = user.generalPermissions.find(
            (p: any) => p.screen === screen
        )
        if (context === 'Edit' && permission.allowEdit) {
            return true
        }
        if (context === 'Delete' && permission.allowDelete) {
            return true
        }
        return false
    }

    const canEditDelete = (context: string, status: string) => {
        switch (status) {
            case PENDING_PUBLISHED_STATUS:
                return false
            case PENDING_APPROVAL_STATUS:
                return false
            case PUBLISHED_STATUS:
                return checkCan(context, PUBLISHED_SCREEN)
            default:
                return checkCan(context, MY_NEWS_SCREEN)
        }
    }

    const deleteNews = () => {
        setOpenModal(!openModal)
        setOpenDeleteModal(!openDeleteModal)
    }

    const sendToApprove = () => {
        const formData = new FormData()
        formData.append('news.id', post.id ? post.id : '')
        formData.append('news.status', PENDING_APPROVAL_STATUS)

        const pendingPost = async () => {
            // TODO: Transfer this request to API layer
            await webApi
                .put('api/site/news/pending', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (response.status === 201) {
                        setOpenModalPending(!openModalPending)
                        showToast({
                            type: 'success',
                            message:
                                postCategory?.title !== 'Vaga'
                                    ? 'Notícia enviada para aprovação'
                                    : 'Vaga enviada para aprovação'
                        })
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                })
                .catch(err => {
                    console.log(err)
                    setOpenModalPending(!openModalPending)
                    showToast({
                        type: 'error',
                        message: 'Ops. Não foi possível realizar esta ação'
                    })
                })
                .finally(() => {
                    dispatch(postsRequest())
                })
        }
        pendingPost()
        sendEmailApprovers(post.title, postCategory.title, user.name, user.id)
    }

    useEffect(() => {
        if (categories.length > 0) {
            const postCategoryId = post.newsCategoriesId
            setPostCategory(
                categories.find((post: any) => post.id === postCategoryId)
            )
            switch (postCategory?.title) {
                case 'Blog':
                    setCategory('blog')
                    break
                case 'Vaga':
                    setCategory('careers')
                    break
                case 'Publicação':
                    setCategory('publications')
                    break
                case 'Defesa':
                    setCategory('defenses')
                    break
                case 'Evento':
                    setCategory('events')
                    break
                case 'Prêmio':
                    setCategory('awards')
                    break
            }
        }
    }, [postCategory])

    return (
        <>
            <DeleteModal
                showModal={openDeleteModal}
                setShowModal={setOpenDeleteModal}
                title={
                    postCategory.title !== 'Vaga'
                        ? 'Deletar notícia?'
                        : 'Deletar vaga?'
                }
                text={
                    postCategory.title !== 'Vaga'
                        ? 'Ao continuar, a notícia não estará mais disponível para os leitores e não poderá ser restaurada.'
                        : 'Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
                }
                id={post.id}
                category={postCategory.title}
            />
            <Modal
                showModal={openModalPending}
                setShowModal={setOpenModalPending}
                buttonLeft={() => setOpenModalPending(!openModalPending)}
                buttonRight={() => sendToApprove()}
                title={'Enviar para aprovação?'}
                description={
                    'A sua publicação será enviada para revisão. Após enviar você não poderá mais editar a publicação.'
                }
                titleButtonLeft={'cancelar'}
                titleButtonRight={'enviar'}
            />
            <Modal
                showModal={openModal}
                setShowModal={setOpenModal}
                buttonLeft={() => setOpenModal(!openModal)}
                buttonRight={() => deleteNews()}
                title={
                    postCategory.title !== 'Vaga'
                        ? 'Deletar notícia?'
                        : 'Deletar vaga?'
                }
                description={
                    postCategory.title !== 'Vaga'
                        ? 'Você tem certeza que deseja deletar a notícia permanentemente? Ao continuar, a notícia não estará mais disponível para os leitores e não poderá ser restaurada.'
                        : 'Você tem certeza que deseja deletar a vaga permanentemente? Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
                }
                titleButtonLeft={'cancelar'}
                titleButtonRight={'avançar'}
            />
            <S.DropdownContainer>
                <KebabButton {...buttonProps}>
                    <ThreeDots size="30" />
                </KebabButton>
                <div
                    className={`dropdown ${isOpen ? 'visible' : ''}`}
                    role="menu"
                >
                    <a
                        {...itemProps[1]}
                        href={
                            postCategory.title === 'Vaga' ||
                            post.status === PUBLISHED_STATUS
                                ? `/news/${category}/${post.id}`
                                : `/news/management/my-news/${post.id}`
                        }
                    >
                        <img src={Eye} />
                        Visualizar
                    </a>

                    {canEditDelete('Edit', post.status) ? (
                        <a
                            {...itemProps[0]}
                            href={`/news/edit-news/${post.id}`}
                        >
                            <img src={Pen} />
                            Editar
                        </a>
                    ) : (
                        <></>
                    )}

                    {(post.status === DRAFT_STATUS ||
                        post.status === RECUSED_STATUS) &&
                    canEditDelete('Edit', post.status) ? (
                        <a
                            {...itemProps[2]}
                            onClick={() =>
                                setOpenModalPending(!openModalPending)
                            }
                        >
                            <img src={SendTo} />
                            Enviar para aprovação
                        </a>
                    ) : (
                        <></>
                    )}

                    {canEditDelete('Delete', post.status) ? (
                        <a
                            {...itemProps[3]}
                            onClick={() => setOpenModal(!openModal)}
                        >
                            <img src={TrashCan} />
                            Deletar
                        </a>
                    ) : (
                        <></>
                    )}
                </div>
            </S.DropdownContainer>
        </>
    )
}

export default MyNewsDropdown
