import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postgraduatePromoBannerLenovo, UFCLogo } from 'src/assets/images/index'
import useMedia from 'src/hooks/useMedia'
import { minDesktopScreenWidth } from 'src/utils/breakpoints'
import * as styles from '../styles'
import * as st from './styles'

const PostGraduateInauguralClassContent = (props: any) => {
  const [formSent, setFormSent] = useState(false)

  const { t } = useTranslation()
  const { width } = useMedia()

  return (
    <>
      <styles.ResponsiveContainer>
        <styles.Container>
          <styles.BreadCrumbWrapper>
            <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.CrumbLink to="/rsc">Pós-Graduação</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.TitleCrumb>Aula Magna</styles.TitleCrumb>
          </styles.BreadCrumbWrapper>

          <styles.ContentWrapper>
            <styles.Header>
              <styles.PageTitle id="title-anchor">
                {t('news.postgraduate.inaugural-class')}
              </styles.PageTitle>
              <st.UFCImage
                style={{ marginTop: '1.5rem' }}
                src={UFCLogo}
              ></st.UFCImage>
            </styles.Header>

            <styles.ContentContainer>
              <styles.Paragraph style={{ marginBottom: '2rem' }}>
                O Reitor da Universidade Federal do Ceará, Professor José
                Cândido Lustosa Bittencourt de Albuquerque, a Diretoria do
                Centro de Ciências, Professora Regina Célia Monteiro de Paula, e
                o Coordenador do Curso de Residência em Segurança Cibernética,
                Professor Javam de Castro Machado, convidam os alunos
                selecionados para a{' '}
                <strong>
                  Aula Inaugural do Curso de Residência em Segurança Cibernética
                </strong>
                .
              </styles.Paragraph>

              <styles.Paragraph>
                Local: Auditório do Departamento de Computação - Campos do Pici
                - Centro de Ciências - Bloco 910 <br></br>
                Data: 10 de maio de 2022 <br></br>
                Horário: 14 horas
              </styles.Paragraph>
            </styles.ContentContainer>
          </styles.ContentWrapper>
        </styles.Container>
      </styles.ResponsiveContainer>
    </>
  )
}

export default PostGraduateInauguralClassContent
