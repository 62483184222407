import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const TextWrapper = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    p:first-child {
      font-size: 36px;
      line-height: 48px;
      padding: 24px 0 50px;
    }
    p:last-child {
      font-size: 28px;
      line-height: 33px;
    }
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 35%;

    p:first-child {
      min-height: 150px;
    }

    p + p {
      margin-top: 3rem;
    }
  }
`

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 1rem 0;

  img {
    width: 100%;
    height: 100%;
  }
`

export const PhasesWrapper = styled.div`
  width: 100%;
  display: flex;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  width: 35%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    width: 100%;
    padding: 32px 0;
    font-size: 16px;
    line-height: 22px;
  }
`

export const TypographyWrapper = styled.div`
  width: 80%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    width: 100%;
    p {
      width: 100%;
      font-size: 16px;
    }
  }
`

export const Phases = styled.div`
  display: flex;
  width: 65%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
  }

  .orangeArrow {
    width: 52px;
    margin: 0 1rem;
  }

  .swiper {
    display: flex;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    width: 100%;
    padding-bottom: 50px;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-next {
    width: 32px;
  }

  .swiper-button-prev {
    display: none;
  }

  .swiper-slide-active {
    display: flex;
    width: 100%;
    height: auto;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #0ea3df;
    border-radius: 40px;
    opacity: 1;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    background: #0ea3df;
  }
`

export const PhasesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    position: relative;
    img {
      padding-bottom: 8px;
    }
  }
`

export const PhasesText = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 65%;
    p {
      font-size: 20px;
      line-height: 24px;
      padding-top: 8px;
    }
  }
`

export const DividerMobile = styled.hr`
  border: 1px solid #000000;
  margin-top: 24px;
`

export const MobileArrow = styled.img`
  position: absolute;
  right: 0;
  width: 32px;
  bottom: 50%;
`
