/* eslint-disable prettier/prettier */

import * as InfoNewsStyles from './styles'
import ViewCareer from './careers'

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Spinner from 'src/components/Spinner'

import usePersistedState from 'src/hooks/usePersistedState'

import stringToDate from 'src/utils/stringToDate'

import {
  CLOSED_STATUS,
  PUBLISHED_STATUS
} from 'src/components/NewsModule/constants'
import {
  getPost,
  getPostCategory,
  getPostTags,
  getTags
} from 'src/services/api/WebApi/NewsModule/newsModule'
import { ITag } from 'src/services/api/WebApi/NewsModule/interfaces'

const ViewNewsContent = (props: any) => {
  const urlCategory = props.props.match.url.split('/')[2]
  const urlTypeView = props.props.match.url.split('/')[3]
  const id = props.props.match.params.id

  const [user, setUser] = usePersistedState('auth-token', '')

  const [loading, setLoading] = useState(true)
  const [news, setNews] = useState<any>({})
  const [category, setCategory] = useState('')
  const [tags, setTags] = useState<ITag[]>([])

  const history = useHistory()

  const fetchTagList = async () => {
    try {
      const postTagsResponse = await getPostTags(id)
      const tagsResponse = await getTags()

      const postTags = postTagsResponse.data
      const tags = tagsResponse.data

      const aux = postTags
        .map(postTag => tags.find(tag => tag.id === postTag.tagId))
        .filter(tag => tag !== undefined) as ITag[]

      setTags(aux)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchNewsAndCategory = async () => {
    try {
      const response = await getPost(id)

      if (response.status === 404 || response.status === 400) {
        history.push('/notFound')
        return
      }

      const post = response.data
      setNews(post)

      try {
        const categoryResponse = await getPostCategory(post.newsCategoriesId)

        if (!user && post.audience === 'Interno') {
          history.push('/notFound')
          return
        }

        setCategory(categoryResponse.data.title)
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNewsAndCategory()

    if (urlCategory !== 'careers') {
      fetchTagList()
    }
    if (
      category &&
      urlTypeView === 'my-news' &&
      news.userId !== parseInt(user.id)
    ) {
      history.push('/notFound')
    }
    if (category) {
      switch (category) {
        case 'Blog':
          if (urlCategory !== 'blog' && urlCategory !== 'management') {
            history.push('/notFound')
          }
          break
        case 'Vaga':
          if (urlCategory !== 'careers') {
            history.push('/notFound')
          }
          if (news.status === CLOSED_STATUS && !user) {
            history.push('/notFound')
          }
          break
        case 'Publicação':
          if (urlCategory !== 'publications' && urlCategory !== 'management') {
            history.push('/notFound')
          }
          break
        case 'Defesa':
          if (urlCategory !== 'defenses' && urlCategory !== 'management') {
            history.push('/notFound')
          }
          break
        case 'Evento':
          if (urlCategory !== 'events' && urlCategory !== 'management') {
            history.push('/notFound')
          }
          break
        case 'Prêmio':
          if (urlCategory !== 'awards' && urlCategory !== 'management') {
            history.push('/notFound')
          }
          break
      }
    }
  }, [category])

  return (
    <>
      <InfoNewsStyles.ResponsiveContainer>
        {loading ? (
          <Spinner heightContainer="100vh" size={'60px'} />
        ) : category !== 'Vaga' ? (
          <InfoNewsStyles.Container>
            {urlCategory !== 'management' ? (
              <InfoNewsStyles.BreadCrumbWrapper>
                <InfoNewsStyles.Careers to="/news/home">
                  Home
                </InfoNewsStyles.Careers>
                <InfoNewsStyles.ChevronWrapper>
                  <InfoNewsStyles.Chevron />
                </InfoNewsStyles.ChevronWrapper>
                <InfoNewsStyles.Careers to={`/news/${urlCategory}`}>
                  {category}
                </InfoNewsStyles.Careers>
                <InfoNewsStyles.ChevronWrapper>
                  <InfoNewsStyles.Chevron />
                </InfoNewsStyles.ChevronWrapper>
                <InfoNewsStyles.TitleCrumb>
                  {news.title}
                </InfoNewsStyles.TitleCrumb>
              </InfoNewsStyles.BreadCrumbWrapper>
            ) : (
              <></>
            )}

            <InfoNewsStyles.ContentWrapper>
              <InfoNewsStyles.NewsTitleContainer>
                <InfoNewsStyles.NewsTitle>
                  {news.title}
                </InfoNewsStyles.NewsTitle>
                {news.author && news.author !== 'null' ? (
                  <InfoNewsStyles.Author>
                    {`Escrito por ${news.author}`}
                  </InfoNewsStyles.Author>
                ) : (
                  <></>
                )}
                {news.status === PUBLISHED_STATUS ? (
                  <InfoNewsStyles.Date>
                    {stringToDate(news.publishedAt)}
                  </InfoNewsStyles.Date>
                ) : (
                  <InfoNewsStyles.Date></InfoNewsStyles.Date>
                )}
              </InfoNewsStyles.NewsTitleContainer>
              <InfoNewsStyles.ImageWrapper>
                <img src={news.path} />
                {news.alt ? (
                  <InfoNewsStyles.AltImage>{news.alt}</InfoNewsStyles.AltImage>
                ) : (
                  <InfoNewsStyles.AltImage></InfoNewsStyles.AltImage>
                )}
              </InfoNewsStyles.ImageWrapper>
              <InfoNewsStyles.ContentContainer
                dangerouslySetInnerHTML={{
                  __html: news.text
                }}
              ></InfoNewsStyles.ContentContainer>
            </InfoNewsStyles.ContentWrapper>
            <InfoNewsStyles.TagContainer>
              <InfoNewsStyles.TagLabel>Tags</InfoNewsStyles.TagLabel>
              <InfoNewsStyles.TagsWrapper>
                {tags.map((data: any, key) => {
                  return (
                    <InfoNewsStyles.Tag key={key}>
                      {data.title}
                    </InfoNewsStyles.Tag>
                  )
                })}
              </InfoNewsStyles.TagsWrapper>
            </InfoNewsStyles.TagContainer>
          </InfoNewsStyles.Container>
        ) : (
          <ViewCareer job={news} />
        )}
      </InfoNewsStyles.ResponsiveContainer>
    </>
  )
}

export default ViewNewsContent
