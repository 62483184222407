import * as styles from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import useMedia from 'src/hooks/useMedia'

import { minDesktopScreenWidth } from 'src/utils/breakpoints'

const HomeNewsSkeleton = () => {
  const { width } = useMedia()
  return (
    <>
      {width >= minDesktopScreenWidth ? (
        <styles.Container>
          <styles.LastNewsContainer>
            <Skeleton height={28} width={300} />
            <styles.LastNewsContent>
              <styles.LastNewsLeftContainer>
                <Skeleton containerClassName="img" height={400} />
              </styles.LastNewsLeftContainer>
              <styles.LastNewsRightContainer>
                <Skeleton containerClassName="title" height={40} />
                <Skeleton containerClassName="text" count={6} height={24} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.LastNewsRightContainer>
            </styles.LastNewsContent>
          </styles.LastNewsContainer>

          <styles.CategoryContainer>
            <Skeleton height={28} width={100} />
            <styles.CategoryContent>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
            </styles.CategoryContent>
            <Skeleton height={28} width={150} />
          </styles.CategoryContainer>
        </styles.Container>
      ) : (
        <>
          <styles.LastNewsContainer>
            <Skeleton containerClassName="img" />
          </styles.LastNewsContainer>
          <styles.Container>
            <styles.CategoryContent>
              <styles.CategoryContentItem>
                <Skeleton
                  containerClassName="category"
                  height={70}
                  width={100}
                />
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton
                  containerClassName="category"
                  height={70}
                  width={100}
                />
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
              <styles.CategoryContentItem>
                <Skeleton
                  containerClassName="category"
                  height={70}
                  width={100}
                />
                <Skeleton containerClassName="img" height={200} />
                <Skeleton containerClassName="title" height={40} />
                <Skeleton
                  containerClassName="read-more"
                  height={20}
                  width={100}
                />
              </styles.CategoryContentItem>
            </styles.CategoryContent>
          </styles.Container>
        </>
      )}
    </>
  )
}

export default HomeNewsSkeleton
