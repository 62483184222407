import { useEffect, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

const ProtectedRoute: React.FC<any> = ({
  component: Component,
  ...restOfProps
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  async function getAuthenticationData() {
    const token = 'bearer ' + Cookies.get('auth-token')
    let result = false

    const requestInfo = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: new Headers({
        'Content-type': 'application/json',
        Authorization: token
      })
    }

    await fetch(
      process.env.REACT_APP_SERVER + 'api/auth/Authenticate',
      requestInfo
    )
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          if (response.status === 401) {
            history.push('/')
          } else {
            console.log(response.status)
          }
          throw new Error('Token inválida! Tente novamente.')
        }
      })
      .then(jwt => {
        const jwtParsed = JSON.parse(jwt)
        localStorage.setItem('auth-token', JSON.stringify(jwtParsed.authData))
        Cookies.set('auth-token', jwtParsed.token, {
          path: '/',
          domain: process.env.REACT_APP_DOMAIN
        })

        if (localStorage.getItem('auth-token') !== null) {
          const tokenString: string = localStorage.getItem('auth-token') || ''
          const auth = JSON.parse(tokenString)
          const array = []
          for (let i = 0; i < auth.permissions.length; i++) {
            const permission = auth.permissions[i].standardauthorizationoption
            const resource = auth.permissions[i].resource
            if (
              permission.allowCreate ||
              permission.allowDelete ||
              permission.allowRead ||
              permission.allowUpdate
            ) {
              array.push(resource)
            }
          }
        }
        result = true
      })
      .catch(error => {
        console.log(error.message)
      })
    return result
  }

  function isLogged() {
    if (Cookies.get('auth-token')) {
      const jwt: any = jwtDecode(Cookies.get('auth-token') || '')
      if (jwt.exp > Date.now() / 1000) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isLogged()) {
        if (
          localStorage.getItem('auth-token') !== null &&
          Cookies.get('auth-token') ===
            JSON.parse(localStorage.getItem('auth-token') || '').token
        ) {
          setIsAuthenticated(true)
        } else {
          const result = await getAuthenticationData()
          setIsAuthenticated(result)
        }
      } else {
        localStorage.clear()
        setIsAuthenticated(false)
      }

      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : loading ? (
          <div>loading...</div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

export default ProtectedRoute
