import { categoriesTypes } from './types'
import { webApi } from '../../../services/api/config'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { categoriesFailure, categoriesSuccess } from './actions'
import { AxiosResponse } from 'axios'

function* fetchCategories() {
    try {
        const response: AxiosResponse = yield call(
            webApi.get,
            'api/site/news/categories'
        )

        yield put(categoriesSuccess({ data: response.data }))
    } catch (err) {
        if (err instanceof Error) {
            yield put(categoriesFailure({ error: err.message }))
        }
    }
}

function* categoriesSaga() {
    yield all([takeLatest(categoriesTypes.CATEGORIES_REQUEST, fetchCategories)])
}

export default categoriesSaga
