// news permissions
export const MY_NEWS_SCREEN = 'MY_NEWS'
export const PUBLISHED_SCREEN = 'PUBLISHED'
export const PENDING_APPROVAL_SCREEN = 'PENDING_FOR_APPROVAL'
export const PENDING_PUBLISHED_SCREEN = 'PENDING_FOR_PUBLICATION'
export const HIGHLIGHT_SCREEN = 'HIGHLIGHT'

// post status
export const DRAFT_STATUS = 'Draft'
export const RECUSED_STATUS = 'Recused'
export const CLOSED_STATUS = 'Closed'
export const PUBLISHED_STATUS = 'Published'
export const PENDING_APPROVAL_STATUS = 'PendingApproval'
export const PENDING_PUBLISHED_STATUS = 'PendingPublished'

// patrimony permissions
export const PATRIMONY_PERMISSIONS = [
  { screen: 'MY_ITENS', route: '/myItens' },
  { screen: 'EQUIPMENTS', route: '/equipments' },
  { screen: 'BOOKS', route: '/books' },
  { screen: 'SOFTWARES', route: '/softwares' },
  { screen: 'MY_REQUISITIONS', route: '/myRequisitions' },
  { screen: 'COLLABORATORS_REQUISITIONS', route: '/collaboratorsRequisitions' },
  { screen: 'REQUISITIONS', route: '/requisitions' },
  { screen: 'BOOKS_CATEGORIES', route: '/booksCategories' },
  { screen: 'ITENS_CATEGORIES', route: '/itensCategories' },
  { screen: 'SOFTWARES_CATEGORIES', route: '/softwaresCategories' },
  { screen: 'PLACES', route: '/places' },
  { screen: 'EXTERNAL_COLLABORATORS', route: '/externalCollaborators' },
  { screen: 'ITENS_STATUS', route: '/itensStatus' }
]

export const NEWS_PERMISSIONS = [
  { screen: MY_NEWS_SCREEN, route: '/news/management/my-news' },
  { screen: PUBLISHED_SCREEN, route: '/news/management' },
  { screen: PENDING_APPROVAL_SCREEN, route: '/news/management/pending-approval' },
  { screen: PENDING_PUBLISHED_SCREEN, route: '/news/management/pending-published' },
  { screen: HIGHLIGHT_SCREEN, route: '/news/management/highlight' }
]

export const HR_PERMISSIONS = [
  { screen: 'COLLABORATORS', route: '/collaborators' },
  { screen: 'COLLABORATORS_VACATIONS', route: '/collaboratorsVacations' },
  { screen: 'JOBS', route: 'jobs' },
  { screen: 'HOLIDAYS_AND_BREAKS', route: '/holidaysAndBreaks' },
  { screen: 'PROFILE_MANAGEMENT', route: '/profileManagement' },
  { screen: 'MY_INFORMATIONS', route: '/myInformations' },
  { screen: 'MY_VACATIONS', route: '/myVacations' },
  { screen: 'VACATION_CALENDAR', route: '/vacationCalendar' },
  { screen: 'ALLOWANCE_REASON', route: '/allowanceReason' },
  { screen: 'BIRTHDAYS', route: '/birthdays' },
  { screen: 'MY_CLOCK_RECORD', route: '/myClockRecord' },
  { screen: 'COLLABORATORS_CLOCK_RECORD', route: '/collaboratorsClockRecord' },
  { screen: 'ACTIVITY_CATEGORIES', route: '/activityCategories' }
]

export const LIBRARY_PERMISSIONS = [
  { screen: 'BOOKS_COLLECTION', route: '/BooksCollection' },
  { screen: 'LIBRARY_CONFIGURATION', route: '/LibraryConfiguration' },
  { screen: 'LIBRARY_REQUISITIONS_GENERAL', route: '/LibraryRequisitions/general' },
  { screen: 'LIBRARY_REQUISITIONS_ALL', route: '/LibraryRequisitions/all' },
  { screen: 'LIBRARY_MY_LOANS', route: '/Library/MyLoans' }
]

export const QUOTA_PERMISSIONS = [
    { screen: 'QUOTAS_DASHBOARD_PERSONAL', route: '/Quotas' },
    { screen: 'QUOTAS_JOBS', route: '/Quotas' },
    { screen: 'QUOTAS_USERS', route: '/Quotas' },
    { screen: 'QUOTAS_TOOLS', route: '/Quotas' }
]

export const ADMIN_PERMISSIONS = [
    { screen: 'ADMIN_USERS', route: '/Admin' }
]
