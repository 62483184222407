import { useLocation } from 'react-router-dom'

/**
 * Hook to work with query strings
 */
function useQueryString(): URLSearchParams {
    return new URLSearchParams(useLocation().search)
}

export default useQueryString
