import styled from 'styled-components'

import { AiOutlineInstagram } from 'react-icons/ai'

import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #2e2972;
  width: 100vw;
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #2e2972;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    padding: 64px 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 57px 86px 24px;
    max-width: 1440px;
  }
`

export const FooterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    align-items: flex-start;
  }
`

export const IconsWrapper = styled.div`
  display: flex;

  img {
    cursor: pointer;
    margin-left: 20px;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    align-self: center;
    padding-bottom: 32px;
  }
`

export const Instagram = styled(AiOutlineInstagram)`
  background: #fff;
  color: #2e2972;

  border-radius: 50%;
  cursor: pointer;
`

export const ContactWrapper = styled.div`
  text-align: center;
`

export const SectionNamesWrapper = styled.div`
  display: flex;

  a + a {
    margin-left: 40px;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    margin-top: 2rem;
    a + a {
      margin: 2rem 0 0 0;
    }
    flex-direction: column;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 38px 0 51px;
  }
`

export const SectionLink = styled(LinkS)`
  cursor: pointer;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    &:after {
      display: block;
      content: '';
      border-bottom: ${({ theme }) =>
        theme ? `2px solid ${theme.colors.buttonColor}` : 'none'};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
      transform-origin: 0% 50%;
    }

    &:hover {
      p {
        opacity: 1;
      }

      &:after {
        transform: scaleX(1);
      }
    }
  }
`

export const Divider = styled.hr`
  border: 1px solid #ffffff;
  width: 100%;
`
export const LogoWrapper = styled.div`
  display: flex;

  a + a {
    margin-left: 40px;
    cursor: pointer;
  }

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 0;
    margin: 4rem auto;

    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;

    a + a {
      margin: 0;
    }
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 58px 0 71px;
  }
`

export const Link = styled(LinkR)`
  text-decoration: none;
  cursor: pointer;
  display: block;

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ theme }) =>
      theme ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`
