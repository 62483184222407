import Highlight from './highlight'
import Management from './management'

const ManagementContent = (props: any) => {
    const { context } = props
    return (
        <>
            {context === 'Highlight' ? (
                <Highlight context={context} />
            ) : (
                <Management context={context} />
            )}
        </>
    )
}

export default ManagementContent
