import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postgraduatePromoBannerLenovo } from 'src/assets/images'
import OrangeButton from 'src/components/Button/OrangeButton'
import WhiteButton from 'src/components/Button/WhiteButton'
import ContactBox from './ContactBox'
import * as styles from './styles'

const Introduction = (props: any) => {
  const { t } = useTranslation()

  const [isClosed, setIsClosed] = useState(Boolean)

  return (
    <>
      <styles.TitleContainer>
        <styles.PromoImg
          style={{ marginBottom: '3.5rem' }}
          src={postgraduatePromoBannerLenovo}
        ></styles.PromoImg>
        <styles.TabTitle>{t('news.postgraduate.ufc-lenovo')}</styles.TabTitle>
        <styles.Title>{t('news.postgraduate.intro.tab-title')}</styles.Title>
      </styles.TitleContainer>

      <styles.ContentContainer>
        <styles.Paragraph>{t('news.postgraduate.intro.p1')}</styles.Paragraph>
        <styles.Paragraph>{t('news.postgraduate.intro.p2')}</styles.Paragraph>
        <styles.Paragraph>{t('news.postgraduate.intro.p3')}</styles.Paragraph>
        <styles.Paragraph>{t('news.postgraduate.intro.p4')}</styles.Paragraph>
      </styles.ContentContainer>

      <styles.Separator />

      <styles.ContentContainer style={{ marginBottom: '4rem' }}>
        <styles.CardGroup>
          <styles.Card>
            <styles.Title>
              {t('news.postgraduate.intro.info.edital')}
            </styles.Title>
            <div style={{ color: '#00000099' }}>
              <styles.Paragraph>
                {t('news.postgraduate.intro.info.p2')}
              </styles.Paragraph>

              <styles.ButtonContainer style={{ marginBottom: '0.2rem' }}>
                <styles.ButtonLink
                  href="https://drive.google.com/file/d/1PPWy2e1ois3NyM1VLgjjZznPpOQyw9NL/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhiteButton
                    title={t('news.postgraduate.intro.info.button-add')}
                  ></WhiteButton>
                </styles.ButtonLink>
              </styles.ButtonContainer>

              <styles.ButtonContainer>
                <styles.ButtonLink
                  href="https://drive.google.com/file/d/1g-S0_N6D9TCma0UHMhWJ-IAv0H54sKgY/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhiteButton
                    title={t('news.postgraduate.intro.info.button')}
                  ></WhiteButton>
                </styles.ButtonLink>
              </styles.ButtonContainer>
            </div>
          </styles.Card>

          <styles.Card>
            <styles.Title style={{ lineHeight: '32px' }}>
              {t('news.postgraduate.sub.results.title')}
            </styles.Title>
            <div style={{ color: '#00000099' }}>
              <styles.Paragraph>
                {t('news.postgraduate.sub.results.call')}
              </styles.Paragraph>

              <styles.ButtonContainer>
                <styles.ButtonLink href="/rsc/resultados">
                  <OrangeButton
                    title={t('news.postgraduate.sub.results.button')}
                  ></OrangeButton>
                </styles.ButtonLink>
              </styles.ButtonContainer>
            </div>
          </styles.Card>
        </styles.CardGroup>

        {/* <styles.Title>{t('news.postgraduate.intro.info.edital')}</styles.Title>
        <div style={{ color: '#00000099' }}>
          <styles.Paragraph>
            {t('news.postgraduate.intro.info.p2')}
          </styles.Paragraph>

          <styles.ButtonContainer>
            <styles.ButtonLink
              href="https://drive.google.com/file/d/1g-S0_N6D9TCma0UHMhWJ-IAv0H54sKgY/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DesktopButton active={true}>
                {t('news.postgraduate.intro.info.button')}
              </DesktopButton>
            </styles.ButtonLink>
          </styles.ButtonContainer>
        </div> */}
      </styles.ContentContainer>

      <styles.Separator />

      <styles.ContentContainer>
        <styles.Title>{t('news.postgraduate.intro.info2.title')}</styles.Title>
        <ContactBox email="rsc@lsbd.ufc.br"></ContactBox>
      </styles.ContentContainer>
    </>
  )
}

export default Introduction
