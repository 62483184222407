import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import TableCellProps from './type'

type TableProps = {
  hasBorder?: boolean
}

export const Table = styled.table<TableProps>`
  margin-bottom: 4rem;
  border: ${props => (props.hasBorder ? '0.5px solid #797979' : 'none')};

  thead {
    background-color: #2e2972;

    td {
      padding: 1rem;
      color: #ffffff;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;

      @media only screen and (min-width: ${minDesktopScreenWidth}px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: #f6f6f6;
      }
    }

    td {
      padding: 0.5rem;
      font-size: 0.9rem;

      @media only screen and (min-width: ${minDesktopScreenWidth}px) {
        padding: 1rem;
        font-size: 1rem;
      }
    }
  }

  &.last-row-should-be-highlighted {
    tbody tr {
      &:last-child {
        color: #1c1c1c;
        background: #ffffff;
      }
    }
  }
`

export const td = styled.td<TableCellProps>`
  text-align: ${props => props.textAlign ?? 'start'};
`

export const thead = styled.thead`
  text-transform: uppercase;
`
