import * as styles from './styles'
import Typography from '../../Typography'

import Linkedin from 'src/assets/icons/social/linkedin.svg'

import UfcLogo from 'src/assets/images/ufc/logoufc.svg'
import LsbdLogo from 'src/assets/images/ufc/lsbd.svg'
import MdccLogo from 'src/assets/images/ufc/mdcc.svg'
import Dc from 'src/assets/images/ufc/dc.svg'

import { useTranslation } from 'react-i18next'

const ModuleFooter = () => {
  const { t } = useTranslation()
  return (
    <styles.ResponsiveContainer>
      <styles.Container>
        <styles.FooterSectionWrapper>
          <styles.IconsWrapper>
            <a
              href="https://www.instagram.com/lsbdufc/"
              target="_blank"
              rel="noreferrer"
            >
              <styles.Instagram size={40} />
            </a>
            <a
              href="https://www.linkedin.com/company/lsbd---ufc/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} />
            </a>
          </styles.IconsWrapper>
          <styles.ContactWrapper>
            <Typography variant="whiteP" style={{ fontWeight: 'bold' }}>
              Laboratório de Sistemas e Bancos de Dados
            </Typography>
            <Typography variant="whiteP">
              {' '}
              E-mail: contato@lsbd.ufc.br
            </Typography>
            <Typography variant="whiteP">Telefone: (85) 3366 9235</Typography>
          </styles.ContactWrapper>

          <styles.ButtonLink to="/">
            <styles.Button>IR PARA SITE LSBD</styles.Button>
          </styles.ButtonLink>

          <styles.Divider></styles.Divider>
          <styles.LogoWrapper>
            <a href="https://www.ufc.br/" target="_blank" rel="noreferrer">
              <img src={UfcLogo} alt="logo da UFC" />
            </a>
            <a href="https://dc.ufc.br" target="_blank" rel="noreferrer">
              <img src={Dc} alt="logo do MDCC-ufc" />
            </a>
            <a href="http://www.mdcc.ufc.br" target="_blank" rel="noreferrer">
              <img src={MdccLogo} alt="logo do MDCC-ufc" />
            </a>
            <a href="/">
              <img src={LsbdLogo} alt="logo do LSBD-ufc" />
            </a>
          </styles.LogoWrapper>
          <Typography variant="whiteP">{t('footer.direitos')}</Typography>
          {/* <styles.TestVersion>
            Versão de teste {process.env.REACT_APP_VERSION}
          </styles.TestVersion> */}
        </styles.FooterSectionWrapper>
      </styles.Container>
    </styles.ResponsiveContainer>
  )
}

export default ModuleFooter
