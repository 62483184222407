/* eslint-disable no-useless-computed-key */
import * as S from './styles'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import GenericButton from 'src/components/Button/GenericButton'
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from 'react-beautiful-dnd'
import { showToast } from 'src/utils/Toast'
import {
    updateHighlightsOrder,
    deleteHighlight
} from 'src/components/NewsModule/Api/api'
import Typography from 'src/components/Typography'
import { formatDateWithDots } from 'src/utils/Date/formatDateWithDots'
import FixedWhite from 'src/assets/icons/news/fixedWhite.svg'
import DragDots from 'src/assets/icons/general/dragDots.svg'
import HighlightManagementDropdown from 'src/components/Dropdown/HighlightManagementDropdown'
import HighlightManagementSkeleton from 'src/components/Skeleton/News/HighlightManagement'
import Modal from 'src/components/Modal'
import HighlightDeleteModal from 'src/components/Modal/HighlightDeleteModal'
import OrderBySelect from 'src/components/Inputs/OrderBySelect'
import { sortByDate } from 'src/utils/Sort/sortByDate'

type ModalConfigProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    highlights: Array<any>
    highlightsOrder: Array<any>
    fetchData: () => void
    highlightPermissions: any
}

const HighlightItem = (props: any) => {
    const {
        provided,
        obj,
        order,
        setOpenDeleteModal,
        setIdDelete,
        highlightPermissions
    } = props

    return (
        <S.HighlightItemContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <S.HighlightItemOrder color={order !== '-' ? '#FEC074' : '#6D6F71'}>
                {order}
            </S.HighlightItemOrder>
            <S.HighlightItemWrapper>
                <S.HighlightItemContent>
                    <S.HighlightItemDragDotsImage src={DragDots} />
                    <S.HighlightItemImage src={obj.image} />
                    <S.HighlightItemInfoContainer>
                        <S.HighlightItemInfoWrapper>
                            <S.HighlightItemDateWrapper>
                                <Typography
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '10px',
                                        lineHeight: '14px'
                                    }}
                                    variant="pBlack"
                                >
                                    {formatDateWithDots(
                                        new Date(obj.createdAt)
                                    )}
                                </Typography>
                                {obj.isFixed && (
                                    <S.HighlightItemFixedWrapper>
                                        <img src={FixedWhite} />
                                    </S.HighlightItemFixedWrapper>
                                )}
                            </S.HighlightItemDateWrapper>

                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80%'
                                }}
                                variant="pBlackMini87"
                            >
                                {obj.title}
                            </Typography>

                            <Typography
                                style={{
                                    fontWeight: 400,
                                    fontSize: '8px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80%',
                                    lineHeight: '14px'
                                }}
                                variant="pBlackMini87"
                            >
                                {obj.category.title}
                            </Typography>
                        </S.HighlightItemInfoWrapper>

                        {obj.subtitle && (
                            <Typography
                                style={{
                                    fontWeight: 400,
                                    fontSize: '10px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80%',
                                    lineHeight: '14px'
                                }}
                                variant="pBlackMini87"
                            >
                                {obj.subtitle}
                            </Typography>
                        )}
                    </S.HighlightItemInfoContainer>
                </S.HighlightItemContent>
                <S.HighlightDropdownContainer>
                    {highlightPermissions &&
                    highlightPermissions.allowDelete ? (
                        <HighlightManagementDropdown
                            item={obj}
                            setOpenDeleteModal={setOpenDeleteModal}
                            setIdDelete={setIdDelete}
                        />
                    ) : null}
                </S.HighlightDropdownContainer>
            </S.HighlightItemWrapper>
        </S.HighlightItemContainer>
    )
}

const HighlightContainer = (props: any) => {
    const {
        dragId,
        columns,
        setOpenDeleteModal,
        setIdDelete,
        highlightPermissions,
        isHighlightsEmpty,
        isDragging
    } = props

    return (
        <S.HighlightManagementContentSideWrapperBody>
            <Droppable droppableId={dragId}>
                {provided => (
                    <S.HighlightDragDropContainer
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        isDragging={isDragging}
                    >
                        {!isHighlightsEmpty ? (
                            columns[dragId].list.map(
                                (item: any, index: number) => {
                                    return (
                                        <Draggable
                                            key={item.id}
                                            draggableId={'' + item.id}
                                            index={index}
                                        >
                                            {provided => (
                                                <HighlightItem
                                                    provided={provided}
                                                    obj={item}
                                                    setIdDelete={setIdDelete}
                                                    setOpenDeleteModal={
                                                        setOpenDeleteModal
                                                    }
                                                    order={
                                                        dragId === 'highlights'
                                                            ? '-'
                                                            : index + 1
                                                    }
                                                    highlightPermissions={
                                                        highlightPermissions
                                                    }
                                                />
                                            )}
                                        </Draggable>
                                    )
                                }
                            )
                        ) : (
                            <S.HighlightManagementEmptyHighlightsContainer>
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '18px'
                                    }}
                                    variant="pBlack"
                                >
                                    {dragId === 'highlights'
                                        ? 'Nenhum destaque encontrado.'
                                        : 'Nenhum destaque exibido.'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '10px'
                                    }}
                                    variant="pBlackMini87"
                                >
                                    Você pode criar novos destaques a partir de
                                    notícias publicadas ou adicionando um
                                    destaque.
                                </Typography>
                            </S.HighlightManagementEmptyHighlightsContainer>
                        )}
                        {provided.placeholder}
                    </S.HighlightDragDropContainer>
                )}
            </Droppable>
        </S.HighlightManagementContentSideWrapperBody>
    )
}

const HighlightManagementModal = ({
    showModal,
    setShowModal,
    highlights,
    highlightsOrder,
    fetchData,
    highlightPermissions
}: ModalConfigProps) => {
    const HIGHLIGHTS = 'highlights'
    const HIGHLIGHTSORDER = 'highlightsOrder'

    const [loading, setLoading] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteModalPassword, setOpenDeleteModalPassword] =
        useState(false)

    const formatArray = (array: Array<any>, type: string) => {
        if (type === 'order') {
            return array.map(item => {
                const aux = { ...item.highlight, isFixed: item.isFixed }
                return aux
            })
        } else {
            return array.map(item => {
                const aux = { ...item, isFixed: false }
                return aux
            })
        }
    }

    const highlightsOrderCount = highlightsOrder.filter(
        (item: any) => item.highlight
    ).length

    const highlightsOrderFormated = formatArray(
        highlightsOrder.filter((item: any) => item.highlight),
        'order'
    )

    const highlightsFormated = formatArray(
        highlights.filter(
            a =>
                !(
                    highlightsOrderFormated.filter((b: any) => b.id === a.id)
                        .length > 0
                )
        ),
        'highlights'
    )

    const [columns, setColumns] = useState<any>({
        highlightsOrder: {
            id: HIGHLIGHTSORDER,
            list: []
        },
        highlights: {
            id: HIGHLIGHTS,
            list: []
        }
    })
    const [loadingSave, setLoadingSave] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    const canInsertCount =
        highlightsOrder.length -
        (columns.highlightsOrder ? columns.highlightsOrder.list.length : 0)

    const handleOnDragStart = () => {
        setIsDragging(true)
    }

    const handleOnDragEnd = ({ source, destination }: DropResult) => {
        if (!destination) {
            setIsDragging(false)
            return null
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            setIsDragging(false)
            return null
        }

        const start = columns[source.droppableId]
        const end = columns[destination.droppableId]

        if (start === end) {
            const newList = start.list.filter(
                (_: any, idx: number) => idx !== source.index
            )

            newList.splice(destination.index, 0, start.list[source.index])

            const newCol = {
                id: start.id,
                list: newList
            }

            setColumns((state: any) => ({ ...state, [newCol.id]: newCol }))
            setIsDragging(false)
            return null
        }

        if (
            destination.droppableId === HIGHLIGHTS &&
            start.list.find((_: any, idx: number) => idx === source.index)
                .isFixed
        ) {
            showToast({
                type: 'error',
                message:
                    'Não foi possível realizar a operação: Destaque fixado.'
            })
            setIsDragging(false)
            return null
        }

        if (
            destination.droppableId === HIGHLIGHTSORDER &&
            canInsertCount === 0
        ) {
            showToast({
                type: 'error',
                message:
                    'Não foi possível realizar a operação: Limite de destaques atingido.'
            })
            setIsDragging(false)
            return null
        }

        const newStartList = start.list.filter(
            (_: any, idx: number) => idx !== source.index
        )

        const newStartCol = {
            id: start.id,
            list: newStartList
        }

        const newEndList = end.list
        newEndList.splice(destination.index, 0, start.list[source.index])
        const newEndCol = {
            id: end.id,
            list: newEndList
        }
        setColumns((state: any) => ({
            ...state,
            [newStartCol.id]: newStartCol,
            [newEndCol.id]: newEndCol
        }))

        setIsDragging(false)
        return null
    }

    const arrayToHighlightsOrder = (array: Array<any>) => {
        return array.map(item => {
            return { highlightId: item.id, isFixed: item.isFixed }
        })
    }

    const fetchSaveHighlightsOrder = async () => {
        setLoadingSave(true)
        const arrayHighlightsOrder = arrayToHighlightsOrder(
            columns.highlightsOrder.list
        )
        const res = await updateHighlightsOrder({
            HighlightsOrder: arrayHighlightsOrder
        })
        await fetchData()
        setLoadingSave(false)
        setShowModal(false)
    }

    const handleSave = () => {
        if (columns.highlightsOrder.list.length < 1) {
            showToast({
                type: 'error',
                message:
                    'Não foi possível realizar a operação: Pelo menos um destaque deve ser exibido.'
            })
        } else {
            fetchSaveHighlightsOrder()
        }
    }

    const fetchDelete = async (id: number) => {
        setLoading(true)
        const res = await deleteHighlight(id)
        await fetchData()
        setLoading(false)
    }

    useEffect(() => {
        const initialColumns: { [index: string]: any } = {
            highlightsOrder: {
                id: HIGHLIGHTSORDER,
                list: highlightsOrderFormated
            },
            highlights: {
                id: HIGHLIGHTS,
                list: highlightsFormated
            }
        }
        setColumns(initialColumns)
    }, [showModal, highlights, highlightsOrder])

    const [idDelete, setIdDelete] = useState(0)

    const [orderValue, setOrderValue] = useState({ value: '', label: '' })

    useEffect(() => {
        if (orderValue) {
            if (orderValue.value === 'MORE_RECENT') {
                setColumns((state: any) => ({
                    ...state,
                    ['highlights']: {
                        id: HIGHLIGHTS,
                        list: sortByDate(columns.highlights.list, 'createdAt')
                    }
                }))
            }
            if (orderValue.value === 'LESS_RECENT') {
                setColumns((state: any) => ({
                    ...state,
                    ['highlights']: {
                        id: HIGHLIGHTS,
                        list: sortByDate(
                            columns.highlights.list,
                            'createdAt',
                            true
                        )
                    }
                }))
            }
        }
    }, [orderValue])

    useEffect(() => {
        setOrderValue({ value: '', label: '' })
    }, [columns.highlights.list, columns.highlightsOrder.list])

    return (
        <>
            <Modal
                showModal={openDeleteModal}
                setShowModal={setOpenDeleteModal}
                buttonLeft={() => setOpenDeleteModal(!openDeleteModal)}
                buttonRight={() => {
                    setOpenDeleteModal(false)
                    setOpenDeleteModalPassword(true)
                }}
                title={'Deletar destaque?'}
                description={
                    'Ao continuar, a notícia destaque não estará mais disponível e não poderá ser restaurada. Se você fez alguma edição salve as alterações feitas antes de deletar um destaque.'
                }
                titleButtonLeft={'cancelar'}
                titleButtonRight={'avançar'}
                zIndex={15}
            />
            <HighlightDeleteModal
                showModal={openDeleteModalPassword}
                setShowModal={setOpenDeleteModalPassword}
                title={'Deletar destaque?'}
                text={
                    'Ao continuar, a notícia destaque não estará mais disponível e não poderá ser restaurada. Se você fez alguma edição salve as alterações feitas antes de deletar um destaque.'
                }
                id={idDelete}
                fetchDelete={fetchDelete}
                zIndex={15}
            />
            {showModal ? (
                <S.Background>
                    <S.HighlightManagementModalWrapper showModal={showModal}>
                        <S.HighlightManagementTitle>
                            Gerenciar os destaques
                        </S.HighlightManagementTitle>

                        <S.HighlightManagementHorizontalDivider></S.HighlightManagementHorizontalDivider>

                        <S.HighlightManagementContent>
                            <DragDropContext
                                onDragStart={handleOnDragStart}
                                onDragEnd={handleOnDragEnd}
                            >
                                <S.HighlightManagementContentSideWrapper>
                                    <S.HighlightManagementContentSideWrapperHeader>
                                        <Typography
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '15px'
                                            }}
                                            variant="pBlack"
                                        >
                                            DESTAQUES DO SITE LSBD
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: 400
                                            }}
                                            variant="pGrayMini87"
                                        >
                                            Destaques atualmente sendo exibidos
                                            na tela incial do Site LSBD. Você
                                            pode trocar os destaques e a ordem
                                            dos apresentados movendo os blocos a
                                            baixo.
                                        </Typography>
                                    </S.HighlightManagementContentSideWrapperHeader>
                                    {loading ? (
                                        <HighlightManagementSkeleton />
                                    ) : (
                                        <HighlightContainer
                                            dragId={HIGHLIGHTSORDER}
                                            columns={columns}
                                            setOpenDeleteModal={
                                                setOpenDeleteModal
                                            }
                                            setIdDelete={setIdDelete}
                                            highlightPermissions={
                                                highlightPermissions
                                            }
                                            isHighlightsEmpty={
                                                columns.highlightsOrder.list
                                                    .length === 0 && !isDragging
                                            }
                                            isDragging={isDragging}
                                        />
                                    )}
                                </S.HighlightManagementContentSideWrapper>
                                <S.HighlightManagementVerticalDivider></S.HighlightManagementVerticalDivider>
                                <S.HighlightManagementContentSideWrapper>
                                    <S.HighlightManagementContentSideWrapperHeaderRight>
                                        <OrderBySelect
                                            list={[
                                                {
                                                    label: 'Mais recente',
                                                    value: 'MORE_RECENT'
                                                },
                                                {
                                                    label: 'Menos recente',
                                                    value: 'LESS_RECENT'
                                                }
                                            ]}
                                            currentValue={orderValue}
                                            setValue={setOrderValue}
                                        />
                                    </S.HighlightManagementContentSideWrapperHeaderRight>
                                    {loading ? (
                                        <HighlightManagementSkeleton />
                                    ) : (
                                        <HighlightContainer
                                            dragId={HIGHLIGHTS}
                                            columns={columns}
                                            setIdDelete={setIdDelete}
                                            setOpenDeleteModal={
                                                setOpenDeleteModal
                                            }
                                            highlightPermissions={
                                                highlightPermissions
                                            }
                                            isHighlightsEmpty={
                                                columns.highlights.list
                                                    .length === 0 && !isDragging
                                            }
                                            isDragging={isDragging}
                                        />
                                    )}
                                </S.HighlightManagementContentSideWrapper>
                            </DragDropContext>
                        </S.HighlightManagementContent>

                        <S.HighlightManagementHorizontalDivider></S.HighlightManagementHorizontalDivider>

                        <S.HighlightManagementButtonsWrapper>
                            <GenericButton
                                backgroundColor={'#FFFFFF'}
                                textColor={'#9A9A9A'}
                                onClick={() => setShowModal(false)}
                                size={'small'}
                            >
                                fechar
                            </GenericButton>
                            {highlights.length && highlightsOrder.length ? (
                                <GenericButton
                                    backgroundColor={'#2E2972'}
                                    textColor={'#FFFFFF'}
                                    onClick={() => handleSave()}
                                    margin={'0 0 0 16px'}
                                    size={'small'}
                                    loading={loadingSave}
                                >
                                    salvar
                                </GenericButton>
                            ) : (
                                <></>
                            )}
                        </S.HighlightManagementButtonsWrapper>

                        <S.CloseModalButton
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.HighlightManagementModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

export default HighlightManagementModal
