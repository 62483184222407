import * as styles from './styles'
import { useEffect, useState } from 'react'
import LoginDropdown from 'src/components/Dropdown/LoginDropdown'
import Logo from 'src/assets/images/ufc/lsbd.svg'
import { DesktopButton } from 'src/components/Button'
import * as S from 'src/components/NewsModule/Management/styles'
import usePersistedState from 'src/hooks/usePersistedState'
import { useHistory } from 'react-router-dom'
import useInput from 'src/hooks/useInput'
import {
    MY_NEWS_SCREEN,
    PUBLISHED_SCREEN,
    PENDING_APPROVAL_SCREEN,
    PENDING_PUBLISHED_SCREEN,
    HIGHLIGHT_SCREEN
} from 'src/components/NewsModule/constants'

type NewsManagementHeaderProps = {
    currentPage: string
}

const EditorHeader = ({ current, search }: any) => {
    const history = useHistory()
    const text = useInput('')
    const [management, setManagement] = usePersistedState('management', {
        access: false,
        link: '/'
    })

    const handleSearch = (e: any) => {
        const words = text.value.split(' ').filter((e: any) => e !== '')

        if (e.key === 'Enter' && words.length > 0) {
            history.push('/news/home/search=' + words.join(' '))
        }
    }

    useEffect(() => {
        if (search) {
            text.setValue(search)
        }
    }, [search])

    return (
        <styles.ResponsiveContainer>
            <styles.Container>
                <styles.HeaderWrapper>
                    <styles.Content>
                        <styles.Link to="/">
                            <img src={Logo} />
                        </styles.Link>
                        <styles.Divider />
                        <p>PORTAL DE NOTÍCIAS</p>
                    </styles.Content>
                    <styles.ButtonsWrapper>
                        <styles.Link to="/">
                            <DesktopButton active={true}>
                                Voltar para página principal
                            </DesktopButton>
                        </styles.Link>
                        <LoginDropdown marginRight={'0'} marginTop={'75px'} />
                    </styles.ButtonsWrapper>
                </styles.HeaderWrapper>
                <styles.NavigationContainer>
                    <styles.TabWrapper>
                        <styles.TabLink
                            active={current === 'HOME'}
                            to="/news/home"
                        >
                            HOME
                        </styles.TabLink>
                        <styles.TabLink
                            active={current === 'VAGA'}
                            to="/news/careers"
                        >
                            VAGAS
                        </styles.TabLink>
                        <styles.TabLink
                            active={current === 'POST-GRADUATE'}
                            to="/rsc"
                        >
                            PÓS-GRADUAÇÃO
                        </styles.TabLink>
                        {management.access && (
                            <styles.TabLink
                                active={current === 'MANAGEMENT'}
                                to={management.link}
                            >
                                GERENCIAMENTO DE NOTÍCIAS
                            </styles.TabLink>
                        )}
                    </styles.TabWrapper>
                    <styles.InputWrapper>
                        <styles.SearchIcon size={40} />
                        <input
                            placeholder="Procurar por título de notícia"
                            type="text"
                            onKeyPress={e => handleSearch(e)}
                            maxLength={100}
                            value={text.value}
                            onChange={text.onChange}
                        />
                    </styles.InputWrapper>
                </styles.NavigationContainer>
            </styles.Container>
        </styles.ResponsiveContainer>
    )
}

const NewsManagementHeader = ({ currentPage }: NewsManagementHeaderProps) => {
    const [user, setUser] = usePersistedState('auth-token', '')

    const [myNewsPermissions, setMyNewsPermissions] = useState<any>({})
    const [publishedPermissions, setPublishedPermissions] = useState<any>({})
    const [pendingApprovalPermissions, setPendingApprovalPermissions] =
        useState<any>({})
    const [pendingPublishedPermissions, setPendingPublishedPermissions] =
        useState<any>({})
    const [highlightPermissions, setHighlightPermissions] = useState<any>({})

    const haveAccess = (permission: any) => {
        if (permission) {
            if (permission.allowRead) {
                return true
            }
        }
        return false
    }

    const canAddNews = () => {
        if (myNewsPermissions) {
            if (myNewsPermissions.allowCreate) {
                return true
            }
        }
        return false
    }

    const canAddHighlight = () => {
        if (highlightPermissions) {
            if (highlightPermissions.allowCreate) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (user) {
            setMyNewsPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === MY_NEWS_SCREEN
                )
            )
            setPublishedPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === PUBLISHED_SCREEN
                )
            )
            setPendingApprovalPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === PENDING_APPROVAL_SCREEN
                )
            )
            setPendingPublishedPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === PENDING_PUBLISHED_SCREEN
                )
            )
            setHighlightPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === HIGHLIGHT_SCREEN
                )
            )
        }
    }, [user])

    return (
        <>
            <S.TitleWrapper>
                <S.Title>Gerenciamento de notícias</S.Title>
            </S.TitleWrapper>

            <S.HeaderWrapper>
                <S.Header>
                    <S.HeaderBoxShadow>
                        <S.HeaderContent>
                            <ul>
                                {haveAccess(myNewsPermissions) ? (
                                    <li>
                                        <S.Navlink
                                            active={currentPage === 'MyNews'}
                                            to="/news/management/my-news"
                                        >
                                            minhas notícias
                                        </S.Navlink>
                                    </li>
                                ) : (
                                    <></>
                                )}

                                {haveAccess(publishedPermissions) ? (
                                    <li>
                                        <S.Navlink
                                            active={currentPage === 'Published'}
                                            to="/news/management"
                                        >
                                            publicados
                                        </S.Navlink>
                                    </li>
                                ) : (
                                    <></>
                                )}

                                {haveAccess(pendingApprovalPermissions) ? (
                                    <li>
                                        <S.Navlink
                                            active={
                                                currentPage ===
                                                'PendingApproval'
                                            }
                                            to="/news/management/pending-approval"
                                        >
                                            pendentes para aprovação
                                        </S.Navlink>
                                    </li>
                                ) : (
                                    <></>
                                )}

                                {haveAccess(pendingPublishedPermissions) && (
                                    <li>
                                        <S.Navlink
                                            active={
                                                currentPage ===
                                                'PendingPublished'
                                            }
                                            to="/news/management/pending-published"
                                        >
                                            pendentes para publicação
                                        </S.Navlink>
                                    </li>
                                )}

                                {haveAccess(highlightPermissions) && (
                                    <li>
                                        <S.Navlink
                                            active={currentPage === 'Highlight'}
                                            to="/news/management/highlight"
                                        >
                                            destaques
                                        </S.Navlink>
                                    </li>
                                )}
                            </ul>
                            {canAddNews() && currentPage !== 'Highlight' && (
                                <styles.Link to="/news/add-news">
                                    <button>ADICIONAR NOVA NOTÍCIA</button>
                                </styles.Link>
                            )}
                            {canAddHighlight() && currentPage === 'Highlight' && (
                                <styles.Link to="/add-highlight">
                                    <button>ADICIONAR NOVO DESTAQUE</button>
                                </styles.Link>
                            )}
                        </S.HeaderContent>
                    </S.HeaderBoxShadow>
                </S.Header>
            </S.HeaderWrapper>
        </>
    )
}

export { EditorHeader, NewsManagementHeader }
