import { AxiosResponse } from 'axios'
import { emailsApi } from '../../config'

/**
 * Sends to user an email with link to reset its password
 */
export const sendForgotPasswordEmail = async (data: {
  email: string
}): Promise<AxiosResponse> => {
  return await emailsApi.post('api/forgotPassword', data)
}

/**
 * Sends to user an email informing that its password have successfully be reset to a new one
 */
export const sendResetPasswordConfirmEmail = async (data: {
  ip?: string
  location?: string
  email: string
}): Promise<AxiosResponse> => {
  return await emailsApi.post('api/passwordResetConfirm', data)
}
