import * as styles from './styles'
import * as stylesHome from '../HomeNews/styles'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  LastNewsItem,
  CategoryContentItem
} from 'src/components/NewsModule/HomeNews'
import Spinner from 'src/components/Spinner'

import useMedia from 'src/hooks/useMedia'

import { categoriesRequest } from 'src/store/ducks/categories/actions'
import { RootState } from 'src/store/ducks/rootReducer'
import { postsRequest } from 'src/store/ducks/news/actions'

import sortByDate from 'src/utils/sortByDate'
import { minDesktopScreenWidth } from 'src/utils/breakpoints'

import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { PUBLISHED_STATUS } from 'src/components/NewsModule/constants'
import usePersistedState from 'src/hooks/usePersistedState'

SwiperCore.use([Navigation, Pagination])

const EmptyPostList = (category: any) => {
  return (
    <styles.EmptyContainer>
      <div>
        <h1>Nenhuma notícia encontrada :( </h1>
        <p>
          Opa, infelizmente não temos notícias na categoria
          <b> &quot;{category.category}&quot;.</b>
        </p>
        <p>Que tal voltar para a página inicial e navegar no nosso portal.</p>
        <styles.Link to={'/news/home'}>
          VOLTAR PARA A PÁGINA INICIAL
        </styles.Link>
      </div>
    </styles.EmptyContainer>
  )
}

const ListNewsContent = (props: any) => {
  const dispatch = useDispatch()
  const { width } = useMedia()

  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = usePersistedState('auth-token', '')

  const [category, setCategory] = useState('')
  const categoryUrl = props.props.match.url.split('/')[2]
  const [categoryTitle, setCategoryTitle] = useState('')

  const { news } = useSelector((state: RootState) => state.news)
  const { categories } = useSelector((state: RootState) => state.categories)

  const categoryId = categories?.find(e => e.title === category)

  const [postList, setPostList] = useState<any>([])

  useEffect(() => {
    dispatch(postsRequest())
    dispatch(categoriesRequest())
  }, [categoryUrl])

  useEffect(() => {
    if (news && news.length && categoryId) {
      setPostList(
        sortByDate(news, PUBLISHED_STATUS).filter(post =>
          user
            ? post.status === PUBLISHED_STATUS &&
              post.newsCategoriesId === categoryId.id
            : post.status === PUBLISHED_STATUS &&
              post.newsCategoriesId === categoryId.id &&
              post.audience === 'Externo'
        )
      )
      setLoading(false)
    }
  }, [news, categoryId])

  useEffect(() => {
    switch (categoryUrl) {
      case 'blog':
        setCategoryTitle('Blog')
        setCategory('Blog')
        break
      case 'publications':
        setCategoryTitle('Publicações')
        setCategory('Publicação')
        break
      case 'events':
        setCategoryTitle('Eventos')
        setCategory('Evento')
        break
      case 'awards':
        setCategoryTitle('Prêmios')
        setCategory('Prêmio')
        break
      case 'defenses':
        setCategoryTitle('Defesas')
        setCategory('Defesa')
        break
    }
  }, [categoryUrl])

  return (
    <styles.ResponsiveContainer>
      {loading ? (
        <Spinner heightContainer="100vh" size={'60px'} />
      ) : (
        <styles.Container>
          {postList.length > 0 ? (
            <>
              <styles.Title>{categoryTitle}</styles.Title>
              {width >= minDesktopScreenWidth ? (
                <>
                  <styles.LastNewsWrapper>
                    <LastNewsItem
                      title={postList[0].title}
                      text={postList[0].text}
                      image={postList[0].path}
                      alt={postList[0].alt}
                      category={categoryUrl}
                      id={postList[0].id}
                    />
                  </styles.LastNewsWrapper>

                  <stylesHome.CategoryContent>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={3}
                      spaceBetween={40}
                      navigation={true}
                    >
                      {postList.slice(1).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={categoryUrl}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </stylesHome.CategoryContent>
                </>
              ) : (
                <styles.CategoryMobileContainer>
                  {postList.map((data: any) => {
                    return (
                      <CategoryContentItem
                        key={data.publishedAt}
                        title={data.title}
                        text={data.text}
                        image={data.path}
                        alt={data.alt}
                        category={categoryUrl}
                        id={data.id}
                      />
                    )
                  })}
                </styles.CategoryMobileContainer>
              )}
            </>
          ) : (
            <>
              <styles.Title>{categoryTitle}</styles.Title>
              <EmptyPostList category={category} />
            </>
          )}
        </styles.Container>
      )}
    </styles.ResponsiveContainer>
  )
}

export default ListNewsContent
