import React, { useEffect } from 'react'

import ModuleFooter from 'src/components/NewsModule/Footer'
import { EditorHeader } from 'src/components/NewsModule/Header'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

import NavbarMobile from 'src/components/Mobile/News/Navbar'
import PostGraduateSubsOpenContent from 'src/components/NewsModule/ViewPostGraduate/Subscribe'
import PostGraduateSubsClosedContent from 'src/components/NewsModule/ViewPostGraduate/Subscribe/closed'
import EmptyLoading from 'src/components/NewsModule/ViewPostGraduate/Subscribe/loading'
import useMedia from 'src/hooks/useMedia'
import subscriptionClosed from 'src/services/time'

const PostGraduateSubscribe = (props: any) => {
  const { width } = useMedia()
  const [isOpen, setIsOpen] = React.useState(false)
  const [content, setContent] = React.useState(<EmptyLoading></EmptyLoading>)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    subscriptionClosed().then(closed => {
      if (closed) {
        setContent(<PostGraduateSubsClosedContent props={props} />)
      } else {
        setContent(<PostGraduateSubsOpenContent props={props} />)
      }
    })
  }, [setContent])

  return (
    <>
      <React.Suspense fallback="loading..">
        {width <= maxMobileScreenWidth ? (
          <NavbarMobile isOpen={isOpen} toggle={toggleSidebar} />
        ) : (
          <EditorHeader current="POST-GRADUATE" />
        )}
        {content}
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default PostGraduateSubscribe
