import { applyMiddleware, createStore, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'

// import logger from 'redux-logger'

import rootReducer from './ducks/rootReducer'
import rootSaga from './ducks/rootSaga'

import { NewsState } from './ducks/news/types'
import { CategoriesState } from './ducks/categories/types'
import { TagsState, TagsListState } from './ducks/tags/types'

export interface ApplicationState {
  news: NewsState
  categories: CategoriesState
  tags: TagsState
  tagsList: TagsListState
}

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = createStore(
  rootReducer,
  // applyMiddleware(sagaMiddleware, logger)
  applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(rootSaga)

export default store
