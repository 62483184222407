import { useEffect, useState } from 'react'
import { NewsManagementHeader } from 'src/components/NewsModule/Header'
import HighlightContainer from 'src/components/NewsModule/Header/highlightContainer'
import * as styles from './styles'
import NewsHighlightSkeleton from 'src/components/Skeleton/News/Highlight'
import 'react-pagination-bar/dist/index.css'
import HighlightConfigModal from 'src/components/Modal/HighlightConfigModal'
import HighlightManagementModal from 'src/components/Modal/HighlightManagementModal'
import {
    ResponsiveContainer,
    ContentContainer
} from 'src/components/Containers/containers'
import WarningOrange from 'src/assets/icons/errors/warningOrange.svg'
import GenericButton from 'src/components/Button/GenericButton'
import {
    getHighlightsOrder,
    putHighlightsAmount,
    putHighlightFixed,
    getHighlights,
    deleteHighlightOrder
} from 'src/components/NewsModule/Api/api'
import { formatDateWithDots } from 'src/utils/Date/formatDateWithDots'
import Typography from 'src/components/Typography'
import HighlightDropdown from 'src/components/Dropdown/HighlightDropdown'
import { sortByNumber } from 'src/utils/Sort/sortByNumber'
import { showToast } from 'src/utils/Toast'
import FixedWhite from 'src/assets/icons/news/fixedWhite.svg'
import usePersistedState from 'src/hooks/usePersistedState'
import { HIGHLIGHT_SCREEN } from 'src/components/NewsModule/constants'

const HighlightItem = (props: any) => {
    const { obj, order, fetchFixed, fetchDelete, highlightPermissions } = props

    return (
        <styles.HighlightItemContainer>
            <styles.HighlightItemOrder
                color={obj.highlight ? '#FEC074' : '#6D6F71'}
            >
                {order}
            </styles.HighlightItemOrder>
            <styles.HighlightItemWrapper>
                {obj.highlight ? (
                    <>
                        <styles.HighlightItemContent>
                            <styles.HighlightItemImage
                                src={obj.highlight.image}
                            />
                            <styles.HighlightItemInfoContainer>
                                <styles.HighlightItemInfoWrapper>
                                    <styles.HighlightItemDateWrapper>
                                        <Typography
                                            style={{
                                                fontWeight: 400,
                                                fontSize: '12px'
                                            }}
                                            variant="pBlack"
                                        >
                                            {formatDateWithDots(
                                                new Date(
                                                    obj.highlight.createdAt
                                                )
                                            )}
                                        </Typography>
                                        {obj.isFixed && (
                                            <styles.HighlightItemFixedWrapper>
                                                <p>fixada</p>
                                                <img src={FixedWhite} />
                                            </styles.HighlightItemFixedWrapper>
                                        )}
                                    </styles.HighlightItemDateWrapper>

                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: '24px',
                                            lineHeight: '30px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '90%'
                                        }}
                                        variant="pBlackMini87"
                                    >
                                        {obj.highlight.title}
                                    </Typography>

                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '90%'
                                        }}
                                        variant="pBlackMini87"
                                    >
                                        {obj.highlight.category.title}
                                    </Typography>
                                </styles.HighlightItemInfoWrapper>

                                {obj.highlight.subtitle && (
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '90%'
                                        }}
                                        variant="pBlackMini87"
                                    >
                                        {obj.highlight.subtitle}
                                    </Typography>
                                )}
                            </styles.HighlightItemInfoContainer>
                        </styles.HighlightItemContent>
                        <styles.HighlightDropdownContainer>
                            <HighlightDropdown
                                item={obj}
                                fetchFixed={fetchFixed}
                                fetchDelete={fetchDelete}
                                highlightPermissions={highlightPermissions}
                            />
                        </styles.HighlightDropdownContainer>
                    </>
                ) : (
                    <styles.HighlightItemEmptyWrapper>
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: '24px',
                                lineHeight: '30px',
                                marginBottom: '8px'
                            }}
                            variant="pBlackMini87"
                        >
                            Vazio
                        </Typography>

                        <Typography
                            style={{
                                fontWeight: 400,
                                fontSize: '14px'
                            }}
                            variant="pBlackMini87"
                        >
                            Adicione um destaque na página inicial clicando no
                            botão <b>“Adicionar novo destaque”</b>.
                        </Typography>
                    </styles.HighlightItemEmptyWrapper>
                )}
            </styles.HighlightItemWrapper>
        </styles.HighlightItemContainer>
    )
}

const Highlight = (props: any) => {
    const { context } = props

    const [showConfigModal, setShowConfigModal] = useState(false)
    const [showManagementModal, setShowManagementModal] = useState(false)
    const [highlightsOrder, setHighlightsOrder] = useState([])
    const [highlights, setHighlights] = useState([])
    const [loading, setLoading] = useState(false)
    const [user, setUser] = usePersistedState('auth-token', '')
    const [highlightPermissions, setHighlightPermissions] = useState<any>({})

    const fetchData = async () => {
        const resHO = await getHighlightsOrder()
        const resH = await getHighlights()
        setHighlightsOrder(resHO.data)
        setHighlights(resH.data)
    }

    const fetchConfig = async (amount: number) => {
        setLoading(true)
        const resC = await putHighlightsAmount(amount)

        const resHO = await getHighlightsOrder()
        const resH = await getHighlights()
        setHighlightsOrder(resHO.data)
        setHighlights(resH.data)

        setLoading(false)

        if (resC.status === 200) {
            showToast({
                type: 'success',
                message: 'Configuração de destaques salva.'
            })
        } else {
            showToast({
                type: 'error',
                message: 'Erro ao salvar a configuração.'
            })
        }
    }

    const fetchFixed = async (id: number, expectedFixed: boolean) => {
        setLoading(true)
        const resC = await putHighlightFixed(id)

        const resHO = await getHighlightsOrder()
        const resH = await getHighlights()
        setHighlightsOrder(resHO.data)
        setHighlights(resH.data)

        setLoading(false)

        if (resC.status === 200) {
            showToast({
                type: 'success',
                message: expectedFixed
                    ? 'Destaque fixado.'
                    : 'Destaque desafixado.'
            })
        } else {
            showToast({
                type: 'error',
                message: expectedFixed
                    ? 'Erro ao fixar o destaque.'
                    : 'Erro ao desafixar o destaque.'
            })
        }
    }

    const fetchDelete = async (id: number) => {
        setLoading(true)
        const resC = await deleteHighlightOrder(id)

        const resHO = await getHighlightsOrder()
        const resH = await getHighlights()
        setHighlightsOrder(resHO.data)
        setHighlights(resH.data)

        setLoading(false)
    }

    const fetchHighlightsData = async () => {
        setLoading(true)

        const resHO = await getHighlightsOrder()
        const resH = await getHighlights()
        setHighlightsOrder(resHO.data)
        setHighlights(resH.data)

        setLoading(false)
    }

    useEffect(() => {
        fetchHighlightsData()
    }, [])

    useEffect(() => {
        if (user) {
            setHighlightPermissions(
                user.generalPermissions.find(
                    (p: any) => p.screen === HIGHLIGHT_SCREEN
                )
            )
        }
    }, [user])

    return (
        <>
            <NewsManagementHeader currentPage={context} />

            {loading ? (
                <ResponsiveContainer color="#ffffff">
                    <ContentContainer>
                        <NewsHighlightSkeleton />
                    </ContentContainer>
                </ResponsiveContainer>
            ) : (
                <>
                    <HighlightContainer
                        showConfigModal={showConfigModal}
                        setShowConfigModal={setShowConfigModal}
                        canEdit={
                            highlightPermissions &&
                            highlightPermissions.allowEdit
                        }
                    />
                    <HighlightManagementModal
                        showModal={showManagementModal}
                        setShowModal={setShowManagementModal}
                        highlights={highlights}
                        highlightsOrder={highlightsOrder}
                        fetchData={fetchData}
                        highlightPermissions={highlightPermissions}
                    />
                    <HighlightConfigModal
                        showModal={showConfigModal}
                        setShowModal={setShowConfigModal}
                        title={'Destaques na Página Inicial'}
                        text={'Quantidade de destaques'}
                        highlightAmount={highlightsOrder.length}
                        fetchConfig={fetchConfig}
                    />
                    <ResponsiveContainer color="#ffffff">
                        <ContentContainer>
                            <styles.HighlightWarningContainer>
                                <styles.HighlightWarningIconTextWrapper>
                                    <styles.HighlightWarningIcon
                                        src={WarningOrange}
                                    />
                                    <styles.HighlightWarningText>
                                        Ordem de exibição dos destaques na
                                        página inicial. Clique no botão{' '}
                                        <b>
                                            {'"'}Editar Destaques{'"'}
                                        </b>{' '}
                                        para alterar a apresentação dos
                                        destaques.
                                        <br />
                                        <b>Mostrando:</b>{' '}
                                        {highlightsOrder.length} destaques na
                                        página inicial
                                    </styles.HighlightWarningText>
                                </styles.HighlightWarningIconTextWrapper>
                                {highlightPermissions &&
                                    highlightPermissions.allowEdit && (
                                        <GenericButton
                                            backgroundColor={'#EEF2F6'}
                                            textColor={'#2E2972'}
                                            onClick={() =>
                                                setShowManagementModal(true)
                                            }
                                            size={'small'}
                                        >
                                            editar DESTaQUES
                                        </GenericButton>
                                    )}
                            </styles.HighlightWarningContainer>

                            <styles.HighlightsContainer>
                                {sortByNumber(highlightsOrder, 'id').map(
                                    (item: any, index: number) => {
                                        return (
                                            <HighlightItem
                                                key={item.id}
                                                obj={item}
                                                order={index + 1}
                                                fetchFixed={fetchFixed}
                                                fetchDelete={fetchDelete}
                                                highlightPermissions={
                                                    highlightPermissions
                                                }
                                            />
                                        )
                                    }
                                )}
                            </styles.HighlightsContainer>
                        </ContentContainer>
                    </ResponsiveContainer>
                </>
            )}
        </>
    )
}

export default Highlight
