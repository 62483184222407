import React, { useEffect } from 'react'
import ModuleFooter from 'src/components/NewsModule/Footer'
import PasswordHeader from 'src/components/Password/Header'
import ForgotPasswordContent from 'src/components/Password/ForgotPasswordContent'

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        <PasswordHeader />
        <ForgotPasswordContent />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default ForgotPassword
