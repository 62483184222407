import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const TextWrapper = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    p:first-child {
      font-size: 36px;
      line-height: 48px;
      padding: 24px 0 50px;
    }
    p:last-child {
      font-size: 28px;
      line-height: 33px;
    }
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 35%;

    p:first-child {
      min-height: 150px;
    }

    p + p {
      margin-top: 3rem;
    }
  }
`

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 1rem 0;

  img {
    width: 100%;
    height: 100%;
  }
`
export const TechnologiesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    flex-wrap: wrap;
  }
`

export const Text = styled.div`
  width: 35%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    padding-top: 32px;
  }
`

export const TypographyWrapper = styled.div`
  width: 80%;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`

export const Technologies = styled.div`
  display: flex;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    justify-content: space-between;
    width: 60%;
    height: 197px;
  }
`

export const TechnologyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100px;
    height: 100px;
    margin-top: 32px;
    flex-basis: 33.333333%;
  }

  img {
    width: 5rem;
    height: 5.375rem;
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      width: 64px;
      height: 64px;
    }
  }
`

export const TechnologyText = styled.div`
  margin-top: 1rem;
`

export const DividerMobile = styled.hr`
  border: 1px solid #000000;
  margin-top: 24px;
`
