import { LabelInput } from 'src/components/Modal/styles'
import { Legend } from '../../styles'
import { Label } from '../styles'
import * as styles from './styles'

type Props = {
  name: string
  required?: boolean
}

const FieldFile: React.FC<Props> = ({ name, children, required }) => {
  return (
    <styles.FieldFile>
      {children}
      <styles.InputFile
        type="file"
        name={name}
        id={name}
        accept=".pdf"
        required={required}
      />
      <Legend style={{ textAlign: 'center' }}>
        Arquivo em formato PDF. Tamanho máximo de até 5MB.
      </Legend>
    </styles.FieldFile>
  )
}

export default FieldFile
