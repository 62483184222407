import styled from 'styled-components'

import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 0 80px;
    max-width: 1440px;
  }
`

export const Wrapper = styled.div`
  width: 100%;

  .post {
    width: 100%;

    .react-loading-skeleton {
      margin-bottom: 8px;
    }
  }
`
