export default function sortByDate(arr: Array<any>, column: string) {
  return arr.sort((a: any, b: any) => {
    if (column === 'createdAt') {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)
      return dateA.getTime() < dateB.getTime() ? 1 : -1
    } else {
      const dateA = new Date(a.publishedAt)
      const dateB = new Date(b.publishedAt)
      return dateA.getTime() < dateB.getTime() ? 1 : -1
    }
  })
}
