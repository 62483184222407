import styled from 'styled-components'

import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px;
    max-width: 1440px;
  }
`

export const LastNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    height: 100vh;
    padding: 92px 12px 12px;

    .img {
      width: 100%;
      height: 100%;

      .react-loading-skeleton {
        width: 100%;
        height: 100%;
      }
    }
  }
`

export const LastNewsContent = styled.div`
  display: flex;
  padding-top: 24px;
  width: 100%;
`

export const LastNewsLeftContainer = styled.div`
  display: flex;
  width: 70%;

  .img {
    width: 100%;

    .react-loading-skeleton {
      width: 100%;
    }
  }
`

export const LastNewsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 24px;

  .title {
    width: 100%;
    .react-loading-skeleton {
      width: 100%;
    }
  }

  .text {
    width: 100%;
    padding: 24px 0;
    .react-loading-skeleton {
      width: 100%;
    }
  }

  .read-more {
    display: flex;
    justify-content: flex-end;
  }
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .results {
    margin-top: 40px;
  }
`

export const CategoryContent = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  padding: 24px 0;

  .title {
    padding: 16px 0;
  }

  .read-more {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
    padding-top: 90px;
    gap: 75px;
  }
`

export const CategoryContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .category {
    padding-bottom: 8px;
  }
`
