import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import AddNewsContent from 'src/components/NewsModule/AddNews'
import ModuleFooter from 'src/components/NewsModule/Footer'

import usePersistedState from 'src/hooks/usePersistedState'
import { MY_NEWS_SCREEN } from 'src/components/NewsModule/constants'
import { useHistory } from 'react-router-dom'

const AddNews = () => {
  const history = useHistory()
  const [user, setUser] = usePersistedState('auth-token', '')

  useEffect(() => {
    const myNewsPermissions = user.generalPermissions.find(
      (p: any) => p.screen === MY_NEWS_SCREEN
    )

    if (myNewsPermissions) {
      if (!myNewsPermissions.allowCreate) {
        history.push('/notFound')
      }
    }
  }, [user])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        <EditorHeader current="MANAGEMENT" />
        <AddNewsContent context={'Add'} />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default AddNews
