import * as S from './styles'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import GenericButton from 'src/components/Button/GenericButton'
import Typography from 'src/components/Typography'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LSBD from 'src/assets/images/ufc/logolsbd.svg'

type ModalPreviewProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    image: string
    labelOrange: string
    labelOrangeEN: string
    title: string
    titleEN: string
    additionalText: string
    additionalTextEN: string
    buttonLabel: string
    id?: number
    hasButtons: boolean
}

interface HeroSlide {
    image: any
    title: string
    buttonText: string
    labelOrange: string
    additionalText: string
}

const NavBarPreview = (props: any) => {
    const { isEnglish, t } = props
    return (
        <S.HighlightPreviewNavBarContainer>
            <S.HighlightPreviewNavBarLogo src={LSBD} />
            <S.HighlightPreviewNavBarWrapper>
                <S.HighlightPreviewNavBarOption>
                    {t('navbar.sobre')}
                </S.HighlightPreviewNavBarOption>
                <S.HighlightPreviewNavBarOption>
                    {t('navbar.news')}
                </S.HighlightPreviewNavBarOption>
                <S.HighlightPreviewNavBarOption>
                    {t('navbar.pos-graduacao')}
                </S.HighlightPreviewNavBarOption>
                <S.HighlightPreviewNavBarOption>
                    {t('navbar.vagas')}
                </S.HighlightPreviewNavBarOption>
                <S.HighlightPreviewNavBarOption>
                    {t('navbar.contato')}
                </S.HighlightPreviewNavBarOption>

                <S.HighlightPreviewNavBarLanguageWrapper>
                    <S.HighlightPreviewNavBarLanguageOption
                        isSelected={!isEnglish}
                    >
                        PT
                    </S.HighlightPreviewNavBarLanguageOption>
                    <S.HighlightPreviewNavBarLanguageDivider></S.HighlightPreviewNavBarLanguageDivider>
                    <S.HighlightPreviewNavBarLanguageOption
                        isSelected={isEnglish}
                    >
                        EN
                    </S.HighlightPreviewNavBarLanguageOption>
                </S.HighlightPreviewNavBarLanguageWrapper>

                <S.HighlightPreviewNavBarLogin>
                    Login
                </S.HighlightPreviewNavBarLogin>
            </S.HighlightPreviewNavBarWrapper>
        </S.HighlightPreviewNavBarContainer>
    )
}

const HeroPreview: React.FC<HeroSlide> = ({
    image,
    title,
    buttonText,
    labelOrange,
    additionalText
}) => {
    return (
        <>
            <S.HeroPreviewLeftContainer url={image} />
            <S.HeroPreviewRightContainer>
                <S.HeroPreviewRightContainerWrapper>
                    <S.HeroPreviewContentWrapper>
                        <S.HeroPreviewTitleContainer>
                            {labelOrange && (
                                <Typography
                                    variant="mobileButtonInactive"
                                    className="info"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontSize: '11px'
                                    }}
                                >
                                    {labelOrange}
                                </Typography>
                            )}
                            <S.HeroPreviewTitleWrapper>
                                <Typography
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                    variant="title"
                                >
                                    {title}
                                </Typography>
                            </S.HeroPreviewTitleWrapper>
                            {additionalText && (
                                <S.HeroPreviewAditionalText>
                                    {additionalText}
                                </S.HeroPreviewAditionalText>
                            )}
                        </S.HeroPreviewTitleContainer>
                        <S.HeroPreviewButtonContainer>
                            <S.HeroPreviewButton>
                                {buttonText}
                            </S.HeroPreviewButton>
                        </S.HeroPreviewButtonContainer>
                    </S.HeroPreviewContentWrapper>
                </S.HeroPreviewRightContainerWrapper>
            </S.HeroPreviewRightContainer>
        </>
    )
}

const HighlightPreviewModal = ({
    showModal,
    setShowModal,
    image,
    title,
    titleEN,
    labelOrange,
    labelOrangeEN,
    additionalText,
    additionalTextEN,
    buttonLabel,
    id,
    hasButtons
}: ModalPreviewProps) => {
    const { t, i18n } = useTranslation()
    const [language, setLanguage] = useState('pt')
    const isEnglish = language === 'en'
    const history = useHistory()

    const handleLanguage = (language: string) => {
        setLanguage(language)
        i18n.changeLanguage(language)
    }

    useEffect(() => {
        i18n.changeLanguage('pt')
    }, [])

    return (
        <>
            {showModal ? (
                <S.Background>
                    <S.HighlightPreviewContainer showModal={showModal}>
                        <S.HighlightPreviewTitleWrapper>
                            <S.HighlightPreviewTitle>
                                Pré-visualização de destaque
                            </S.HighlightPreviewTitle>
                        </S.HighlightPreviewTitleWrapper>

                        <S.HighlightPreviewContentContainer>
                            <NavBarPreview isEnglish={isEnglish} t={t} />
                            <HeroPreview
                                image={image}
                                labelOrange={
                                    isEnglish
                                        ? labelOrangeEN.toUpperCase()
                                        : labelOrange.toUpperCase()
                                }
                                title={isEnglish ? titleEN : title}
                                additionalText={
                                    isEnglish
                                        ? additionalTextEN
                                        : additionalText
                                }
                                buttonText={t(buttonLabel, language)}
                            />
                        </S.HighlightPreviewContentContainer>

                        <S.HighlightPreviewButtonsWrapper>
                            <S.HighlightPreviewLanguageWrapper>
                                <S.HighlightPreviewLanguageOption
                                    isSelected={!isEnglish}
                                    onClick={() => handleLanguage('pt')}
                                >
                                    PT
                                </S.HighlightPreviewLanguageOption>
                                <S.HighlightPreviewLanguageDivider></S.HighlightPreviewLanguageDivider>
                                <S.HighlightPreviewLanguageOption
                                    isSelected={isEnglish}
                                    onClick={() => handleLanguage('en')}
                                >
                                    EN
                                </S.HighlightPreviewLanguageOption>
                            </S.HighlightPreviewLanguageWrapper>
                            <div>
                                <GenericButton
                                    backgroundColor={'#FFFFFF'}
                                    textColor={'#9A9A9A'}
                                    onClick={() => setShowModal(false)}
                                    size={'small'}
                                >
                                    fechar
                                </GenericButton>
                                {hasButtons && (
                                    <GenericButton
                                        backgroundColor={'#2E2972'}
                                        textColor={'#FFFFFF'}
                                        onClick={() =>
                                            history.push(
                                                '/edit-highlight/' + id
                                            )
                                        }
                                        margin={'0 0 0 16px'}
                                        size={'small'}
                                    >
                                        editar destaque
                                    </GenericButton>
                                )}
                            </div>
                        </S.HighlightPreviewButtonsWrapper>

                        <S.CloseModalButton
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.HighlightPreviewContainer>
                </S.Background>
            ) : null}
        </>
    )
}

export default HighlightPreviewModal
