import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
    tagsSuccess,
    tagsFailure,
    tagsListFailure,
    tagsListSuccess,
    createTagSuccess,
    createTagFailure
} from './actions'

import {
    FetchTagsListRequest,
    tagsTypes,
    CreateTagInNewsRequest
} from './types'

import { webApi } from 'src/services/api/config'

import { AxiosResponse } from 'axios'

function* fetchTagsSaga() {
    try {
        const response: AxiosResponse = yield call(
            webApi.get,
            'api/site/news/tags'
        )
        yield put(
            tagsSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(tagsFailure({ error: err.message }))
        }
    }
}

function* fetchListTagsSaga() {
    try {
        const response: AxiosResponse = yield call(
            webApi.get,
            'api/site/news/tags-list'
        )

        yield put(
            tagsListSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(tagsListFailure({ error: err.message }))
        }
    }
}

function* fetchUniqueTagList(action: FetchTagsListRequest) {
    try {
        const response: AxiosResponse = yield call(
            webApi.get,
            `api/site/news/tags/:${action.payload}`
        )

        yield put(
            tagsListSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(tagsListFailure({ error: err.message }))
        }
    }
}

function* createTagsSaga(action: CreateTagInNewsRequest) {
    try {
        const response: AxiosResponse = yield call(
            webApi.post,
            'api/site/news/tags-list',
            action.payload,

            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        yield put(
            createTagSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(createTagFailure({ error: err.message }))
        }
    }
}

function* tagsSaga() {
    yield all([
        takeLatest(tagsTypes.TAGS_REQUEST, fetchTagsSaga),
        takeLatest(tagsTypes.TAGS_LIST_REQUEST, fetchListTagsSaga),
        takeLatest(tagsTypes.REQUEST_UNIQUE_NEWS_TAGS, fetchUniqueTagList),
        takeLatest(tagsTypes.CREATE_TAG_IN_NEWS_REQUEST, createTagsSaga)
    ])
}

export default tagsSaga
