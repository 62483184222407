import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

type TranslateOptionProps = {
  readonly active: boolean
  onClick?: React.MouseEventHandler
}

export const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 45%;

  img {
    height: 45px;
    max-width: 100%;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 38px;

    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.87);
  }
`

export const Link = styled(LinkR)`
  button {
    &:hover {
      opacity: 0.8;
    }
  }
`

export const Divider = styled.hr`
  height: 40px;
  margin: 0 1.5rem;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 55%;

  .linkButton {
    text-decoration: none;
    margin-left: 20px;
  }

  .loginContainer {
    margin-left: 20px;
    height: 100%;
  }
`

export const TranslateOption = styled.div`
  display: flex;
`

export const Option = styled.p<TranslateOptionProps>`
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : 'rgba(255, 255, 255, 0.3)'};
  font-size: 1rem;
  line-height: 1.188rem;

  cursor: pointer;
`

export const DividerTranslation = styled.div`
  border: 1px solid #ffffff;
  height: 19px;
  margin: 0 8px;
`
