/* eslint-disable prettier/prettier */
import * as S from './styles'

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import useInput from 'src/hooks/useInput'

type ModalProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    buttonLeft: () => void
    buttonRight: () => void
    title: string
    description: string
    titleButtonLeft: string
    titleButtonRight: string
    zIndex?: number
}

type ModalPropsRecuse = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    buttonLeft: () => void
    buttonRight: () => void
    title: string
    description: string
    titleButtonLeft: string
    titleButtonRight: string
    setJustify: (jus: string) => void
}

type ModalRequiredProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
}

export const ModalRequired = ({
    showModal,
    setShowModal
}: ModalRequiredProps) => {
    return (
        <>
            {showModal ? (
                <S.Background>
                    <S.ModalWrapper showModal={showModal}>
                        <S.Content>
                            <h1>Não foi possível publicar essa notícia</h1>
                            <p>
                                Todos os campos obrigatórios devem ser
                                preenchidos.
                            </p>
                        </S.Content>
                        <S.OkayButton
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        >
                            ok
                        </S.OkayButton>
                    </S.ModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

export const ModalRecuse = ({
    showModal,
    setShowModal,
    buttonLeft,
    buttonRight,
    title,
    description,
    titleButtonLeft,
    titleButtonRight,
    setJustify
}: ModalPropsRecuse) => {
    const justify = useInput('')
    const [requiredValue, setRequiredValue] = useState(false)

    const isEmpty = (item: any) => {
        if (item.length === 0) {
            setRequiredValue(true)
            return true
        }
        return false
    }

    useEffect(() => {
        setRequiredValue(false)
        setJustify(justify.value)
    }, [justify.value])

    return (
        <>
            {showModal ? (
                <S.Background>
                    <S.ModalWrapper showModal={showModal}>
                        <S.ModalContent>
                            <h1>{title}</h1>
                            <p>{description}</p>
                            <p className="justifyText">
                                Por favor, preencha a justificativa para o
                                autor:
                            </p>
                            <S.TextAreaWrapper>
                                <textarea
                                    placeholder="Justificativa"
                                    autoComplete="new-password"
                                    onChange={justify.onChange}
                                    maxLength={200}
                                />
                                {justify.value.length === 200 ? (
                                    <div className="textLengthFull">
                                        {justify.value.length}/200
                                    </div>
                                ) : justify.value.length < 200 &&
                                  justify.value.length >= 190 ? (
                                    <div className="textLengthAlmostFull">
                                        {justify.value.length}/200
                                    </div>
                                ) : (
                                    <div className="textLength">
                                        {justify.value.length}/200
                                    </div>
                                )}
                                {requiredValue ? (
                                    <S.EmptyJustify>
                                        Este Campo é obrigatório.
                                    </S.EmptyJustify>
                                ) : (
                                    <></>
                                )}
                            </S.TextAreaWrapper>
                            <div>
                                <S.ButtonLeft onClick={buttonLeft}>
                                    {titleButtonLeft}
                                </S.ButtonLeft>
                                <S.ButtonRight
                                    onClick={() => {
                                        if (!isEmpty(justify.value)) {
                                            buttonRight()
                                        }
                                    }}
                                >
                                    {titleButtonRight}
                                </S.ButtonRight>
                            </div>
                        </S.ModalContent>
                        <S.CloseModalButton
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.ModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

const Modal = ({
    showModal,
    setShowModal,
    buttonLeft,
    buttonRight,
    title,
    description,
    titleButtonLeft,
    titleButtonRight,
    zIndex
}: ModalProps) => {
    return (
        <>
            {showModal ? (
                <S.Background zIndex={zIndex}>
                    <S.ModalWrapper zIndex={zIndex} showModal={showModal}>
                        <S.ModalContent>
                            <h1>{title}</h1>
                            <p>{description}</p>
                            <div>
                                <S.ButtonLeft onClick={buttonLeft}>
                                    {titleButtonLeft}
                                </S.ButtonLeft>
                                <S.ButtonRight onClick={buttonRight}>
                                    {titleButtonRight}
                                </S.ButtonRight>
                            </div>
                        </S.ModalContent>
                        <S.CloseModalButton
                            zIndex={zIndex}
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.ModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

export default Modal
