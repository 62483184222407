import { useState, useEffect } from 'react'
import * as styles from './styles'

import { News } from 'src/store/ducks/news/types'

import CareersDropdown from 'src/components/Dropdown/CareersDropdown'

import usePersistedState from 'src/hooks/usePersistedState'

import {
  CLOSED_STATUS,
  PUBLISHED_SCREEN,
  PUBLISHED_STATUS
} from 'src/components/NewsModule/constants'
import { postsRequest } from 'src/store/ducks/news/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/ducks/rootReducer'
import { categoriesRequest } from 'src/store/ducks/categories/actions'
import Spinner from 'src/components/Spinner'

const JobNotFound = () => (
  <styles.JobNotFoundContainer>
    <styles.JobNotFoundText>
      <styles.JobNotFoundP1>Nenhuma Vaga Encontrada!</styles.JobNotFoundP1>
      <styles.JobNotFoundP2>
        Não encontramos a vaga que você procurou.
      </styles.JobNotFoundP2>
    </styles.JobNotFoundText>
  </styles.JobNotFoundContainer>
)

const LinkCareersItem: React.FC<any> = ({
  id,
  title,
  regime,
  status,
  post,
  canEdit,
  canDelete
}) => {
  const havePermission = canEdit || canDelete

  return (
    <>
      {!havePermission ? (
        <styles.LinkC to={'/news/careers/' + id}>
          <CareersItem
            id={id}
            title={title}
            regime={regime}
            status={status}
            havePermission={havePermission}
            post={post}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        </styles.LinkC>
      ) : (
        <CareersItem
          id={id}
          title={title}
          regime={regime}
          status={status}
          havePermission={havePermission}
          post={post}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      )}
    </>
  )
}

const CareersItem: React.FC<any> = ({
  id,
  title,
  regime,
  status,
  post,
  canEdit,
  canDelete
}) => {
  const havePermission = canEdit || canDelete

  return (
    <styles.CareersItemContainer hoverActive={!havePermission}>
      <styles.LeftContainer>
        <styles.TitleMoreContainer>
          <styles.TagsContainer>
            <styles.CareersItemTag
              className={`${regime
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()}`}
            >
              {regime.toUpperCase()}
            </styles.CareersItemTag>
            {status === CLOSED_STATUS ? (
              <styles.CareersItemTag className="closed">
                ENCERRADA
              </styles.CareersItemTag>
            ) : (
              <></>
            )}
          </styles.TagsContainer>
          <styles.CareersItemTitle>{title}</styles.CareersItemTitle>
        </styles.TitleMoreContainer>
        {havePermission ? (
          <styles.Link to={'/news/careers/' + id}>Ver Mais</styles.Link>
        ) : (
          <styles.ReadMoreCareer>Ver Mais</styles.ReadMoreCareer>
        )}
      </styles.LeftContainer>
      {havePermission && (
        <styles.RightContainer>
          <CareersDropdown
            id={id}
            post={post}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        </styles.RightContainer>
      )}
    </styles.CareersItemContainer>
  )
}

const ListCareers = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = usePersistedState('auth-token', '')

  const [publishedCareers, setPublishedCareers] = useState<News[]>([])

  const [filter, setFilter] = useState('')

  const { news } = useSelector((state: RootState) => state.news)
  const { categories } = useSelector((state: RootState) => state.categories)
  const categoryCareer = categories?.find(e => e.title === 'Vaga')

  useEffect(() => {
    dispatch(postsRequest())
    dispatch(categoriesRequest())
  }, [])

  const checkCan = (context: string) => {
    if (user) {
      const permission = user.generalPermissions.find(
        (p: any) => p.screen === PUBLISHED_SCREEN
      )
      if (!permission) {
        return false
      }
      if (context === 'Edit' && permission.allowEdit) {
        return true
      }
      if (context === 'Delete' && permission.allowDelete) {
        return true
      }
      return false
    }
    return false
  }

  const handleChange = (value: any) => {
    setFilter(value)
  }

  const postContext = (categoryCareer: any) => {
    if (!checkCan('Edit') && !checkCan('Delete')) {
      return news.filter(
        (post: any) =>
          post.status === PUBLISHED_STATUS &&
          post.status !== CLOSED_STATUS &&
          post.newsCategoriesId === categoryCareer.id
      )
    } else {
      return news.filter(
        (post: any) => post.newsCategoriesId === categoryCareer.id
      )
    }
  }

  useEffect(() => {
    if (news && news.length && categoryCareer) {
      setPublishedCareers(postContext(categoryCareer))
      setLoading(false)
    }
  }, [news, categoryCareer])

  useEffect(() => {
    if (filter.length > 0) {
      setPublishedCareers(
        postContext(categoryCareer)?.filter((post: any) =>
          post.title.toLowerCase().includes(filter.toLowerCase())
        )
      )
    } else {
      setPublishedCareers(postContext(categoryCareer))
    }
  }, [filter])

  return (
    <styles.ResponsiveContainer>
      {loading ? (
        <Spinner heightContainer="100vh" size={'60px'} />
      ) : (
        <styles.Container>
          <styles.TitleWrapper>
            <styles.Title>VAGAS</styles.Title>
            <styles.InputWrapper>
              <styles.SearchIcon size={40} />
              <styles.Input
                value={filter}
                onChange={ev => {
                  handleChange(ev.target.value)
                }}
                placeholder="Procurar por título da vaga"
                type="text"
              />
            </styles.InputWrapper>
          </styles.TitleWrapper>
          {publishedCareers.length > 0 ? (
            <styles.CareersWrapper>
              {publishedCareers.map(data => {
                return (
                  <styles.CareersWrapperItem key={data.id}>
                    <LinkCareersItem
                      id={data.id}
                      title={data.title}
                      regime={data.alt}
                      status={data.status}
                      post={data}
                      canEdit={checkCan('Edit')}
                      canDelete={checkCan('Delete')}
                    />
                  </styles.CareersWrapperItem>
                )
              })}
            </styles.CareersWrapper>
          ) : (
            <JobNotFound />
          )}
        </styles.Container>
      )}
    </styles.ResponsiveContainer>
  )
}

export default ListCareers
