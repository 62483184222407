import * as S from './styles'
import * as styles from '../../Navbar/styles'

import { Bars } from '@styled-icons/fa-solid/Bars'

import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Logo from 'src/assets/images/ufc/lsbd.svg'
import Sidebar from '../Sidebar'
import { animateScroll as scroll } from 'react-scroll'
const NavbarMobile = ({ isOpen, toggle }: any) => {
  const { i18n } = useTranslation()

  const [languageOption, setLanguageOption] = useState<string>('pt')

  useEffect(() => {
    setLanguageOption(i18n.language)
  }, [i18n.language])

  const handleChangeLanguage = (lang: string) => {
    setLanguageOption(lang)
    i18n.changeLanguage(lang)
  }

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      <S.Nav>
        <S.NavbarContainer>
          <S.NavLogo onClick={toggleHome} to="/">
            <img src={Logo} alt="logo lsbd" />
          </S.NavLogo>
          <S.RightContainer>
            <styles.TranslateOption>
              <styles.Option
                onClick={() => {
                  handleChangeLanguage('pt')
                }}
                active={languageOption === 'pt'}
              >
                PT
              </styles.Option>
              <styles.Divider />
              <styles.Option
                onClick={() => {
                  handleChangeLanguage('en')
                }}
                active={languageOption === 'en'}
              >
                EN
              </styles.Option>
            </styles.TranslateOption>
            <S.MobileIcon>
              <Bars size={24} onClick={toggle} />
            </S.MobileIcon>
          </S.RightContainer>
        </S.NavbarContainer>
      </S.Nav>
      <Sidebar isOpen={isOpen} toggleSidebar={toggle} />
    </>
  )
}

export default NavbarMobile
