/* eslint-disable prettier/prettier */
import * as S from './styles'

import useDropdownMenu from 'react-accessible-dropdown-menu-hook'

import LoginButton from 'src/components/Button/LoginButton'
import ModalLogin from 'src/components/Modal/LoginModal'

import usePersistedState from 'src/hooks/usePersistedState'

const LoginDropdown = (props: any) => {
  const { marginRight, marginTop } = props
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(1)
  const [loggedIn, setLoggedIn] = usePersistedState('auth-token', '')

  return (
    <>
      <S.LoginDropdownContainer marginRight={marginRight} marginTop={marginTop}>
        <LoginButton isLoggedIn={loggedIn} {...buttonProps} />
        <div className={`dropdown ${isOpen ? 'visible' : ''}`} role="menu">
          <ModalLogin
            openModal={setIsOpen}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
          />
        </div>
      </S.LoginDropdownContainer>
    </>
  )
}

export default LoginDropdown
