/* eslint-disable no-unneeded-ternary */
import styled from 'styled-components'

type LoginProps = {
  readonly marginRight: string
  readonly marginTop: string
}

type DropdownContainerProps = {
    marginTop?: string
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .dropdown {
    background: #fff;
    margin: auto;
    margin-top: 0.8rem;
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.2s ease, opacity 0.2s ease,
      visibility 0s linear 0.2s;
    will-change: transform;
    text-align: left;
    position: absolute;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    margin-top: ${({ marginTop }) => marginTop ? marginTop : '95px'};
  }
  .dropdown.visible {
    transform: translateY(-0.3rem);
    transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
  }

  .dropdown a {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.25rem;
    img {
      padding-right: 12px;
    }
  }

  .dropdown a {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: 0.1rem solid transparent;
    border-radius: 0.2rem;
  }

  .dropdown a:hover {
    text-decoration: underline;
  }

  .dropdown a:focus {
    border-color: #000000;
  }

  .dropdown a:focus:not(:focus-visible) {
    border-color: transparent;
  }

  .dropdown a:focus-visible {
    border-color: #000000;
  }

  .dropdown a svg {
    color: #000000;
    margin-right: 0.5rem;
  }
`

export const LoginDropdownContainer = styled.div<LoginProps>`
  display: flex;
  height: 100%;

  .dropdown {
    opacity: 0;
    visibility: hidden;
    transition: transform 0.2s ease, opacity 0.2s ease,
      visibility 0s linear 0.2s;
    will-change: transform;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    margin-top: ${({ marginTop }) => marginTop};
    margin-right: ${({ marginRight }) => marginRight};
  }

  .dropdown.visible {
    transform: translateY(-0.3rem);
    transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
  }
`
