import * as styles from './styles'
import Logo from 'src/assets/images/ufc/lsbd.svg'
import LoginDropdown from 'src/components/Dropdown/LoginDropdown'
import { DesktopButton } from 'src/components/Button'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const TranslationOptions = () => {
  const [languageOption, setLanguageOption] = useState<string>('pt')
  const { i18n } = useTranslation()

  const handleChangeLanguage = (lang: string) => {
    setLanguageOption(lang)
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    setLanguageOption(i18n.language)
  }, [i18n.language])

  return (
    <styles.TranslateOption>
      <styles.Option
        onClick={() => {
          handleChangeLanguage('pt')
        }}
        active={languageOption === 'pt'}
      >
        PT
      </styles.Option>
      <styles.DividerTranslation />
      <styles.Option
        onClick={() => {
          handleChangeLanguage('en')
        }}
        active={languageOption === 'en'}
      >
        EN
      </styles.Option>
    </styles.TranslateOption>
  )
}

const Header = (props: any) => {
  const { title, hasTranslation } = props
  const { t } = useTranslation()

  return (
    <styles.HeaderWrapper>
      <styles.Content>
        <styles.Link to="/">
          <img src={Logo} />
        </styles.Link>
        <styles.Divider />
        <p>{title}</p>
      </styles.Content>
      <styles.ButtonsWrapper>
        {hasTranslation && <TranslationOptions />}
        <styles.Link className="linkButton" to="/">
          <DesktopButton active={true}>{t('button.goBack')}</DesktopButton>
        </styles.Link>
        <div className="loginContainer">
          <LoginDropdown marginRight={'0'} marginTop={'75px'} />
        </div>
      </styles.ButtonsWrapper>
    </styles.HeaderWrapper>
  )
}

export default Header
