import * as styles from './styles'

import { useEffect, useState } from 'react'

import Logo from 'src/assets/images/ufc/logolsbd.svg'

import Typography from 'src/components/Typography/index'
import LoginDropdown from 'src/components/Dropdown/LoginDropdown'

import { useTranslation } from 'react-i18next'

const Navbar = () => {
  const { t, i18n } = useTranslation()
  const [languageOption, setLanguageOption] = useState<string>('pt')

  useEffect(() => {
    setLanguageOption(i18n.language)
  }, [i18n.language])

  const handleChangeLanguage = (lang: string) => {
    setLanguageOption(lang)
    i18n.changeLanguage(lang)
  }

  return (
    <styles.Container>
      <styles.LogoWrapper>
        <styles.LogoLSBD src={Logo} />
      </styles.LogoWrapper>
      <styles.NavMenu>
        <styles.NavLink smooth={true} duration={1000} to="about">
          <Typography variant="whiteP">{t('navbar.sobre')}</Typography>
        </styles.NavLink>
        <styles.Link to="/news/home">
          <Typography variant="whiteP">{t('navbar.news')}</Typography>
        </styles.Link>
        <styles.Link to="/rsc">
          <Typography variant="whiteP">{t('navbar.pos-graduacao')}</Typography>
        </styles.Link>
        <styles.Link to="/news/careers">
          <Typography variant="whiteP">{t('navbar.vagas')}</Typography>
        </styles.Link>
        <styles.NavLink smooth={true} duration={1000} to="contact">
          <Typography variant="whiteP">{t('navbar.contato')}</Typography>
        </styles.NavLink>
        <styles.TranslateOption>
          <styles.Option
            onClick={() => {
              handleChangeLanguage('pt')
            }}
            active={languageOption === 'pt'}
          >
            PT
          </styles.Option>
          <styles.Divider />
          <styles.Option
            onClick={() => {
              handleChangeLanguage('en')
            }}
            active={languageOption === 'en'}
          >
            EN
          </styles.Option>
        </styles.TranslateOption>
        <LoginDropdown marginRight={'32px'} marginTop={'90px'} />
      </styles.NavMenu>
    </styles.Container>
  )
}

export default Navbar
