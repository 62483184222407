import styled from 'styled-components'

import { DesktopButton } from '../Button/index'

import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

type TranslateOptionProps = {
  readonly active: boolean
  onClick?: React.MouseEventHandler
}

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  background: transparent;
  position: absolute;
  z-index: 10;
  width: 100vw;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 36px;
  padding-top: 12px;
  width: 50%;
`

export const LogoLSBD = styled.img``

export const NavMenu = styled.ul`
  display: flex;
  width: 50%;
  padding: 24px 32px 0 22px;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const MobileIcon = styled.img`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavLink = styled(LinkS)`
  cursor: pointer;
  display: block;
  padding: 6px 0px;

  p {
    font-size: 14px;
  }

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ theme }) =>
      theme ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`

export const NavBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: 50%;
  }
`

export const Link = styled(LinkR)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 6px 0px;

  p {
    font-size: 14px;
  }

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ theme }) =>
      theme ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`
export const NavBtnLink = styled(DesktopButton)``

export const TranslateOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Option = styled.p<TranslateOptionProps>`
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : 'rgba(255, 255, 255, 0.3)'};
  font-size: 1rem;
  line-height: 1.188rem;

  cursor: pointer;
`

export const Divider = styled.div`
  border: 1px solid #ffffff;
  height: 19px;
  margin: 0 8px;
`
