/* eslint-disable no-unneeded-ternary */
import { ThreeDots } from '@styled-icons/bootstrap'

import * as S from './styles'

const KebabButton = (props: any) => {
    const { dotSize } = props
    return (
        <>
            <S.KebabButton {...props}>
                <ThreeDots size={dotSize ? dotSize : '30'} />
            </S.KebabButton>
        </>
    )
}

export default KebabButton
