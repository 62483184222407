import * as S from './styles'

interface OrangeButtonProps {
    title: string
    onClick?: (event: any) => void
    disabled?: boolean
}

const OrangeButton: React.FC<OrangeButtonProps> = ({
    title,
    onClick,
    disabled = false
}) => {
    return (
        <>
            <S.OrangeButton disabled={disabled} onClick={onClick}>
                {title}
            </S.OrangeButton>
        </>
    )
}

export default OrangeButton
