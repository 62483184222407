import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { Link as LinkR } from 'react-router-dom'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 80px 24px 36px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px;
    max-width: 1440px;
  }
`

export const PostsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 48px;
  width: 100%;
`

export const PostItem = styled.li`
  flex-grow: 1;
  width: 30%;
  max-width: 30%;
  margin: 0 16px 48px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`

export const Results = styled.p`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  margin-top: 40px;
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  background: #eef2f6;
  margin-top: 24px;
  height: 200px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    height: 230px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }

  h1 {
    padding-bottom: 12px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: rgba(0, 0, 0, 0.87);
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
  }
`

export const Link = styled(LinkR)`
  display: flex;
  width: fit-content;
  padding-top: 16px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #009fdf;

  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: transform 0.5s;

  &:hover {
    border-bottom: 1px solid #009fdf;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 13px;
    padding-top: 8px;
  }
`
