import React, {
  ReactNode,
  useState,
  createContext,
  SetStateAction,
  Dispatch
} from 'react'

type TabContextData = {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

type TabProvider = {
  children: ReactNode
}

export const TabContext = createContext({} as TabContextData)

export function TabNavigationProvider({ children }: TabProvider) {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <TabContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </TabContext.Provider>
  )
}
