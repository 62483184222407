export const sortByDate = (array, labelAttribute, isDec) => {
    return isDec
        ? array.sort(
              (a, b) =>
                  new Date(a[labelAttribute]) - new Date(b[labelAttribute])
          )
        : array.sort(
              (a, b) =>
                  new Date(b[labelAttribute]) - new Date(a[labelAttribute])
          )
}
