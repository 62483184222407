import { createGlobalStyle } from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', Work sans;
    @media only screen and (max-width: ${maxMobileScreenWidth}px) {
      -webkit-tap-highlight-color: transparent;
    }
  }

  html, body {
    overflow-x: hidden;
  }

  /* @media (min-width: 1920px) {
    html {
      width: 1920px;

      margin: 0 auto;
    }
  } */

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  button {
    cursor: pointer;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

`
