/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  postTypes,
  FetchPostsFailure,
  FetchPostsFailurePayload,
  FetchPostsRequest,
  FetchPostsSuccess,
  FetchPostsSuccessPayload,
  CreatePostFailure,
  CreatePostRequest,
  CreatePostSuccess,
  DeletePostRequest,
  DeletePostSuccess,
  DeletePostFailure,
  DeleteAllRequest
} from './types'

export const postsRequest = (): FetchPostsRequest => ({
  type: postTypes.POST_REQUEST
})

export const postsSuccess = (
  payload: FetchPostsSuccessPayload
): FetchPostsSuccess => ({
  type: postTypes.POST_SUCCESS,
  payload
})

export const postsFailure = (
  payload: FetchPostsFailurePayload
): FetchPostsFailure => ({
  type: postTypes.POST_FAILURE,
  payload
})

export const createRequest = (obj: FormData): CreatePostRequest => ({
  type: postTypes.CREATE_REQUEST,
  payload: obj
})

export const createSuccess = (
  payload: FetchPostsSuccessPayload
): CreatePostSuccess => ({
  type: postTypes.CREATE_SUCCESS,
  payload
})

export const createFailure = (
  payload: FetchPostsFailurePayload
): CreatePostFailure => ({
  type: postTypes.CREATE_FAILURE,
  payload
})

export const deleteRequest = (id: number): DeletePostRequest => ({
  type: postTypes.DELETE_REQUEST,
  payload: id
})

export const deleteAllRequest = (): DeleteAllRequest => ({
  type: postTypes.DELETE_ALL_REQUEST
})

export const deleteSuccess = (
  payload: FetchPostsSuccessPayload
): DeletePostSuccess => ({
  type: postTypes.DELETE_SUCCESS,
  payload
})

export const deleteFailure = (
  payload: FetchPostsFailurePayload
): DeletePostFailure => ({
  type: postTypes.DELETE_FAILURE,
  payload
})
