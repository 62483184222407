import * as styles from './styles'

import Trophy from 'src/assets/icons/awards/trophy.svg'
import Medal from 'src/assets/icons/awards/medal.svg'
import Typography from '../Typography'
import { useTranslation } from 'react-i18next'

const Awards = () => {
  const { t } = useTranslation()
  return (
    <styles.ResponsiveContainer>
      <styles.Container id="awards">
        <styles.AwardsWrapper>
          <styles.AwardItem>
            <img src={Trophy} />
            <styles.AwardText>
              <Typography variant="h2White">
                best student paper award
              </Typography>
              <Typography variant="whiteP">
                Eduardo Rodrigues, SBC/SBBD - 2018
              </Typography>
            </styles.AwardText>
          </styles.AwardItem>
          <styles.AwardItem>
            <img src={Trophy} />
            <styles.AwardText>
              <Typography variant="h2White">best DEMO award</Typography>
              <Typography variant="whiteP">
                {t('awards.demo-description')}
              </Typography>
            </styles.AwardText>
          </styles.AwardItem>
          <styles.AwardItem>
            <img src={Medal} />
            <styles.AwardText>
              <Typography variant="h2White">{t('awards.mention')}</Typography>
              <Typography variant="whiteP">
                {t('awards.mention-description')}
              </Typography>
            </styles.AwardText>
          </styles.AwardItem>
        </styles.AwardsWrapper>
      </styles.Container>
    </styles.ResponsiveContainer>
  )
}

export default Awards
