import * as styles from '../styles'

import { useState, useEffect, FormEventHandler } from 'react'
import { useHistory } from 'react-router-dom'

import { postgraduatePromoBannerLenovo } from 'src/assets/images/index'

import { useTranslation } from 'react-i18next'

import * as st from './styles'
import ContactBox from '../ContactBox'

const PostGraduateSubsClosedContent = (props: any) => {
  const { t } = useTranslation()

  return (
    <>
      <styles.ResponsiveContainer>
        <styles.Container>
          <styles.BreadCrumbWrapper>
            <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.CrumbLink to="/rsc">Pós-Graduação</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.TitleCrumb>Inscrição</styles.TitleCrumb>
          </styles.BreadCrumbWrapper>

          <styles.ContentWrapper>
            <styles.Header>
              <styles.PageTitle>
                {t('news.postgraduate.sub.page-title')}
              </styles.PageTitle>
              <styles.PromoImg
                src={postgraduatePromoBannerLenovo}
              ></styles.PromoImg>
            </styles.Header>

            <styles.ContentContainer>
              <styles.Title>
                {t('news.postgraduate.sub.closed.title')}
              </styles.Title>
              <styles.Paragraph style={{ fontSize: '1.3rem' }}>
                {t('news.postgraduate.sub.closed.p1')}
              </styles.Paragraph>
              <styles.Paragraph>
                {t('news.postgraduate.sub.closed.p2')}
              </styles.Paragraph>

              <styles.Separator></styles.Separator>

              <styles.Title>{t('news.postgraduate.sub.contact')}</styles.Title>

              <ContactBox email="rsc@lsbd.ufc.br"></ContactBox>
            </styles.ContentContainer>
          </styles.ContentWrapper>
        </styles.Container>
      </styles.ResponsiveContainer>
    </>
  )
}

export default PostGraduateSubsClosedContent
