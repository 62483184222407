import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import ViewNewsContent from 'src/components/NewsModule/ViewNews'
import ModuleFooter from 'src/components/NewsModule/Footer'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

import NavbarMobile from 'src/components/Mobile/News/Navbar'
import useMedia from 'src/hooks/useMedia'

const ViewNews = (props: any) => {
  const { width } = useMedia()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        {width <= maxMobileScreenWidth ? (
          <NavbarMobile isOpen={isOpen} toggle={toggleSidebar} />
        ) : (
          <EditorHeader current="" />
        )}
        <ViewNewsContent props={props} />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default ViewNews
