import * as styles from './styles'

import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Paragraph, Separator, Title } from '../../styles'
import FieldFile from './FieldFile'
import ReactTooltip from 'react-tooltip'

const SendFileForm = (props: any) => {
  return (
    <>
      <Title>Documentação</Title>
      <Paragraph>Anexe nos campos abaixo os documentos solicitados:</Paragraph>

      <FieldFile name="FileDocPhoto" required>
        <styles.Label htmlFor="FileDocPhoto">Documento com foto *</styles.Label>
        <styles.Help data-tip="Anexe um documento de <br /> identificação oficial com foto"></styles.Help>
      </FieldFile>

      <FieldFile name="FileDocSIGAA">
        <styles.Label htmlFor="FileDocSIGAA">
          Comprovante de inscrição gerado e emitido pelo SIGAA
        </styles.Label>
        <styles.Help data-tip="Anexe o documento de inscrição <br /> gerado e emitido pelo SIGAA <br /> no ato da inscrição online"></styles.Help>
      </FieldFile>

      <FieldFile name="FileDocCommitment" required>
        <styles.Label htmlFor="FileDocCommitment">
          Termo de Compromisso (Anexo I do{' '}
          <a
            href="https://drive.google.com/file/d/1g-S0_N6D9TCma0UHMhWJ-IAv0H54sKgY/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edital
          </a>{' '}
          de Seleção) *
        </styles.Label>
        <styles.Help data-tip="Anexe o termo de compromisso <br /> preenchido e assinado"></styles.Help>
      </FieldFile>

      <FieldFile name="FileDocCourse" required>
        <styles.Label htmlFor="FileDocCourse">
          Comprovante de Conclusão de Curso *
        </styles.Label>
        <styles.Help data-tip="Anexe o comprovante de <br /> conclusão do curso superior ou <br /> outro comprovante que o substitua"></styles.Help>
      </FieldFile>

      <FieldFile name="FileDocUFC">
        <styles.Label htmlFor="FileDocUFC">
          Comprovante de Vínculo com a UFC
        </styles.Label>
        <styles.Help data-tip="Caso seja um colaborador da <br /> Universidade Federal do Ceará, <br /> anexe um comprovante <br /> de vínculo com a Instituição"></styles.Help>
        <styles.Description>
          Esse documento se aplica para servidores da Universidade que estejam
          pleiteando a vaga.
        </styles.Description>
      </FieldFile>

      <FieldFile name="FileDocBarema" required>
        <styles.Label htmlFor="FileDocBarema">
          Barema Curricular (Anexo II do{' '}
          <a
            href="https://drive.google.com/file/d/1g-S0_N6D9TCma0UHMhWJ-IAv0H54sKgY/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edital
          </a>{' '}
          de Seleção) *
        </styles.Label>
        <styles.Help data-tip="Anexe o barema curricular e os <br /> documentos comprobatórios <br /> em um único arquivo pdf"></styles.Help>
      </FieldFile>

      <FieldFile name="FileDocLetter" required>
        <styles.Label htmlFor="FileDocLetter">
          Carta de Motivação *
        </styles.Label>
        <styles.Help data-tip="Anexe um pdf de no máximo <br /> uma página contendo <br /> a carta de motivação"></styles.Help>
      </FieldFile>

      <ReactTooltip event="click" multiline={true} />
    </>
  )
}

export default SendFileForm
