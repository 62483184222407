import styled from 'styled-components'

type PasswordInputProps = {
  readonly invalid: boolean
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 80px auto;
`

export const InputPassword = styled.input<PasswordInputProps>`
  width: 100%;
  padding: 24px 20px;
  border: ${({ invalid }) =>
    invalid ? '2px solid #ff3366;' : '2px solid #2e2972;'};
  border-radius: 32px;
  margin: 24px 0 25px;

  &:focus {
    outline: none;
  }

  &:focus-within {
    border: ${({ invalid }) =>
      invalid ? '2px solid #ff3366;' : '2px solid #2e2972;'};
  }
`

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 20px auto 0;
`

export const InputPasswordContainer = styled.div`
  position: relative;
  padding-bottom: 40px;
`

export const InputPasswordWrapper = styled.div`
  display: flex;
  position: relative;
`

export const EyeIcon = styled.img`
  width: 20px;
  height: 20px;
  right: 0;
  margin: 48px 32px 0 0;
  position: absolute;
  cursor: pointer;
`

export const TokenImage = styled.img`
  width: 200px;
  height: 200px;
`

export const TokenImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`

export const PasswordRepInvalidWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
`

export const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
`

export const ResetPasswordInstruction = styled.div`
  display: flex;
  margin-bottom: 48px;
  border-radius: 20px;
  padding: 24px;
  background: rgba(254, 192, 116, 0.15);

  .icon {
    margin-right: 24px;
  }

  .text {
    max-width: 85%;
  }
`

export const TokenInvalidMotivesList = styled.ul`
  margin: 0 0 80px 32px;

  font-family: 'Rubik';
  color: rgba(0, 0, 0, 0.6);
`

export const TokenInvalidContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`
