import * as styles from './styles'

import { useTranslation } from 'react-i18next'

import Scrum from 'src/assets/icons/development/scrum.svg'
import Kanban from 'src/assets/icons/development/kanban.svg'
import Xp from 'src/assets/icons/development/xp.svg'
import Divider from 'src/assets/developmentBar.svg'

import Typography from 'src/components/Typography'

import useMedia from 'src/hooks/useMedia'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

const Management = () => {
  const { t } = useTranslation()
  const { width } = useMedia()

  return (
    <>
      <styles.TextWrapper>
        <Typography variant="h1Blue" style={{ fontWeight: 'bold' }}>
          {t('desenvolvimento.gerenciamento.titulo')}
        </Typography>
        <Typography variant="h1LightBlue" style={{ fontWeight: 'bold' }}>
          {t('desenvolvimento.gerenciamento.steps')}
        </Typography>
      </styles.TextWrapper>
      {width <= maxMobileScreenWidth ? (
        <styles.DividerMobile />
      ) : (
        <styles.BarWrapper>
          <img src={Divider} />
        </styles.BarWrapper>
      )}
      <styles.ToolsWrapper>
        <styles.Text>
          <styles.TypographyWrapper>
            <Typography variant="blueP">
              {t('desenvolvimento.gerenciamento.descricao')}
            </Typography>
          </styles.TypographyWrapper>
        </styles.Text>
        <styles.Tools>
          <styles.ToolsItem>
            <img src={Scrum} />
            <styles.ToolsText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Scrum
              </Typography>
            </styles.ToolsText>
          </styles.ToolsItem>

          <styles.ToolsItem>
            <img src={Xp} />
            <styles.ToolsText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Extreme Programming (XP)
              </Typography>
            </styles.ToolsText>
          </styles.ToolsItem>

          <styles.ToolsItem>
            <img src={Kanban} />
            <styles.ToolsText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Kanban
              </Typography>
            </styles.ToolsText>
          </styles.ToolsItem>
        </styles.Tools>
      </styles.ToolsWrapper>
    </>
  )
}

export default Management
