import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from '../../utils/breakpoints'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  background: ${({ theme }) => theme.colors.lightBlue};
`

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightBlue};
  width: 100%;

  display: flex;
  align-items: center;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    justify-content: center;
    max-width: 480px;
    padding: 50px 24px 82px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 1440px;
    padding: 90px 86px;
  }
`

export const AwardsWrapper = styled.div`
  display: flex;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 100%;
    gap: 24px;
    justify-content: space-between;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const AwardItem = styled.div`
  display: flex;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  img {
    width: 80px;
    height: 80px;

    @media only screen and (max-width: ${maxMobileScreenWidth}px) {
      margin-top: 32px;
    }

    @media only screen and (min-width: ${minDesktopScreenWidth}px) {
      margin-right: 40px;
    }
  }
`

export const AwardText = styled.div`
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: flex;
    flex-direction: column;

    :last-child {
      max-width: 400px;
    }
  }

  p {
    font-family: Rubik;
  }

  p:first-child {
    size: 28px;
    line-height: 33px;
    text-transform: capitalize;
    font-weight: 500;

    @media only screen and (max-width: ${maxMobileScreenWidth}px) {
      padding-top: 24px;
    }
  }

  p:last-child {
    padding-top: 6px;
    size: 16px;
    line-height: 19px;
    font-weight: 400;
  }
`
