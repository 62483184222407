import styled from 'styled-components'

type SpinnerProps = {
  readonly heightContainer: any
  readonly size: string
}

export const SpinnerContainer = styled.div<SpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: ${({ heightContainer }) => heightContainer};

  svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 40px;
  height: 40px;

  & .path {
    stroke: #5652bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
