import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'

import ArrowRightMobile from 'src/assets/icons/news/arrowRightMobile.svg'
import ArrowLeftMobile from 'src/assets/icons/news/arrowLeftMobile.svg'
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px;
    max-width: 1440px;
  }
`

export const LastNewsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .swiper-container {
    width: 100%;

    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      height: 400px;
    }
  }

  .swiper-slide {
    display: flex;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(${ArrowLeftMobile});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 32px;
    height: 32px;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next {
    background-image: url(${ArrowRightMobile});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 32px;
    height: 32px;
  }

  .swiper-button-next::after {
    display: none;
  }
`

export const LastNewsLeftContainer = styled.div<any>`
  display: flex;
  width: 100%;
  max-width: 900px;

  background: ${props => `url(${props.url})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  cursor: pointer;
  transition: transform 0.7s ease;
  transform-origin: 50% 50%;

  &:hover {
    transform: scale(1.1);
    width: 100%;
  }
`

export const LastNewsLink = styled(LinkR)`
  display: flex;
  width: 70%;
  max-width: 70%;
  overflow: hidden;
  margin-right: 24px;
`

export const LastNewsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  height: 80%;
`

export const LastNewsTitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 90%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-bottom: 75px;
  }
`

export const LastNewsTitle = styled.p`
  display: flex;
  padding-bottom: 16px;
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.87);
`
export const LastNewsText = styled.p`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  word-break: break-word;
`

export const Title = styled.p`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  color: #000000;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 36px;
  }
`

export const ReadMore = styled(LinkR)`
  display: flex;
  width: 100%;

  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  justify-content: flex-end;
  color: #000000;

  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`

export const LastNewsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
`

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    padding-top: 60px;
  }

  .swiper {
    width: 100%;
  }

  .swiper-pagination {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      padding-top: 48px;
    }
  }

  .swiper-pagination-bullet {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      background: transparent;
      border: 1px solid #797979;
      border-radius: 40px;
      width: 15px;
      height: 15px;
    }
  }

  .swiper-pagination-bullet-active {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      background: #797979;
    }
  }

  .swiper-slide {
    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(${ArrowLeftMobile});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 28px;
    height: 28px;
    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      width: 32px;
      height: 32px;
    }
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next {
    background-image: url(${ArrowRightMobile});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 28px;
    height: 28px;
    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      width: 32px;
      height: 32px;
    }
  }

  .swiper-button-next::after {
    display: none;
  }
`

export const Link = styled(LinkR)`
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: none;
`

export const CategorySliderImage = styled.div<any>`
  display: flex;
  width: 100%;
  height: 200px;

  background: ${props => `url(${props.url})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const CategorySliderTitle = styled(LinkR)`
  display: flex;
  width: fit-content;
  padding: 16px 0;

  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);

  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`

export const MoreCategory = styled(LinkR)`
  display: flex;
  width: fit-content;
  padding-top: 16px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #009fdf;

  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: transform 0.5s;

  &:hover {
    border-bottom: 1px solid #009fdf;
  }
`

export const ImageBg = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  background: ${props => `url(${props.url})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const ConstrastBgHome = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;

  background: linear-gradient(
    180deg,
    rgba(48, 43, 116, 0) 0%,
    rgba(46, 41, 114, 0.7) 100%
  );
  mix-blend-mode: normal;
`

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 24px 80px;
  flex-direction: column;
`

export const TypeNews = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.buttonColor};
  z-index: 2;
`

export const WelcomeText = styled.h1`
  margin-top: 1rem;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.heroTitle};
  z-index: 2;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 75px;
  padding-top: 24px;
  z-index: 2;
`

export const ReadMoreBtn = styled.button`
  background: ${({ theme }) => theme.colors.buttonColor};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin: 0 24px;
  border: 0;
  outline: 0;
  padding: 16px 40px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
`

export const Chevron = styled(ChevronDown)`
  color: ${({ theme }) => theme.colors.white};
`

export const ChevronWrapper = styled(LinkScroll)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 12px;
  position: absolute;
  z-index: 1;
`

export const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;

  .swiper {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      width: 100vw;
      min-height: 100vh;
    }
  }

  .swiper-pagination {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      margin-bottom: 144px;
    }
  }

  .swiper-pagination-bullet {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      background: transparent;
      border: 1px solid #deeffe;
      border-radius: 40px;
      width: 12px;
      height: 12px;
    }
  }

  .swiper-pagination-bullet-active {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      background: #deeffe;
    }
  }

  .swiper-slide {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      display: flex;
    }
  }
`

export const DividerCategory = styled.div`
  margin: 70px 0 24px;
  width: 100%;
  border-top: 1px solid #797979;
`
