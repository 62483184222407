import * as styles from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ManagementNewsSkeleton = () => {
  return (
    <>
      <styles.Container>
        <styles.Wrapper>
          <Skeleton containerClassName="post" count={5} height={160} />
        </styles.Wrapper>
      </styles.Container>
    </>
  )
}

export default ManagementNewsSkeleton
