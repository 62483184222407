/* eslint-disable prettier/prettier */
import * as styles from './styles'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ChevronDown from 'src/assets/icons/news/chevronDown.svg'

import HomeNewsSkeleton from 'src/components/Skeleton/News/Home'

import useMedia from 'src/hooks/useMedia'

import { categoriesRequest } from 'src/store/ducks/categories/actions'
import { RootState } from 'src/store/ducks/rootReducer'
import { postsRequest } from 'src/store/ducks/news/actions'

import sortByDate from 'src/utils/sortByDate'

import { minDesktopScreenWidth } from 'src/utils/breakpoints'

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { PUBLISHED_STATUS } from 'src/components/NewsModule/constants'
import usePersistedState from 'src/hooks/usePersistedState'

SwiperCore.use([Navigation, Pagination, Autoplay])

interface LastNewsSlide {
  title: string
  text: string
  image: string
  alt: string
  category: any
  id: number
}

const LastNewsItem: React.FC<LastNewsSlide> = ({
  title,
  text,
  image,
  alt,
  category,
  id
}) => {
  const { width } = useMedia()

  return (
    <>
      {width >= minDesktopScreenWidth ? (
        <>
          <styles.LastNewsLink to={`/news/${category}/${id}`}>
            <styles.LastNewsLeftContainer url={image} />
          </styles.LastNewsLink>
          <styles.LastNewsRightContainer>
            <styles.LastNewsTitleTextContainer>
              <styles.LastNewsTitle>{title}</styles.LastNewsTitle>
              <styles.LastNewsText
                dangerouslySetInnerHTML={{
                  __html: text
                }}
              ></styles.LastNewsText>
            </styles.LastNewsTitleTextContainer>
            <styles.ReadMore to={`/news/${category}/${id}`}>
              Leia mais
            </styles.ReadMore>
          </styles.LastNewsRightContainer>
        </>
      ) : (
        <styles.ImageBg url={image}>
          <styles.ConstrastBgHome />
          <styles.TextWrapper>
            <styles.TypeNews>Mais recentes</styles.TypeNews>
            <styles.WelcomeText>{title}</styles.WelcomeText>
          </styles.TextWrapper>
          <styles.ButtonWrapper>
            <styles.Link to={`/news/${category}/${id}`}>
              <styles.ReadMoreBtn>LEIA MAIS</styles.ReadMoreBtn>
            </styles.Link>
          </styles.ButtonWrapper>
        </styles.ImageBg>
      )}
    </>
  )
}

const CategoryContentItem: React.FC<LastNewsSlide> = ({
  title,
  text,
  image,
  alt,
  category,
  id
}) => {
  return (
    <>
      {image === 'undefined' ? (
        <></>
      ) : (
        <styles.Link to={`/news/${category}/${id}`}>
          <styles.CategorySliderImage url={image} />
        </styles.Link>
      )}
      <styles.LastNewsTitleTextContainer>
        <styles.CategorySliderTitle to={`/news/${category}/${id}`}>
          {title}
        </styles.CategorySliderTitle>
        <styles.ReadMore to={`/news/${category}/${id}`}>
          Leia mais
        </styles.ReadMore>
      </styles.LastNewsTitleTextContainer>
    </>
  )
}

const HomeNews = () => {
  const dispatch = useDispatch()

  const { width } = useMedia()
  const { news } = useSelector((state: RootState) => state.news)
  const { categories } = useSelector((state: RootState) => state.categories)

  const [postList, setPostList] = useState<any>([])

  const [loading, setLoading] = useState(true)
  const [user, setUser] = usePersistedState('auth-token', '')

  const career = categories?.find(e => e.title === 'Vaga')

  const blog = categories?.find(e => e.title === 'Blog')
  const [blogList, setBlogList] = useState<any>([])

  const event = categories?.find(e => e.title === 'Evento')
  const [eventList, setEventList] = useState<any>([])

  const publication = categories?.find(e => e.title === 'Publicação')
  const [publicationList, setPublicationList] = useState<any>([])

  const defense = categories?.find(e => e.title === 'Defesa')
  const [defenseList, setDefenseList] = useState<any>([])

  const award = categories?.find(e => e.title === 'Prêmio')
  const [awardList, setAwardList] = useState<any>([])

  const urlCategory = (category: any) => {
    switch (category?.title) {
      case 'Blog':
        return 'blog'
      case 'Vaga':
        return 'careers'
      case 'Publicação':
        return 'publications'
      case 'Defesa':
        return 'defenses'
      case 'Evento':
        return 'events'
      case 'Prêmio':
        return 'awards'
    }
  }

  useEffect(() => {
    dispatch(postsRequest())
    dispatch(categoriesRequest())
  }, [])

  useEffect(() => {
    if (news && news.length && career) {
      setPostList(
        sortByDate(news, PUBLISHED_STATUS)
          .filter(
            post =>
              user
                ? post.status === PUBLISHED_STATUS && post.newsCategoriesId !== career.id
                : post.status === PUBLISHED_STATUS && post.newsCategoriesId !== career.id && post.audience === 'Externo'
          )
      )
      setLoading(false)
    }
  }, [news, career])

  useEffect(() => {
    if (postList.length > 0 && career && blog && event && publication && defense && award) {
      setBlogList(
        sortByDate(postList, PUBLISHED_STATUS)
          .filter(post => post.newsCategoriesId === blog.id)
          .slice(0, 5)
      )
      setEventList(
        sortByDate(postList, PUBLISHED_STATUS)
          .filter(post => post.newsCategoriesId === event.id)
          .slice(0, 5)
      )
      setPublicationList(
        sortByDate(postList, PUBLISHED_STATUS)
          .filter(post => post.newsCategoriesId === publication.id)
          .slice(0, 5)
      )
      setDefenseList(
        sortByDate(postList, PUBLISHED_STATUS)
          .filter(post => post.newsCategoriesId === defense.id)
          .slice(0, 5)
      )
      setAwardList(
        sortByDate(postList, PUBLISHED_STATUS)
          .filter(post => post.newsCategoriesId === award.id)
          .slice(0, 5)
      )
      setLoading(false)
    }
  }, [postList])

  return (
    <styles.ResponsiveContainer>
      {!loading ? (
        <>
          {width >= minDesktopScreenWidth ? (
            <styles.Container>
              <styles.LastNewsContent>
                <styles.Title>Mais recentes</styles.Title>
                <Swiper
                  observer={true}
                  observeParents={true}
                  className="swiper"
                  slidesPerView={1}
                  spaceBetween={48}
                  navigation={true}
                >
                  {sortByDate(postList, PUBLISHED_STATUS)
                    .slice(0, 3)
                    .map(data => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <LastNewsItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
              </styles.LastNewsContent>

              {blogList.length > 0 ? (
                <styles.CategoryContent>
                  <styles.Title>Blog</styles.Title>
                  <Swiper
                    observer={true}
                    observeParents={true}
                    className="swiper"
                    slidesPerView={3}
                    spaceBetween={40}
                    navigation={true}
                  >
                    {blogList.map((data: any) => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <CategoryContentItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  {blogList.length > 3 ? (
                    <styles.MoreCategory to={'/news/blog'}>
                      Ver blog
                    </styles.MoreCategory>
                  ) : (
                    <></>
                  )}
                </styles.CategoryContent>
              ) : (
                <></>
              )}

              {eventList.length > 0 ? (
                <styles.CategoryContent>
                  <styles.Title>Eventos</styles.Title>
                  <Swiper
                    observer={true}
                    observeParents={true}
                    className="swiper"
                    slidesPerView={3}
                    spaceBetween={40}
                    navigation={true}
                  >
                    {eventList.map((data: any) => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <CategoryContentItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  {eventList.length > 3 ? (
                    <styles.MoreCategory to={'/news/events'}>
                      Ver lista de eventos
                    </styles.MoreCategory>
                  ) : (
                    <></>
                  )}
                </styles.CategoryContent>
              ) : (
                <></>
              )}

              {publicationList.length > 0 ? (
                <styles.CategoryContent>
                  <styles.Title>Publicações</styles.Title>
                  <Swiper
                    observer={true}
                    observeParents={true}
                    className="swiper"
                    slidesPerView={3}
                    spaceBetween={40}
                    navigation={true}
                  >
                    {publicationList.map((data: any) => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <CategoryContentItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  {publicationList.length > 3 ? (
                    <styles.MoreCategory to={'/news/publications'}>
                      Ver lista de publicações
                    </styles.MoreCategory>
                  ) : (
                    <></>
                  )}
                </styles.CategoryContent>
              ) : (
                <></>
              )}

              {defenseList.length > 0 ? (
                <styles.CategoryContent>
                  <styles.Title>Defesas</styles.Title>
                  <Swiper
                    observer={true}
                    observeParents={true}
                    className="swiper"
                    slidesPerView={3}
                    spaceBetween={40}
                    navigation={true}
                  >
                    {defenseList.map((data: any) => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <CategoryContentItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  {defenseList.length > 3 ? (
                    <styles.MoreCategory to={'/news/defenses'}>
                      Ver lista de defesas
                    </styles.MoreCategory>
                  ) : (
                    <></>
                  )}
                </styles.CategoryContent>
              ) : (
                <></>
              )}

              {awardList.length > 0 ? (
                <styles.CategoryContent>
                  <styles.Title>Prêmios</styles.Title>
                  <Swiper
                    observer={true}
                    observeParents={true}
                    className="swiper"
                    slidesPerView={3}
                    spaceBetween={40}
                    navigation={true}
                  >
                    {awardList.map((data: any) => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <CategoryContentItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  {awardList.length > 3 ? (
                    <styles.MoreCategory to={'/news/awards'}>
                      Ver lista de prêmios
                    </styles.MoreCategory>
                  ) : (
                    <></>
                  )}
                </styles.CategoryContent>
              ) : (
                <></>
              )}
            </styles.Container>
          ) : (
            <>
              <styles.MobileContainer>
                <Swiper
                  observer={true}
                  observeParents={true}
                  className="swiper"
                  slidesPerView={1}
                  pagination={true}
                  loop={true}
                  autoplay={{
                    delay: 4300,
                    disableOnInteraction: false
                  }}
                >
                  {sortByDate(news, PUBLISHED_STATUS)
                    .filter(
                      post =>
                        post.status === PUBLISHED_STATUS &&
                        post.newsCategoriesId !== career?.id &&
                        post.path !== 'undefined'
                    )
                    .slice(0, 3)
                    .map(data => {
                      return (
                        <SwiperSlide key={data.publishedAt}>
                          <LastNewsItem
                            title={data.title}
                            text={data.text}
                            image={data.path}
                            alt={data.alt}
                            category={urlCategory(
                              categories?.find(
                                (e: any) => e.id === data.newsCategoriesId
                              )
                            )}
                            id={data.id}
                          />
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
                <styles.ChevronWrapper
                  smooth={true}
                  duration={1000}
                  to="newsMobile"
                  offset={-80}
                >
                  <img src={ChevronDown} alt="" />
                </styles.ChevronWrapper>
              </styles.MobileContainer>
              <styles.Container id="newsMobile">
                {blogList.length > 0 ? (
                  <styles.CategoryContent>
                    <styles.Title>Blog</styles.Title>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={1}
                      spaceBetween={40}
                      navigation={true}
                      pagination={true}
                    >
                      {blogList.slice(0, 3).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={urlCategory(
                                categories?.find(
                                  (e: any) => e.id === data.newsCategoriesId
                                )
                              )}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <styles.DividerCategory></styles.DividerCategory>
                  </styles.CategoryContent>
                ) : (
                  <></>
                )}
                {eventList.length > 0 ? (
                  <styles.CategoryContent>
                    <styles.Title>Eventos</styles.Title>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={1}
                      spaceBetween={40}
                      navigation={true}
                      pagination={true}
                    >
                      {eventList.slice(0, 3).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={urlCategory(
                                categories?.find(
                                  (e: any) => e.id === data.newsCategoriesId
                                )
                              )}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <styles.DividerCategory></styles.DividerCategory>
                  </styles.CategoryContent>
                ) : (
                  <></>
                )}
                {publicationList.length > 0 ? (
                  <styles.CategoryContent>
                    <styles.Title>Publicações</styles.Title>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={1}
                      spaceBetween={40}
                      navigation={true}
                      pagination={true}
                    >
                      {publicationList.slice(0, 3).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={urlCategory(
                                categories?.find(
                                  (e: any) => e.id === data.newsCategoriesId
                                )
                              )}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <styles.DividerCategory></styles.DividerCategory>
                  </styles.CategoryContent>
                ) : (
                  <></>
                )}
                {defenseList.length > 0 ? (
                  <styles.CategoryContent>
                    <styles.Title>Defesas</styles.Title>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={1}
                      spaceBetween={40}
                      navigation={true}
                      pagination={true}
                    >
                      {defenseList.slice(0, 3).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={urlCategory(
                                categories?.find(
                                  (e: any) => e.id === data.newsCategoriesId
                                )
                              )}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <styles.DividerCategory></styles.DividerCategory>
                  </styles.CategoryContent>
                ) : (
                  <></>
                )}
                {awardList.length > 0 ? (
                  <styles.CategoryContent>
                    <styles.Title>Prêmios</styles.Title>
                    <Swiper
                      observer={true}
                      observeParents={true}
                      className="swiper"
                      slidesPerView={1}
                      spaceBetween={40}
                      navigation={true}
                      pagination={true}
                    >
                      {awardList.slice(0, 3).map((data: any) => {
                        return (
                          <SwiperSlide key={data.publishedAt}>
                            <CategoryContentItem
                              title={data.title}
                              text={data.text}
                              image={data.path}
                              alt={data.alt}
                              category={urlCategory(
                                categories?.find(
                                  (e: any) => e.id === data.newsCategoriesId
                                )
                              )}
                              id={data.id}
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </styles.CategoryContent>
                ) : (
                  <></>
                )}
              </styles.Container>
            </>
          )}
        </>
      ) : (
        <HomeNewsSkeleton />
      )}
    </styles.ResponsiveContainer>
  )
}

export default HomeNews
export { LastNewsItem, CategoryContentItem }
