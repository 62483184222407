import axios, { AxiosResponse } from 'axios'
import { GeolocationDb } from './types'

/**
 * Use https://geolocation-db.com/ to get users IP
 */
export const getUserGeolocation = async (): Promise<
  AxiosResponse<GeolocationDb>
> => {
  return await axios.get('https://geolocation-db.com/json/')
}
