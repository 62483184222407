import { ReactElement, useState } from 'react'
import { TabList } from './styles'
import TabTitle from './tab-title'

type Props = {
  children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children }) => {
  // @TODO: Mostrar conteúdo de todas as abas mesmo, usando display: none para os desabilitados

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <TabList>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            active={selectedTab === index}
          />
        ))}
      </TabList>
      {children[selectedTab]}
    </div>
  )
}

export default Tabs
