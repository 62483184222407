import styled from 'styled-components'

export const Container = styled.div`
    max-width: 750px;
    margin: 48px auto;
`

export const ImageContainer = styled.div`
    max-height: 360px;
    margin-bottom: 24px;
`

export const SupportBilingualContainer = styled.div`
    padding: 20px;
    background: #2E2972;
    border-radius: 16px;
`

export const SelectContainer = styled.div`
    width: 250px;
`

export const ButtonSelectContainer = styled.div`
    width: 250px;
    margin-bottom: 32px;
`

export const SupportBilingualIcon = styled.img`
`

export const SupportBilingualIconWrapper = styled.div`
    display: flex;
`

export const PreviewHighlight = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    p {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        color: rgba(0, 0, 0, 0.54);

        b {
            font-weight: 500;
            color: #2E2972;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    img {
        margin-left: 10px;
    }
`
