import * as styles from './styles'

import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Paragraph, Separator, Title } from '../../styles'
import { webApi } from 'src/services/api/config'
import { Legend } from '../styles'

const SubscribeForm = (props: any) => {
  const year = new Date().getFullYear()

  const getYearsFrom = (startYear: number, lastYear: number) => {
    const rs = []
    for (let i = startYear; i <= lastYear; i++) {
      rs.push(i)
    }
    return rs
  }

  const getYearOptions = (years: Array<number>) => {
    return years.reverse().map((year, index) => {
      return (
        <option key={`year-${index}`} value={`${year}-1-1`}>
          {year}
        </option>
      )
    })
  }

  const inputs = document.querySelectorAll('input[type=text], input[type=tel]')
  inputs.forEach(input => {
    input.addEventListener('focusout', e => {
      const target = e.target as HTMLInputElement
      target.value = target.value.trim()
    })
  })

  const onlyNumbers = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  const updateEndDate = (event: any) => {
    const target = event.target as HTMLSelectElement
    const selectedYear = parseInt(target.value.substring(0, 4))
    setYearsEndDate(getYearOptions(getYearsFrom(selectedYear, year)))
  }

  const [yearsEndDate, setYearsEndDate] = useState(
    getYearOptions(getYearsFrom(year - 70, year))
  )

  return (
    <>
      <Title id="sub-form-id-anchor">Identificação</Title>
      <Paragraph>Preencha os campos abaixo com seus dados pessoais:</Paragraph>

      <styles.ErrorWrapper>{props.errorMessage}</styles.ErrorWrapper>

      <styles.Field>
        <styles.Label htmlFor="FullName">Nome Completo *</styles.Label>
        <styles.InputText
          type="text"
          name="FullName"
          id="FullName"
          maxLength={100}
          required
        />
      </styles.Field>
      <styles.Field>
        <styles.Label htmlFor="SocialName">Nome Social</styles.Label>
        <styles.InputText
          type="text"
          name="SocialName"
          id="SocialName"
          maxLength={100}
        />
      </styles.Field>

      <styles.FieldGroup>
        <styles.Field>
          <styles.Label htmlFor="RG">RG *</styles.Label>
          <styles.InputText
            type="tel"
            name="RG"
            id="RG"
            pattern="[0-9]*"
            onKeyPress={onlyNumbers}
            placeholder="Digite o seu RG (apenas números)"
            maxLength={30}
            required
          />
        </styles.Field>

        <styles.Field>
          <styles.Label htmlFor="CPF">CPF *</styles.Label>
          <styles.InputText
            type="tel"
            name="CPF"
            id="CPF"
            pattern="[0-9]*"
            minLength={11}
            maxLength={11}
            onKeyPress={onlyNumbers}
            placeholder="Digite o seu CPF (apenas números)"
            required
          />
        </styles.Field>
      </styles.FieldGroup>

      <styles.Field>
        <styles.Label htmlFor="Address">Endereço *</styles.Label>
        <styles.InputText
          type="text"
          name="Address"
          id="Address"
          maxLength={120}
          required
        />
      </styles.Field>

      <styles.FieldGroup>
        <styles.Field>
          <styles.Label htmlFor="City">Cidade *</styles.Label>
          <styles.InputText
            type="text"
            name="City"
            id="City"
            maxLength={60}
            required
          />
        </styles.Field>
        <styles.Field>
          <styles.Label htmlFor="State">Estado *</styles.Label>
          <styles.InputText
            type="text"
            name="State"
            id="State"
            maxLength={60}
            required
          />
        </styles.Field>
        <styles.Field>
          <styles.Label htmlFor="Country">País *</styles.Label>
          <styles.InputText
            type="text"
            name="Country"
            id="Country"
            maxLength={60}
            required
          />
        </styles.Field>
      </styles.FieldGroup>

      <styles.FieldGroup>
        <styles.Field>
          <styles.Label htmlFor="Email">E-mail *</styles.Label>
          <styles.InputText
            type="email"
            name="Email"
            id="Email"
            maxLength={30}
            required
          />
        </styles.Field>

        <styles.Field>
          <styles.Label htmlFor="Phone">Celular *</styles.Label>
          <styles.InputText
            type="tel"
            name="Phone"
            id="Phone"
            pattern="[0-9]*"
            onKeyPress={onlyNumbers}
            placeholder="Digite o seu número de telefone"
            maxLength={30}
            required
          />
        </styles.Field>
      </styles.FieldGroup>

      <Separator></Separator>

      <Title>Informações Acadêmicas</Title>
      <Paragraph>
        Preencha os campos abaixo com as suas informações acadêmicas:
      </Paragraph>

      <styles.Field>
        <styles.Label htmlFor="CourseName">
          Nome do Curso Superior *
        </styles.Label>
        <styles.InputText
          type="text"
          name="CourseName"
          id="CourseName"
          maxLength={100}
          required
        />
      </styles.Field>

      <styles.Field>
        <styles.Label htmlFor="EducationalInstitution">
          Instituição de Ensino *
        </styles.Label>
        <styles.InputText
          type="text"
          name="EducationalInstitution"
          id="EducationalInstitution"
          maxLength={100}
          required
        />
      </styles.Field>

      <styles.FieldGroup>
        <styles.Field>
          <styles.Label htmlFor="CourseStartDate">
            Ano de Início do Curso *
          </styles.Label>
          <styles.InputDate
            as="select"
            name="CourseStartDate"
            id="CourseStartDate"
            onChange={updateEndDate}
            required
          >
            {getYearOptions(getYearsFrom(year - 70, year))}
          </styles.InputDate>
          {/* <styles.CalendarIcon></styles.CalendarIcon> */}
        </styles.Field>
        <styles.Field>
          <styles.Label htmlFor="CourseEndDate">
            Ano de Conclusão do Curso *
          </styles.Label>
          <styles.InputDate
            as="select"
            name="CourseEndDate"
            id="CourseEndDate"
            required
          >
            {yearsEndDate}
          </styles.InputDate>
          {/* <styles.CalendarIcon></styles.CalendarIcon> */}
        </styles.Field>
      </styles.FieldGroup>
    </>
  )
}

export default SubscribeForm
