import React, { useEffect } from 'react'

import { EditorHeader } from 'src/components/NewsModule/Header'
import AddNewsContent from 'src/components/NewsModule/AddNews'
import ModuleFooter from 'src/components/NewsModule/Footer'

const EditNews = (props: any) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <React.Suspense fallback="loading..">
        <EditorHeader current="MANAGEMENT" />
        <AddNewsContent id={props.match.params.id} context={'Edit'} />
        <ModuleFooter />
      </React.Suspense>
    </>
  )
}

export default EditNews
