import styled from 'styled-components'
import { Search } from '@styled-icons/boxicons-regular'
import { Link as LinkR } from 'react-router-dom'
import { maxMobileScreenWidth } from 'src/utils/breakpoints'

type TabButtonProps = {
  readonly active: boolean
}

type TranslateOptionProps = {
  readonly active: boolean
  onClick?: React.MouseEventHandler
}

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  background: ${({ theme }) => theme.colors.defaultBlue};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding: 24px 80px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 32px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  img {
    height: 45px;
    max-width: 100%;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 38px;

    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.87);
  }
`

export const Link = styled(LinkR)`
  button {
    &:hover {
      opacity: 0.8;
    }
  }
`

export const Divider = styled.hr`
  height: 40px;
  margin: 0 1.5rem;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

  a {
    text-decoration: none;
  }

  button {
    margin-left: 20px;
  }
`

export const TranslateOption = styled.div`
  display: flex;
`

export const Option = styled.p<TranslateOptionProps>`
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : 'rgba(255, 255, 255, 0.3)'};
  font-size: 1rem;
  line-height: 1.188rem;

  cursor: pointer;
`

export const DividerTranslation = styled.div`
  border: 1px solid #ffffff;
  height: 19px;
  margin: 0 8px;
`

export const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
`

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  /* gap: 24px; */

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 17px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`

export const TabLink = styled(LinkR)<TabButtonProps>`
  margin-right: 24px;
  text-decoration: none;
  cursor: pointer;
  display: block;

  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 17px;

  color: #ffffff;

  border-bottom: ${({ active, theme }) =>
    active ? `2px solid ${theme.colors.buttonColor}` : 'none'};

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ active, theme }) =>
      !active ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`

export const TabLinkCareer = styled.a<TabButtonProps>`
  text-decoration: none;
  cursor: pointer;
  display: block;

  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 17px;

  color: #ffffff;

  border-bottom: ${({ active, theme }) =>
    active ? `2px solid ${theme.colors.buttonColor}` : 'none'};

  &:after {
    display: block;
    content: '';
    border-bottom: ${({ active, theme }) =>
      !active ? `2px solid ${theme.colors.buttonColor}` : 'none'};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover {
    p {
      opacity: 1;
    }

    &:after {
      transform: scaleX(1);
    }
  }
`

export const SearchIcon = styled(Search)`
  color: #797979;
  position: absolute;
  padding: 0 10px;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    padding: 0 24px 24px;
  }

  input {
    height: 100%;
    width: 17.5rem;
    border-radius: 30px;
    outline: none;
    border: none;

    padding: 10px 2.275rem;

    @media only screen and (max-width: ${maxMobileScreenWidth}px) {
      width: 100%;
      padding: 12px 2.275rem;
    }
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;
  margin-right: 1rem;
  align-items: center;
  cursor: pointer;
  height: 35px;
`

export const DropDownHeader = styled.div`
  display: flex;
  width: 170px;
  padding: 0 12px;
  border: 1px solid #ffffff;
  border-radius: 24px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #ffffff;
`

export const DropDownListContainer = styled.div`
  position: absolute;
  margin-top: 38px;
  top: 0;
  z-index: 100;
  width: 170px;
`

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #f2f2f2;
  box-sizing: border-box;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
`

export const ListItem = styled.li`
  list-style: none;
  text-align: center;
  border-bottom: 1px solid #000000;
  padding: 8px 0;
  &:hover {
    background: #fd9e46;
  }
`

export const ListItemAll = styled.li`
  list-style: none;
  text-align: center;
  padding: 8px 0;
  border-bottom: 1px solid #000000;

  &:hover {
    background: #fd9e46;
  }
`
