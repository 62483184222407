import { useCallback } from 'react'
import { TabButton, TabListItem } from './styles'

type Props = {
  title: string
  index: number
  setSelectedTab: (index: number) => void
  active: boolean
}

const TabTitle: React.FC<Props> = ({
  index,
  title,
  setSelectedTab,
  active
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <TabListItem>
      <TabButton onClick={onClick} className={active ? 'active' : ''}>
        {title}
      </TabButton>
    </TabListItem>
  )
}

export default TabTitle
