import * as S from './styles'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import useInput from 'src/hooks/useInput'
import usePersistedState from 'src/hooks/usePersistedState'
import { showToast } from 'src/utils/Toast'
import { webApi } from 'src/services/api/config'

type ModalDeleteProps = {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    title: string
    text: string
    id: number
    fetchDelete: (id: number) => void
    zIndex?: number
}

const HighlightDeleteModal = ({
    showModal,
    setShowModal,
    id,
    title,
    text,
    fetchDelete,
    zIndex
}: ModalDeleteProps) => {
    const password = useInput('')

    const [user, setUser] = usePersistedState('auth-token', '')
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [requiredValue, setRequiredValue] = useState(false)

    const sendRequest = async (email: string, password: string) => {
        // TODO: Transfer this request to API layer
        if (password) {
            const requestInfo = await webApi
                .post(
                    'api/auth/login',
                    JSON.stringify({
                        email: email,
                        password: password
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then(response => {
                    fetchDelete(id)
                    setShowModal(false)
                })
                .catch(err => {
                    console.log(err)
                    setIsPasswordValid(false)
                })
        } else {
            setRequiredValue(true)
        }
    }

    useEffect(() => {
        setIsPasswordValid(true)
        setRequiredValue(false)
    }, [password.value])

    return (
        <>
            {showModal ? (
                <S.Background zIndex={zIndex}>
                    <S.ModalWrapper zIndex={zIndex} showModal={showModal}>
                        <S.ModalContent>
                            <h1>{title}</h1>
                            <p>Por favor, insira a sua senha para confirmar</p>
                            <input
                                type="password"
                                placeholder="Senha"
                                autoComplete="new-password"
                                onChange={password.onChange}
                                maxLength={50}
                            />
                            {isPasswordValid ? (
                                <></>
                            ) : (
                                <S.InvalidPassword>
                                    Senha inválida.
                                </S.InvalidPassword>
                            )}
                            {requiredValue ? (
                                <S.InvalidPassword>
                                    Este Campo é obrigatório.
                                </S.InvalidPassword>
                            ) : (
                                <></>
                            )}

                            <p>{text}</p>
                            <div>
                                <S.ButtonLeft
                                    onClick={() => setShowModal(!showModal)}
                                >
                                    cancelar
                                </S.ButtonLeft>
                                <S.ButtonRight
                                    onClick={() =>
                                        sendRequest(
                                            user.userName,
                                            password.value
                                        )
                                    }
                                >
                                    deletar
                                </S.ButtonRight>
                            </div>
                        </S.ModalContent>
                        <S.CloseModalButton
                            zIndex={zIndex}
                            aria-label="Close modal"
                            onClick={() =>
                                setShowModal((prev: boolean) => !prev)
                            }
                        />
                    </S.ModalWrapper>
                </S.Background>
            ) : null}
        </>
    )
}

export default HighlightDeleteModal
