import * as styles from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const NewsHighlightSkeleton = () => {
    return (
        <>
            <styles.Container>
                <styles.Wrapper>
                    <Skeleton containerClassName="post" height={60} />
                    <Skeleton containerClassName="post" height={90} />
                    <Skeleton
                        containerClassName="post"
                        count={3}
                        height={150}
                    />
                </styles.Wrapper>
            </styles.Container>
        </>
    )
}

export default NewsHighlightSkeleton
