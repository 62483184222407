import { useTranslation } from 'react-i18next'
import * as styles from './styles'

const InfoBox = (props: any) => {
  const { t } = useTranslation()

  const boxes = props.boxes.map((box: any, i: any) => {
    return (
      <styles.InfoBoxColumn key={i}>
        <styles.InfoBoxContent
          dangerouslySetInnerHTML={{ __html: box }}
        ></styles.InfoBoxContent>
      </styles.InfoBoxColumn>
    )
  })

  return <styles.InfoBox>{boxes}</styles.InfoBox>
}

export default InfoBox
