import React, { Component, ReactNode } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import { withIdleTimer, IIdleTimer } from 'react-idle-timer'
import loginModal from './components/Modal/LoginModal'

interface IIdleTimerProps extends IIdleTimer {
  // children: ReactNode | undefined
}

interface IState {
  bar: string
}

// let active = true

class IdleTimerComponent extends Component<IIdleTimerProps, IState> {
  active: boolean

  constructor(props: any) {
    super(props)
    this.active = true
  }

  onPrompt(): void {
    // Fire a Modal Prompt
  }

  onIdle(): void {
    // Close Modal Prompt
    // Do some idle action like log out your user
    // console.log('user is idle, timeout: ', process.env.REACT_APP_TIMEOUT)

    // const lastActiveTime = this.props.getLastActiveTime() as Date
    // console.log('last active', new Date(lastActiveTime))
    this.active = false

    localStorage.clear()
    Cookies.remove('auth-token', {
      path: '/',
      domain: process.env.REACT_APP_DOMAIN
    })

    // window.location.reload()
  }

  onActive(event: Event): void {
    // Close Modal Prompt
    // Do some active action
  }

  onAction(event: Event): void {
    // Do something when a user triggers a watched event
    if (this.active === undefined) this.active = true

    // console.log('user is active',' ',this.active,process.env.REACT_APP_TIMEOUT,process.env.REACT_APP_THROTTLE)

    if (this.active === false) {
      this.active = true
      if (
        Cookies.get('auth-token') == null ||
        localStorage.getItem('auth-token') == null
      ) {
        window.location.reload()
      } else {
        // console.log("c: ", process.env.REACT_APP_SERVER + `api/auth/RefreshToken`)
        axios
          .get(process.env.REACT_APP_SERVER + 'api/auth/RefreshToken', {
            headers: {
              'Content-type': 'application/json',
              Authorization:
                'bearer ' +
                (Cookies.get('auth-token') ? Cookies.get('auth-token') : '')
            }
          })
          .then(res => {
            if (res.statusText === 'OK') {
              if (res.data !== null) {
                Cookies.set('auth-token', res.data, {
                  path: '/',
                  domain: process.env.REACT_APP_DOMAIN
                  // maxAge: 900,
                  // secure: false,
                  // httpOnly: false
                })
                const tokenString: string =
                  localStorage.getItem('auth-token') || ''
                const authData = JSON.parse(tokenString)

                authData.token = res.data
                localStorage.setItem('auth-token', JSON.stringify(authData))
                // getSystemAccess(response.data.authData)
                // getApprovers(response.data.authData)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }

  componentDidMount(): void {
    // The IIdleTimer interface is supplied via props to your component
    this.props.start()
  }

  render(): ReactNode {
    return (
      // <>{this.props.children !== undefined ? this.props.children : <div />}</>
      <div></div>
    )
  }
}

export const IdleTimer = withIdleTimer<IIdleTimerProps>(IdleTimerComponent)
