import * as styles from './styles'

import { useTranslation } from 'react-i18next'
import { DesktopButton } from 'src/components/Button'
import ContactBox from './ContactBox'
import Typography from 'src/components/Typography'
import { postgraduatePromoBannerLenovo } from 'src/assets/images'
import Table, { TableBody, TableCell, TableHeader, TableRow } from './Table'

const Selection = (props: any) => {
  const { t } = useTranslation()

  const data = [
    {
      action: 'Divulgação do resultado e da lista de selecionados',
      period: '29 de abril de 2022'
    },
    {
      action: 'Interposição de recurso ao  resultado da seleção',
      period: '02 e 03 de maio de 2022'
    },
    {
      action: 'Divulgação do resultado após análise do recurso',
      period: '05 de maio de 2022'
    },
    {
      action: 'Divulgação da lista consolidada de selecionados',
      period: '06 de maio de 2022'
    },
    { action: 'Início das atividades do curso', period: '10 de maio de 2022' }
  ]

  return (
    <>
      <styles.TitleContainer>
        <styles.PromoImg
          style={{ marginBottom: '3.5rem' }}
          src={postgraduatePromoBannerLenovo}
        ></styles.PromoImg>
        <styles.TabTitle>{t('news.postgraduate.ufc-lenovo')}</styles.TabTitle>
        <styles.Title>{t('news.postgraduate.selc.tab-title')}</styles.Title>
      </styles.TitleContainer>

      <styles.ContentContainer>
        <styles.Paragraph>
          As inscrições estarão abertas no{' '}
          <strong>
            período 08h do dia 04 de abril de 2022 às 23h59min do dia 22 de
            abril de 2022 (Inscrições prorrogadas)
          </strong>
          . O preenchimento da ficha de inscrição deverá ser feito pela
          internet, via SIGAA, por meio do endereço eletrônico:{' '}
          <a
            href="https://si3.ufc.br/sigaa/public"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.si3.ufc.br/sigaa/public
          </a>{' '}
          (selecione a aba processos seletivos - lato sensu).
        </styles.Paragraph>

        {/* <styles.Paragraph>
          <styles.Warning>
            <styles.WarningTitle>Aviso!</styles.WarningTitle>
            <p>
              Devido a problemas técnicos ocorridos no sistema de inscrição da
              UFC, o candidato que não conseguir realizar a inscrição pelo
              SIGAA, poderá ainda sim realizar a inscrição pelo nosso site, sem
              a necessidade de envio do comprovante do SIGAA.
            </p>
          </styles.Warning>
        </styles.Paragraph> */}

        <styles.Paragraph>
          Adicionalmente, o(a) candidato(a) deverá enviar os seguintes
          documentos, por meio do endereço eletrônico:{' '}
          <a href="/rsc/inscricao">www.lsbd.ufc.br/rsc/inscricao</a>:
        </styles.Paragraph>

        <styles.DotList>
          <li>documento de identificação oficial com foto;</li>
          <li>
            comprovante de inscrição gerado e emitido pelo SIGAA no ato da
            inscrição online (item 5.2);
          </li>
          <li>termo de compromisso preenchido e assinado (Anexo I);</li>
          <li>
            comprovante de conclusão de curso superior ou comprovante que o
            substitua, de IES reconhecida pelo MEC, nas áreas de Ciência da
            Computação, Engenharia de Computação, Informática, Sistemas de
            Informação, Engenharia de Software, Sistemas e Mídias Digitais,
            Redes de Computadores e áreas afins.
          </li>
          <li>
            comprovante de vínculo com a Universidade Federal do Ceará, no caso
            de servidores dessa instituição;
          </li>
          <li>
            barema curricular preenchido (anexo II) e documentos comprobatórios
            em um único arquivo pdf;
          </li>
          <li>
            carta de motivação (de no máximo uma página) com justificativa de
            interesse na Residência em Segurança Cibernética, em formato pdf.
          </li>
        </styles.DotList>

        <styles.Paragraph>
          Veja abaixo o <strong>cronograma</strong> do processo seletivo:
        </styles.Paragraph>

        <Table hasBorder>
          <TableHeader>
            <TableCell>{t('news.postgraduate.selc.action')}</TableCell>
            <TableCell>{t('news.postgraduate.selc.period')}</TableCell>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                Inscrições on-line no site <br></br>
                <a
                  href="https://si3.ufc.br/sigaa/public"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.si3.ufc.br/sigaa/public
                </a>
                <br></br>
                <small>
                  (selecione a aba processos seletivos - lato sensu)
                </small>
                <br></br>
                <br></br>
                Inscrições e envio dos documentos por meio do endereço
                eletrônico:{' '}
                <a href="/rsc/inscricao">www.lsbd.ufc.br/rsc/inscricao</a>
              </TableCell>
              <TableCell>04 a 22 de abril de 2022</TableCell>
            </TableRow>
            {data.map((row, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{row.action}</TableCell>
                  <TableCell>{row.period}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </styles.ContentContainer>
    </>
  )
}

export default Selection
