import * as styles from '../styles'

import { useState, useEffect, FormEventHandler } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as st from './styles'
import Spinner from 'src/components/Spinner'

const EmptyLoading = (props: any) => {
  const { t } = useTranslation()

  return (
    <>
      <styles.ResponsiveContainer>
        <styles.Container>
          <styles.BreadCrumbWrapper>
            <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.CrumbLink to="/rsc">Pós-Graduação</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.TitleCrumb>Inscrição</styles.TitleCrumb>
          </styles.BreadCrumbWrapper>

          <styles.ContentWrapper>
            <Spinner></Spinner>
          </styles.ContentWrapper>
        </styles.Container>
      </styles.ResponsiveContainer>
    </>
  )
}

export default EmptyLoading
