import * as styles from '../styles'

import { useState, useEffect, FormEventHandler } from 'react'
import { useHistory } from 'react-router-dom'

import { postgraduatePromoBannerLenovo } from 'src/assets/images/index'

import { useTranslation } from 'react-i18next'

import * as st from './styles'
import SubscribeForm from './Forms'
import SendFilesForm from './Forms/docs'
import { webApi } from 'src/services/api/config'
import FormSent from './Forms/sent'
import Spinner from 'src/components/Spinner'
import axios, { AxiosError } from 'axios'
import subscriptionClosed from 'src/services/time'

const PostGraduateSubsOpenContent = (props: any) => {
  const [formSent, setFormSent] = useState(false)
  const [err, setErr] = useState(<></>)
  const [canSubmit, setCanSubmit] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const formData = new FormData(event.target)

    const submitBtn = document.getElementById(
      'submit-sub-form'
    ) as HTMLButtonElement

    if (submitBtn) {
      submitBtn.innerHTML = 'ENVIANDO...'
      submitBtn.style.background = 'gray'
      submitBtn.disabled = true
    }

    subscriptionClosed().then(closed => {
      if (closed) {
        document.getElementById('sub-form-id-anchor')?.scrollIntoView()
        setErr(<span>Inscrições encerradas.</span>)

        submitBtn.innerHTML = 'REALIZAR A INSCRIÇÃO'
        submitBtn.style.background = '#fec074'
        submitBtn.disabled = false
      } else {
        // TODO: Transfer this request to API layer
        webApi
          .post('/api/site/postgraduate/subscriptions/cybersec', formData, {
            headers: { 'Content-Type': 'application/json' }
          })
          .then(data => {
            document.getElementById('title-anchor')?.scrollIntoView()
            setFormSent(true)
          })
          .catch((err: AxiosError) => {
            document.getElementById('sub-form-id-anchor')?.scrollIntoView()

            if (err.response?.status === 400) {
              setErr(<span>Inscrição já cadastrada ou dados incorretos.</span>)
            } else {
              setErr(
                <span>
                  Não foi possível realizar a sua inscrição. Por favor, entre em
                  contato com nosso suporte ou tente novamente mais tarde.
                </span>
              )
            }

            submitBtn.innerHTML = 'REALIZAR A INSCRIÇÃO'
            submitBtn.style.background = '#fec074'
            submitBtn.disabled = false
          })
      }
    })
  }

  return (
    <>
      <styles.ResponsiveContainer>
        <styles.Container>
          <styles.BreadCrumbWrapper>
            <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.CrumbLink to="/rsc">Pós-Graduação</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.TitleCrumb>Inscrição</styles.TitleCrumb>
          </styles.BreadCrumbWrapper>

          <styles.ContentWrapper>
            <styles.Header>
              <styles.PageTitle id="title-anchor">
                {t('news.postgraduate.sub.page-title')}
              </styles.PageTitle>
              <styles.PromoImg
                style={{ marginTop: '1.5rem' }}
                src={postgraduatePromoBannerLenovo}
              ></styles.PromoImg>
            </styles.Header>

            {/* form de inscrição */}
            {!formSent && (
              <>
                <styles.ContentContainer style={{ marginBottom: '2rem' }}>
                  <styles.Paragraph>
                    <strong>Período</strong>: 04 de abril de 2022 a 22 de abril
                    de 2022<strong> (Inscrições prorrogadas)</strong>
                  </styles.Paragraph>
                  <styles.Warning>
                    <styles.WarningTitle>Aviso!</styles.WarningTitle>
                    <p>
                      Devido a problemas técnicos ocorridos no sistema de
                      inscrição da UFC, o candidato que não conseguir realizar a
                      inscrição pelo SIGAA, poderá ainda sim realizar a
                      inscrição pelo nosso site, sem a necessidade de envio do
                      comprovante do SIGAA.
                    </p>
                  </styles.Warning>
                  <styles.Paragraph>
                    A seleção para a Residência em Segurança Cibernética é
                    ofertada no formato de Pós-Graduação Lato Sensu
                    (Especialização) na modalidade de educação presencial, sob o
                    fomento da Lenovo Brasil.
                  </styles.Paragraph>
                  <styles.Paragraph>
                    <strong>Importante</strong>: Primeiramente, o candidato
                    deverá realizar a inscrição pela ferramente SIGAA, através{' '}
                    <a
                      href="https://si3.ufc.br/sigaa/public"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      desse link
                    </a>
                    . Após isso, deverá preencher o formulário abaixo
                    corretamente. No final, o candidato poderá realizar envio
                    dos seus documentos.
                  </styles.Paragraph>
                </styles.ContentContainer>

                <styles.ContentContainer>
                  {/* @TODO: Abas */}
                  <form
                    method="POST"
                    name="subscribe-form"
                    encType="multipart/form-data"
                    onSubmit={handleSubmit}
                  >
                    <SubscribeForm errorMessage={err}></SubscribeForm>

                    <styles.Separator></styles.Separator>

                    <SendFilesForm></SendFilesForm>

                    <div style={{ marginBottom: '4rem' }}>
                      <st.Legend>
                        * Campos de preenchimento obrigatório.
                      </st.Legend>
                    </div>

                    <st.ButtonGroup>
                      <st.ButtonContainer>
                        <st.Submit id="submit-sub-form" type="submit">
                          REALIZAR A INSCRIÇÃO
                        </st.Submit>
                      </st.ButtonContainer>
                    </st.ButtonGroup>
                  </form>
                </styles.ContentContainer>
              </>
            )}

            {/* Após enviar o form mostrar página de confirmação */}
            {formSent && (
              <styles.ContentContainer>
                <FormSent></FormSent>
              </styles.ContentContainer>
            )}
          </styles.ContentWrapper>
        </styles.Container>
      </styles.ResponsiveContainer>
    </>
  )
}

export default PostGraduateSubsOpenContent
