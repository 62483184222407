import colors from './colors'
import metrics from './metrics'
import { fonts, lineHeight, fontFamily, fontWeight } from './fonts'

const theme = {
  colors,
  fonts,
  metrics,
  lineHeight,
  fontFamily,
  fontWeight
}

export default theme
