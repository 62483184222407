import styled from 'styled-components'
import { maxMobileScreenWidth } from '../../utils/breakpoints'
import { Link as LinkR } from 'react-router-dom'

type ButtonProps = {
  readonly active: boolean
}

type SelectButtonProps = {
  readonly colorBackground: string
  readonly colorText: string
}

type GenericButtonProps = {
    backgroundColor: string
    textColor: string
    size: string
    margin: string
    isDisabled: boolean
}

type CancelButtonProps = {
    textColor: string
}

export const CancelButton = styled.button<CancelButtonProps>`
    font-weight: 700;
    font-size: 14px;
    background: none;
    border: none;
    color: ${props => (props.textColor)};
`

export const DesktopBtn = styled.button<ButtonProps>`
  background: ${({ active, theme }) =>
    active ? theme.colors.buttonColor : 'none'};
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.buttonColor};
  padding: ${({ active }) => (active ? '22px 64px' : '22px 48px')};
  border-radius: ${({ active }) => (active ? '30px' : '100px')};
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: ${({ active }) => (active ? 'none' : '1px solid #fec074')};
  text-transform: ${({ active }) => (active ? 'uppercase' : 'capitalize')};

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 14px 40px;
    min-width: 300px;
    width: 100%;
    height: 56px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const MobileBtn = styled.button`
  background: ${({ theme }) => theme.colors.buttonColor};
  padding: 14px 40px;
  border-radius: 30px;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: none;
  text-transform: uppercase;
`

export const MobileSec = styled.button<ButtonProps>`
  background: ${({ active, theme }) =>
    active ? theme.colors.buttonColor : 'none'};
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.buttonColor};

  border: ${({ active }) => (active ? 'none' : '1px solid #fec074')};
  padding: 14px 32px;
  border-radius: 100px;
  width: 130px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  white-space: nowrap;

  text-transform: uppercase;
`

type SelectWrapperProps = {
    width: number
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
    width: ${({ width }) => `${width}px`};
`

export const SelectedButton = styled.div<SelectButtonProps>`
  .select-box {
    display: flex;
    flex-direction: column;
    /* max-width: 240px; */
  }

  .select-box .options-container {
    color: #ffffff;
    max-height: 0;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
    order: 1;
  }

  .selected {
    background: ${({ colorBackground }) => colorBackground};
    border-radius: 30px;
    /* max-width: 260px; */
    color: ${({ colorText }) => colorText};
    position: relative;
    order: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .select-box .options-container.active {
    max-height: 300px;
    opacity: 1;
    overflow-y: hidden;
    border: 0.25px solid #2e2972;
    border-radius: 0px 0px 30px 30px;
  }

  .select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0 8px 8px 0;
  }

  .select-box .options-container::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonColor};
    border-radius: 0 8px 8px 0;
  }

  .select-box .option,
  .selected {
    padding: 12px 20px;
    text-align: center;
  }

  .select-box .option:not(:last-child) {
    color: #000000;
    background: #f2f2f2;
    border-bottom: 0.25px solid #2e2972;
  }

  .select-box .option:last-child {
    border-radius: 0px 0px 30px 30px;
    color: #000000;
    background: #f2f2f2;
  }

  .select-box .option:hover {
    background: ${({ theme }) => theme.colors.buttonColor};
  }

  .selected.active {
    border-radius: 30px 30px 0px 0px;
  }

  .select-box .option .radio {
    display: none;
  }
`

type KebabButtonProps = {
    size?: string
}

export const KebabButton = styled.button<KebabButtonProps>`
  width: ${props => props.size === 'small' ? '32px' : '80px'};
  height: ${props => props.size === 'small' ? '32px' : '80px'};
  border-radius: 50%;

  background: #ffffff;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  min-height: 50px;
  height: 100%;
  width: 100%;

  color: #fec074;
  background: rgba(207, 221, 228, 0.1);
  border: 1px solid #fec074;
  border-radius: 100px;

  &:hover {
    opacity: 0.6;
  }
`

export const NewsButton = styled.button<SelectButtonProps>`
  display: flex;
  background: ${({ colorBackground }) => colorBackground};
  color: ${({ colorText }) => colorText};
  padding: 20px 60px;
  border-radius: 30px;
  border: 1px solid #fec074;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    opacity: 0.6;
  }
`

export const WhiteButton = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid #fec074;
  border-radius: 30px;
  background: none;
  padding: 15px 20px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #fec074;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
    color: #ffffff;
    background: #fec074;
  }
`

export const OrangeButton = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid #fec074;
  border-radius: 30px;
  background: none;
  padding: 22px 50px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  background: #fec074;

  transition: 0.5s;

  &:hover {
    opacity: 0.8;
    color: #fec074;
    background: #ffffff;
  }
`

export const GrayButton = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  background: #c4c4c4;
  padding: 22px 30px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
`

export const GenericButton = styled.button<GenericButtonProps>`
    background-color: ${props => (props.backgroundColor)};
    color: ${props => (props.textColor)};
    font-weight: 700;
    border-radius: ${props => props.size === 'small' ? '18px' : props.size === 'large' ? '32px' : '0'};
    padding: ${props => props.size === 'small' ? '12px 20px' : props.size === 'large' ? '22px 64px' : '0'};
    text-transform: uppercase;
    border: ${props => (`1px solid ${props.textColor}`)};
    transition: ease-in-out 0.3s;
    margin: ${props => (props.margin)};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    &:hover {
        background-color: ${props => (props.textColor)};
        color: ${props => (props.backgroundColor)};
        border: ${props => (`1px solid ${props.backgroundColor}`)};
    }
`
