import * as styles from './styles'
import { useState } from 'react'
import Filter from 'src/assets/icons/news/filter.svg'
import FilterChevronDown from 'src/assets/icons/news/filterChevronDown.svg'
import * as S from 'src/components/NewsModule/Management/styles'

type FilterContainerProps = {
    categoriesList: any
    filter: any
    select: any
    filterValue: string
}

const FilterContainer = ({
    categoriesList,
    select,
    filter,
    filterValue
}: FilterContainerProps) => {
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')

    const toggleFilter = () => setFilterIsOpen(!filterIsOpen)

    const onOptionClicked = (value: any) => () => {
        setSelectedOption(value)
        setFilterIsOpen(false)
        select(value !== 'Todos' ? value : '')
    }

    const handleFilter = (e: any) => {
        filter(e.target.value)
    }

    return (
        <S.SearchHeaderWrapper>
            <S.SearchHeader>
                <S.SearchHeaderBoxShadow>
                    <S.SearchHeaderContent>
                        <S.FilterSearchContainer>
                            <styles.FilterWrapper>
                                <styles.DropDownHeader onClick={toggleFilter}>
                                    <img src={Filter} />
                                    {selectedOption || 'Filtro'}
                                    <img src={FilterChevronDown} />
                                </styles.DropDownHeader>
                                {filterIsOpen && (
                                    <styles.DropDownListContainer>
                                        <styles.DropDownList>
                                            <styles.ListItemAll
                                                onClick={onOptionClicked(
                                                    'Todos'
                                                )}
                                            >
                                                Todos
                                            </styles.ListItemAll>
                                            {categoriesList.map(
                                                (category: any) => (
                                                    <styles.ListItem
                                                        onClick={onOptionClicked(
                                                            category.title
                                                        )}
                                                        key={category.id}
                                                    >
                                                        {category.title}
                                                    </styles.ListItem>
                                                )
                                            )}
                                        </styles.DropDownList>
                                    </styles.DropDownListContainer>
                                )}
                            </styles.FilterWrapper>
                            <styles.InputWrapper>
                                <S.SearchIcon size={40} />
                                <input
                                    value={filterValue}
                                    onChange={e => handleFilter(e)}
                                    placeholder="Procurar notícias"
                                    type="text"
                                    autoComplete="new-password"
                                />
                            </styles.InputWrapper>
                        </S.FilterSearchContainer>
                    </S.SearchHeaderContent>
                </S.SearchHeaderBoxShadow>
            </S.SearchHeader>
        </S.SearchHeaderWrapper>
    )
}

export default FilterContainer
