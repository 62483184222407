import * as styles from './styles'

type InputProps = {
    required?: boolean
    placeholder: string
    value: any
    valueName: string
    handleChange: (name: string, value: any) => void
    error?: boolean
    errorMessage?: string
    maxLength?: number
    setError?: (a: boolean) => void
    margin?: string
    whiteBackground?: boolean
}

export const Input = ({
    required,
    placeholder,
    value,
    valueName,
    handleChange,
    error,
    errorMessage,
    maxLength,
    setError,
    margin,
    whiteBackground
}: InputProps) => {
    const handleOnChange = (ev: any) => {
        if (setError) {
            setError(false)
        }
        handleChange(valueName, ev.target.value)
    }
    return (
        <>
            <styles.Input
                type="text"
                maxLength={maxLength}
                placeholder={required ? placeholder + '*' : placeholder}
                value={value}
                onChange={ev => handleOnChange(ev)}
                error={error || false}
                margin={margin || '0'}
                whiteBackground={whiteBackground}
            />
            {error && <styles.TextError>{errorMessage}*</styles.TextError>}
        </>
    )
}
