import * as styles from './styles'
import { useEffect, useState } from 'react'
import {
    ResponsiveContainer,
    ContentContainer
} from 'src/components/Containers/containers'
import Typography from 'src/components/Typography'
import useInput from 'src/hooks/useInput'
import { useTranslation } from 'react-i18next'
import EyeOpen from 'src/assets/icons/password/eyeOpen.svg'
import EyeClosed from 'src/assets/icons/password/eyeClosed.svg'
import ErrorIcon from 'src/assets/icons/password/error.svg'
import ErrorOrangeIcon from 'src/assets/icons/password/errorOrange.svg'
import OrangeButton from 'src/components/Button/OrangeButton'
import alert from 'src/assets/images/password/alert.svg'
import padlock from 'src/assets/images/password/padlock.svg'
import Spinner from 'src/components/Spinner'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import {
    getPasswordResetToken,
    postResetPassword
} from 'src/services/api/WebApi/PasswordReset/passwordReset'
import useQueryString from 'src/hooks/useQueryString'
import { sendResetPasswordConfirmEmail } from 'src/services/api/EmailsApi/PasswordReset/forgotPassword'
import { getUserGeolocation } from 'src/services/api/External/GeolocationDb/geolocationDb'

const ResetPasswordContent = (props: any) => {
    const query = useQueryString()
    const token = query.get('token')

    const password = useInput('')
    const passwordRep = useInput('')

    const [loading, setLoading] = useState(true)
    const [isTokenValid, setIsTokenValid] = useState(false)
    const [successReset, setSuccessReset] = useState(false)
    const [newPasswordEqualsToOldPassword, setNewPasswordEqualsToOldPassword] =
        useState(false)

    const checkToken = async (token: any) => {
        await getPasswordResetToken(token)
            .then(response => {
                if (response.status === 200 && !response.data.isUsed) {
                    setIsTokenValid(true)
                }
            })
            .catch(err => {
                console.log(err.response)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        checkToken(token)
    }, [])

    useEffect(() => {
        setNewPasswordEqualsToOldPassword(false)
    }, [password.value])

    return (
        <ResponsiveContainer color={''}>
            <ContentContainer>
                {loading ? (
                    <Spinner heightContainer="100vh" size={'60px'} />
                ) : isTokenValid ? (
                    <Content
                        token={token}
                        setLoading={setLoading}
                        setSuccessReset={setSuccessReset}
                        setIsTokenValid={setIsTokenValid}
                        newPasswordEqualsToOldPassword={
                            newPasswordEqualsToOldPassword
                        }
                        setNewPasswordEqualsToOldPassword={
                            setNewPasswordEqualsToOldPassword
                        }
                        password={password}
                        passwordRep={passwordRep}
                    />
                ) : successReset ? (
                    <SuccessReset />
                ) : (
                    <TokenInvalid />
                )}
            </ContentContainer>
        </ResponsiveContainer>
    )
}

type ContentProps = {
    token: string | null
    setLoading: (p: boolean) => void
    setSuccessReset: (p: boolean) => void
    setIsTokenValid: (p: boolean) => void
    newPasswordEqualsToOldPassword: boolean
    setNewPasswordEqualsToOldPassword: (p: boolean) => void
    password: any
    passwordRep: any
}

const Content = ({
    token,
    setLoading,
    setSuccessReset,
    setIsTokenValid,
    newPasswordEqualsToOldPassword,
    setNewPasswordEqualsToOldPassword,
    password,
    passwordRep
}: ContentProps) => {
    const { t } = useTranslation()
    const [isEyeOpen, setIsEyeOpen] = useState(false)
    const [isEyeOpenRep, setIsEyeOpenRep] = useState(false)

    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false)
    const [isPasswordRepEmpty, setIsPasswordRepEmpty] = useState(false)
    const [isPasswordRepDifferent, setIsPasswordRepDifferent] = useState(false)
    const [isPasswordWeak, setIsPasswordWeak] = useState(false)

    const checkIsPasswordWeak = (password: string) => {
        if (
            password.length < 8 ||
            password.search(/[a-z]/) < 0 ||
            password.search(/[A-Z]/) < 0 ||
            password.search(/[0-9]/) < 0
        ) {
            setIsPasswordWeak(true)
        } else {
            // TODO: Validation token!!
            submitPassword(token!, password)
        }
    }

    const handlePasswordReset = () => {
        if (password.value.length === 0) {
            setIsPasswordEmpty(true)
        }
        if (passwordRep.value.length === 0) {
            setIsPasswordRepEmpty(true)
        }
        if (passwordRep.value.length > 0 && password.value.length > 0) {
            if (password.value !== passwordRep.value) {
                setIsPasswordRepDifferent(true)
            } else {
                checkIsPasswordWeak(password.value)
            }
        }
    }

    const handleEye = () => {
        setIsEyeOpen(!isEyeOpen)
    }

    const handleEyeRep = () => {
        setIsEyeOpenRep(!isEyeOpenRep)
    }

    // TODO: ERR_BLOCKED_BY_CLIENT blocking email
    const sendConfirmationEmail = async (email: string) => {
        const getUserGeolocationResponse = await getUserGeolocation()
        if (getUserGeolocationResponse.status === 200) {
            await sendResetPasswordConfirmEmail({
                email: email,
                ip: getUserGeolocationResponse.data.IPv4,
                location: `${getUserGeolocationResponse.data.city}, ${getUserGeolocationResponse.data.state}, ${getUserGeolocationResponse.data.country_name}`
            })
        }
    }

    const submitPassword = async (token: string, newPassword: string) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('token', token)
        formData.append('newPassword', newPassword)
        postResetPassword(formData)
            .then(response => {
                if (response.status === 200) {
                    sendConfirmationEmail(response.data.email)
                    setIsTokenValid(false)
                    setSuccessReset(true)
                }
            })
            .catch(err => {
                console.log(err)
                if (err.response.status === 422) {
                    setNewPasswordEqualsToOldPassword(true)
                } else {
                    setIsTokenValid(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setIsPasswordEmpty(false)
        setIsPasswordWeak(false)
    }, [password.value])

    useEffect(() => {
        setIsPasswordRepEmpty(false)
    }, [passwordRep.value])

    useEffect(() => {
        setIsPasswordRepDifferent(false)
    }, [passwordRep.value, password.value])

    return (
        <styles.ContentWrapper>
            <Typography variant="h1DarkBlue" style={{ textAlign: 'center' }}>
                {t('password.reset')}
            </Typography>
            <Typography
                variant="pBlack60"
                style={{ textAlign: 'center', margin: '48px 0' }}
            >
                {t('password.resetInfo')}
            </Typography>

            <styles.ResetPasswordInstruction>
                <styles.Icon className="icon" src={ErrorOrangeIcon} />
                <Typography className="text" variant="pBlack">
                    {t('password.instruction')}
                </Typography>
            </styles.ResetPasswordInstruction>

            <styles.InputPasswordContainer>
                <Typography variant="pDarkBlue" style={{ fontWeight: 'bold' }}>
                    {t('password.insertPassword')}
                </Typography>
                <styles.InputPasswordWrapper>
                    <styles.InputPassword
                        invalid={
                            isPasswordEmpty ||
                            isPasswordWeak ||
                            newPasswordEqualsToOldPassword
                        }
                        value={password.value}
                        type={isEyeOpen ? 'text' : 'password'}
                        onChange={password.onChange}
                        maxLength={50}
                        placeholder={t('password.inputPassword')}
                    />
                    <styles.EyeIcon
                        src={isEyeOpen ? EyeOpen : EyeClosed}
                        onClick={handleEye}
                    />
                </styles.InputPasswordWrapper>
                {isPasswordEmpty && (
                    <styles.PasswordRepInvalidWrapper>
                        <styles.Icon src={ErrorIcon} />
                        <Typography variant="pBlack">
                            {t('contato.obrigatorio')}
                        </Typography>
                    </styles.PasswordRepInvalidWrapper>
                )}
                {isPasswordWeak && (
                    <styles.PasswordRepInvalidWrapper>
                        <styles.Icon src={ErrorIcon} />
                        <Typography variant="pBlack">
                            {t('password.weak')}
                        </Typography>
                    </styles.PasswordRepInvalidWrapper>
                )}
                {newPasswordEqualsToOldPassword && (
                    <styles.PasswordRepInvalidWrapper>
                        <styles.Icon src={ErrorIcon} />
                        <Typography variant="pBlack">
                            {t('password.EqualsToOldPassword')}
                        </Typography>
                    </styles.PasswordRepInvalidWrapper>
                )}
            </styles.InputPasswordContainer>

            <styles.InputPasswordContainer>
                <Typography variant="pDarkBlue" style={{ fontWeight: 'bold' }}>
                    {t('password.insertPasswordRep')}
                </Typography>
                <styles.InputPasswordWrapper>
                    <styles.InputPassword
                        invalid={isPasswordRepDifferent || isPasswordRepEmpty}
                        type={isEyeOpenRep ? 'text' : 'password'}
                        value={passwordRep.value}
                        onChange={passwordRep.onChange}
                        maxLength={50}
                        placeholder={t('password.inputPasswordRep')}
                    />
                    <styles.EyeIcon
                        src={isEyeOpenRep ? EyeOpen : EyeClosed}
                        onClick={handleEyeRep}
                    />
                </styles.InputPasswordWrapper>
                {isPasswordRepEmpty && (
                    <styles.PasswordRepInvalidWrapper>
                        <styles.Icon src={ErrorIcon} />
                        <Typography variant="pBlack">
                            {t('contato.obrigatorio')}
                        </Typography>
                    </styles.PasswordRepInvalidWrapper>
                )}
                {isPasswordRepDifferent && (
                    <styles.PasswordRepInvalidWrapper>
                        <styles.Icon src={ErrorIcon} />
                        <Typography variant="pBlack">
                            {t('password.passwordRepInvalid')}
                        </Typography>
                    </styles.PasswordRepInvalidWrapper>
                )}
            </styles.InputPasswordContainer>

            <styles.ButtonWrapper>
                <OrangeButton
                    title={t('password.request')}
                    onClick={() => handlePasswordReset()}
                ></OrangeButton>
            </styles.ButtonWrapper>

            <Typography
                variant="pBlack60"
                style={{ textAlign: 'center', margin: '48px 0 0' }}
            >
                {t('password.support')}
            </Typography>
        </styles.ContentWrapper>
    )
}

const TokenInvalid = () => {
    const { t } = useTranslation()
    const history = useHistory()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <styles.TokenInvalidContainer>
            <styles.TokenImageWrapper>
                <styles.TokenImage src={alert} />
            </styles.TokenImageWrapper>
            <Typography
                variant="h1DarkBlue"
                style={{ textAlign: 'center', margin: '80px 0 48px' }}
            >
                {t('password.tokenInvalid')}
            </Typography>
            <Typography variant="pBlack60">
                {t('password.tokenInvalidText1')}
            </Typography>
            <Typography variant="pBlack60" style={{ marginBottom: '24px' }}>
                {t('password.tokenInvalidText2')}
            </Typography>
            <styles.TokenInvalidMotivesList>
                <li>{t('password.tokenInvalidMotive1')}</li>
                <li>{t('password.tokenInvalidMotive2')}</li>
                <li>{t('password.tokenInvalidMotive3')}</li>
            </styles.TokenInvalidMotivesList>

            <Typography
                variant="pBlackMini60"
                style={{ textAlign: 'center', marginBottom: '24px' }}
            >
                {t('password.clickAndChange')}
            </Typography>

            <styles.ButtonWrapper>
                <OrangeButton
                    title={t('password.change')}
                    onClick={() => history.push('/forgot-password')}
                ></OrangeButton>
            </styles.ButtonWrapper>

            <Typography
                variant="pBlack60"
                style={{ textAlign: 'center', margin: '48px 0 80px' }}
            >
                {t('password.support')}
            </Typography>
        </styles.TokenInvalidContainer>
    )
}

const SuccessReset = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <>
            <styles.TokenImageWrapper>
                <styles.TokenImage src={padlock} />
            </styles.TokenImageWrapper>
            <Typography
                variant="h1DarkBlue"
                style={{ textAlign: 'center', margin: '80px 0 48px' }}
            >
                {t('password.successfulReset')}
            </Typography>
            <Typography
                variant="pBlack60"
                style={{ textAlign: 'center', marginBottom: '80px' }}
            >
                {t('password.successfulResetWarning')}
            </Typography>
        </>
    )
}

export default ResetPasswordContent
