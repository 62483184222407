import styled from 'styled-components'

type InputProps = {
  readonly isEmpty: boolean
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 80px auto;
`

export const InputEmail = styled.input<InputProps>`
  width: 100%;
  padding: 24px 20px;
  border: ${({ isEmpty }) =>
    isEmpty ? '2px solid #ff3366;' : '2px solid #2e2972;'};
  border-radius: 32px;
  margin: 24px 0 25px;

  &:focus {
    outline: none;
  }

  &:focus-within {
    border: ${({ isEmpty }) =>
      isEmpty ? '2px solid #ff3366;' : '2px solid #2e2972;'};
  }
`

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
`

export const InputEmailWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
`

export const Invalid = styled.p`
  position: absolute;
  bottom: 0;
  color: #ff3366;
`

export const EmailImage = styled.img`
  width: 200px;
  height: 200px;
`

export const EmailImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`

export const RequiredWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
`

export const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
`
