import React, { Component } from 'react'
import * as styles from './style'
import TableCellProps from './type'

type TableProps = {
  hasBorder?: boolean
  lastRowShouldBeHighlighted?: boolean
}

const Table: React.FC<TableProps> = ({
  children,
  hasBorder,
  lastRowShouldBeHighlighted
}) => {
  return (
    <styles.Table
      hasBorder={hasBorder}
      className={
        lastRowShouldBeHighlighted ? 'last-row-should-be-highlighted' : ''
      }
      cellSpacing="0"
    >
      {children}
    </styles.Table>
  )
}

export const TableHeader: React.FC = ({ children }) => {
  return (
    <styles.thead>
      <TableRow>{children}</TableRow>
    </styles.thead>
  )
}

export const TableBody: React.FC = ({ children }) => {
  return <tbody>{children}</tbody>
}

export const TableRow: React.FC = ({ children }) => {
  return <tr>{children}</tr>
}

export const TableCell: React.FC<TableCellProps> = ({
  textAlign,
  children
}) => {
  return <styles.td textAlign={textAlign}>{children}</styles.td>
}

export default Table
