import usePersistedState from 'src/hooks/usePersistedState'
import * as styles from './styles'

type CancelButtonProps = {
    textColor: string
    onClick: () => void
}

const CancelButton = ({ textColor, onClick }: CancelButtonProps) => {
    return (
        <styles.CancelButton onClick={onClick} textColor={textColor}>
            CANCELAR
        </styles.CancelButton>
    )
}

export default CancelButton
