import { TagsActionTypes, TagsState, TagsListState } from './types'

const INITIAL_STATE: TagsState = {
  tags: [],
  error: false,
  loading: false
}

const TAGS_LIST_STATE: TagsListState = {
  tagsList: [],
  error: false,
  loading: false
}

export function listTagsReducer(
  state = TAGS_LIST_STATE,
  action: TagsActionTypes
): TagsListState {
  switch (action.type) {
    case '@tags/FETCH_TAGS_LIST_REQUEST':
      return { ...state, loading: true }
    case '@tags/FETCH_TAGS_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        tagsList: action.payload.data
      }
    case '@tags/FETCH_TAGS_LIST_FAILURE':
      return { ...state, loading: false, error: true, tagsList: [] }
    case '@tags/CREATE_TAG_IN_NEWS_REQUEST':
      return { ...state, loading: true }
    case '@tags/CREATE_TAG_IN_NEWS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        tagsList: action.payload.data
      }
    case '@tags/CREATE_TAG_IN_NEWS_FAILURE':
      return { ...state, loading: false, error: true, tagsList: [] }
    default:
      return state
  }
}

export default function (
  state = INITIAL_STATE,
  action: TagsActionTypes
): TagsState {
  switch (action.type) {
    case '@tags/FETCH_TAGS_REQUEST':
      return { ...state, loading: true }
    case '@tags/FETCH_TAGS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        tags: action.payload.data
      }
    case '@tags/FETCH_TAGS_FAILURE':
      return { ...state, loading: false, error: true, tags: [] }
    case '@tags/REQUEST_UNIQUE_NEWS_TAGS':
      return { ...state, loading: true }
    case '@tags/SUCCESS_UNIQUE_NEWS_TAGS':
      return {
        ...state,
        loading: false,
        error: false,
        tags: action.payload.data
      }
    case '@tags/FAILURE_UNIQUE_NEWS_TAGS':
      return { ...state, loading: false, error: true, tags: [] }
    default:
      return state
  }
}
