import { UserCircle } from '@styled-icons/boxicons-regular'

import * as S from './styles'

const LoginButton = (props: any) => {
  return (
    <>
      {props.isLoggedIn ? (
        <S.LoginButton {...props}>
          <UserCircle size={22} style={{ marginRight: '9px' }} />
          {props.isLoggedIn.name.split(' ')[0]}
        </S.LoginButton>
      ) : (
        <S.LoginButton {...props}>Login</S.LoginButton>
      )}
    </>
  )
}

export default LoginButton
