import * as styles from './styles'

import { useTranslation } from 'react-i18next'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Brain from 'src/assets/icons/expertise/brain.svg'
import Cloud from 'src/assets/icons/expertise/cloud.svg'
import Database from 'src/assets/icons/expertise/database.svg'
import Secure from 'src/assets/icons/expertise/secure.svg'
import Graphism from 'src/assets/images/graphism.svg'
import Divider from 'src/assets/divider.svg'

import Typography from 'src/components/Typography'

import useMedia from 'src/hooks/useMedia'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

SwiperCore.use([Pagination])

const Expertise = () => {
  const { width } = useMedia()
  const { t } = useTranslation()

  return (
    <styles.ResponsiveContainer>
      {width <= maxMobileScreenWidth ? (
        <styles.Container id="expertise">
          <styles.Divider />
          <styles.SectionWrapper>
            <styles.Title>
              <Typography style={{ fontWeight: 'bold' }} variant="h1Black">
                Expertise
              </Typography>
            </styles.Title>
            <styles.ExpertiseCard>
              <Swiper
                observer={true}
                observeParents={true}
                pagination={true}
                slidesPerView={1}
              >
                <SwiperSlide>
                  <styles.Card>
                    <img src={Secure} />
                    <styles.CardTitle>
                      <Typography variant="h3Black">
                        {t('expertise.data-privacy.title')}
                      </Typography>
                    </styles.CardTitle>
                    <styles.CardTextList>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.data-privacy.list-item1')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.data-privacy.list-item2')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.data-privacy.list-item3')}
                        </Typography>
                      </styles.CardListItem>
                    </styles.CardTextList>
                  </styles.Card>
                </SwiperSlide>

                <SwiperSlide>
                  <styles.Card>
                    <img src={Brain} />
                    <styles.CardTitle>
                      <Typography variant="h3Black">
                        {t('expertise.machine-learning.title')}
                      </Typography>
                    </styles.CardTitle>
                    <styles.CardTextList>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.machine-learning.list-item1')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.machine-learning.list-item2')}
                        </Typography>
                      </styles.CardListItem>
                    </styles.CardTextList>
                  </styles.Card>
                </SwiperSlide>

                <SwiperSlide>
                  <styles.Card>
                    <img src={Cloud} />
                    <styles.CardTitle>
                      <Typography variant="h3Black">
                        {t('expertise.cloud-computing.title')}
                      </Typography>
                    </styles.CardTitle>
                    <styles.CardTextList>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          SaaS / PaaS / IaaS
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.cloud-computing.list-item2')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.cloud-computing.list-item3')}
                        </Typography>
                      </styles.CardListItem>
                    </styles.CardTextList>
                  </styles.Card>
                </SwiperSlide>

                <SwiperSlide>
                  <styles.Card>
                    <img src={Database} />
                    <styles.CardTitle>
                      <Typography variant="h3Black">
                        {t('expertise.database.title')}
                      </Typography>
                    </styles.CardTitle>
                    <styles.CardTextList>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.database.list-item1')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.database.list-item2')}
                        </Typography>
                      </styles.CardListItem>
                      <styles.CardListItem>
                        <Typography variant="smallMobile">
                          {t('expertise.database.list-item3')}
                        </Typography>
                      </styles.CardListItem>
                    </styles.CardTextList>
                  </styles.Card>
                </SwiperSlide>
              </Swiper>
            </styles.ExpertiseCard>
          </styles.SectionWrapper>
          <img src={Graphism} className="graphism" />
        </styles.Container>
      ) : (
        <styles.Container id="expertise">
          <img src={Divider} style={{ width: '100%' }} />
          <styles.SectionWrapper>
            <styles.Title>
              <Typography style={{ fontWeight: 'bold' }} variant="h1Black">
                Expertise
              </Typography>
            </styles.Title>
            <styles.ExpertiseCard>
              <styles.Card>
                <img src={Secure} />
                <styles.CardTitle>
                  <Typography variant="h4Black">
                    {t('expertise.data-privacy.title')}
                  </Typography>
                </styles.CardTitle>
                <styles.CardTextList>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.data-privacy.list-item1')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.data-privacy.list-item2')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.data-privacy.list-item3')}
                    </Typography>
                  </styles.CardListItem>
                </styles.CardTextList>
              </styles.Card>

              <styles.Card>
                <img src={Brain} />
                <styles.CardTitle>
                  <Typography variant="h4Black">
                    {t('expertise.machine-learning.title')}
                  </Typography>
                </styles.CardTitle>
                <styles.CardTextList>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.machine-learning.list-item1')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.machine-learning.list-item2')}
                    </Typography>
                  </styles.CardListItem>
                </styles.CardTextList>
              </styles.Card>

              <styles.Card>
                <img src={Cloud} />
                <styles.CardTitle>
                  <Typography variant="h4Black">
                    {t('expertise.cloud-computing.title')}
                  </Typography>
                </styles.CardTitle>
                <styles.CardTextList>
                  <styles.CardListItem>
                    <Typography variant="small">SaaS / PaaS / IaaS</Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.cloud-computing.list-item2')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.cloud-computing.list-item3')}
                    </Typography>
                  </styles.CardListItem>
                </styles.CardTextList>
              </styles.Card>

              <styles.Card>
                <img src={Database} />
                <styles.CardTitle>
                  <Typography variant="h4Black">
                    {t('expertise.database.title')}
                  </Typography>
                </styles.CardTitle>
                <styles.CardTextList>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.database.list-item1')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.database.list-item2')}
                    </Typography>
                  </styles.CardListItem>
                  <styles.CardListItem>
                    <Typography variant="small">
                      {t('expertise.database.list-item3')}
                    </Typography>
                  </styles.CardListItem>
                </styles.CardTextList>
              </styles.Card>
            </styles.ExpertiseCard>
          </styles.SectionWrapper>
          <img src={Graphism} className="graphism" />
        </styles.Container>
      )}
    </styles.ResponsiveContainer>
  )
}

export default Expertise
