import React, { useEffect } from 'react'
import { EditorHeader } from 'src/components/NewsModule/Header'
import AddHighlightContent from 'src/components/NewsModule/AddHighlight'
import ModuleFooter from 'src/components/NewsModule/Footer'
import usePersistedState from 'src/hooks/usePersistedState'
import { HIGHLIGHT_SCREEN } from 'src/components/NewsModule/constants'
import { useHistory } from 'react-router-dom'

const EditHighlight = (props: any) => {
    const history = useHistory()
    const [user, setUser] = usePersistedState('auth-token', '')

    useEffect(() => {
        const permission = user.generalPermissions.find(
            (p: any) => p.screen === HIGHLIGHT_SCREEN
        )

        if (permission) {
            if (!permission.allowEdit) {
                history.push('/notFound')
            }
        }
    }, [user])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <>
            <React.Suspense fallback="loading..">
                <EditorHeader current="MANAGEMENT" />
                <AddHighlightContent
                    context={'EDIT'}
                    id={props.match.params.id}
                />
                <ModuleFooter />
            </React.Suspense>
        </>
    )
}

export default EditHighlight
