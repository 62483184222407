import React from 'react'
import styled from 'styled-components'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type ToastProps = {
  type: string
  message: string
}

const Toast = styled(ToastContainer)`
  .Toastify__toast--info {
    background: 'rgb(51, 102, 255)';
  }
  .Toastify__toast--success {
    background: 'rgb(51, 187, 102)';
  }
  .Toastify__toast--warning {
    background: 'rgb(254, 255, 20)';
  }
  .Toastify__toast--error {
    background: 'rgb(255, 102, 102)';
  }
`
export const showToast = ({ type, message }: ToastProps) => {
  const customId = 'custom-id-yes' // toast id
  switch (type) {
    case 'success':
      toast.success(message, {
        toastId: customId
      })
      break
    case 'warn':
      toast.warn(message, {
        toastId: customId
      })
      break
    case 'error':
      toast.error(message, {
        toastId: customId
      })
      break
    default:
      toast.info(message, {
        toastId: customId
      })
  }
}
export default function ToastAnimated() {
  return <Toast />
}
