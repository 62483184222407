import { useEffect, useState } from 'react'
import { NewsManagementHeader } from 'src/components/NewsModule/Header'
import * as S from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/ducks/rootReducer'
import { postsRequest } from 'src/store/ducks/news/actions'
import { News } from 'src/store/ducks/news/types'
import stringToDate from 'src/utils/stringToDate'
import { categoriesRequest } from 'src/store/ducks/categories/actions'
import sortByDate from 'src/utils/sortByDate'
import usePersistedState from 'src/hooks/usePersistedState'
import PublishedDropdown from 'src/components/Dropdown/PublishedDropdown'
import MyNewsDropdown from 'src/components/Dropdown/MyNewsDropdown'
import PendingDropdown from 'src/components/Dropdown/PendingDropdown'
import ManagementNewsSkeleton from 'src/components/Skeleton/News/Management'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'react-pagination-bar'
import 'react-pagination-bar/dist/index.css'
import {
    PUBLISHED_STATUS,
    PENDING_APPROVAL_STATUS,
    PENDING_PUBLISHED_STATUS,
    RECUSED_STATUS,
    CLOSED_STATUS,
    DRAFT_STATUS,
    PENDING_APPROVAL_SCREEN,
    PENDING_PUBLISHED_SCREEN
} from 'src/components/NewsModule/constants'
import FilterContainer from 'src/components/NewsModule/Header/filterContainer'

const Management = (props: any) => {
    const { context } = props
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { news } = useSelector((state: RootState) => state.news)
    const { categories } = useSelector((state: RootState) => state.categories)

    const [postList, setPostList] = useState<News[]>([])
    const [postListDefault, setPostListDefault] = useState<News[]>([])

    const [filter, setFilter] = useState<string>('')
    const [select, setSelect] = useState<string>('')

    const [user, setUser] = usePersistedState('auth-token', '')
    const [approvers, setApprovers] = usePersistedState('approvers', [])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const pagePostsLimit = 5

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const postContext = (context: string) => {
        const aux = categories?.find(e => e.title === select)
        const isManager = user.generalPermissions.find(
            (p: any) => p.screen === PENDING_APPROVAL_SCREEN
        ).isManager

        switch (context) {
            case 'MyNews':
                return news.filter(post =>
                    select
                        ? post.userId === parseInt(user.id) &&
                          post.newsCategoriesId === aux?.id
                        : post.userId === parseInt(user.id)
                )
            case 'Published':
                return news.filter(post =>
                    select
                        ? post.status === PUBLISHED_STATUS &&
                          post.newsCategoriesId === aux?.id
                        : post.status === PUBLISHED_STATUS
                )
            case 'PendingApproval':
                if (isManager) {
                    return news.filter(post =>
                        select
                            ? approvers.includes(post.userId) &&
                              post.status === PENDING_APPROVAL_STATUS &&
                              post.newsCategoriesId === aux?.id
                            : approvers.includes(post.userId) &&
                              post.status === PENDING_APPROVAL_STATUS
                    )
                } else {
                    return news.filter(post =>
                        select
                            ? post.status === PENDING_APPROVAL_STATUS &&
                              post.newsCategoriesId === aux?.id
                            : post.status === PENDING_APPROVAL_STATUS
                    )
                }

            case 'PendingPublished':
                return news.filter(post =>
                    select
                        ? post.status === PENDING_PUBLISHED_STATUS &&
                          post.newsCategoriesId === aux?.id
                        : post.status === PENDING_PUBLISHED_STATUS
                )
            default:
                return []
        }
    }

    const statusLabel = (status: string) => {
        switch (status) {
            case PUBLISHED_STATUS:
                return 'Publicada'
            case PENDING_APPROVAL_STATUS:
                return 'Aguardando aprovação'
            case RECUSED_STATUS:
                return 'Recusada'
            case CLOSED_STATUS:
                return 'Encerrada'
            case PENDING_PUBLISHED_STATUS:
                return 'Aguardando publicação'
            default:
                break
        }
    }

    useEffect(() => {
        dispatch(postsRequest())
        dispatch(categoriesRequest())
    }, [])

    useEffect(() => {
        if (news && categories && categories.length && news.length) {
            setPostList(postContext(context))
            setPostListDefault(postContext(context))
            setLoading(false)
        }
    }, [news, categories])

    useEffect(() => {
        if (filter.length > 0) {
            setPostList(
                postContext(context)?.filter(post =>
                    post.title.toLowerCase().includes(filter.toLowerCase())
                )
            )
        } else {
            setPostList(postContext(context))
        }
    }, [filter])

    useEffect(() => {
        if (filter) {
            setPostList(
                postContext(context)?.filter(post =>
                    post.title.toLowerCase().includes(filter.toLowerCase())
                )
            )
        } else {
            setPostList(postContext(context))
        }
    }, [select])

    return (
        <>
            <NewsManagementHeader currentPage={context} />

            <FilterContainer
                filter={setFilter}
                filterValue={filter}
                categoriesList={categories.filter(
                    category =>
                        category.title === 'Blog' ||
                        category.title === 'Vaga' ||
                        category.title === 'Evento' ||
                        category.title === 'Publicação' ||
                        category.title === 'Defesa' ||
                        category.title === 'Prêmio'
                )}
                select={setSelect}
            />

            <S.Content>
                {loading ? (
                    <ManagementNewsSkeleton />
                ) : (
                    <S.PostsContainer>
                        {postList.length > 0 ? (
                            <S.Pagination>
                                <S.PostList>
                                    {sortByDate(
                                        postList,
                                        context === 'Published'
                                            ? 'publishedAt'
                                            : 'createdAt'
                                    )
                                        .slice(
                                            (currentPage - 1) * pagePostsLimit,
                                            (currentPage - 1) * pagePostsLimit +
                                                pagePostsLimit
                                        )
                                        .map(post => {
                                            return (
                                                <S.ItemContainer key={post.id}>
                                                    <S.LeftContainer>
                                                        <div className="category">
                                                            {
                                                                categories.find(
                                                                    element =>
                                                                        element.id ===
                                                                        post.newsCategoriesId
                                                                )?.title
                                                            }
                                                        </div>
                                                        <div className="description">
                                                            <div className="dateLabelContainer">
                                                                <p className="date">
                                                                    {stringToDate(
                                                                        context !==
                                                                            'Published'
                                                                            ? post.createdAt
                                                                            : post.publishedAt
                                                                    )}
                                                                </p>
                                                                {post.status !==
                                                                    DRAFT_STATUS &&
                                                                context ===
                                                                    'MyNews' ? (
                                                                    <div className="status">
                                                                        <p
                                                                            className={post.status.toLowerCase()}
                                                                        >
                                                                            {statusLabel(
                                                                                post.status
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                            <p className="title">
                                                                {post.title}
                                                            </p>
                                                        </div>
                                                    </S.LeftContainer>

                                                    <S.RightContainer>
                                                        {context ===
                                                        'Published' ? (
                                                            <PublishedDropdown
                                                                post={post}
                                                                categories={
                                                                    categories
                                                                }
                                                            />
                                                        ) : context ===
                                                          'MyNews' ? (
                                                            <MyNewsDropdown
                                                                post={post}
                                                                categories={
                                                                    categories
                                                                }
                                                            />
                                                        ) : context ===
                                                              'PendingApproval' ||
                                                          context ===
                                                              'PendingPublished' ? (
                                                            <PendingDropdown
                                                                post={post}
                                                                screen={
                                                                    context ===
                                                                    'PendingApproval'
                                                                        ? PENDING_APPROVAL_SCREEN
                                                                        : PENDING_PUBLISHED_SCREEN
                                                                }
                                                                context={
                                                                    context ===
                                                                    'PendingApproval'
                                                                        ? 'approve'
                                                                        : 'publish'
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </S.RightContainer>
                                                </S.ItemContainer>
                                            )
                                        })}
                                </S.PostList>
                                <Pagination
                                    onPageСhange={pageNumber =>
                                        handlePageChange(pageNumber)
                                    }
                                    initialPage={currentPage}
                                    itemsPerPage={pagePostsLimit}
                                    totalItems={postList.length}
                                    pageNeighbours={2}
                                    nextLabel=">"
                                    prevLabel="<"
                                    endLabel=">>"
                                    startLabel="<<"
                                />
                            </S.Pagination>
                        ) : postListDefault.length === 0 ? (
                            <S.EmptyList>
                                <S.EmptyTitle>
                                    {t('news.management.empty.title')}
                                </S.EmptyTitle>
                                <S.EmptyText>
                                    {context === 'Published' ? (
                                        t('news.management.empty.published')
                                    ) : context === 'MyNews' ? (
                                        t('news.management.empty.my-news')
                                    ) : context === 'PendingApproval' ? (
                                        t(
                                            'news.management.empty.pending-approval'
                                        )
                                    ) : context === 'PendingPublished' ? (
                                        t(
                                            'news.management.empty.pending-published'
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </S.EmptyText>
                            </S.EmptyList>
                        ) : filter ? (
                            <S.EmptyList>
                                <S.EmptyTitle>
                                    {t('news.management.empty.search')}
                                </S.EmptyTitle>
                                <S.EmptyText>
                                    Sua busca por &quot;{filter}&quot; não
                                    retornou resultados.
                                </S.EmptyText>
                            </S.EmptyList>
                        ) : (
                            <S.EmptyList>
                                <S.EmptyTitle>
                                    {t('news.management.empty.search')}
                                </S.EmptyTitle>
                                <S.EmptyText>
                                    Sua busca pela categoria &quot;
                                    {select}
                                    &quot; não retornou resultados.
                                </S.EmptyText>
                            </S.EmptyList>
                        )}
                    </S.PostsContainer>
                )}
            </S.Content>
        </>
    )
}

export default Management
