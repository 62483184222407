import { combineReducers } from 'redux'
import newsReducer from './news'
import categoriesReducer from './categories'
import tagsReducer, { listTagsReducer } from './tags'

const rootReducer = combineReducers({
  news: newsReducer,
  categories: categoriesReducer,
  tags: tagsReducer,
  tagsList: listTagsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
