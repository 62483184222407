import * as styles from './styles'
import {
  ResponsiveContainer,
  ContentContainer
} from 'src/components/Containers/containers'
import { useTranslation } from 'react-i18next'
import Header from 'src/components/Header'

const PasswordHeader = () => {
  const { t } = useTranslation()

  return (
    <ResponsiveContainer color={'#2E2972'}>
      <ContentContainer>
        <styles.HeaderWrapper>
          <Header title={t('password.recover')} hasTranslation={true} />
        </styles.HeaderWrapper>
      </ContentContainer>
    </ResponsiveContainer>
  )
}

export default PasswordHeader
