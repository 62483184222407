import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const UFCImage = styled.img`
  height: auto;
  width: 80px;
  object-fit: contain;
  margin: auto;
`
