import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 24px 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px 80px 135px;
    max-width: 1440px;
    min-height: 100vh;
  }
`

export const LastNewsWrapper = styled.div`
  display: flex;
  height: 400px;
  margin-top: 60px;
`

export const Title = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  color: #2c3e51;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 36px;
    line-height: 48px;
    padding-top: 80px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    font-size: 48px;
    line-height: 48px;
  }
`

export const Link = styled(LinkR)`
  display: flex;
  width: fit-content;
  padding-top: 16px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #009fdf;

  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: transform 0.5s;

  &:hover {
    border-bottom: 1px solid #009fdf;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 13px;
    padding-top: 8px;
  }
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  background: #eef2f6;
  margin-top: 24px;
  height: 260px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    height: 230px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }

  h1 {
    padding-bottom: 12px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: rgba(0, 0, 0, 0.87);
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
  }
`

export const CategoryMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
`
