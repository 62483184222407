import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

export const Nav = styled.nav`
  position: fixed;
  width: 100%;
  background: #2e2972;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  transition: 0.8 all ease;
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1px;

  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`

export const MobileIcon = styled.div`
  color: #fec074;
  margin-left: 24px;
`

export const NavLogo = styled(LinkRouter)`
  justify-self: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DividerMobile = styled.div`
  display: flex;
  align-self: center;
  border-left: 0.5px solid rgba(255, 255, 255, 0.87);
  height: 24px;
  margin: 0px 8px;
`

export const NavTitle = styled.p`
  align-self: center;

  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.87);
`

export const LeftContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
