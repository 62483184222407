import * as styles from './styles'
import * as InfoNewsStyles from '../ViewNews/styles'

import { useState, useEffect } from 'react'

import SearchNewsSkeleton from 'src/components/Skeleton/News/Search'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/ducks/rootReducer'
import { postsRequest } from 'src/store/ducks/news/actions'
import { categoriesRequest } from 'src/store/ducks/categories/actions'
import sortByDate from 'src/utils/sortByDate'

import { CategoryContentItem } from 'src/components/NewsModule/HomeNews'

import { PUBLISHED_STATUS } from 'src/components/NewsModule/constants'
import usePersistedState from 'src/hooks/usePersistedState'

const EmptyPostList = (search: any) => {
  return (
    <styles.EmptyContainer>
      <div>
        <h1>Nenhum resultado encontrado :( </h1>
        <p>
          Sua busca por
          <b> &quot;{search.search}&quot; </b> não retornou resultados.
        </p>
        <p>Tente novamente com outros termos.</p>
        <styles.Link to={'/news/home'}>
          VOLTAR PARA A PÁGINA INICIAL
        </styles.Link>
      </div>
    </styles.EmptyContainer>
  )
}

const SearchNewsContent = (props: any) => {
  const search = props.props.match.params.text
  const [loading, setLoading] = useState(true)
  const [user, setUser] = usePersistedState('auth-token', '')

  const dispatch = useDispatch()
  const { news } = useSelector((state: RootState) => state.news)
  const { categories } = useSelector((state: RootState) => state.categories)

  const categoryCareer = categories?.find(e => e.title === 'Vaga')
  const [postList, setPostList] = useState<any>([])

  useEffect(() => {
    dispatch(postsRequest())
    dispatch(categoriesRequest())
  }, [])

  useEffect(() => {
    if (news && categoryCareer && news.length) {
      if (user) {
        setPostList(
          sortByDate(news, PUBLISHED_STATUS).filter(
            post =>
              post.status === PUBLISHED_STATUS &&
              post.newsCategoriesId !== categoryCareer.id &&
              (post.title.toLowerCase().includes(search.toLowerCase()) ||
                post.text.toLowerCase().includes(search.toLowerCase()))
          )
        )
      } else {
        setPostList(
          sortByDate(news, PUBLISHED_STATUS).filter(
            post =>
              post.audience === 'Externo' &&
              post.status === PUBLISHED_STATUS &&
              post.newsCategoriesId !== categoryCareer.id &&
              (post.title.toLowerCase().includes(search.toLowerCase()) ||
                post.text.toLowerCase().includes(search.toLowerCase()))
          )
        )
      }

      setLoading(false)
    }
  }, [news, categoryCareer, search])

  const urlCategory = (category: any) => {
    switch (category?.title) {
      case 'Blog':
        return 'blog'
      case 'Vaga':
        return 'careers'
      case 'Publicação':
        return 'publications'
      case 'Defesa':
        return 'defenses'
      case 'Evento':
        return 'events'
      case 'Prêmio':
        return 'awards'
    }
  }

  return (
    <>
      <styles.ResponsiveContainer>
        {loading ? (
          <SearchNewsSkeleton />
        ) : (
          <styles.Container>
            <InfoNewsStyles.BreadCrumbWrapper>
              <InfoNewsStyles.Careers to="/news/home">
                Home
              </InfoNewsStyles.Careers>
              <InfoNewsStyles.ChevronWrapper>
                <InfoNewsStyles.Chevron />
              </InfoNewsStyles.ChevronWrapper>
              <InfoNewsStyles.TitleCrumb>{search}</InfoNewsStyles.TitleCrumb>
            </InfoNewsStyles.BreadCrumbWrapper>
            <styles.Results>
              Mostrando {postList.length} resultados para: <b>{search}</b>
            </styles.Results>
            {postList.length > 0 ? (
              <styles.PostsWrapper>
                {postList.map((data: any) => {
                  return (
                    <styles.PostItem key={data.publishedAt}>
                      <CategoryContentItem
                        title={data.title}
                        text={data.text}
                        image={data.path}
                        alt={data.alt}
                        category={urlCategory(
                          categories?.find(
                            (e: any) => e.id === data.newsCategoriesId
                          )
                        )}
                        id={data.id}
                      />
                    </styles.PostItem>
                  )
                })}
              </styles.PostsWrapper>
            ) : (
              <EmptyPostList search={search} />
            )}
          </styles.Container>
        )}
      </styles.ResponsiveContainer>
    </>
  )
}

export default SearchNewsContent
