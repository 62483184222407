import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { Calendar } from '@styled-icons/bootstrap'
import { Link as LinkRouter } from 'react-router-dom'
import { Legend } from '../styles'
import { QuestionCircle } from '@styled-icons/fa-solid'

export const Field = styled.div`
  margin-bottom: 1.5rem;
  padding-inline: 0.5rem;
  width: 100%;

  border: none;
`

export const FieldGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-direction: row;
  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const Label = styled.label`
  /* display: block; */
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;

  color: #2c3e51;
`

export const Input = styled.input`
  padding: 12px;
  margin-top: 0.5rem;

  border: 1px solid #2e2972;
  border-radius: 13px;
  border-color: #2e2972;

  width: 100%;
  height: 52px;

  font-size: 1rem;
  color: #2c3e51;
`

export const InputText = styled(Input)``

export const InputDate = styled(Input)`
  border-radius: 4px;
  height: 42px;

  color: #2c3e51;
  background: #fff;
`

export const CalendarIcon = styled(Calendar)`
  width: 1.2rem;
  height: auto;
  color: #2c3e51;

  position: relative;
  float: right;
  right: 10px;
  bottom: 30px;
`

export const ErrorWrapper = styled.div`
  text-align: center;
  background: #ff9b9b;
  color: #3c0000;
  border-radius: 8px;

  span {
    display: block;
    padding: 1rem;
    margin-bottom: 2rem;
  }
`
export const Description = styled(Legend)`
  margin-left: 0.5rem;
  text-align: left;
`

export const Help = styled(QuestionCircle)`
  display: inline;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  height: 1rem;
  color: rgba(87, 87, 87, 0.6);

  cursor: help;

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    margin-bottom: 5px;
  }
`
