import {
  FetchTagsFailurePayload,
  FetchTagsRequest,
  FetchTagsSuccess,
  FetchTagsFailure,
  FetchTagsSuccessPayload,
  tagsTypes,
  FetchTagsListRequest,
  FetchTagsListSuccess,
  FetchTagsListFailure,
  FetchTagsListSuccessPayload,
  FetchTagsListFailurePayload,
  CreateTagInNewsRequest,
  CreateTagInNewsFailure,
  CreateTagInNewsSuccess
} from './types'

export const tagsRequest = (): FetchTagsRequest => ({
  type: tagsTypes.TAGS_REQUEST
})

export const tagsSuccess = (
  payload: FetchTagsSuccessPayload
): FetchTagsSuccess => ({
  type: tagsTypes.TAGS_SUCCESS,
  payload
})

export const tagsFailure = (
  payload: FetchTagsFailurePayload
): FetchTagsFailure => ({
  type: tagsTypes.TAGS_FAILURE,
  payload
})

export const tagsListRequest = (): FetchTagsListRequest => ({
  type: tagsTypes.TAGS_LIST_REQUEST
})

export const uniqueTagListRequest = (id: number): FetchTagsListRequest => ({
  type: tagsTypes.TAGS_LIST_REQUEST,
  payload: id
})

export const tagsListSuccess = (
  payload: FetchTagsListSuccessPayload
): FetchTagsListSuccess => ({
  type: tagsTypes.TAGS_LIST_SUCCESS,
  payload
})

export const tagsListFailure = (
  payload: FetchTagsListFailurePayload
): FetchTagsListFailure => ({
  type: tagsTypes.TAGS_LIST_FAILURE,
  payload
})

export const createTagRequest = (obj: FormData): CreateTagInNewsRequest => ({
  type: tagsTypes.CREATE_TAG_IN_NEWS_REQUEST,
  payload: obj
})

export const createTagSuccess = (
  payload: FetchTagsListSuccessPayload
): CreateTagInNewsSuccess => ({
  type: tagsTypes.CREATE_TAG_IN_NEWS_SUCCESS,
  payload
})

export const createTagFailure = (
  payload: FetchTagsListFailurePayload
): CreateTagInNewsFailure => ({
  type: tagsTypes.CREATE_TAG_IN_NEWS_FAILURE,
  payload
})
