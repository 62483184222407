import colors from './colors'
import { fonts, lineHeight, fontFamily } from './fonts'

const typography = {
  title: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.title}px`,
    color: colors.heroTitle
  },
  phasesTitle: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.title}px`,
    color: colors.phasesTitle
  },
  h1Black: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.h1}px`,
    color: colors.black
  },
  h1Blue: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.h1}px`,
    color: colors.defaultBlue
  },
  h1LightBlue: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.h1}px`,
    color: colors.lightBlue
  },
  h2White: {
    fontFamily: `${fontFamily.specific}`,
    fontSize: `${fonts.h2}rem`,
    lineHeight: `${lineHeight.h2}px`,
    color: colors.white
  },
  h2Black: {
    fontFamily: `${fontFamily.specific}`,
    fontSize: `${fonts.h2}rem`,
    lineHeight: `${lineHeight.h2}px`,
    color: colors.black
  },
  h3Black: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h3}rem`,
    lineHeight: `${lineHeight.h3}px`,
    color: colors.black
  },
  h4Blue: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h4}rem`,
    lineHeight: `${lineHeight.h4}px`,
    color: colors.defaultBlue
  },
  h4Black: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h4}rem`,
    lineHeight: `${lineHeight.h4}px`,
    color: colors.black
  },
  blueP: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.p}rem,`,
    lineHeight: `${lineHeight.p}px`,
    color: colors.defaultBlue
  },
  whiteP: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.p}rem`,
    lineHeight: `${lineHeight.p}px`,
    color: colors.white
  },
  small: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.small}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.black,
    opacity: 0.5
  },
  smallMobile: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.p}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.black,
    opacity: 0.5
  },
  buttonText: {
    fontFamily: `${fontFamily.specific}`,
    fontSize: `${fonts.small}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.white
  },
  buttonInactive: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.small}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.buttonColor
  },
  mobileButton: {
    fontFamily: `${fontFamily.specific}`,
    fontSize: `${fonts.p}rem`,
    lineHeight: `${lineHeight.p}px`,
    color: colors.white
  },
  mobileButtonInactive: {
    fontFamily: `${fontFamily.specific}`,
    fontSize: `${fonts.p}rem`,
    lineHeight: `${lineHeight.p}px`,
    color: colors.buttonColor
  },
  headerTitle: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h2}rem`,
    lineHeight: `${lineHeight.h2}px`,
    color: colors.heroTitle
  },
  h1DarkBlue: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.title}rem`,
    lineHeight: `${lineHeight.h1}px`,
    color: colors.darkBlue,
    fontWeight: 700
  },
  pBlack60: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h4}rem`,
    lineHeight: `${lineHeight.h4}px`,
    color: colors.black60,
    fontWeight: 400
  },
  pDarkBlue: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.h4}rem`,
    lineHeight: `${lineHeight.h4}px`,
    color: colors.darkBlue
  },
  pBlack: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.p}rem`,
    lineHeight: `${lineHeight.h4}px`,
    color: colors.black
  },
  pBlackMini60: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.small}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.black
  },
  pWhite: {
    fontFamily: `${fontFamily.default}`,
    fontSize: `${fonts.small}rem`,
    lineHeight: `${lineHeight.small}px`,
    color: colors.white
  },
  pBlackMini55: {
    fontFamily: `${fontFamily.default}`,
    fontSize: '12px',
    lineHeight: `${lineHeight.small}px`,
    color: colors.black55
  },
  pBlackMini87: {
    fontFamily: `${fontFamily.default}`,
    fontSize: '12px',
    lineHeight: `${lineHeight.small}px`,
    color: colors.black87
  },
  pGrayMini87: {
    fontFamily: `${fontFamily.default}`,
    fontSize: '10px',
    color: colors.gray87
  }
}

export type TypographyVariants = keyof typeof typography

export default typography
