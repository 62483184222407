import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 85%;
`

export const Wrapper = styled.div`
  width: 100%;

  .post {
    width: 100%;

    .react-loading-skeleton {
      margin-bottom: 4px;
    }
  }
`
