import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postgraduatePromoBannerLenovo } from 'src/assets/images/index'
import useMedia from 'src/hooks/useMedia'
import { minDesktopScreenWidth } from 'src/utils/breakpoints'
import ContactBox from '../../ContactBox'
import * as styles from '../../styles'
import Table, { TableBody, TableCell, TableHeader, TableRow } from '../../Table'

const PostGraduateResultsContent = (props: any) => {
  const [formSent, setFormSent] = useState(false)

  const { t } = useTranslation()
  const { width } = useMedia()

  const data = [
    { name: 'AMARILDO MAIA ROLIM FILHO' },
    { name: 'ANA PAULA ASSIS BUOSI' },
    { name: 'ANNE CAROLINE RODRIGUES PEREIRA' },
    { name: 'DANIEL DE MOURA CRUZ' },
    { name: 'DANIEL REZENDE DE BARROS' },
    { name: 'ELDER CORDEIRO' },
    { name: 'JOSE ALEXSANDRO DO NASCIMENTO SILVA' },
    { name: 'MARIA EDUARDA DE SOUSA VIRIATO' },
    { name: 'SASKYA ALVES PIMENTA' },
    { name: 'VALDECLEBIO FARRAPO COSTA' },
    { name: 'WALLISSON FREITAS DE SOUZA' }
  ]

  return (
    <>
      <styles.ResponsiveContainer>
        <styles.Container>
          <styles.BreadCrumbWrapper>
            <styles.CrumbLink to="/news/home">Home</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.CrumbLink to="/rsc">Pós-Graduação</styles.CrumbLink>
            <styles.ChevronWrapper>
              <styles.Chevron />
            </styles.ChevronWrapper>
            <styles.TitleCrumb>Resultados</styles.TitleCrumb>
          </styles.BreadCrumbWrapper>

          <styles.ContentWrapper>
            <styles.Header>
              <styles.PageTitle id="title-anchor">
                {t('news.postgraduate.sub.results.page-title')}
              </styles.PageTitle>
              <styles.PromoImg
                style={{ marginTop: '1.5rem' }}
                src={postgraduatePromoBannerLenovo}
              ></styles.PromoImg>
            </styles.Header>

            <styles.ContentContainer>
              <styles.Title>Classificação do Processo Seletivo</styles.Title>
              <styles.Paragraph style={{ marginBottom: '2rem' }}>
                A comissão de seleção do processo seletivo da Residência em
                Segurança Cibernética Edital nº 01/2022 divulga hoje, dia 6 de
                Maio de 2022, a lista definitiva dos selecionados. Confira
                abaixo:
              </styles.Paragraph>

              <styles.TitleSmaller
                style={{ textAlign: 'center', marginBottom: '.5rem' }}
              >
                {t('news.postgraduate.sub.results.table.title4')}
              </styles.TitleSmaller>
              <styles.Subtitle style={{ textAlign: 'center' }}>
                {t('news.postgraduate.sub.results.table.subtitle')}
              </styles.Subtitle>
              <Table hasBorder>
                <TableHeader>
                  <TableCell>
                    {t('news.postgraduate.sub.results.table.name')}
                  </TableCell>
                </TableHeader>
                <TableBody>
                  {data.map((person, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell textAlign="center">{person.name}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

              <styles.Separator></styles.Separator>

              <styles.Title>{t('news.postgraduate.sub.contact')}</styles.Title>
              <ContactBox email="rsc@lsbd.ufc.br"></ContactBox>
            </styles.ContentContainer>
          </styles.ContentWrapper>
        </styles.Container>
      </styles.ResponsiveContainer>
    </>
  )
}

export default PostGraduateResultsContent
