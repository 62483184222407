import styled from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'

export const TextWrapper = styled.div`
  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    p:first-child {
      font-size: 36px;
      line-height: 48px;
      padding: 24px 0 50px;
    }
    p:last-child {
      font-size: 28px;
      line-height: 33px;
    }
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    width: 35%;

    p:first-child {
      min-height: 150px;
    }

    p + p {
      margin-top: 3rem;
    }
  }
`

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 1rem 0;

  img {
    width: 100%;
    height: 100%;
  }
`
export const ToolsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  width: 35%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
    padding: 32px 0;
  }
`

export const TypographyWrapper = styled.div`
  width: 80%;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    width: 100%;
  }
`

export const Tools = styled.div`
  display: flex;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    justify-content: space-between;
  }

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    justify-content: space-around;
    width: 60%;
    height: 197px;
  }
`

export const ToolsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  img {
    @media screen and (max-width: ${maxMobileScreenWidth}px) {
      width: 64px;
      height: 64px;
    }

    @media screen and (min-width: ${minDesktopScreenWidth}px) {
      width: calc(100% / 3);
      width: 5rem;
      height: 5.375rem;
    }
  }
`

export const ToolsText = styled.div`
  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    max-width: 120px;
  }

  @media screen and (min-width: ${minDesktopScreenWidth}px) {
    /* width: 60%; */
    max-width: 140px;
    margin-top: 1rem;
    text-align: center;
  }
`

export const DividerMobile = styled.hr`
  border: 1px solid #000000;
  margin-top: 24px;
`
