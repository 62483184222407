import styled, { css } from 'styled-components'
import {
  maxMobileScreenWidth,
  minDesktopScreenWidth
} from 'src/utils/breakpoints'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { Link as LinkRouter } from 'react-router-dom'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding: 80px 24px 36px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    padding: 80px;
    max-width: 1440px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    max-width: 480px;
    padding-top: 24px;
  }

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 1440px;
    align-items: center;
    padding-top: 40px;
  }
`

export const BreadCrumbWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
  }
`

export const TitleCrumb = styled.p`
  display: flex;
  align-items: center;
`

export const Chevron = styled(ChevronRight)`
  display: flex;
  width: 15px;

  color: #000000;
`

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0 19px;
`

export const CrumbLink = styled(LinkRouter)`
  display: flex;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.62%;

  color: #009fdf;
  cursor: pointer;
  text-decoration: none;
`

export const Header = styled.header`
  margin-bottom: 3.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const PageTitle = styled.h1`
  font-family: Rubik;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 38px;
  color: #2c3e51;
  margin-bottom: 2rem;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 32px;
    line-height: 36px;
  }
`

export const TitleContainer = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const TitleStyle = css`
  font-family: Rubik;
  font-style: normal;
  color: #2c3e51;
`

export const Title = styled.h3`
  ${TitleStyle}
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 32px;
    line-height: 36px;
  }
`

export const TitleSmaller = styled.h4`
  ${TitleStyle}
  margin-bottom: 4rem;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const Subtitle = styled.h5`
  ${TitleStyle}
  margin-bottom: 4rem;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #2c3e51b5;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    font-size: 14px;
    line-height: 26px;
  }
`

export const TabTitle = styled.h2`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #2c3e51;
  text-transform: uppercase;

  margin-bottom: 1rem;
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding-top: 18px;
  }
  @media only screen and (min-width: ${minDesktopScreenWidth}px) {
    max-width: 700px;
  }
`

export const Paragraph = styled.p`
  margin-bottom: 2rem;
`

export const Separator = styled.hr`
  margin-top: 2rem;
  margin-bottom: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
`

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 260px;
  height: 60px;

  border-radius: 30px;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ButtonLink = styled.a`
  text-decoration: none;
  white-space: nowrap;
`
export const BasicList = styled.ul`
  list-style: none;

  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    strong {
      font-weight: 600;
    }
  }
`

export const DotList = styled.ul`
  margin-bottom: 2rem;

  list-style-position: inside;
  padding-inline-start: 1rem;

  li {
    line-height: 26px;

    strong {
      font-weight: 600;
    }

    &::marker {
      font-size: 12px;
    }
  }
`

export const CardGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  padding: 4rem 2rem;
  width: 330px;
  text-align: center;

  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.12);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: ${maxMobileScreenWidth}px) {
    padding: 0;
    box-shadow: none;
    width: 100%;
    margin-bottom: 4rem;
  }
`

export const Warning = styled.div`
  background: #ffb76a;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 32px;
`

export const WarningTitle = styled.strong`
  text-transform: uppercase;
`

export const PromoImg = styled.img`
  max-height: 700px;
  height: auto;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: ${maxMobileScreenWidth}px) {
    height: 65px;
    object-position: right;
  }
`
