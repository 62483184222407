import React, { useEffect } from 'react'
import Awards from 'src/components/Awards'
import Expertise from 'src/components/Expertise'
import Development from 'src/components/Development'
import Footer from 'src/components/Footer'
import { maxMobileScreenWidth } from 'src/utils/breakpoints'
import NavbarMobile from 'src/components/Mobile/Navbar'
import Navbar from 'src/components/Navbar'
import useMedia from 'src/hooks/useMedia'
import logoutQueryString from '../utils/logoutQueryString'

const Hero = React.lazy(
    () => import(/* webpackChunkName: "Hero" */ 'src/components/Hero')
)

const LaboratoryInfo = React.lazy(
    () =>
        import(
            /* webpackChunkName: "LaboratoryInfo" */ 'src/components/LaboratoryInfo'
        )
)
const Contact = React.lazy(
    () => import(/* webpackChunkName: "Contact" */ 'src/components/Contact')
)
const PartnersCompanies = React.lazy(
    () =>
        import(
            /* webpackChunkName: "PartnersCompanies" */ 'src/components/Partners/companies'
        )
)
const AcademicsPartners = React.lazy(
    () =>
        import(
            /* webpackChunkName: "AcademicsPartners" */ 'src/components/Partners'
        )
)

const Home = () => {
    const { width } = useMedia()
    const [isOpen, setIsOpen] = React.useState(false)

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
      logoutQueryString()
    }, [])

    return (
        <>
            <React.Suspense fallback="loading..">
                {width <= maxMobileScreenWidth ? (
                    <NavbarMobile isOpen={isOpen} toggle={toggleSidebar} />
                ) : (
                    <Navbar />
                )}
                <Hero />
                <Awards />
                <LaboratoryInfo />
                <Expertise />
                <AcademicsPartners />
                <Development />
                <PartnersCompanies />
                <Contact />
                <Footer />
            </React.Suspense>
        </>
    )
}

export default Home
