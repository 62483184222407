import { useState } from 'react'
import * as styles from './styles'
import ChevronDownBlue from 'src/assets/icons/general/chevronDownBlue.svg'

type OrderBySelectProps = {
    list: Array<any>
    setValue: any
    currentValue: any
}

const OrderBySelect = ({
    list,
    currentValue,
    setValue
}: OrderBySelectProps) => {
    const [filterIsOpen, setFilterIsOpen] = useState(false)

    const toggleFilter = () => setFilterIsOpen(!filterIsOpen)

    const onOptionClicked = (value: any) => () => {
        setValue(value)
        setFilterIsOpen(false)
    }

    return (
        <styles.FilterWrapper>
            <styles.DropDownHeader isOpen={filterIsOpen} onClick={toggleFilter}>
                {currentValue.value ? currentValue.label : 'Ordenar por'}
                <img src={ChevronDownBlue} />
            </styles.DropDownHeader>
            {filterIsOpen && (
                <styles.DropDownListContainer>
                    <styles.DropDownList>
                        {list.map((item: any, index: number) => (
                            <styles.ListItem
                                onClick={onOptionClicked(item)}
                                key={index}
                                isTheLast={index === list.length - 1}
                            >
                                {item.label}
                            </styles.ListItem>
                        ))}
                    </styles.DropDownList>
                </styles.DropDownListContainer>
            )}
        </styles.FilterWrapper>
    )
}

export default OrderBySelect
