import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ThreeDots } from '@styled-icons/bootstrap'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'

import * as S from './styles'

import KebabButton from 'src/components/Button/KebabButton'
import Modal from 'src/components/Modal'
import DeleteModal from 'src/components/Modal/DeleteModal'

import { webApi } from 'src/services/api/config'

import { postsRequest } from 'src/store/ducks/news/actions'

import { formatDateToBack } from 'src/utils/Date/formatDateToBack'
import { showToast } from 'src/utils/Toast'

import { CLOSED_STATUS } from 'src/components/NewsModule/constants'

const CareersDropdown = (props: any) => {
  const { id, post, canEdit, canDelete } = props
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3)

  const [openModalClosed, setOpenModalClosed] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [job, setJob] = useState<any>({})
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const deleteNews = () => {
    setOpenModal(!openModal)
    setOpenDeleteModal(!openDeleteModal)
  }

  const closeJob = () => {
    const formData = new FormData()
    formData.append('news.id', job.id ? job.id : '')
    formData.append(
      'news.lastEdit',
      job.lastEdit ? formatDateToBack(new Date()) : ''
    )
    const editJob = async () => {
      // TODO: Transfer this request to API layer
      await webApi
        .put('api/site/news/closed', formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if (response.status === 201) {
            setOpenModalClosed(!openModalClosed)
            showToast({
              type: 'success',
              message: 'Vaga encerrada com sucesso'
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          dispatch(postsRequest())
        })
    }
    if (job.status === CLOSED_STATUS) {
      setOpenModalClosed(!openModalClosed)
      showToast({
        type: 'error',
        message: t('news.careers.encerrado-erro')
      })
    } else {
      editJob()
    }
  }

  useEffect(() => {
    setJob(post)
  }, [post])

  return (
    <>
      <DeleteModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        title={'Deletar vaga?'}
        text={
          'Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
        }
        id={id}
        category={'Vaga'}
      />
      <Modal
        showModal={openModalClosed}
        setShowModal={setOpenModalClosed}
        buttonLeft={() => setOpenModalClosed(!openModalClosed)}
        buttonRight={() => closeJob()}
        title={'Encerrar vaga?'}
        description={
          'Você deseja encerrar a vaga? Ao continuar, o sistema irá ocultar a noticía da publicação da vaga.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'encerrar'}
      />
      <Modal
        showModal={openModal}
        setShowModal={setOpenModal}
        buttonLeft={() => setOpenModal(!openModal)}
        buttonRight={() => deleteNews()}
        title={'Deletar vaga?'}
        description={
          'Você tem certeza que deseja deletar a vaga permanentemente? Ao continuar, a vaga não estará mais disponível para os leitores e não poderá ser restaurada.'
        }
        titleButtonLeft={'cancelar'}
        titleButtonRight={'avançar'}
      />
      <S.DropdownContainer>
        <KebabButton {...buttonProps}>
          <ThreeDots size="30" />
        </KebabButton>
        <div className={`dropdown ${isOpen ? 'visible' : ''}`} role="menu">
          {canEdit ? (
            <a {...itemProps[0]} href={`/news/edit-news/${id}`}>
              Editar Vaga
            </a>
          ) : (
            <></>
          )}

          {job.status === CLOSED_STATUS ? (
            <></>
          ) : canEdit ? (
            <a
              {...itemProps[1]}
              onClick={() => setOpenModalClosed(!openModalClosed)}
            >
              Encerrar Vaga
            </a>
          ) : (
            <></>
          )}

          {canDelete ? (
            <a {...itemProps[2]} onClick={() => setOpenModal(!openModal)}>
              Deletar
            </a>
          ) : (
            <></>
          )}
        </div>
      </S.DropdownContainer>
    </>
  )
}

export default CareersDropdown
