import * as styles from './styles'

import { useTranslation } from 'react-i18next'

import Divider from 'src/assets/developmentBar.svg'
import Javascript from 'src/assets/icons/development/javascript.svg'
import Python from 'src/assets/icons/development/python.svg'
import ReactIcon from 'src/assets/icons/development/react.svg'
import Qt from 'src/assets/icons/development/qt.svg'
import Cplusplus from 'src/assets/icons/development/cplus.svg'
import Flutter from 'src/assets/icons/development/flutter.svg'

import Typography from 'src/components/Typography'

import useMedia from 'src/hooks/useMedia'

import { maxMobileScreenWidth } from 'src/utils/breakpoints'

const Technology = () => {
  const { t } = useTranslation()
  const { width } = useMedia()

  return (
    <>
      <styles.TextWrapper>
        <Typography variant="h1Blue" style={{ fontWeight: 'bold' }}>
          {t('desenvolvimento.tecnologias.titulo')}
        </Typography>
        <Typography variant="h1LightBlue" style={{ fontWeight: 'bold' }}>
          {t('desenvolvimento.tecnologias.steps')}
        </Typography>
      </styles.TextWrapper>
      {width < maxMobileScreenWidth ? (
        <styles.DividerMobile />
      ) : (
        <styles.BarWrapper>
          <img src={Divider} />
        </styles.BarWrapper>
      )}
      <styles.TechnologiesWrapper>
        <styles.Text>
          <styles.TypographyWrapper>
            <Typography variant="blueP">
              {t('desenvolvimento.tecnologias.descricao')}
            </Typography>
          </styles.TypographyWrapper>
        </styles.Text>
        <styles.Technologies>
          <styles.TechnologyItem>
            <img src={Javascript} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Javascript
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
          <styles.TechnologyItem>
            <img src={Python} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Python
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
          <styles.TechnologyItem>
            <img src={ReactIcon} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                React
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
          <styles.TechnologyItem>
            <img src={Qt} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                QT
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
          <styles.TechnologyItem>
            <img src={Cplusplus} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                C++
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
          <styles.TechnologyItem>
            <img src={Flutter} />
            <styles.TechnologyText>
              <Typography variant="h4Blue" style={{ fontWeight: 'bold' }}>
                Flutter
              </Typography>
            </styles.TechnologyText>
          </styles.TechnologyItem>
        </styles.Technologies>
      </styles.TechnologiesWrapper>
    </>
  )
}

export default Technology
