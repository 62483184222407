import React from 'react'
import * as styles from './styles'
import Spinner from 'src/components/Spinner'

type GenericButtonProps = {
    backgroundColor: string
    textColor: string
    children?: React.ReactNode
    onClick: () => void
    size: string
    margin?: string
    loading?: boolean
    disabled?: boolean
}

const GenericButton = ({
    backgroundColor,
    textColor,
    children,
    onClick,
    size,
    margin,
    loading,
    disabled
}: GenericButtonProps) => {
    return (
        <styles.GenericButton
            disabled={disabled || loading}
            isDisabled={disabled || false}
            onClick={onClick}
            backgroundColor={disabled ? '#797979' : backgroundColor}
            textColor={disabled ? '#ffffff' : textColor}
            size={size}
            margin={margin || '0'}
        >
            {loading ? (
                <Spinner heightContainer="100%" size={'15px'} />
            ) : (
                children
            )}
        </styles.GenericButton>
    )
}

export default GenericButton
