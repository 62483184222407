import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
    postsFailure,
    postsSuccess,
    createSuccess,
    createFailure,
    deleteSuccess,
    deleteFailure
} from './actions'
import {
    postTypes,
    CreatePostRequest,
    DeletePostRequest
    // FetchPostsRequestUnique
} from './types'
import { webApi } from 'src/services/api/config'
import { AxiosResponse } from 'axios'

const getNews = () => webApi.get('api/site/news')

function* fetchPostsSaga() {
    try {
        const response: AxiosResponse = yield call(getNews)
        yield put(
            postsSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(postsFailure({ error: err.message }))
        }
    }
}

// function* fetchUniqueNews(action: FetchPostsRequestUnique) {
//   try {
//     const response: AxiosResponse = yield call(
//       api.get,
//       `api/site/news/:${action.payload}`
//     )
//     yield put(
//       postsSuccess({
//         data: response.data
//       })
//     )
//   } catch (err) {
//     if (err instanceof Error) {
//       yield put(postsFailure({ error: err.message }))
//     }
//   }
// }

function* createNewsSaga(action: CreatePostRequest) {
    try {
        const response: AxiosResponse = yield call(
            webApi.post,
            'api/site/news',
            action.payload,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        yield put(
            createSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(createFailure({ error: err.message }))
        }
    }
}

function* deletePost(action: DeletePostRequest) {
    try {
        const response: AxiosResponse = yield call(
            webApi.delete,
            `api/site/news/${action.payload}`
        )
        yield put(
            deleteSuccess({
                data: response.data
            })
        )
    } catch (err) {
        if (err instanceof Error) {
            yield put(deleteFailure({ error: err.message }))
        }
    }
}

function* postsSaga() {
    yield all([
        takeLatest(postTypes.POST_REQUEST, fetchPostsSaga),
        // takeLatest(postTypes.POST_REQUEST_UNIQUE_NEWS, fetchUniqueNews),
        takeLatest(postTypes.CREATE_REQUEST, createNewsSaga),
        takeLatest(postTypes.DELETE_REQUEST, deletePost)
    ])
}

export default postsSaga
