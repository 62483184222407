import axios from 'axios'

const subscriptionClosed = async (): Promise<boolean | null> => {
  let rs = null

  await axios
    .get('https://worldtimeapi.org/api/timezone/America/Fortaleza')
    .then(response => {
      if (response.data) {
        const now = new Date(response.data.datetime)
        // 23/04/2022 às 12:00
        const date = new Date(Date.UTC(2022, 3, 23, 3, 0))

        rs = now >= date
      }
    })

  return rs
}

export default subscriptionClosed
